import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IOysterTheme } from '@theme/index';

export interface IUseStyles {
  variant: IOysterTheme;
  opacity: number;
  fontSize: number;
  iconSize: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBar: {
      width: '100%',
      display: 'flex',
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 100,
    },
    btnWithText: (props: IUseStyles) => ({
      fontSize: props.fontSize,
      fontWeight: 600,
      color: theme.oysterTheme[props.variant],
      opacity: props.opacity,
      backgroundColor: 'transparent',
      fontFamily: 'Montserrat',
      border: 0,
      '& svg': {
        color: theme.oysterTheme[props.variant],
        fontSize: `${props.iconSize}px !important`,
      },
    }),
    btnWithTextSpan: {
      paddingLeft: 4,
    },
    btn: (props: IUseStyles) => ({
      cursor: 'pointer',
      fontWeight: 600,
      padding: 6,
      color: theme.oysterTheme[props.variant],
      opacity: props.opacity,
      '& svg': {
        color: theme.oysterTheme[props.variant],
        fontSize: `${props.iconSize}px !important`,
      },
    }),
    rightComponent: {
      flexGrow: 1,
      textAlign: 'right',
    },
  }),
);

export default useStyles;
