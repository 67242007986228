import { FC } from 'react';
import ReactNumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

interface NumberFormatCustomProps extends Omit<NumberFormatProps, 'onChange'> {
  onChange?: (event: { target: { name?: string; value: string } }) => void;
  inputRef?: NumberFormatProps['getInputRef'];
}

const NumberFormat: FC<NumberFormatCustomProps> = ({ inputRef, onChange, name, ...other }) => {
  return (
    <ReactNumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          });
        }
      }}
      isNumericString
    />
  );
};

export default NumberFormat as FC<InputBaseComponentProps>;
