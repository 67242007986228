import { FC } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Input from '../Input';
import useStyles from './styles';

type SelectOptionProps = Record<string, string | number>;

export type SelectProps = TextFieldProps & {
  isAdornment?: boolean;
  valueKey?: keyof SelectOptionProps;
  labelKey?: keyof SelectOptionProps;
  options: SelectOptionProps[];
  'data-testid'?: string;
};

interface ArrowDownProps {
  className: string;
}

export const ArrowDown: FC<ArrowDownProps> = ({ className, ...rest }) => {
  const theme = useTheme();
  return (
    <KeyboardArrowDownIcon
      {...rest}
      className={className}
      style={{
        color: theme.oysterTheme.oyster,
      }}
    />
  );
};

export const SelectInput: FC<SelectProps> = ({
  options,
  valueKey = 'value',
  labelKey = 'label',
  isAdornment,
  ...restProps
}) => {
  const css = useStyles({ isAdornment });

  return (
    <Input
      {...restProps}
      select
      SelectProps={{
        IconComponent: ArrowDown,
        classes: {
          root: isAdornment ? css.rootAdornment : css.root,
          select: css.select,
        },
      }}
    >
      {options?.map((opt) => (
        <MenuItem
          key={opt[valueKey]}
          data-testid={opt[valueKey]}
          value={opt[valueKey]}
          className={css.item}
        >
          {opt[labelKey]}
        </MenuItem>
      ))}
    </Input>
  );
};

export default SelectInput;
