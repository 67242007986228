import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  separator: {
    width: '100%',
    borderBottom: `solid 1px ${theme.oysterTheme.stone}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default styles;
