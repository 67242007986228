import { cast, Instance, types } from 'mobx-state-tree';
import { PaymentOrderFilterStatus } from '@constants/paymentOrder';
import Pagination from '../pagination';

const PaymentOrderFilter = types
  .compose(
    Pagination,
    types.model({
      status: types.optional(
        types.enumeration<PaymentOrderFilterStatus>(Object.values(PaymentOrderFilterStatus)),
        PaymentOrderFilterStatus.ALL,
      ),
    }),
  )
  .actions((self) => ({
    setStatus: (status: PaymentOrderFilterStatus) => {
      self.status = cast(status);
    },
  }));

export type IPaymentOrderFilter = Instance<typeof PaymentOrderFilter>;
export default PaymentOrderFilter;
