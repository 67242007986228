export const SETTINGS_TYPES = {
  PAYMENT_LINK_REMINDERS: 'PAYMENT_LINK_REMINDERS',
  INVOICES: 'INVOICES',
  COBRANDING: 'PAYMENT_LINK_COBRANDING',
} as const;

export const REMINDERS = [
  '-7d',
  '-6d',
  '-5d',
  '-4d',
  '-3d',
  '-2d',
  '-1d',
  '0d',
  '1d',
  '2d',
  '3d',
  '4d',
  '5d',
  '6d',
  '7d',
] as const;

export enum Option {
  notifications = 'notifications',
  invoices = 'invoices',
}
