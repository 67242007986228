import { Instance, getSnapshot, flow, types, applySnapshot } from 'mobx-state-tree';

import Common from '../common';
import { errors } from '../constants';

import { PhoneModel } from '../business';
import BeneficiariesService from './service';

export interface IVolatileState {
  service: null | BeneficiariesService;
}

export const Beneficiary = types
  .model({
    beneficiaryId: types.string,
    fullName: types.string,
    fullAddress: types.optional(types.string, ''),
    inheritancePercent: types.optional(types.number, 0),
    businessId: types.string,
    createdAt: types.string,
    relation: types.string,
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    secondLastName: types.optional(types.string, ''),
    birthdate: types.optional(types.string, ''),
    phone: PhoneModel,
    phoneStr: types.string,
  })
  .views((self) => ({
    get assembleFullName(): string {
      return `${self.firstName} ${self.lastName} ${self.secondLastName}`;
    },
  }));

const BeneficiariesStore = types
  .compose(
    Common,
    types.model({
      selectedBeneficiary: -1,
      submitting: false,
      success: false,
      all: types.array(Beneficiary),
    }),
  )
  .volatile(
    (): IVolatileState => ({
      service: null,
    }),
  )
  .views((self) => ({
    get allBeneficiaries() {
      return getSnapshot(self.all);
    },
    get totalPercentage() {
      return self.all.reduce((accumulator: number, beneficiary: TBeneficiary) => {
        return beneficiary.inheritancePercent + accumulator;
      }, 0);
    },
    get beneficiaryCount() {
      return self.all.length || 0;
    },
  }))
  .actions((self) => ({
    updateService: flow(function* () {
      self.service = new BeneficiariesService();
      yield Promise.resolve('ok');
    }),
    resetBeneficiaries: () => {
      applySnapshot(self.all, []);
    },
    pushBeneficiary: (data: TBeneficiary) => {
      self.all.push(
        Beneficiary.create({
          beneficiaryId: data.beneficiaryId || '',
          fullName: data.fullName || '',
          fullAddress: data.fullAddress || '',
          inheritancePercent: data.inheritancePercent || 0,
          businessId: data.businessId || '',
          createdAt: data.createdAt || '',
          relation: data.relation || '',
          firstName: !data.firstName ? data.fullName : data.firstName || '',
          lastName: data.lastName || '',
          secondLastName: data.secondLastName || '',
          birthdate: data.birthdate || '',
          phoneStr: data.phone.fullPhoneNumber || '',
          phone: PhoneModel.create({
            valid: true,
            countryCode: data.phone.countryCode,
            fullPhoneNumber: data.phone.fullPhoneNumber,
            phone: data.phone.phone,
          }),
        }),
      );
    },
  }))
  .actions((self) => ({
    toggleBeneficiary: (idx: number) => (self.selectedBeneficiary = idx),
    getBeneficiaries: flow(function* () {
      if (!self.service) return yield Promise.reject(errors.SERVICE_ERROR);

      try {
        self.resetBeneficiaries();
        self.submitting = true;
        const { data } = yield self.service.getBeneficiaries();
        const { beneficiaries: all } = data;

        all.forEach((row: TBeneficiary) => self.pushBeneficiary(row));
      } catch (error) {
        self.setError({
          type: errors.BENEFICIARIES,
          msg: JSON.stringify(error),
        });
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TBeneficiary = Instance<typeof Beneficiary>;
export type TBeneficiariesStore = Instance<typeof BeneficiariesStore>;

export default BeneficiariesStore;
