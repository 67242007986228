import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    drawerContent: {
      padding: '0 28px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    detailsLabel: { color: theme.oysterTheme.oyster, fontSize: 16 },
    batchLabel: {
      color: theme.oysterTheme.peacock,
      fontSize: 24,
      marginBottom: 27,
      '& span': { fontWeight: '400' },
    },
    stepperDrawer: {
      padding: '16px 0',
      borderTop: `1px solid ${theme.oysterTheme.stone}`,
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
    },

    infoActionsContainer: {
      marginTop: 48,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    divider: { borderColor: theme.oysterTheme.pearl, height: '4px' },
    seeDetailsButton: { color: theme.oysterTheme.pine },
    cancelButton: { color: theme.oysterTheme.pine, fontWeight: 400 },
    optionalText: {
      marginTop: 0,
      marginBottom: 40,
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
    },
    buttonsContainer: { paddingBottom: 45 },

    // FundingRequest
    totalAmountLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 19,
      '& span': { fontSize: 26, color: theme.oysterTheme.pine },
    },
    fundingRequestContainer: {
      marginTop: 34,
    },
    bankData: {
      marginTop: 42,
      '& > div > div:first-child': { fontWeight: 500 },
      marginBottom: 40,
    },
    sharingLabel: { fontSize: 18, fontWeight: 700, color: theme.oysterTheme.pine, marginTop: 42 },
    helpEmailFormat: {
      display: 'block',
      color: theme.oysterTheme.shark,
      marginBottom: 8,
    },
    sendDataInput: { marginBottom: 30 },
    sendEmailButton: {
      minWidth: 'auto',
      fontSize: 14,
      boxShadow: 'none',
      backgroundColor: theme.oysterTheme.oyster,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'rgb(21, 150, 121)',
      },
    },
  });
};

export default makeStyles(styles);
