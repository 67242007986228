import { FC, MouseEvent, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import clsx from 'clsx';

import BackIcon from '@icons/Arrows/Left';
import BackTransparentIcon from '@icons/Arrows/LeftTransparent';
import CloseIcon from '@icons/Close';
import CloseTransparentIcon from '@icons/CloseTransparent';

import useStyles, { IUseStyles } from './styles';

export enum IconVariants {
  BACK = 'back',
  CLOSE = 'close',
  CLOSE_TRANSPARENT = 'close-transparent',
  BACK_TRANSPARENT = 'back-transparent',
}

export interface IBackPageButton extends Partial<IUseStyles> {
  onClick: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  buttonText?: string;
  icon?: IconVariants;
  rightComponent?: ReactNode;
  className?: string;
  fontSize?: number;
  iconSize?: number;
}

const BackPageButton: FC<IBackPageButton> = ({
  buttonText,
  onClick,
  className,
  icon = 'back',
  variant = 'oyster',
  opacity = 1,
  fontSize = 14,
  iconSize = 18,
  ...restProps
}) => {
  const css = useStyles({ variant, opacity, fontSize, iconSize });
  let Icon = icon === IconVariants.BACK ? BackIcon : CloseIcon;
  if (icon === IconVariants.CLOSE_TRANSPARENT) Icon = CloseTransparentIcon;
  if (icon === IconVariants.BACK_TRANSPARENT) Icon = BackTransparentIcon;

  return (
    <nav className={clsx(css.backBar, className)} data-testid="backPageButton" {...restProps}>
      {!buttonText ? (
        <IconButton
          className={css.btn}
          onClick={onClick}
          type="button"
          data-testid="backPageBtnIcon"
        >
          <Icon data-testid={`icon-${icon}`} />
        </IconButton>
      ) : (
        <Button
          disableElevation
          className={css.btnWithText}
          startIcon={<Icon data-testid={`icon-${icon}`} />}
          onClick={onClick}
          data-testid="backPageBtnIcon"
        >
          <span className={css.btnWithTextSpan}>{buttonText}</span>
        </Button>
      )}
    </nav>
  );
};

export default BackPageButton;
