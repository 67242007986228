import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';

import CustomDrawer from '@components/CustomDrawer';
import RefundTicket from '@components/Tickets/Refund';
import DepositTicket from '@components/Tickets/Deposit';
import PaymentLinkTicket from '@components/Tickets/PaymentLink';

type TicketType = 'deposit' | 'link' | 'refund';

type UseTicket = (type: TicketType) => (id: string) => void;

const ticketResolver = (type: TicketType, t: TFunction) => {
  switch (type) {
    case 'deposit':
      return { title: t('collect.checkout.ticket.title'), Component: DepositTicket };
    case 'link':
      return { title: t('collect.checkout.ticket.title'), Component: PaymentLinkTicket };
    case 'refund':
      return { title: t('collect.refund.ticket.title'), Component: RefundTicket };
  }
};

const useTicket: UseTicket = (type) => {
  const { t } = useTranslation();
  const { showDrawer } = useDrawer();
  const { title, Component } = ticketResolver(type, t);

  return (id) => {
    showDrawer({
      component: () => (
        <CustomDrawer title={title}>
          <Component id={id} />
        </CustomDrawer>
      ),
      options: {
        useCustomDrawer: true,
        useDefaultWidth: true,
      },
    });
  };
};

export default useTicket;
