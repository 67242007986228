import { FC } from 'react';
import { IconProps, obsidian } from './types';

const PayCardIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H5.64216L5.00129 17.7127C4.97249 18.284 5.42797 18.763 6.00003 18.763H7.94814L8.17831 5H10.2363L10.4481 18.763H17.8967C18.4687 18.763 18.9242 18.284 18.8954 17.7127L18.2545 5H21C21.5523 5 22 4.55228 22 4C22 3.44772 21.5523 3 21 3H3ZM17.065 15.1872C17.065 14.6437 16.6265 14.2066 16.0856 14.2109C15.5446 14.2153 15.1061 14.6594 15.1061 15.2028C15.1061 15.519 15.2545 15.7991 15.4851 15.9773C15.2863 16.159 15.1613 16.4208 15.1613 16.711C15.1613 17.2545 15.5998 17.6915 16.1408 17.6872C16.6817 17.6829 17.1203 17.2388 17.1203 16.6953C17.1203 16.3791 16.9719 16.099 16.7412 15.9208C16.9401 15.7391 17.065 15.4773 17.065 15.1872Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

PayCardIcon.defaultProps = {
  fill: obsidian,
};

export default PayCardIcon;
