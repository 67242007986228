import { FC } from 'react';

import PaidIcon from '@yaydoo/react-components/lib/icons/Arrows/Incoming';
import CalendarMonthIcon from '@yaydoo/react-components/lib/icons/CalendarMonth';
import CloseIcon from '@yaydoo/react-components/lib/icons/Close';
import AllIcon from '@yaydoo/react-components/lib/icons/Sales';
import PendingIcon from '@yaydoo/react-components/lib/icons/Unpaid';

import Filter from '@components/Filter';
import { BatchFilterCreatedAt, BatchFilterStatus } from '@constants/batch';
import { IconProps } from '@yaydoo/react-components/lib/icons/types';
import { useTranslation } from 'react-i18next';

export interface IBatchFilters {
  isMobile: boolean;
  currentFilterStatus: BatchFilterStatus;
  currentFiltercreatedAt: BatchFilterCreatedAt;
  onChangeFilterStatus: (newStatus: BatchFilterStatus, filter: string) => void;
  onChangeFiltercreatedAt: (newcreatedAt: BatchFilterCreatedAt, filter: string) => void;
}

const BatchFilters: FC<IBatchFilters> = ({
  isMobile,
  currentFilterStatus,
  currentFiltercreatedAt,
  onChangeFilterStatus,
  onChangeFiltercreatedAt,
}) => {
  const { t } = useTranslation();
  const statusOptions = [
    {
      label: t('batch.dashboard.filter.status.all'),
      icon: AllIcon,
      value: BatchFilterStatus.ALL,
    },
    {
      label: t('batch.dashboard.filter.status.pending'),
      icon: PendingIcon,
      value: BatchFilterStatus.PENDING,
    },
    {
      label: t('batch.dashboard.filter.status.dispersed'),
      icon: PaidIcon,
      value: BatchFilterStatus.DISPERSED,
    },
    {
      label: t('batch.dashboard.filter.status.partiallyDispersed'),
      icon: (props: IconProps) => <CloseIcon style={{ fontSize: 15 }} {...props} />,
      value: BatchFilterStatus.PARTIALLY_DISPERSED,
      hide: false,
    },
  ].filter(({ hide }) => !hide);

  const dueDateOptions = [
    {
      label: t('batch.dashboard.filter.createdAt.currentMonth'),
      icon: CalendarMonthIcon,
      value: BatchFilterCreatedAt.CURRENT_MONTH,
    },
    {
      label: t('batch.dashboard.filter.createdAt.lastMonth'),
      icon: CalendarMonthIcon,
      value: BatchFilterCreatedAt.LAST_MONTH,
    },
    {
      label: t('batch.dashboard.filter.createdAt.lastThreeMonths'),
      icon: CalendarMonthIcon,
      value: BatchFilterCreatedAt.LAST_THREE_MONTHS,
    },
    {
      label: t('batch.dashboard.filter.createdAt.LastSixMonths'),
      icon: CalendarMonthIcon,
      value: BatchFilterCreatedAt.LAST_SIX_MONTHS,
      adujstWidth: true,
      hide: false,
    },
  ].filter(({ hide }) => !hide);

  return (
    <>
      <Filter
        isMobile={isMobile}
        filterLabel={t('batch.dashboard.filter.status.title')}
        filterName="status"
        showDivider={true}
        currentFilterValue={currentFilterStatus}
        filterOptions={statusOptions}
        onFilterChange={onChangeFilterStatus}
      />
      <Filter
        isMobile={isMobile}
        filterLabel={t('batch.dashboard.filter.createdAt.title')}
        filterName="createdAt"
        showDivider={false}
        currentFilterValue={currentFiltercreatedAt}
        filterOptions={dueDateOptions}
        onFilterChange={onChangeFiltercreatedAt}
      />
    </>
  );
};

export default BatchFilters;
