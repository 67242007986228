import { FC } from 'react';
import { IconProps, obsidian } from '../types';

const IncomingIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4C18.4477 4 18 4.44772 18 5V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM10.6539 16.5287V14.4084H4.5C4.22386 14.4084 4 14.1846 4 13.9084V10.0884C4 9.8123 4.22386 9.58844 4.5 9.58844H10.6539V7.46819C10.6547 7.37645 10.6803 7.28695 10.7277 7.2106C10.775 7.13425 10.8421 7.07436 10.9205 7.03822C10.999 7.00208 11.0856 6.99126 11.1697 7.00708C11.2539 7.0229 11.3319 7.06468 11.3943 7.12731L15.8636 11.6576C15.9067 11.7011 15.941 11.7537 15.9645 11.812C15.9879 11.8703 16 11.9332 16 11.9967C16 12.0603 15.9879 12.1231 15.9645 12.1815C15.941 12.2398 15.9067 12.2923 15.8636 12.3359L11.399 16.8679C11.3369 16.9321 11.2586 16.9753 11.1739 16.9921C11.0891 17.009 11.0016 16.9987 10.9222 16.9625C10.8429 16.9264 10.7751 16.866 10.7274 16.7889C10.6797 16.7118 10.6541 16.6213 10.6539 16.5287Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

IncomingIcon.defaultProps = {
  fill: obsidian,
};

export default IncomingIcon;
