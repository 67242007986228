import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnDrop, UploadState } from '@yaydoo/react-components/lib/components/Uploader/types';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import { IUploadBatch } from '@components/UploadBatch';
import UploadBatchDrawer from '@components/UploadBatchDrawer';
import { BATCH_URL as batchUrl } from '@constants/csv';
import useStore from '@hooks/useStore';
import { formatDate } from '@utils/format';
import { getAccountsPayableUrl } from '@utils/getAccountsPayableUrl';
import getAssetsUrl from '@utils/getAssetsUrl';

export interface IUseCreateBatch {
  openCreateBatchDrawer: () => void;
}

function useCreateBatch(): IUseCreateBatch {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { showDrawer } = useDrawer();
  const environmentVars = useEnvironmentVars();
  const { batch } = useStore();
  const { flags, lastBatchCreated } = batch;
  const { isSubmitting } = flags;
  const { accountsPayableLinkId, createdAt, metadata } = lastBatchCreated;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileContent, setFileContent] = useState<string | undefined>(undefined);
  const [uploadState, setUploadState] = useState(UploadState.default);
  const canSubmit = fileContent && !isSubmitting;
  const MAX_FILE_SIZE = 1000 * 1024; // 1000kb

  const successHandler = () => {
    openToast({
      type: 'success',
      title: t('batch.dashboard.createBatch.toast.success.title'),
      message: t('batch.dashboard.createBatch.toast.success.msg'),
    });
    setFile(undefined);
    setFileContent(undefined);
    setUploadState(UploadState.default);
    batch.setForceRefreshList(true);
  };

  const errorHandler = (message?: string) => {
    openToast({
      type: 'error',
      title: t('batch.dashboard.createBatch.toast.error.title'),
      message: message || t('batch.dashboard.createBatch.toast.error.msg'),
    });
  };

  const handleSubmitBatch = async () => {
    if (!canSubmit) return;
    try {
      await batch.uploadBatchFile(fileContent);
      successHandler();
    } catch (error) {
      errorHandler((error as { message: string }).message);
    }
  };

  const handleUploadFile = ({ files: addedFiles, rejectedFiles: addedRejectedFiles }: OnDrop) => {
    if (addedRejectedFiles?.length) {
      setFileContent(undefined);
      let sizeError = '';
      addedRejectedFiles[0].errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          sizeError = t('batch.dashboard.createBatch.fileSizeLimitExceeded', {
            maxFileSize: `${MAX_FILE_SIZE / 1024 / 1000} MB`,
          });
        }
      });
      errorHandler(sizeError);
      return;
    }
    if (!addedFiles[0]) return;

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const content = reader.result as string;
        setFileContent(content);
      },
      false,
    );

    setFile(addedFiles[0]);
    reader.readAsDataURL(addedFiles[0]);
    setUploadState(UploadState.uploaded);
  };

  const handleUploadedFileRemove = () => {
    setFile(undefined);
    setFileContent(undefined);
    setUploadState(UploadState.default);
  };

  const uploadBatchProps: IUploadBatch = useMemo(() => {
    return {
      onUpload: handleUploadFile,
      onUploadedFileRemove: handleUploadedFileRemove,
      file,
      buttonProps: {
        disabled: !canSubmit,
        onClick: handleSubmitBatch,
        label: t('batch.dashboard.createBatchButton'),
        isSubmitting,
      },
      batchTemplateLink: getAssetsUrl(batchUrl, environmentVars.s3Assets),
      uploadState,
      maxFileSize: MAX_FILE_SIZE,
    };
  }, [fileContent, isSubmitting, uploadState]);

  const handleViewDetails = () => {
    const accountsPayableLinkUrl =
      metadata?.accountsPayableLinkUrl ||
      getAccountsPayableUrl(environmentVars.environment, accountsPayableLinkId);
    window.open(accountsPayableLinkUrl, '_blank');
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    lastBatchCreated.resetValues();
  };

  useEffect(() => {
    if (isDrawerOpen) {
      showDrawer({
        component: UploadBatchDrawer,
        options: {
          useCustomDrawer: true,
          useDefaultWidth: true,
          customOnClose: handleCloseDrawer,
        },
        props: {
          success: accountsPayableLinkId,
          onClick: handleViewDetails,
          createdDate: formatDate(createdAt),
          ...uploadBatchProps,
        },
      });
    }
  }, [isDrawerOpen, uploadBatchProps, accountsPayableLinkId]);

  const openCreateBatchDrawer = () => {
    setIsDrawerOpen(true);
  };

  return {
    openCreateBatchDrawer,
  };
}
export default useCreateBatch;
