import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentOrderPaymentMethods } from '@constants/paymentOrder';
import useStyles from './styles';

const PaymentStatusBadge: FC<{ paymentMethod: PaymentOrderPaymentMethods }> = ({
  paymentMethod,
}) => {
  const css = useStyles();
  const { t } = useTranslation();

  const renderText = () => {
    switch (paymentMethod) {
      case PaymentOrderPaymentMethods.CARD_PAYMENT:
        return t('collect.payments.links.paymentMethod.card');
      case PaymentOrderPaymentMethods.SPEI_PAYMENT:
        return t('collect.payments.links.paymentMethod.spei');
      case PaymentOrderPaymentMethods.CASH_PAYMENT:
        return t('collect.payments.links.paymentMethod.cash');
    }
  };

  return <span className={css.badgeText}>{renderText()}</span>;
};

export default PaymentStatusBadge;
