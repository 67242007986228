import EnvironmentVars, { Environments } from '@utils/Variables';
import useInitEnvironmentVars from './useInitEnvironmentVars';
import { TFirebaseConfig } from '@utils/firebase';

/**
 *  @deprecated
 * */
export interface IUseEnvVars {
  enviromentVars: EnvironmentVars;
  areEnviromentVarsLoaded: boolean;
  isFirebaseInit: boolean;
}
/**
 *  @deprecated use useInitEnvironmentVars instead
 * */
const useInitEnviromentVars = (
  environment: Environments,
  useAnalytics?: boolean,
  customConfig?: TFirebaseConfig,
): IUseEnvVars => {
  const values = useInitEnvironmentVars(environment, useAnalytics, customConfig);

  return {
    enviromentVars: values.environmentVars,
    areEnviromentVarsLoaded: values.areEnvironmentVarsLoaded,
    isFirebaseInit: values.isFirebaseInit,
  };
};

export default useInitEnviromentVars;
