import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      flexDirection: 'column',
      padding: theme.spacing(2.5, 0),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(4),
    },
  });
};

export default makeStyles(styles);
