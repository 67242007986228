import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');
  return createStyles({
    root: {
      '& hr': {
        border: 'unset',
        borderTop: `1px solid ${theme.oysterTheme.stone}`,
      },
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: 'minmax(max-content, 1fr) auto',
    },
    header: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      [upScale]: {
        justifyContent: 'unset',
      },
    },
    pageHeader: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      gap: theme.spacing(2),
      [upScale]: {
        justifyContent: 'space-between',
        padding: 0,
      },
    },
    divider: {
      marginTop: 0,
      marginBottom: 0,
      [upScale]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    listHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [upScale]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    listTitle: {
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      fontSize: 12,
      fontWeight: 600,
      color: theme.oysterTheme.shark,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    listContainer: {
      [upScale]: {
        overflowY: 'auto',
        paddingBottom: theme.spacing(10),
        '& ::-webkit-scrollbar': {
          width: 4,
        },
        '& ::-webkit-scrollbar-track': {
          background: theme.oysterTheme.stone,
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '#bdbdbd60',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          background: '#bdbdbd60',
        },
      },
    },
  });
});

export default styles;
