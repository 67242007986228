import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 0',
      justifyContent: 'center',
      alignItems: 'center',
    },
    footer: {
      marginTop: theme.spacing(2),
    },
    info: {
      padding: '0 20px',
      fontSize: 14,
      color: theme.oysterTheme.shark,
    },
    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default makeStyles(styles);
