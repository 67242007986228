import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const CloseIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM16.1246 6.50011C16.5043 6.12041 17.1199 6.12041 17.4996 6.50011C17.8793 6.8798 17.8793 7.49541 17.4996 7.8751L13.3746 12.0001L17.4996 16.1251C17.8793 16.5048 17.8793 17.1204 17.4996 17.5001C17.1199 17.8798 16.5043 17.8798 16.1246 17.5001L11.9996 13.3751L7.87462 17.5001C7.49492 17.8798 6.87931 17.8798 6.49962 17.5001C6.11992 17.1204 6.11992 16.5048 6.49962 16.1251L10.6246 12.0001L6.49962 7.8751C6.11992 7.49541 6.11992 6.8798 6.49962 6.5001C6.87931 6.12041 7.49492 6.12041 7.87462 6.5001L11.9996 10.6251L16.1246 6.50011Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CloseIcon.defaultProps = {
  fill: defaultIconColor,
};

export default CloseIcon;
