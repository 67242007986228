import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      textAlign: 'left',
      color: theme.oysterTheme.shark,
      fontSize: '16px',
      top: 0,
    },
    marginDenseLabel: {
      transform: 'translate(14px, 16px) scale(1) !important',
    },
    labelFocused: {
      transform: 'translate(14px, -6px) scale(0.75) !important',
      color: `${theme.oysterTheme.shark} !important`,
      '&:hover, &:focus': {
        color: `${theme.oysterTheme.shark} !important`,
      },
      '&.Mui-error': {
        color: `${theme.palette.error.main} !important`,
      },
    },
    shrink: {
      width: '100%',
      color: theme.oysterTheme.shark,
      transform: 'translate(14px, -6px) scale(0.75) !important',
    },
    labelAsterisk: {
      display: 'none',
    },
    input: {
      fontSize: '16px',
      borderRadius: 8,
      height: '48px',
      '& fieldset': {
        border: `1px solid ${theme.oysterTheme.stone}`,
      },
      // eslint-disable-next-line max-len
      '&.Mui-focused fieldset, &:active fieldset, &:hover fieldset, &:active fieldset, &:focus fieldset':
        {
          borderColor: `${theme.oysterTheme.stone} !important`,
          borderWidth: '1px !important',
        },
      ':-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: `${theme.oysterTheme.cotton} !important`,
      },
      ':-internal-autofill-selected': {
        backgroundColor: `${theme.oysterTheme.cotton} !important`,
      },
      '& .MuiFilledInput-root': {
        backgroundColor: `${theme.oysterTheme.cotton} !important`,
      },
      caretColor: theme.oysterTheme.oyster,
    },
    baseInput: {
      ':-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: `${theme.oysterTheme.cotton} !important`,
      },
    },
    error: {
      '& fieldset, &:focus fieldset, &:hover fieldset': {
        border: `1px solid ${theme.oysterTheme.ferrari}`,
      },
    },
  }),
);

export default useStyles;
