import { TextFieldProps } from '@material-ui/core';
import { FC } from 'react';

import Button, { ButtonProps } from '@components/Button';
import Input from '@components/Input';

import useStyles from './styles';

export interface ISendDataInput {
  inputProps: TextFieldProps;
  buttonProps: ButtonProps;
}

const SendDataInput: FC<ISendDataInput> = ({ inputProps, buttonProps }) => {
  const css = useStyles();

  const InputProps: Pick<TextFieldProps, 'InputProps'> = {
    InputProps: {
      ...inputProps.InputProps,
      endAdornment: (
        <Button className={css.button} {...buttonProps} color={buttonProps.color || 'primary'} />
      ),
    },
  };

  return <Input variant={inputProps.variant || 'outlined'} {...inputProps} {...InputProps} />;
};

export default SendDataInput;
