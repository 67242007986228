import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      maxWidth: '100%',
      '& .slick-slider': {
        position: 'relative',
        display: 'block',
        boxSizing: 'border-box',
        userSelect: 'none',
        touchAction: 'pan-y',
      },
      '& .slick-list': {
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
      },
      '& .slick-list:focus': {
        outline: 'none',
      },
      '& .slick-list.dragging': {
        cursor: 'pointer',
      },
      '& .slick-list .slick-track': {
        transform: 'translate3d(0, 0, 0)',
      },
      '& .slick-list .slick-list': {
        transform: 'translate3d(0, 0, 0)',
      },
      '& .slick-slide': {
        display: 'none',
        float: 'left',
        height: '100%',
        minHeight: 1,
      },
      '& [dir="rtl"] .slick-slide': {
        float: 'right',
      },
      '& .slick-slide img': {
        display: 'block',
      },
      '& .slick-slide.slick-loading img': {
        display: 'none',
      },
      '& .slick-slide.dragging img': {
        display: 'block',
      },
      '& .slick-initialized .slick-slide': {
        display: 'block',
      },
      '& .slick-loading .slick-slide': {
        visibility: 'hidden',
      },
      '& .slick-vertical .slick-slide': {
        display: 'block',
        height: 'auto',
        border: '1px solid transparent',
      },
      '& .slick-arrow.slick-hidden': {
        display: 'none',
      },
    },
    hasControlsAtTop: {
      paddingTop: theme.spacing(5),
      '& $dots': {
        top: -35,
      },
      '& $controlButton': {
        top: 0,
      },
    },
    hasControlsAtBottom: {
      paddingBottom: theme.spacing(5),
      '& $dots': {
        bottom: -35,
      },
      '& $controlButton': {
        bottom: 0,
      },
    },
    dots: {
      position: 'absolute',
      display: 'block',
      width: '100%',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      textAlign: 'center',
      '& li': {
        position: 'relative',
        display: 'inline-block',
        margin: 0,
        padding: 0,
        cursor: 'pointer',
      },
      '& li button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: 20,
        height: 20,
        margin: 0,
        padding: 0,
        cursor: 'pointer',
        color: 'transparent',
        border: 0,
        outline: 'none',
        background: 'transparent',
      },
      '& li button:hover, & li button:focus': {
        outline: 'none',
      },
      '& li button:hover:before, & li button:focus:before': {
        opacity: 1,
      },
      '& li button:before': {
        position: 'absolute',
        top: 'calc(50% - 4px)',
        left: 'calc(50% - 4px)',
        width: 8,
        height: 8,
        content: '""',
        borderRadius: '50%',
        backgroundColor: theme.oysterTheme.stone,
      },
      '& li.slick-active button:before': {
        backgroundColor: theme.oysterTheme.shark,
      },
    },
    controlButton: {
      fontSize: 14,
      fontWeight: 600,
    },
    nextButton: {
      position: 'absolute',
      right: 0,
    },
    prevButton: {
      position: 'absolute',
      left: 0,
    },
  }),
);

export default useStyles;
