import { ElementType, useContext } from 'react';
import { ModalContext, IModalComponent } from '@components/ModalWrapper/ModalContext';

interface IShowModal {
  component: ElementType;
  props?: Record<string, unknown>;
}

export interface IUseModal {
  showModal({ component, props }: IShowModal): void;
  closeModal(): void;
  component: IModalComponent | null;
}

const useModal = (): IUseModal => {
  const { modalComponent, setModalComponent } = useContext(ModalContext);

  const showModal = ({ component, props = {} }: IShowModal) => {
    setModalComponent({ component, props });
  };

  const closeModal = () => {
    setModalComponent(null);
  };

  return { showModal, closeModal, component: modalComponent };
};

export default useModal;
