import { FC, useCallback, ElementType, useEffect, useState, CSSProperties, ReactNode } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { IconProps } from '@icons/types';
import clsx from 'clsx';

import useStyles, { TVariant } from './styles';

export interface CustomRowProps {
  primaryText?: string;
  leftIcon?: ElementType | FC<IconProps>;
  secondaryText?: string;
  secondaryTextComponent?: ReactNode;
  hideBottomSeparator?: boolean;
  onClick?: () => void;
  rightComponent?: ReactNode;
  variant?: TVariant;
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  style?: CSSProperties;
  id?: string;
}

const CustomRow: FC<CustomRowProps> = ({
  hideBottomSeparator = false,
  leftIcon: Lefticon,
  primaryText,
  secondaryText,
  secondaryTextComponent,
  rightComponent,
  variant = 'medium',
  className,
  id,
  ...restProps
}) => {
  const css = useStyles({ variant, hideBottomSeparator });
  const listClass = clsx(css.listItem, className);
  const [hasClick, toggleClick] = useState(!!restProps.onClick);

  useEffect(() => {
    toggleClick(!!restProps.onClick);
  }, [restProps.onClick]);

  const secondText = useCallback(() => {
    if (secondaryTextComponent) {
      return secondaryTextComponent;
    }
    return secondaryText;
  }, [secondaryTextComponent, secondaryText]);

  const getContent = useCallback(() => {
    return (
      <>
        {Lefticon && (
          <ListItemIcon data-testid="leftIcon" className={css.icon}>
            <Lefticon />
          </ListItemIcon>
        )}
        <ListItemText primary={primaryText} secondary={secondText()} />
        {rightComponent && <div data-testid="rightComponent">{rightComponent}</div>}
      </>
    );
  }, [variant, Lefticon, primaryText, secondText, rightComponent]);

  return (
    <>
      {/* Doing this because an issue with button prod to avoid any use
        see: https://github.com/mui-org/material-ui/issues/14971#issuecomment-675588395
      */}
      {hasClick ? (
        <ListItem
          id={id}
          data-testid="customRowComp"
          button={true}
          className={listClass}
          {...restProps}
        >
          {getContent()}
        </ListItem>
      ) : (
        <ListItem
          id={id}
          data-testid="customRowComp"
          button={false}
          className={listClass}
          {...restProps}
        >
          {getContent()}
        </ListItem>
      )}
    </>
  );
};

export default CustomRow;
