import { FC } from 'react';
import { IconProps, defaultIconColor } from '../types';

const LeftTransparentIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8054 17.7071C14.4149 18.0977 13.7817 18.0977 13.3912 17.7071L8.44147 12.7574C8.25273 12.5687 8.14728 12.3123 8.14858 12.0454C8.14989 11.7785 8.25786 11.5232 8.44844 11.3363L13.3982 6.48273C13.7925 6.09605 14.4257 6.10227 14.8123 6.4966C15.199 6.89094 15.1928 7.52408 14.7985 7.91075L10.5698 12.0573L14.8054 16.2929C15.196 16.6835 15.196 17.3166 14.8054 17.7071Z"
        fill="white"
      />
    </svg>
  );
};

LeftTransparentIcon.defaultProps = {
  fill: defaultIconColor,
};

export default LeftTransparentIcon;
