export default {
  listTitle: 'Resumen de mis cobros',
  action: 'Cobrar',
  common: {
    payInfo: 'Verás reflejados los fondos en tu cuenta en un máximo de 2 días hábiles.',
  },
  buttons: {
    settings: 'Configuración',
    options: 'Opciones',
    csv: 'Cargar archivo masivo',
    downloadCsv: 'Descargar reporte',
  },
  csv: {
    title: 'Carga un archivo masivo',
    step: 'Paso',
    step1: 'Descarga la plantilla .csv',
    step2: 'Completa la plantilla y sube hasta 100 enlaces de pago.',
    step3: 'Carga la plantilla con tus datos.',
    next: 'Siguiente',
    download: 'Descargar plantilla.csv',
    input: 'Cargar .csv',
    submit: 'Generar Links',
    success: 'Archivo cargado',
    successMsg:
      'Recibirás una notificación por correo cuando se hayan generado tus links correctamente',
    error: 'Error',
    errorMsg: 'No se pudo cargar el archivo, verifica los datos e intenta nuevamente.',
  },
  downloadCsv: {
    title: 'Descarga los cobros de tus Links de pago',
    subtitle: 'Elige un rango de tres meses',
    from: 'De',
    to: 'Hasta',
    download: 'Descargar',
    success: {
      title: '.CSV En proceso',
      message: 'Se enviará el .CSV a tu correo electrónico',
    },
    error: {
      title: 'Ha ocurrido un error',
      message: 'No se ha podido solicitar el reporte, por favor intenta nuevamente',
    },
  },
  links: {
    paymentMethod: {
      card: 'Pago con tarjeta',
      cash: 'Pago con efectivo',
      spei: 'Pago con SPEI',
    },
    createdAt: 'Creado el {{createdAt}}',
    emptyStateLabel: 'Aún no tienes links',
    loadingLabel: 'Estamos recuperando tu información...',
    statuses: {
      all: 'Todos',
      pending: 'Pendientes',
      paid: 'Pagados',
      settled: 'Depositados',
    },
    status: {
      pending: 'Pendiente',
      paid: 'Pagado',
      settled: 'Depositado',
      refunded: 'Reembolsado',
    },
    optionsMenu: {
      delete: 'Borrar',
      close: 'Cerrar',
      copy: 'Copiar',
      markAsPaid: 'Marcar como pagado',
      paid: 'Pagado',
      copied: 'Copiado',
      copySuccess: 'Enlace de pago copiado',
      deleted: 'Borrado',
      deleteSuccess: 'Enlace de pago borrado',
      markedAsPaid: 'Marcado como pagado',
      markAsPaidSuccess: 'Enlace de pago marcado como pagado',
      refund: 'Reembolsar',
    },
    refund: 'reembolso{{plural}}',
  },
  filters: {
    title: 'Filtrar',
    titles: {
      type: 'Por tipo',
      status: 'Estado',
    },
    type: {
      paymentLink: 'Link de Pago',
      deposit: 'Depósitos',
    },
    links: {
      all: 'Todos',
      pending: 'Pendiente',
      paid: 'Pagado',
      deposited: 'Depositado',
      refunded: 'Reembolsado',
    },
  },
  details: {
    title: 'Detalles del link reutilizable',
    paymentsNumber: 'Número de pagos',
    paidAmount: 'Cantidad pagada',
    payment: 'Pago',
  },
  settings: {
    errors: {
      saving: 'Ocurrió un error al guardar los cambios, por favor intenta de nuevo',
    },
    invoices: {
      title: 'Mis facturas',
      description: 'Genera facturas para tus clientes',
      upload: 'Sube tu archivo .cer y .key',
      rfc: 'RFC',
      password: 'Contraseña de los sellos digitales',
      submit: 'Cargar',
      digitalSeals: 'Mis sellos digitales',
      edit: 'Editar',
      success: {
        title: 'Información guardada',
        message: 'Se han cargado tus archivos con éxito',
      },
      error: {
        title: 'Ha ocurrido un problema',
        message: 'Inténtalo nuevamente',
      },
    },
    notifications: {
      title: 'Notificaciones',
      description: 'Recordatorios de pago',
      sendReminders: 'Enviar recordatorios de pago',
      add: 'Agregar recordatorio',
      save: 'Guardar cambios',
      reminders: {
        '-7d': 'Siete días antes del pago',
        '-6d': 'Seis días antes del pago',
        '-5d': 'Cinco días antes del pago',
        '-4d': 'Cuatro días antes del pago',
        '-3d': 'Tres días antes del pago',
        '-2d': 'Dos días antes del pago',
        '-1d': 'Un día antes del pago',
        '0d': 'El día de pago',
        '1d': 'Un día después de expirar',
        '2d': 'Dos días después de expirar',
        '3d': 'Tres días después de expirar',
        '4d': 'Cuatro días después de expirar',
        '5d': 'Cinco días después de expirar',
        '6d': 'Seis días después de expirar',
        '7d': 'Siete días después de expirar',
        '8d': 'Ocho días después de expirar',
        '9d': 'Nueve días después de expirar',
        '10d': 'Diez días después de expirar',
        '11d': 'Once días después de expirar',
        '12d': 'Doce días después de expirar',
        '13d': 'Trece días después de expirar',
        '14d': 'Catorce días después de expirar',
        '15d': 'Quince días después de expirar',
        '16d': 'Dieciséis días después de expirar',
        '17d': 'Diecisiete días después de expirar',
        '18d': 'Dieciocho días después de expirar',
        '19d': 'Diecinueve días después de expirar',
        '20d': 'Veinte días después de expirar',
        '21d': 'Veintiún días después de expirar',
        '22d': 'Veintidós días después de expirar',
        '23d': 'Veintitrés días después de expirar',
        '24d': 'Veinticuatro días después de expirar',
        '25d': 'Veinticinco días después de expirar',
        '26d': 'Veintiséis días después de expirar',
        '27d': 'Veintisiete días después de expirar',
        '28d': 'Veintiocho días después de expirar',
        '29d': 'Veintinueve días después de expirar',
        '30d': 'Treinta días después de expirar',
      },
    },
  },
  deposits: {
    depositId: 'ID',
    deposited: 'Depositado',
    info: 'Los depósitos se realizarán en tu cuenta que termina en ***{{lastFourDigits}}',
    empty: 'Aún no tienes cobros',
    loadingLabel: 'Estamos recuperando tu información...',
    optionsMenu: {
      downloadReceipt: 'Descargar CEP',
    },
    errorDownloadTitle: 'Hubo un error',
    errorDownloadMsg: 'No se pudo descargar el archivo.',
  },
  depositDetails: {
    title: 'Depósito',
    payment: 'Pago',
    paidAmount: 'Cantidad pagada',
    fee: 'Comisión',
    total: 'Total',
    payments: 'Pagos',
    grossAmount: 'Cantidad bruta',
    downloadReceipt: 'Descargar CEP',
  },
  capabilities: {
    title: 'Tipos de cobros',
    cardMethod: 'Con tarjeta',
    cashMethod: 'Efectivo',
    transfersMethod: 'Transferencias',
    cardCap: 'Limitado a {{amount}}',
    noCap: 'Ilimitado',
    upsell: {
      title: 'Cobros con tarjeta',
      description: 'Para tener cobros ilimitados con tarjeta, verifica tu identidad.',
      next: 'Siguiente',
      callToActionButtonLabel: 'Activar cobros con tarjeta',
      steps: {
        selfie: {
          title: 'Paso 1',
          description: 'Graba un video “selfie”',
        },
        poa: {
          title: 'Paso 2',
          description: 'Sube un comprobante de domicilio',
        },
        rfc: {
          title: 'Paso 3',
          description: 'Escribe tu RFC',
        },
      },
    },
  },
};
