import { Instance, types } from 'mobx-state-tree';

const FundInfoStore = types.model({
  clabe: '',
  reference: '',
  amount: types.optional(types.union(types.number, types.string), ''),
  currency: '',
  bank: '',
  beneficiaryName: '',
});

export interface TFundInfoStore extends Instance<typeof FundInfoStore> {}
export default FundInfoStore;
