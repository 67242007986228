import { FC } from 'react';
import clsx from 'clsx';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import BackPageButton from '@yaydoo/react-components/lib/components/BackPageButton';
import useStyles from './styles';

export interface ICustomDrawer {
  title?: string;
  headerClassName?: string;
}

const CustomDrawer: FC<ICustomDrawer> = ({ children, title, headerClassName }) => {
  const css = useStyles();
  const { closeDrawer } = useDrawer();
  const headerCls = clsx(headerClassName, css.header);

  return (
    <>
      <div className={headerCls} data-testid="header">
        <BackPageButton
          onClick={closeDrawer}
          data-testid="close-btn"
          className={css.returnButton}
          iconSize={24}
          variant="dolphin"
        />
        <span className={css.title}>{title}</span>
      </div>
      {children}
    </>
  );
};

export default CustomDrawer;
