import { FC } from 'react';
import { IconProps, obsidian } from './types';

const CommissionIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 20C32.4183 20 36.0001 16.4183 36.0001 12C36.0001 7.58173 32.4183 4 28 4C23.5818 4 20 7.58173 20 12C20 12.7788 20.1113 13.5316 20.3189 14.2434C22.6547 15.4363 24.5638 17.3454 25.7567 19.6812C26.4685 19.8888 27.2213 20 28 20ZM28.6378 11.3976C29.8584 11.6631 31.2 12.041 31.2 13.481C31.2 14.5431 30.3642 15.4623 28.6378 15.6563V16.8001H27.5381V15.687C26.4494 15.6257 25.4048 15.3091 24.8 14.8699L25.4048 13.624C25.9546 14.0018 26.7463 14.2776 27.5381 14.3491V12.6435C26.3175 12.3678 24.9539 11.9899 24.9539 10.5295C24.9539 9.44694 25.8006 8.51758 27.5381 8.33375V7.20013H28.6378V8.31332C29.4625 8.3746 30.2653 8.57885 30.8701 8.9363L30.3202 10.1925C29.7594 9.8963 29.1876 9.72268 28.6378 9.66141V11.3976ZM27.5381 9.69205C26.9553 9.8146 26.7244 10.1108 26.7244 10.4478C26.7244 10.8052 27.0543 10.9993 27.5381 11.1423V9.69205ZM28.6378 12.8989V14.3082C29.1876 14.1959 29.4295 13.9304 29.4295 13.6035C29.4295 13.2461 29.1106 13.0521 28.6378 12.8989ZM24.7778 25.6107C24.7778 31.3483 20.1265 35.9996 14.3889 35.9996C8.65127 35.9996 4 31.3483 4 25.6107C4 19.8731 8.65127 15.2218 14.3889 15.2218C20.1265 15.2218 24.7778 19.8731 24.7778 25.6107ZM18.5446 27.5339C18.5446 25.6639 16.8024 25.1732 15.2173 24.8284V22.5738C15.9313 22.6534 16.6739 22.8788 17.4022 23.2634L18.1162 21.6321C17.3308 21.168 16.2883 20.9027 15.2173 20.8231V19.3775H13.7893V20.8497C11.533 21.0884 10.4334 22.2953 10.4334 23.7011C10.4334 25.5976 12.2042 26.0883 13.7893 26.4464V28.6612C12.7611 28.5684 11.7329 28.2103 11.0189 27.7196L10.2335 29.3376C11.0189 29.9079 12.3755 30.319 13.7893 30.3986V31.8442H15.2173V30.3588C17.4593 30.1068 18.5446 28.9132 18.5446 27.5339ZM12.7325 23.595C12.7325 23.1573 13.0324 22.7727 13.7893 22.6136V24.4968C13.1609 24.3112 12.7325 24.0592 12.7325 23.595ZM15.2173 26.778C15.8314 26.9769 16.2455 27.2289 16.2455 27.6931C16.2455 28.1175 15.9313 28.4623 15.2173 28.6082V26.778Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CommissionIcon.defaultProps = {
  fill: obsidian,
};

export default CommissionIcon;
