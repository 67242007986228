import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  const upScale = theme.breakpoints.up('md');

  return createStyles({
    container: {
      width: '100%',
      '& .react-datepicker__today-button': {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1.2),
        background: 'unset',
        borderTop: 'unset',
        padding: 'unset',
      },
      '& .react-datepicker__portal > div': {
        position: 'relative',
        border: `1px solid ${theme.oysterTheme.stone}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: theme.oysterTheme.cotton,
      },
      '& .react-datepicker__portal, & .react-datepicker-popper': {
        [upScale]: {
          border: `1px solid ${theme.oysterTheme.stone}`,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
          zIndex: 999,
          backgroundColor: theme.oysterTheme.cotton,
        },
        '& .react-datepicker__navigation': {
          color: theme.oysterTheme.oyster,
          top: theme.spacing(5),
          '&--next': {
            right: theme.spacing(2),
          },
          '&--previous': {
            left: theme.spacing(2),
          },
        },
        '& .react-datepicker__navigation-icon::before': {
          borderColor: 'unset',
        },
        '& .react-datepicker__header': {
          paddingTop: theme.spacing(2),
          backgroundColor: 'unset',
          borderBottom: 'unset',
        },
        '& .react-datepicker__day-names': {
          marginTop: theme.spacing(1),
          textTransform: 'capitalize',
        },
        '& .react-datepicker__day-name': {
          color: `${theme.oysterTheme.shark}`,
        },
        '& .react-datepicker__current-month': {
          textTransform: 'capitalize',
          fontSize: 16,
          color: theme.oysterTheme.shark,
          fontWeight: 400,
        },
        '& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
          backgroundColor: theme.oysterTheme.oyster,
          borderRadius: theme.spacing(4),
          color: theme.oysterTheme.cotton,
          fontWeight: '700 !important',
        },
        '& .react-datepicker__day--today': {
          fontWeight: 'unset',
        },
        '& .react-datepicker__day--outside-month, & .react-datepicker__day--disabled': {
          color: theme.oysterTheme.dolphin,
        },
      },
    },
    calendar: {
      borderBottom: `${theme.oysterTheme.pearl} 1px solid`,
      paddingBottom: theme.spacing(1),
    },
    header: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    todayButton: {
      color: theme.oysterTheme.oyster,
      fontWeight: 600,
      fontSize: 16,
    },
  });
};

export default makeStyles(styles);
