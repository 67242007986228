import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    container: {
      width: '100%',
      '& hr': {
        border: 'unset',
        borderTop: `1px solid ${theme.oysterTheme.stone}`,
      },
    },
    header: {
      color: theme.oysterTheme.shark,
      letterSpacing: '0.08em',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
    },

    // Mobile
    mobileContainer: {
      width: '100%',
      fontSize: 14,
      marginBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
    },
    accordionSummary: {
      minHeight: 'unset !important',
      height: 60,
    },
    accordionDetails: {
      padding: 0,
    },
    arrow: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
      transition: 'transform 0.2s',
    },
    arrowDown: {
      transform: 'rotate(90deg)',
    },
    filtersButton: {
      textAlign: 'left',
      justifySelf: 'left',
      width: '100%',
    },
    filtersMenu: {
      width: '100%',
      backgroundColor: theme.oysterTheme.pearl,
      borderTop: `1px solid ${theme.oysterTheme.stone}`,
    },
    filtersOptions: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
      gap: theme.spacing(1),
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    innerFilter: {
      display: 'flex',
    },
    icon: {
      fontSize: 16,
    },
    innerFilterOption: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    activeOption: {
      fontWeight: 600,
      '& svg': {
        color: theme.oysterTheme.oyster,
      },
    },
  });
});

export default styles;
