import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import background from '@assets/images/mobileBg.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      display: 'grid',
      gridTemplate: 'auto / 1fr 3fr 1em 3fr 1fr',
      gap: '1em 0',
      gridAutoRows: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    },
    links: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '1em 0',
    },
    funds: {
      gridColumn: 'span 2',
      alignSelf: 'center',
      gap: '0.2em',
      justifySelf: 'start',
    },
    addFunds: {
      gridColumn: 'span 2',
      alignSelf: 'center',
      justifySelf: 'end',
    },
    homeLink: {
      gridColumn: 'span 3',
      textDecoration: 'none',
      color: theme.oysterTheme.charcoal,
      '&:hover, &:active': {
        textDecoration: 'none',
      },
    },
    greetings: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5em',
      textAlign: 'center',
    },
    logoutBtn: {
      margin: 'auto auto auto 0',
      '& svg': {
        color: theme.oysterTheme.obsidian,
        height: '24px',
        width: '24px',
      },
    },
    returnBtn: {
      alignSelf: 'center',
      justifySelf: 'start',
      width: '25px',
      height: '25px',
    },
    returnIcon: {
      color: theme.oysterTheme.dolphin,
      fontSize: 20,
    },
    navigationInRoute: {
      display: 'grid',
      gridTemplate: 'auto / 50px 1fr 50px',
      gap: '1em 0',
      gridAutoRows: 'auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    pageTitle: {
      alignSelf: 'center',
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 700,
      color: theme.oysterTheme.charcoal,
    },
  }),
);

export default useStyles;
