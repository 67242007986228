import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import BackPageButton from '@yaydoo/react-components/lib/components/BackPageButton';

import useStore from '@hooks/useStore';
import APP, {
  HEADER_ROUTES_ALL as routes,
  HEADER_ROUTES_PARENT as parentRoutes,
} from '@constants/routes';

import useStyles from './styles';

interface MobileNavigationProps {
  hideRoute?: boolean;
}

const Navigation: FC<MobileNavigationProps> = ({ hideRoute }) => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const parentRoute = parentRoutes.find(({ ROUTE }) => location?.pathname?.includes(ROUTE));
  const currentRoute = routes.find(({ ROUTE }) => location?.pathname?.includes(ROUTE));
  const label = currentRoute ? currentRoute.LABEL : 'default';

  const handleReturn = () => {
    history.push(parentRoute?.ROUTE || APP.COLLECT);
  };

  return (
    <div className={css.navigationInRoute} data-testid="child-navigation">
      <BackPageButton variant="dolphin" iconSize={20} onClick={handleReturn} />
      {!hideRoute && (
        <span className={css.pageTitle}>{t(`collect.layout.mobileRoutes.${label}`)}</span>
      )}
    </div>
  );
};

const MobileNavigation: FC<MobileNavigationProps> = (props) => {
  const location = useLocation();
  const currentRoute = parentRoutes.find(({ ROUTE }) => location?.pathname?.includes(ROUTE));
  const isChildRoute = location?.pathname !== currentRoute?.ROUTE;
  const { app } = useStore();

  if (!app.displayHeader) return <div />;

  return <>{isChildRoute && <Navigation {...props} />}</>;
};

export default observer(MobileNavigation);
