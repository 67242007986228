import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

export default class AccountService extends OysterService {
  /**
   * Get authenticated user clabe
   * @return Promise<AxiosResponse>
   */
  getClabe(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/account/clabe');
  }
  /**
   * Get authenticated user debit balance
   * @return Promise<AxiosResponse>
   */
  getBalance(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/account/balance');
  }
}
