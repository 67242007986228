import { useEffect, useRef } from 'react';

type UseIntervalCallback = () => void;

function useInterval(callback: UseIntervalCallback, delay = 1000): void {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      ref.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
