import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import { BatchFilterCreatedAt, BatchFilterStatus } from '@constants/batch';
import { BATCH_ROUTES } from '@constants/routes';
import useBreakpoints from '@hooks/useBreakpoints';
import useCancelBatch from '@hooks/useCancelBatch';
import useProcessingBatch from '@hooks/useProcessingBatch';
import useStore from '@hooks/useStore';
import { IBatchFilters } from '@pages/Dashboard/BatchFilters';
import { IBatchList } from '@pages/Dashboard/BatchList';
import { IAccountsPayableBatchListItem } from '@store/batch/models/AccountsPayableBatchListItem';

export interface IUseBatchList {
  batchListProps: IBatchList;
  batchFiltersProps: IBatchFilters;
}

function useBatchList(): IUseBatchList {
  const { t } = useTranslation();
  const history = useHistory();
  const { openToast } = useToast();
  const { isTouchScreen } = useBreakpoints();
  const { openProcessingBatchDrawer } = useProcessingBatch();
  const { openCancelOperationModal } = useCancelBatch();
  const { batch, accountsPayableLink } = useStore();
  const { flags, accountsPayableBatchList, batchListPagination } = batch;
  const { isFetching } = flags;
  const [filterStatus, setFilterStatus] = useState<BatchFilterStatus>(BatchFilterStatus.ALL);
  const [filterCreatedAt, setFiltercreatedAt] = useState<BatchFilterCreatedAt>(
    BatchFilterCreatedAt.CURRENT_MONTH,
  );

  const handleError = (error: { message: string }) => {
    const { message = '' } = error;
    openToast({
      type: 'error',
      title: t('batch.common.error.title'),
      message: message || t('batch.common.error.unknown'),
    });
  };

  const getFromDate = (filterCreatedAt: BatchFilterCreatedAt) => {
    switch (filterCreatedAt) {
      case BatchFilterCreatedAt.CURRENT_MONTH:
        return moment().startOf('month').format('YYYY/MM/DD');
      case BatchFilterCreatedAt.LAST_MONTH:
        return moment().subtract(30, 'days').format('YYYY/MM/DD');
      case BatchFilterCreatedAt.LAST_THREE_MONTHS:
        return moment().subtract(3, 'months').format('YYYY/MM/DD');
      case BatchFilterCreatedAt.LAST_SIX_MONTHS:
        return moment().subtract(6, 'months').format('YYYY/MM/DD');
      default:
        return undefined;
    }
  };

  const getBatchList = async () => {
    try {
      const fromDate = getFromDate(filterCreatedAt);
      const timeRangeProps = fromDate ? { from: fromDate, to: moment().format('YYYY/MM/DD') } : {};
      await batch.getBatchList({ status: filterStatus, ...timeRangeProps });
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  useEffect(() => {
    batch.clearBatchList();
    getBatchList();
  }, [filterStatus, filterCreatedAt]);

  useEffect(() => {
    if (batch.forceRefreshList) {
      batch.clearBatchList();
      getBatchList();
      batch.setForceRefreshList(false);
    }
  }, [batch.forceRefreshList]);

  useEffect(() => {
    return () => {
      batch.clearBatchList();
    };
  }, []);

  const handleGetMoreBatchs = async () => {
    const fromDate = getFromDate(filterCreatedAt);
    const timeRangeProps = fromDate ? { from: fromDate, to: moment().format('YYYY/MM/DD') } : {};
    try {
      await batch.getMoreBatchs({ status: filterStatus, ...timeRangeProps });
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const handleClickBatch = ({ accountsPayableLinkId, status }: IAccountsPayableBatchListItem) => {
    if (status !== AccountsPayableLinkStatus.CANCELED) {
      openProcessingBatchDrawer(accountsPayableLinkId);
    }
  };

  const handleCancelBatch = ({ accountsPayableLinkId }: IAccountsPayableBatchListItem) => {
    accountsPayableLink.updateField('accountsPayableLinkId', accountsPayableLinkId);
    openCancelOperationModal(() => null);
  };

  const handleSeeDetails = ({ accountsPayableLinkId }: IAccountsPayableBatchListItem) => {
    history.push(
      BATCH_ROUTES.BATCH_DETAILS.replace(':accountsPayableLinkId', accountsPayableLinkId),
    );
  };

  const batchListProps: IBatchList = useMemo(() => {
    return {
      isFetching,
      batchList: accountsPayableBatchList,
      hasMore: batchListPagination.hasMore,
      onClickBatch: handleClickBatch,
      onCancel: handleCancelBatch,
      onSeeDetails: handleSeeDetails,
      getMoreBatchs: handleGetMoreBatchs,
    };
  }, [isFetching, accountsPayableBatchList, batchListPagination.hasMore]);

  const batchFiltersProps: IBatchFilters = useMemo(
    () => ({
      isMobile: isTouchScreen,
      currentFilterStatus: filterStatus,
      currentFiltercreatedAt: filterCreatedAt,
      onChangeFilterStatus: setFilterStatus,
      onChangeFiltercreatedAt: setFiltercreatedAt,
    }),
    [filterStatus, filterCreatedAt, isTouchScreen],
  );

  return {
    batchListProps,
    batchFiltersProps,
  };
}

export default useBatchList;
