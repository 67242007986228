import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    title: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.oysterTheme.charcoal,
    },
    link: {
      fontSize: 14,
      fontWeight: 700,
      color: theme.oysterTheme.oyster,
      textDecoration: 'none',
    },
    input: {
      flex: 1,
    },
    button: {
      flex: 1,
    },
    eventsRow: {
      '& .MuiListItemText-primary': {
        color: theme.oysterTheme.pine,
      },
      '& .MuiListItemIcon-root': {
        color: theme.oysterTheme.pine,
        fontSize: 20,
      },
    },
    formBtn: {
      boxShadow: '0px 8px 24px rgba(1, 70, 81, 0.16)',
    },
    activeIcon: {
      color: theme.oysterTheme.oyster,
      width: 20,
      height: 20,
    },
    disableIcon: {
      color: theme.oysterTheme.shark,
      width: 20,
      height: 20,
    },
    circularProgress: {
      marginTop: 15,
    },
  });
};

export default makeStyles(styles);
