import { FC, useEffect } from 'react';
import { useTranslation, Trans, TFunction, Namespace } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';
import Retreats from '@yaydoo/react-components/lib/icons/Retreats';
import PayTicket from '@yaydoo/react-components/lib/icons/PayTicket';
import Deposit from '@yaydoo/react-components/lib/icons/Deposit';
import Profile from '@yaydoo/react-components/lib/icons/Profile';
import SectionHeader from '@yaydoo/react-components/lib/components/Typography/SectionHeaderV2';

import Loading from '@components/Loading';

import useStore from '@hooks/useStore';
import useCommonStore from '@hooks/useCommonStore';
import useStyles from './styles';

import WebhookForm from './components/webhookForm';
import EnableDisableWebhook from './components/enableDisableWebhook';
import SendNotificationToWebhook from './components/sendNotificationToWebhook';

const eventRows = (t: TFunction<Namespace<'paymentTools'>>) => [
  {
    name: t('paymentTools:tools.webhooks.events.reimbursement'),
    description: t('paymentTools:tools.webhooks.events.reimbursementDescription'),
    Icon: Retreats,
  },
  {
    name: t('paymentTools:tools.webhooks.events.payments'),
    description: t('paymentTools:tools.webhooks.events.paymentsDescription'),
    Icon: PayTicket,
  },
  {
    name: t('paymentTools:tools.webhooks.events.deposits'),
    description: t('paymentTools:tools.webhooks.events.depositsDescription'),
    Icon: Deposit,
  },
  {
    name: t('paymentTools:tools.webhooks.events.deletedPayments'),
    description: t('paymentTools:tools.webhooks.events.deletedPaymentsDescription'),
    Icon: Profile,
  },
];

const Webhooks: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const { business } = useCommonStore();
  const { webhooks } = useStore();
  const { flags, getWebhook, webhookURL } = webhooks;
  const { isWebhookFetched, isEditEnabled } = flags;
  const eventRowsItems = eventRows(t);

  const loadData = async () => {
    await getWebhook({ businessId: business.businessId });
  };

  useEffect(() => {
    if (business?.businessId !== '') {
      loadData();
    }
  }, [business?.businessId]);

  if (!isWebhookFetched) {
    return <Loading />;
  }

  return (
    <div className={css.container}>
      <h2 className={css.title}>{t('paymentTools:tools.webhooks.title')}</h2>
      <Box marginBottom={1}>
        <Typography className={css.text}>
          <Trans i18nKey="paymentTools:tools.webhooks.description">
            <a
              href="https://help.oyster.io/es/articles/6210373-webhooks"
              target="_blank"
              rel="noreferrer"
              className={css.link}
            />
          </Trans>
        </Typography>
      </Box>
      <Box mb={2}>
        <SectionHeader
          isBoldTitle
          id="tools-webhooks-urlForm-title"
          title={t('paymentTools:tools.webhooks.urlForm.title')}
          description={
            webhookURL === ''
              ? t('paymentTools:tools.webhooks.urlForm.description')
              : t('paymentTools:tools.webhooks.urlForm.afterSavedDescription')
          }
        />
      </Box>
      <Box mb={2}>
        <WebhookForm />
      </Box>
      {webhookURL !== '' && !isEditEnabled && (
        <>
          <Box mb={2}>
            <EnableDisableWebhook />
          </Box>
          <Box mb={2}>
            <SendNotificationToWebhook />
          </Box>
        </>
      )}
      <SectionHeader
        isBoldTitle
        id="tools-webhooks-events-title"
        title={t('paymentTools:tools.webhooks.events.title')}
      />
      {eventRowsItems.map((item, index) => (
        <CustomRow
          className={css.eventsRow}
          variant="big"
          primaryText={item.name}
          secondaryText={item.description}
          leftIcon={item.Icon}
          key={index}
        />
      ))}
    </div>
  );
};

export default observer(Webhooks);
