import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import {
  AccountsPayableLinkStatus,
  AccountsPayableLinkUpdateStatus,
} from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import { AccountsPayableLinkErrorCodes } from '@constants/batch';
import { BATCH_ROUTES } from '@constants/routes';
import useCancelBatch from '@hooks/useCancelBatch';
import useStore from '@hooks/useStore';
import ProcessingBatch, { IProcessingBatch } from '@pages/Dashboard/ProcessingBatch';
import { IErrors } from '@store/batch';
import { formattedAmount } from '@utils/format';
import { getAccountsPayableUrl } from '@utils/getAccountsPayableUrl';
import { emailsSeparatedCommas } from '@utils/validators';

export interface IUseProcessingBatch {
  openProcessingBatchDrawer: (accountsPayableLinkId: string) => void;
}

function useProcessingBatch(): IUseProcessingBatch {
  const { t } = useTranslation();
  const history = useHistory();
  const environmentVars = useEnvironmentVars();
  const { openToast } = useToast();

  const { accountsPayableLink, batch } = useStore();
  const { flags, fundInfo, accountsPayableLinkId } = accountsPayableLink;
  const { amount, reference } = fundInfo;
  const { isRequesting, isSubmitting } = flags;
  const { showDrawer, closeDrawer } = useDrawer();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [emails, setEmails] = useState('');
  const [emailsError, setEmailsError] = useState(false);
  const [base64File, setBase64File] = useState('');
  const { openCancelOperationModal } = useCancelBatch();

  const reportName = `${reference} ${moment().format('DD/MM/YYYY').replace(/\//g, '')}`;

  const accountsPayableLinkUrl = getAccountsPayableUrl(
    environmentVars.environment,
    accountsPayableLinkId,
  );

  const handleError = (error: { message: string }) => {
    const { message = '' } = error;
    openToast({
      type: 'error',
      title: t('batch.common.error.title'),
      message: message || t('batch.common.error.unknown'),
    });
  };

  const handleViewDetails = () => {
    if (accountsPayableLink.getCurrentStep === 3) {
      history.push(
        BATCH_ROUTES.BATCH_DETAILS.replace(':accountsPayableLinkId', accountsPayableLinkId),
      );
      closeDrawer();
      setIsDrawerOpen(false);
    } else {
      window.open(accountsPayableLinkUrl, '_blank');
    }
  };

  const handleValidate = async () => {
    try {
      await accountsPayableLink.updateStatus(AccountsPayableLinkUpdateStatus.CONFIRMED);
      accountsPayableLink.setCurrentStatus(AccountsPayableLinkStatus.CONFIRMED);
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const handleCancel = async () => {
    openCancelOperationModal(() => {
      closeDrawer();
      setIsDrawerOpen(false);
    });
  };

  const showSuccessToast = ({ title, message }: { title: string; message: string }) => {
    openToast({
      type: 'success',
      title,
      message,
    });
  };

  const handleOnCopy = (isUrl: boolean) => {
    const translation = isUrl ? 'copyUrl' : 'copyText';
    showSuccessToast({
      title: t('batch.dashboard.toast.success.title'),
      message: t(`batch.dashboard.toast.success.${translation}`),
    });
  };

  const handleRequestFunds = async () => {
    try {
      if (emails && !emailsError) {
        await accountsPayableLink.requestFunds(emails.split(','));
        showSuccessToast({
          title: t('batch.dashboard.toast.success.title'),
          message: t('batch.dashboard.toast.success.sendEmail'),
        });
      }
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const handleGetReport = async () => {
    try {
      const data = await batch.getResume(accountsPayableLink.accountsPayableLinkId);
      setBase64File(data.fileContent);
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  useEffect(() => {
    if (accountsPayableLink.getCurrentStep === 3 && isDrawerOpen) {
      handleGetReport();
    }
  }, [accountsPayableLink.getCurrentStep, isDrawerOpen]);

  const handleDownloadReport = () => {
    showSuccessToast({
      title: t('batch.dashboard.toast.success.title'),
      message: t('batch.dashboard.toast.success.report', {
        name: reportName,
      }),
    });
  };

  const processingBatchProps: IProcessingBatch | undefined = useMemo(() => {
    if (isRequesting || !amount) {
      return undefined;
    }
    const { getCurrentStep, accountsPayable, status, createdAt } = accountsPayableLink;
    const { reference, currency, bank, clabe, beneficiaryName } = fundInfo;
    const { canProcess, canNotProcess } = accountsPayable;
    const step = status === AccountsPayableLinkStatus.CANCELED ? undefined : getCurrentStep;
    return {
      batchReference: reference,
      step: step,
      successDate: createdAt,
      successTotalAmount: accountsPayableLink.getAmountCanProcess,
      successOperations: canProcess.length,
      errorDate: canNotProcess.length ? createdAt : '',
      errorTotalAmount: accountsPayableLink.getAmountCanNotProcess,
      errorOperations: canNotProcess.length,
      onSeeDetails: handleViewDetails,
      onValidate: handleValidate,
      onCancel: handleCancel,
      totalAmount: formattedAmount(amount, currency),
      reference,
      clabe,
      beneficiaryName,
      bank,
      url: `${accountsPayableLinkUrl}?confirm=true`,
      sendButtonDisabled: emailsError || !emails.trim() || isSubmitting,
      sendButtonSubmitting: isSubmitting,
      onCopyBankInfo: () => handleOnCopy(false),
      onCopyShareLink: () => handleOnCopy(true),
      onChangeEmailData: setEmails,
      emailsError,
      onSendEmail: handleRequestFunds,
      onDownloadReport: handleDownloadReport,
      base64File,
      reportName,
      isSubmitting,
    };
  }, [
    isRequesting,
    isSubmitting,
    amount,
    emailsError,
    emails,
    accountsPayableLink.status,
    base64File,
  ]);

  useEffect(() => {
    if (isDrawerOpen && !isRequesting) {
      showDrawer({
        component: ProcessingBatch,
        options: {
          useCustomDrawer: true,
          useDefaultWidth: true,
          customOnClose: () => {
            setIsDrawerOpen(false);
            setBase64File('');
          },
        },
        props: { ...processingBatchProps },
      });
    }
  }, [isDrawerOpen, processingBatchProps, isRequesting]);

  useEffect(() => {
    if (emails) {
      setEmailsError(!emailsSeparatedCommas(emails.replace(/ /g, '')));
    } else {
      setEmailsError(false);
    }
  }, [emails]);

  const handleOpenProcessingBatchDrawer = async (accountsPayableLinkId: string) => {
    try {
      await accountsPayableLink.getAccountsPayableLink(accountsPayableLinkId);
      if (accountsPayableLink.status !== AccountsPayableLinkStatus.CANCELED) setIsDrawerOpen(true);
    } catch (error) {
      const { response, errorCode, msg } = error as IErrors;
      let errorToShow = msg || response?.data.msg;
      if (errorCode === AccountsPayableLinkErrorCodes.NOT_FOUND || response?.status === 404) {
        errorToShow = t('batch.dashboard.toast.errors.notFound');
      }
      handleError({ message: errorToShow });
    }
  };

  return {
    openProcessingBatchDrawer: handleOpenProcessingBatchDrawer,
  };
}

export default useProcessingBatch;
