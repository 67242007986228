import { FC } from 'react';

import ConfirmationModal, {
  ConfirmModalProps,
} from '@yaydoo/react-components/lib/components/ConfirmationModal';

import CancelIcon from './CancelIcon';
import SendIcon from './SendIcon';
import useStyles from './styles';

export interface IAccountsPayableConfirmation extends ConfirmModalProps {
  cancel?: boolean;
}

const AccountsPayableConfirmation: FC<IAccountsPayableConfirmation> = ({
  cancel = true,
  wide = true,
  ...restProps
}) => {
  const css = useStyles({ cancel });

  return (
    <div className={css.root}>
      <ConfirmationModal
        {...restProps}
        customIcon={cancel ? <CancelIcon /> : <SendIcon />}
        wide={wide}
      />
    </div>
  );
};

export default AccountsPayableConfirmation;
