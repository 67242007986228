import { flow, Instance, types } from 'mobx-state-tree';
import ProfileContractService from './service';
import { API_STATUS } from '../constants';
import CommonStore from '../common';

const ProfileContractStore = types
  .compose(
    CommonStore,
    types.model({
      loading: false,
      contractUrl: '',
    }),
  )
  .views(() => ({
    get service() {
      return new ProfileContractService();
    },
  }))
  .actions((self) => ({
    getProfileContract: flow(function* (prodCode: string) {
      self.loading = true;
      const { data: res } = yield self.service.getProfileContract(prodCode);
      self.loading = false;
      const { meta, url } = res;

      if (API_STATUS?.SUCCESS === meta?.status) {
        self.contractUrl = url;
      }
      return res;
    }),
  }));

export type ProfileContractStoreType = Instance<typeof ProfileContractStore>;

export default ProfileContractStore;
