import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';
import QRCode from 'qrcode.react';

import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Logo } from '@assets/images/logos/oyster_gray.svg';
import { DATE_FORMAT_V1, TIME_FORMAT } from '@constants/date';
import { TicketStatus } from '@constants/paymentOrder';
import { camelize } from '@utils/stringUtils';
import useMakeCheckoutUrl from '@hooks/useMakeCheckoutUrl';

import To from './components/To';
import Total from './components/Total';
import CopyLink from './components/CopyLink';
import TicketItem from './components/TicketItem';
import { ITicketProps } from './types';
import useStyles from './styles';

const Ticket: FC<ITicketProps> = ({
  link,
  onCopyLink,
  showLink = true,
  hideTo = false,
  details: {
    userFullName,
    ticketNumber,
    title,
    status,
    payeeName,
    payeeEmail,
    ticketItems,
    paymentDate,
    refundedDate,
    createdAt,
    totalNetAmount = 0,
    total = 0,
    fee = 0,
    refundedAmount = 0,
    refundFee = 0,
  },
}) => {
  const css = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const getCheckoutUrl = useMakeCheckoutUrl();

  const checkoutUrl = link && getCheckoutUrl(link);
  const createdDate = createdAt && moment(createdAt).utc().format(DATE_FORMAT_V1);
  const createdTime = createdAt && moment(createdAt).utc().format(TIME_FORMAT);
  const paidDate = paymentDate && moment(paymentDate).utc().format(DATE_FORMAT_V1);
  const refundDate = refundedDate && moment(refundedDate).utc().format(DATE_FORMAT_V1);
  const isSecondaryView =
    link || status === TicketStatus.SETTLED || status === TicketStatus.REFUNDED;
  const totalToDisplay = status === TicketStatus.REFUNDED ? refundedAmount : total;
  const headerCls = clsx('ticket-header-info-wrapper', {
    'secondary-view': isSecondaryView,
  });

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <div className={css.root}>
      <div data-testid="ticket" className="ticket">
        <section className={css.header}>
          <div className={headerCls}>
            <Grid container className="ticket-header-info">
              <Grid item className="ticket-header-info-col">
                <label className="ticket-status">
                  <strong className={camelize(status)}>
                    {t(`collect.checkout.ticket.status.${status}`)}
                  </strong>{' '}
                  {(status === TicketStatus.PAID || status === TicketStatus.SETTLED) && paidDate}
                  {status === TicketStatus.REFUNDED &&
                    `${t('collect.layout.datePrefix')} ${refundDate}`}
                </label>
              </Grid>
              {isSecondaryView && <Total amount={totalToDisplay} />}
              <Grid className="ticket-header-info-col">
                <div className="ticket-title">
                  <label className={`${css.text} muted`}>{title}</label>
                </div>
                <div className="ticket-number">
                  <label className={`${css.text} muted`}>{ticketNumber}</label>
                </div>
              </Grid>
            </Grid>
            {!isSecondaryView && <Total amount={totalToDisplay} />}
          </div>
          {checkoutUrl && <QRCode value={checkoutUrl} />}
        </section>
        {status === TicketStatus.REFUNDED && (
          <section className={css.body}>
            <Grid container className="payee-info">
              <Grid container className="payee-info-row">
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.refunded.amount')}
                </Grid>
                <Grid item xs={4} className={css.alignRight}>
                  {formatMoney(+refundedAmount)}
                </Grid>
              </Grid>
              <Grid container className={css.feeContainer}>
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.refunded.fee')}
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(css.alignRight, {
                    [css.refundAmount]: +refundFee === 0,
                    [css.fee]: +refundFee > 0,
                  })}
                >
                  {formatMoney(+refundFee)}
                </Grid>
              </Grid>
              <Grid container className={css.total}>
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.refunded.total')}
                </Grid>
                <Grid item xs={4} className={css.alignRight}>
                  {formatMoney(+refundedAmount + +refundFee)}
                </Grid>
              </Grid>
            </Grid>
          </section>
        )}
        {status === TicketStatus.SETTLED && (
          <section className={css.body}>
            <Grid container className="payee-info">
              <Grid container className="payee-info-row">
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.settled.amount')}
                </Grid>
                <Grid item xs={4} className={css.alignRight}>
                  {formatMoney(+total)}
                </Grid>
              </Grid>
              <Grid container className={css.feeContainer}>
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.settled.fee')}
                </Grid>
                <Grid item xs={4} className={`${css.fee} ${css.alignRight}`}>
                  -{formatMoney(+fee)}
                </Grid>
              </Grid>
              <Grid container className={css.total}>
                <Grid item xs={8}>
                  {t('collect.checkout.ticket.settled.total')}
                </Grid>
                <Grid item xs={4} className={css.alignRight}>
                  {formatMoney(+totalNetAmount)}
                </Grid>
              </Grid>
            </Grid>
          </section>
        )}
        <section className={css.body}>
          <Grid container className="payee-info">
            {!hideTo && <To payeeEmail={payeeEmail} payeeName={payeeName} />}
            <Grid container className="payee-info-row">
              <Grid item xs={2}>
                {t('collect.checkout.ticket.from')}
              </Grid>
              <Grid item xs={10}>
                <strong>{userFullName}</strong>
              </Grid>
            </Grid>
          </Grid>
          {checkoutUrl && showLink && <CopyLink link={checkoutUrl} onCopyLink={onCopyLink} />}
          <div className="ticket-list-collapse">
            <ListItem button onClick={toggleList} data-testid="ticket-list-trigger">
              <ListItemText primary={t('collect.checkout.ticket.details')} />
              {open ? <ExpandLess className={css.icon} /> : <ExpandMore className={css.icon} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit data-testid="ticket-list">
              {ticketItems.map((item, index) => (
                <TicketItem key={index} item={item} />
              ))}
            </Collapse>
          </div>
        </section>
        <section className={css.footer}>
          <div className="ticket-date">
            {t('collect.checkout.ticket.createAt', { date: createdDate, time: createdTime })}
          </div>
          <div>
            <Logo className="oyster-logo" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Ticket;
