import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    settingsLink: {
      textDecoration: 'none',
      color: 'unset',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    optionsContainer: {
      flex: 1,
      flexBasis: 'auto',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        '& button': {
          color: theme.oysterTheme.oyster,
        },
      },
    },
    menuOption: {
      width: 'auto',
      gap: '0.5em',
      fontSize: 12,
      letterSpacing: '0.01em',
      color: theme.oysterTheme.oyster,
      display: 'flex',
      margin: 0,
      padding: '1em 2em',
      borderBottom: `1px solid ${theme.oysterTheme.pearl}`,
      borderRadius: 0,
      fontWeight: 500,
      background: 'none',
      '&:hover, &:active': {
        background: 'none',
        color: theme.oysterTheme.opal,
      },
    },
    optionIcon: {
      width: '0.5em',
      height: '0.5em',
    },
    noBorder: {
      border: 'unset',
    },
    drawer: {
      backgroundColor: theme.oysterTheme.stone,
    },
  });

export default makeStyles(styles);
