import { FC } from 'react';
import { IconProps } from '@yaydoo/react-components/lib/icons/types';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';

export enum TypeOptions {
  PAYMENT_LINKS = 'PAYMENT LINKS',
  DEPOSITS = 'DEPOSITS',
}

export interface FilterProps {
  onChangeType: (newType: TypeOptions) => void;
  onChangeLinkStatus: (newStatus: PaymentLinkFilterStatus) => void;
  currentType: TypeOptions;
  currentLinkStatus: PaymentLinkFilterStatus;
}

type option<T> = {
  label: string;
  icon: FC<IconProps>;
  value: T;
};

export interface FilterComponentProps extends FilterProps {
  linkStatusOptions: option<PaymentLinkFilterStatus>[];
}
