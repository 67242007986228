import { getString } from 'firebase/remote-config';

export type Environments = 'local' | 'dev' | 'stage' | 'prod' | 'prod1';

const PATH = 'web_hub_dashboard';

class Variables {
  isLoaded: boolean;
  api: string;
  authApp: string;
  cookieName: string;
  cookieDomain: string;
  deviceIdExpMins: string;
  title: string;
  description: string;
  trackingId: string;
  pmExpTimeZone: string;
  s3Assets: string;
  paymentLinksUrl: string;
  invoicingUrl: string;
  upsellUrl: string;
  txnApi: string;
  showInvoicing: boolean;
  paymentCheckoutUrl: string;
  securityCookieName?: string;
  cacaoOnboardedCookieName?: string;
  showInvoiceStamp?: boolean;
  apiHeader?: string;
  environment: string;

  /**
   *  @deprecated use Environments type instead
   * */
  enviroment: string;

  constructor() {
    this.isLoaded = false;
    this.api = '';
    this.authApp = '';
    this.cookieName = '';
    this.cookieDomain = '';
    this.deviceIdExpMins = '';
    this.title = '';
    this.description = '';
    this.trackingId = '';
    this.pmExpTimeZone = '';
    this.s3Assets = '';
    this.paymentLinksUrl = '';
    this.invoicingUrl = '';
    this.upsellUrl = '';
    this.txnApi = '';
    this.showInvoicing = false;
    this.securityCookieName = '';
    this.cacaoOnboardedCookieName = '';
    this.paymentCheckoutUrl = '';
    this.showInvoiceStamp = false;
    this.environment = '';

    /**
     *  @deprecated use Environments type instead
     * */
    this.enviroment = this.environment;
  }

  init(environment: Environments): void {
    const { remoteConfig } = window.__oyster_firebase;
    const values = getString(remoteConfig, PATH);
    const parsedValues = JSON.parse(values);
    const environmentValues = parsedValues[environment] ? parsedValues[environment] : {};

    this.api = environmentValues.api || '';
    this.authApp = environmentValues.authApp || '';
    this.cookieName = environmentValues.cookieName || '';
    this.cookieDomain = environmentValues.cookieDomain || '';
    this.deviceIdExpMins = environmentValues.deviceIdExpMins || '';
    this.title = environmentValues.title || '';
    this.description = environmentValues.description || '';
    this.trackingId = environmentValues.trackingId || '';
    this.pmExpTimeZone = environmentValues.pmExpTimeZone || '';
    this.s3Assets = environmentValues.s3Assets || '';
    this.paymentLinksUrl = environmentValues.paymentLinksUrl || '';
    this.invoicingUrl = environmentValues.invoicingUrl || '';
    this.upsellUrl = environmentValues.upsellUrl || '';
    this.txnApi = environmentValues.txnApi || '';
    this.securityCookieName = environmentValues.securityCookieName || '';
    this.cacaoOnboardedCookieName = environmentValues.cacaoOnboardedCookieName || '';
    this.paymentCheckoutUrl = environmentValues.paymentCheckoutUrl || '';
    this.showInvoicing = environmentValues.showInvoicing;
    this.showInvoiceStamp = environmentValues.showInvoiceStamp;
    this.apiHeader = environmentValues.apiHeader;
    this.environment = environmentValues.environment || '';
    this.isLoaded = true;

    /**
     *  @deprecated use Environments type instead
     * */
    this.enviroment = this.environment;
  }
}

/**
 *  @deprecated use Environments type instead
 * */
export type Enviroments = Environments;

export default Variables;
