export default {
  reports: {
    daily: 'Reporte Diario',
    monthly: 'Reporte Mensual',
    empty: {
      title: 'Aún no cuentas con ningún depósito',
      msg: 'En esta sección podrás descargar el reporte de los depósitos que irás recibiendo por tus ventas con Oyster Link. No esperes más y comienza a crear tu historial de actividad al crear tu primer Oyster Link.',
      createPaymentLink: 'Crear un Oyster Link',
    },
  },
  dailyTable: {
    settlementId: 'ID del depósito',
    settledAt: 'Fecha de depósito',
    totalNetAmount: 'Cantidad depositada',
    fees: 'Comisiones',
    status: 'Estatus',
    settled: 'Depositado',
  },
  deposit: {
    detail: {
      status: 'Estatus',
      settled: 'Depositado',
      settlementId: 'ID del depósito',
      totalPayments: 'Total de pagos',
      subTotal: 'Subtotal',
      totalFee: 'Total comisión',
      totalDeposit: 'Total depositado',
      downloadReceipt: 'Descargar CEP',
      errorDownloadTitle: 'Hubo un error',
      errorDownloadMsg: 'No se pudo descargar el archivo.',
    },
    table: {
      paymentId: 'ID Pago',
      paymentDate: 'Fecha de pago',
      concept: 'Concepto',
      grossAmount: 'Monto Bruto',
      fee: 'Comisión',
      netAmount: 'Monto neto',
      currency: 'Moneda',
      paymentMethod: 'Método de pago',
    },
  },
};
