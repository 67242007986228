import clsx from 'clsx';
import { FC } from 'react';
import mergewith from 'lodash.mergewith';
import Switch from '@material-ui/core/Switch';

import useStyles from './styles';
import { ISwitchClasses, IOysterSwitchProps } from '@components/common/Switch/types';

const OysterSwitch: FC<IOysterSwitchProps> = ({ classes, ...props }) => {
  const css: ISwitchClasses = useStyles();
  const switchClasses: ISwitchClasses = mergewith(css, classes, clsx);
  return <Switch disableRipple classes={switchClasses} {...props} />;
};

export default OysterSwitch;
