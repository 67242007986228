import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isWebhookFetched: false,
    isWebhookLoading: false,
    isUpdatingWebhook: false,
    isURLSavedSuccess: false,
    isURLSavedError: false,
    isEditEnabled: false,
    enableSuccess: false,
    disableSuccess: false,
    isSendingNotification: false,
    sendingNotificationSuccess: false,
    sendingNotificationError: false,
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
