import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) =>
  createStyles({
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    titles: {
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'column',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 14,
      textAlign: 'center',
    },
    header: {
      backgroundColor: theme.oysterTheme.stone,
      height: 200,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(4),
    },
    formContainer: {
      margin: 'auto',
      width: '80%',
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

export default styles;
