import { createStyles, makeStyles } from '@material-ui/core/styles';

type WithCha = boolean | undefined;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: ({ withCha }: { withCha: WithCha }) => ({
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridTemplateColumns: withCha ? 'minmax(max-content, 1fr) minmax(auto, max-content)' : '1fr',
      maxHeight: '100vh',
      width: '100%',
      padding: theme.spacing(3),
    }),
    content: {
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'auto',
    },
    cha: {
      gridRow: 'span 2',
    },
  }),
);

export default useStyles;
