import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    list: {
      width: '100%',
    },
    iconContainer: {
      position: 'relative',
    },
    icon: {
      fontSize: 24,
      color: theme.oysterTheme.charcoal,
    },
    iconText: {
      lineHeight: 1,
      position: 'absolute',
      bottom: '-1.2em',
      left: 3,
      userSelect: 'none',
      display: 'block',
      fontSize: 10,
      fontWeight: 600,
      color: theme.oysterTheme.charcoal,
    },
    item: {
      width: '100%',
      lineHeight: 1.3,
    },
    itemTitle: {
      color: theme.oysterTheme.charcoal,
      [theme.breakpoints.up('md')]: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    itemLinkNumber: {
      display: 'block',
    },
    itemCreatedAt: {
      display: 'block',
    },
    itemTotal: {
      display: 'block',
      textAlign: 'right',
      fontSize: 14,
      marginBottom: theme.spacing(0.5),
    },
    alignRight: {
      display: 'flex',
      alignItems: 'end',
    },
    refund: {
      color: theme.oysterTheme.dolphin,
      fontSize: 10,
      fontWeight: 600,
    },
  });

export default makeStyles(styles);
