import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

interface ToProps {
  payeeName: string;
  payeeEmail: string;
}

const To: FC<ToProps> = ({ payeeName, payeeEmail }) => {
  const { t } = useTranslation();

  return (
    <Grid container className="payee-info-row">
      <Grid item xs={2}>
        {t('collect.checkout.ticket.to')}
      </Grid>
      <Grid item xs={10}>
        <div>
          <strong>{payeeName}</strong>
        </div>
        <div>{payeeEmail}</div>
      </Grid>
    </Grid>
  );
};

export default To;
