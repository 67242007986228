import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import OperationsResume from '@components/OperationsResume';
import Button from '@yaydoo/react-components/lib/components/FormButton';

import useStyles from './styles';

export interface IValidation {
  successDate: string;
  successTotalAmount: string;
  successOperations: number;
  errorDate: string;
  errorTotalAmount: string;
  errorOperations: number;
  onSeeDetails: () => void;
  onValidate: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
}

const Validation: FC<IValidation> = ({
  successDate,
  successTotalAmount,
  successOperations,
  errorDate,
  errorTotalAmount,
  errorOperations,
  onSeeDetails,
  onValidate,
  onCancel,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const css = useStyles();
  const hasErrors = errorDate || errorOperations;
  const hasSuccessOperations = successTotalAmount && successOperations;

  return (
    <div className={css.infoActionsContainer}>
      <div>
        <OperationsResume
          success
          title={t('batch.dashboard.proccessingBatch.stepper.validation.success')}
          totalOperations={successOperations}
          date={successDate}
          totalAmount={successTotalAmount}
        />
        {!!hasErrors && (
          <>
            <p className={css.optionalText} />
            <OperationsResume
              title={t('batch.dashboard.proccessingBatch.stepper.validation.error')}
              totalOperations={errorOperations}
              date={errorDate}
              totalAmount={errorTotalAmount}
            />
          </>
        )}
        <Button
          className={css.seeDetailsButton}
          variant="outlined"
          color="secondary"
          fullWidth
          label={t('batch.common.labels.seeDetails')}
          onClick={onSeeDetails}
        />
        <br />
        <br />
      </div>
      <div className={css.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          label={t('batch.dashboard.proccessingBatch.stepper.validation.validate')}
          onClick={onValidate}
          disabled={!hasSuccessOperations || isSubmitting}
          isSubmitting={isSubmitting}
        />
        <br />
        <br />
        <Button
          className={css.cancelButton}
          variant="text"
          fullWidth
          label={t('batch.common.labels.cancelOperation')}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default Validation;
