import { FC } from 'react';
import { IconProps, obsidian } from './types';

const DisbursementIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4L3 12.0667L8.99995 16.1L21 8.03337L15 4ZM17.9016 7.32546C18.1841 7.32546 18.4547 7.39945 18.6537 7.53324C18.8527 7.66702 18.9639 7.84885 18.9627 8.03879C18.9615 8.22877 18.8483 8.41129 18.6477 8.54614C18.2299 8.82693 17.5553 8.8287 17.1409 8.55009C16.9419 8.41634 16.8307 8.23451 16.8318 8.04457C16.833 7.85459 16.9463 7.67208 17.1469 7.53723C17.3475 7.40238 17.619 7.32612 17.9016 7.32536V7.32546ZM12.0126 7.94519C12.843 7.94286 13.6381 8.16257 14.2229 8.55574C15.4408 9.37443 15.4329 10.7071 14.2052 11.5324C13.6157 11.9287 12.8179 12.1526 11.9874 12.1548C11.157 12.1572 10.362 11.9375 9.77711 11.5444C9.19222 11.1512 8.86552 10.6167 8.86884 10.0585C8.8722 9.50023 9.20521 8.96394 9.7948 8.56762C10.3844 8.1713 11.1821 7.94742 12.0126 7.94519H12.0126ZM10.5339 8.64222L10.008 8.99575L10.5472 9.35811C10.1725 9.71826 10.1633 10.0916 10.5589 10.3576C11.4236 10.9389 12.4806 9.91291 12.8517 10.1624C12.9637 10.2377 12.9174 10.3394 12.7621 10.4437C12.5081 10.6145 11.9341 10.7838 11.4665 10.8111L11.7444 11.368C12.1699 11.3314 12.6276 11.1978 13.0083 11.0125L13.5684 11.3891L14.0943 11.0356L13.5411 10.6637C13.9227 10.3225 14.0201 9.92764 13.5719 9.62641C12.6862 9.03101 11.6081 10.0428 11.2475 9.80043C11.1564 9.73923 11.1957 9.6564 11.3192 9.57336C11.5274 9.43341 12.0169 9.27853 12.4146 9.20422L12.1261 8.64502C11.7778 8.6956 11.394 8.82179 11.0625 8.99754L10.5339 8.64222H10.5339ZM20.1298 9.39834L8.99995 16.88L3.87024 13.4317L3 14.0167L8.99995 18.0501L21 9.98332L20.1298 9.39834ZM6.10699 11.3421C6.38958 11.3421 6.66015 11.416 6.85916 11.5498C7.05818 11.6836 7.16926 11.8655 7.1681 12.0554C7.1669 12.2454 7.0537 12.4278 6.85312 12.5627C6.43537 12.8435 5.76076 12.8453 5.34634 12.5666C5.14732 12.4329 5.03616 12.2511 5.03728 12.0611C5.03848 11.8712 5.1518 11.6886 5.35238 11.5538C5.55301 11.4189 5.82443 11.3428 6.10699 11.342V11.3421ZM20.1298 11.3484L8.99995 18.83L3.87024 15.3818L3 15.9668L8.99995 20L21 11.9334L20.1298 11.3484Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

DisbursementIcon.defaultProps = {
  fill: obsidian,
};

export default DisbursementIcon;
