import { useContext } from 'react';
import { DrawerContext } from '@components/DrawerWrapper/DrawerContext';
import { IOptionsDrawer, IShowDrawer, IUseDrawer } from '@components/DrawerWrapper/types';

const defaultOptions: IOptionsDrawer = {
  title: '',
  subtitle: '',
  showCloseButton: true,
  anchor: 'right',
  useCustomDrawer: false,
};

const useDrawer = (): IUseDrawer => {
  const { setDrawerComponent, closeDrawer } = useContext(DrawerContext);

  const showDrawer = ({ component, props = {}, options = {} }: IShowDrawer) => {
    setDrawerComponent({ component, props, options: { ...defaultOptions, ...options } });
  };

  return { showDrawer, closeDrawer };
};

export default useDrawer;
