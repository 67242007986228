import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

export default class UserService extends OysterService {
  /**
   * Get authenticated user details
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchUser(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/user');
  }

  /**
   * Upload user profile pic
   * @param formData
   * @returns Promise<AxiosResponse | AxiosError>
   */
  uploadProfilePic(formData: FormData): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/user/profile/picture', formData);
  }

  /**
   * Fetch user intercom hash
   * @returns Promise<AxiosResponse | AxiosError>
   */
  fetchIntercomHash(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/user/intercom/hash');
  }

  /**
   * Fetch initial access user
   * @returns Promise<AxiosResponse | AxiosError>
   */
  fetchInitialAccess(): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/user/initial/access');
  }
}
