import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CustomTable from '@yaydoo/react-components/lib/components/CustomTable';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import AccountsPayableStatus from '@components/AccountsPayableStatus';
import EmptyListSkeleton from '@components/EmptyListSkeleton';
import InfiniteScroll from '@components/InfiniteScroll';
import { IAccountsPayableListItem } from '@store/batchDetails/models/AccountsPayableListItem';
import { formattedAmount } from '@utils/format';

import useStyles from './styles';

export interface IDetailsList {
  isFetching: boolean;
  accountsPayableList: IAccountsPayableListItem[];
  hasMore: boolean;
  getMoreDetails: () => void;
}

const DetailsList: FC<IDetailsList> = ({
  isFetching,
  accountsPayableList,
  hasMore,
  getMoreDetails,
}) => {
  const css = useStyles();
  const { t } = useTranslation();
  const isEmptyList = !accountsPayableList.length;

  if (isEmptyList) {
    return (
      <EmptyListSkeleton
        label={t('batch.details.batchDetailList.emptyStateLabel')}
        loadingLabel={t('batch.dashboard.batchList.loadingLabel')}
        isLoading={isFetching}
      />
    );
  }

  const translationRoot = 'batch.details.batchDetailList.headers';
  const columnItem = (label: string) => {
    return <span className={css.columnItem}>{label}</span>;
  };

  const operationsRows = (rows: IAccountsPayableListItem[]) => {
    return rows.map(
      ({
        createdAt,
        businessName,
        clabe,
        amount,
        status,
        errMsg,
        trackingId,
        cepUrl,
        currency,
      }: IAccountsPayableListItem) => {
        const error = status !== AccountsPayableLinkStatus.DISPERSION_SUCCESS;
        return {
          createdAt: <span className={css.commonCell}>{createdAt}</span>,
          businessName: <span className={css.businessName}>{businessName}</span>,
          clabe: <span className={`${css.commonCell} ${css.wordBreak}`}>{clabe}</span>,
          amount: (
            <span className={`${css.amount} ${error && css.dispersionFail}`}>{`${formattedAmount(
              amount,
              currency || 'MXN',
            )} ${currency || 'MXN'}`}</span>
          ),
          status: <AccountsPayableStatus status={status} />,
          errMsg: (
            <span className={`${css.commonCell} ${error && css.error}`}>
              {errMsg ? errMsg : t('batch.details.batchDetailList.doesNotApply')}
            </span>
          ),
          trackingId: <span className={`${css.commonCell} ${css.wordBreak}`}>{trackingId}</span>,
          cepUrl: (
            <a href={cepUrl} target="_blank" rel="noreferrer noopener" className={css.link}>
              {t('batch.details.batchDetailList.seeCEP')}
            </a>
          ),
        };
      },
    );
  };

  const columns = [
    {
      label: columnItem(t(`${translationRoot}.createdAt`)),
      name: 'createdAt',
    },
    {
      label: columnItem(t(`${translationRoot}.businessName`)),
      name: 'businessName',
    },
    {
      label: columnItem(t(`${translationRoot}.clabe`)),
      name: 'clabe',
    },
    {
      label: columnItem(t(`${translationRoot}.amount`)),
      name: 'amount',
    },
    {
      label: columnItem(t(`${translationRoot}.status`)),
      name: 'status',
    },
    {
      label: columnItem(t(`${translationRoot}.details`)),
      name: 'errMsg',
    },
    {
      label: columnItem(t(`${translationRoot}.trackingId`)),
      name: 'trackingId',
    },
    {
      label: '',
      name: 'cepUrl',
    },
  ];

  const rows = operationsRows(accountsPayableList);

  return (
    <div className={css.root}>
      <InfiniteScroll
        next={getMoreDetails}
        hasMore={hasMore}
        dataLength={accountsPayableList?.length}
        scrollableTarget="list-container"
      >
        <CustomTable columns={columns} data={rows} emptyFetchingRows={10} oddColorRows={false} />
      </InfiniteScroll>
    </div>
  );
};

export default DetailsList;
