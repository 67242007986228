import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      width: '100%',
    },
    formLabel: {
      paddingTop: 29,
      fontSize: 14,
      fontWeight: 400,
      color: theme.oysterTheme.shark,
      paddingBottom: 30,
    },
    formControlLabel: {
      '& .MuiFormControlLabel-label': {
        width: '100% !important',
      },
    },
    labelIcon: {
      marginRight: 15,
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.oysterTheme.obsidian,
    },
    formControlLabelContainer: {
      display: 'flex',
    },
  }),
);

export default useStyles;
