import { ReactElement } from 'react';

import { IFilter } from './types';
import DesktopFilter from './Desktop';
import MobileFilter from './Mobile';

const Filter = <T,>({ isMobile, ...filterProps }: IFilter<T>): ReactElement => {
  return isMobile ? <MobileFilter {...filterProps} /> : <DesktopFilter {...filterProps} />;
};

export default Filter;
