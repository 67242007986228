import { cast, flow, Instance, types } from 'mobx-state-tree';
import CreditCommonStore from '@yaydoo/react-mobx-stores/lib/creditCommon';
import debug from 'debug';
import { PaymentOrderFilterStatus } from '@constants/paymentOrder';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';

import PaymentLink, { LinkStatusStore } from './models/Link';
import Pagination from '@store/common/pagination';
import PaymentOrderFilter from '../common/order/Filter';
import PaymentOrder from '../common/order';
import PaymentLinkFilter from './models/Filter';
import PaymentFlags from './models/Flags';
import Balance from './models/Balance';
import Errors from './models/Errors';

import PaymentLinkService from './service';
import {
  PaymentLink as PaymentLinkData,
  PaymentLinkFetchingOptions,
  PaymentLinkOrdersQueryParams,
  PaymentLinkQueryParams,
} from './types';

const log = debug('PaymentStore:log');

const LinksStore = types
  .model({
    links: types.array(PaymentLink),
    link: types.maybeNull(PaymentLink),
    linkOrders: types.array(PaymentOrder),
    filters: types.optional(PaymentLinkFilter, {
      pageSize: 25,
    }),
    linkOrderFilters: types.optional(PaymentOrderFilter, {
      status: PaymentOrderFilterStatus.PAID,
      pageSize: 25,
    }),
    flags: types.optional(PaymentFlags, {}),
    errors: types.optional(Errors, {}),
    status: types.optional(LinkStatusStore, PaymentLinkFilterStatus.ALL),
    linksPagination: types.optional(Pagination, { pageSize: 25 }),
    kyc: types.optional(CreditCommonStore, {}),
    balance: types.optional(Balance, {}),
  })
  .volatile((): { service: PaymentLinkService | null } => ({ service: null }))
  .views((self) => ({
    get PaymentLinkService() {
      if (!self.service) throw new Error('No service found');
      return self.service;
    },
    get isFetchingDetails() {
      return (
        !self.flags.isFetchingMoreOrders &&
        (self.flags.isFetchingLinkDetails || self.flags.isFetchingLinkOrders)
      );
    },
  }))
  .actions((self) => ({
    updateService: () => {
      self.service = new PaymentLinkService();
    },
    uploadPaymentLinkBatchFile: flow(function* (fileContent: string) {
      log('trying to upload payment link batch file');
      yield self.PaymentLinkService.uploadPaymentLinkBatchFile(fileContent);
    }),
    getPaymentLink: flow(function* (uuid: string) {
      if (self.flags.isFetchingLinkDetails) return;

      self.flags.isFetchingLinkDetails = true;
      try {
        const { data } = yield self.PaymentLinkService.getPaymentLink(uuid);

        self.link = data.paymentLink;
      } finally {
        self.flags.isFetchingLinkDetails = false;
      }
    }),
    getPaymentLinkOrders: flow(function* (uuid: string, params?: PaymentLinkOrdersQueryParams) {
      if (self.flags.isFetchingLinkOrders) return;

      self.flags.isFetchingLinkOrders = true;
      try {
        const queryParams: PaymentLinkOrdersQueryParams = {
          page: self.linkOrderFilters.page,
          status: self.linkOrderFilters.status,
          pageSize: self.linkOrderFilters.pageSize,
          ...params,
        };

        const { data } = yield self.PaymentLinkService.getPaymentLinkOrders(uuid, queryParams);
        const orders = data.paymentOrders ?? [];

        self.linkOrders = cast([...self.linkOrders, ...orders]);
        self.linkOrderFilters.setData(data.pagination);
      } finally {
        self.flags.isFetchingLinkOrders = false;
      }
    }),
    resetLinks: () => {
      self.links = cast([]);
    },
    updateStatus: (status: PaymentLinkQueryParams['status']) => {
      if (status !== undefined && status !== self.status) self.status = status;
    },
  }))
  .actions((self) => ({
    getPaymentLinks: flow(function* (
      params: PaymentLinkQueryParams = {},
      options: PaymentLinkFetchingOptions = {},
    ) {
      const hasLinks = self.links.length > 0;
      const isSameStatus = self.status === params.status || !params.status;
      const isNextPage = params.page && params.page > self.linksPagination.page;
      const alreadyFetched = isSameStatus && hasLinks && !isNextPage;
      const shouldReturn = (alreadyFetched && !options.force) || self.flags.isFetchingLinks;

      if (shouldReturn) return;

      const linkParams = {
        page: self.linksPagination.page,
        pageSize: self.linksPagination.pageSize,
        status: self.status,
        ...params,
      };

      self.flags.isFetchingLinks = true;

      try {
        if (linkParams.status !== self.status) {
          self.resetLinks();
          self.updateStatus(linkParams.status);
        }

        const { data } = yield self.PaymentLinkService.getPaymentLinkList(linkParams);
        const paymentLinks = data.paymentLinks ?? [];
        const links = options.force ? paymentLinks : [...self.links, ...paymentLinks];

        self.links = cast(links);
        self.linksPagination.setData(data.pagination);
      } finally {
        self.flags.isFetchingLinks = false;
      }
    }),
  }))
  .actions((self) => ({
    refreshLinks: flow(function* () {
      yield self.getPaymentLinks({ page: 1 }, { force: true });
    }),
    clearLinks: () => {
      self.links = cast([]);
      self.linksPagination.page = 1;
      self.status = PaymentLinkFilterStatus.ALL;
    },
    clearPaymentLinkData: () => {
      self.link = null;
      self.linkOrders = cast([]);
      self.linkOrderFilters.page = 1;
    },
    refreshOrders: flow(function* () {
      if (!self.link) return;

      const { pageSize } = self.linkOrderFilters;
      const { paymentLinkId } = self.link;
      self.linkOrders = cast([]);
      yield self.getPaymentLinkOrders(paymentLinkId, { pageSize, page: 1 });
    }),
  }))
  .actions((self) => ({
    markPaymentLinkAsPaid: flow(function* (paymentLinkId: string) {
      yield self.PaymentLinkService.markPaymentLinkAsPaid(paymentLinkId);
      const link = self.links.find(({ paymentLinkId: id }) => paymentLinkId === id);
      link?.markAsPaid();
    }),
    deletePaymentLink: flow(function* (paymentLinkId: string) {
      yield self.PaymentLinkService.deletePaymentLink(paymentLinkId);
      self.links = cast(self.links.filter(({ paymentLinkId: id }) => paymentLinkId !== id));
    }),
    getPaymentLinkData: flow(function* (uuid: string) {
      const { status, page, pageSize } = self.linkOrderFilters;

      yield Promise.all([
        self.getPaymentLink(uuid),
        self.getPaymentLinkOrders(uuid, { status, page, pageSize }),
      ]);
    }),
    getMoreOrders: flow(function* () {
      if (!self.link?.paymentLinkId || self.flags.isFetchingMoreOrders) return;
      self.flags.isFetchingMoreOrders = true;

      const { nextPage: page } = self.linkOrderFilters;
      yield self.getPaymentLinkOrders(self.link?.paymentLinkId, { page });

      self.flags.isFetchingMoreOrders = false;
    }),
    getMoreLinks: flow(function* () {
      const { nextPage: page, pageSize } = self.linksPagination;
      yield self.getPaymentLinks({ page, pageSize });
    }),
    createLink: flow(function* (data: PaymentLinkData) {
      const { data: link } = yield self.PaymentLinkService.createPaymentLink(data);
      return link?.paymentLink?.paymentLinkId || '';
    }),
    sendPaymentLinksReport: flow(function* (params: { to: string; from: string }) {
      yield self.PaymentLinkService.sendPaymentLinksReport(params);
    }),
    refundPaymentOrder: flow(function* (orderId: string) {
      self.flags.isRefunding = true;
      yield self.PaymentLinkService.refundPaymentOrder(orderId);
      self.flags.isRefunding = false;
    }),
    getBalance: flow(function* () {
      self.flags.isFetchingBalance = true;
      const { data } = yield self.PaymentLinkService.getBalance();

      self.balance = data.balance;
      self.flags.isFetchingBalance = false;
    }),
  }));

export interface LinksStoreType extends Instance<typeof LinksStore> {}
export default LinksStore;
