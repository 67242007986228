import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      margin: theme.spacing(1),
    },
  });
});

export default styles;
