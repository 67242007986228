import { oysterTheme } from '@yaydoo/react-components/lib/theme/index';

export const defaultTheme = {
  primary: oysterTheme.pine,
  secondary: oysterTheme.oyster,
};

export const validFormatForAssets = 'image/svg+xml';

export type ContactInfo = {
  email?: string;
  phone?: string;
};

export type Assets = {
  logo?: File | string;
  favicon?: File | string;
};

export type Theme = {
  primaryColor?: string;
  secondaryColor?: string;
};

export interface CobrandingData {
  contactInfo: ContactInfo;
  assets: Assets;
  theme: Theme;
}
