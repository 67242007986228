import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import Badge, { IBadge } from '@components/Badge';
import { IAccountsPayableBatchListItem } from '@store/batch/models/AccountsPayableBatchListItem';

import BatchOptions from './BatchOptions';
import useStyles from './styles';

export const getBadgeColor = (status: string): IBadge['color'] => {
  const accountsPayableLinkStep = {
    [`${AccountsPayableLinkStatus.ACTIVE}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.CONFIRMED}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.FUNDED}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.ON_DISPERSION}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.DISPERSION_SUCCESS}`]: 'oyster',
    [`${AccountsPayableLinkStatus.PARTIALLY_DISPERSED}`]: 'mango',
    [`${AccountsPayableLinkStatus.DISPERSION_ERROR}`]: 'ferrari',
    [`${AccountsPayableLinkStatus.CANCELED}`]: 'ferrari',
  };
  return accountsPayableLinkStep[status] as IBadge['color'];
};

export interface IRightComponent {
  batch: IAccountsPayableBatchListItem;
  onCancel: (accountsPayableLinkId: string) => void;
  onSeeDetails: (accountsPayableLinkId: string) => void;
}

const RightComponent: FC<IRightComponent> = ({ batch, onCancel, onSeeDetails }) => {
  const css = useStyles();
  const { t } = useTranslation();

  const batchStatusLabels = {
    [`${AccountsPayableLinkStatus.ACTIVE}`]: t('batch.dashboard.batchList.status.pending'),
    [`${AccountsPayableLinkStatus.CONFIRMED}`]: t('batch.dashboard.batchList.status.pending'),
    [`${AccountsPayableLinkStatus.FUNDED}`]: t('batch.dashboard.batchList.status.pending'),
    [`${AccountsPayableLinkStatus.ON_DISPERSION}`]: t('batch.dashboard.batchList.status.pending'),
    [`${AccountsPayableLinkStatus.DISPERSION_SUCCESS}`]: t(
      'batch.dashboard.batchList.status.success',
    ),
    [`${AccountsPayableLinkStatus.PARTIALLY_DISPERSED}`]: t(
      'batch.dashboard.batchList.status.partiallyDispersed',
    ),
    [`${AccountsPayableLinkStatus.DISPERSION_ERROR}`]: t('batch.dashboard.batchList.status.error'),
    [`${AccountsPayableLinkStatus.CANCELED}`]: t('batch.dashboard.batchList.status.canceled'),
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <Grid container direction="column" className={css.alignRight}>
          <span data-testid="batch-total" className={css.itemTotal}>
            {formatMoney(+batch.amount)}
          </span>
          <span data-testid="operation-number" className={css.operations}>
            {t('batch.dashboard.batchList.totalAccountsPayable', {
              totalAccountsPayable: batch.totalAccountsPayable,
            })}
          </span>
          <Badge label={batchStatusLabels[batch.status]} color={getBadgeColor(batch.status)} />
        </Grid>
      </Grid>
      <Grid item>
        <BatchOptions
          batch={batch}
          onCancel={() => onCancel(batch.accountsPayableLinkId)}
          onSeeDetails={() => onSeeDetails(batch.accountsPayableLinkId)}
        />
      </Grid>
    </Grid>
  );
};

export default RightComponent;
