import { ReactElement, FC } from 'react';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';

import useStyles from './styles';
import OysterYaydooLogo from '../../icons/Logos/OysterYaydoo';

export interface IWebDashboardLayout {
  'data-testid'?: string;
  contentClassName?: string;
  sidebarClassName?: string;
  rightHeaderComp?: ReactElement;
  leftHeaderComp?: ReactElement;
  sidebarComp?: ReactElement;
  sidebarMenu?: ReactElement;
  footerComp?: ReactElement;
}

const WebDashboardLayout: FC<IWebDashboardLayout> = ({
  children,
  'data-testid': dataTestId,
  sidebarClassName,
  contentClassName,
  rightHeaderComp,
  leftHeaderComp,
  sidebarComp,
  sidebarMenu,
  footerComp,
}) => {
  const css = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const sidebarCss = clsx(css.sidebar, sidebarClassName);
  const contentCss = clsx(css.content, contentClassName);

  return (
    <div className={css.root} data-testid={dataTestId || 'WebDashboardLayout'}>
      <div className={sidebarCss}>
        <header className={css.sidebarHeader}>
          {isSmallScreen && leftHeaderComp && (
            <div className={css.leftHeaderComp}>{leftHeaderComp}</div>
          )}
          <OysterYaydooLogo className={css.headerLogo} defaultColor />
          {rightHeaderComp && <div className={css.rightHeaderComp}>{rightHeaderComp}</div>}
        </header>
        <section className={css.sidebarContent}>
          {sidebarComp}
          {sidebarMenu}
        </section>
        {!isSmallScreen && footerComp && (
          <footer className={css.footer} data-testid="sidebarFooter">
            {footerComp}
          </footer>
        )}
      </div>
      <div className={contentCss}>{children}</div>
    </div>
  );
};

export default WebDashboardLayout;
