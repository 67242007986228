import { FC } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { formatPhoneNumber } from '@utils/format';

import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import useStyles from '../../styles';

interface TabProps {
  email?: string;
  phone?: string;
  secondaryColor?: string;
}

const Tab: FC<TabProps> = ({ email, phone, secondaryColor }) => {
  const css = useStyles();
  const { t } = usePaymentToolsTranslation();

  if (!(email || phone)) {
    return null;
  }

  return (
    <Grid item xs={12} className={clsx(css.wrapper, css.noBorder)}>
      <div className={clsx(css.sectionTitle, css.supportHeader)}>
        {t('tools.cobranding.support')}
      </div>
      <div className={css.support}>
        {email && (
          <div className={css.contactInfo}>
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.973841 1.52951C2.98678 3.1826 6.51887 6.09088 7.557 6.99769C7.69636 7.12008 7.84583 7.1823 8.00101 7.1823C8.15587 7.1823 8.30508 7.12066 8.44413 6.99885C9.48314 6.09116 13.0152 3.1826 15.0282 1.52951C15.1535 1.42679 15.1726 1.24623 15.0712 1.12065C14.8366 0.830452 14.4869 0.664062 14.1121 0.664062H1.88991C1.51513 0.664062 1.1654 0.830452 0.930873 1.12067C0.829409 1.24623 0.848516 1.42679 0.973841 1.52951Z"
                fill={secondaryColor}
              />
              <path
                d="M15.1559 2.42606C15.0476 2.37717 14.9202 2.39423 14.8301 2.46889C12.5978 4.30395 9.74872 6.65613 8.85234 7.43938C8.34923 7.87977 7.65099 7.87977 7.14671 7.43879C6.19125 6.60401 2.99186 3.96656 1.16958 2.46887C1.07886 2.3942 0.951158 2.37773 0.843736 2.42603C0.735741 2.47467 0.666504 2.57942 0.666504 2.69487V10.1463C0.666504 10.7999 1.21464 11.3315 1.88874 11.3315H14.111C14.785 11.3315 15.3332 10.7999 15.3332 10.1463V2.69487C15.3332 2.57942 15.2639 2.47439 15.1559 2.42606Z"
                fill={secondaryColor}
              />
            </svg>
            {email}
          </div>
        )}
        {phone && (
          <div className={css.contactInfo}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3262 12.2389C15.3573 12.4772 15.2847 12.6843 15.1088 12.8604L13.0438 14.9111C12.9506 15.0147 12.829 15.1028 12.6789 15.1753C12.5288 15.2478 12.3814 15.2944 12.2365 15.3151C12.2261 15.3151 12.195 15.3178 12.1432 15.3229C12.0916 15.3281 12.0243 15.3307 11.9415 15.3307C11.7447 15.3307 11.4264 15.2971 10.9865 15.2297C10.5466 15.1624 10.0084 14.9966 9.37181 14.7325C8.73512 14.4684 8.01316 14.0722 7.20581 13.544C6.39845 13.0158 5.53929 12.2907 4.62839 11.3688C3.90382 10.6542 3.30346 9.97054 2.82731 9.31803C2.35116 8.66546 1.96817 8.06212 1.67834 7.50798C1.38849 6.95384 1.17112 6.45148 1.0262 6.00092C0.881288 5.55035 0.782953 5.16194 0.731198 4.83567C0.679443 4.50939 0.658741 4.25304 0.669092 4.0666C0.679443 3.88016 0.684618 3.77658 0.684618 3.75586C0.70532 3.61086 0.7519 3.46326 0.824357 3.31307C0.896815 3.16288 0.984799 3.04118 1.08831 2.94796L3.15334 0.881576C3.29826 0.736567 3.46388 0.664062 3.65019 0.664062C3.78476 0.664062 3.9038 0.702904 4.00731 0.780588C4.11082 0.858271 4.1988 0.954081 4.27126 1.06802L5.9326 4.22197C6.02576 4.38769 6.05164 4.56895 6.01023 4.76575C5.96883 4.96255 5.88085 5.12827 5.74628 5.26292L4.98548 6.02422C4.96478 6.04494 4.94666 6.0786 4.93114 6.12521C4.91561 6.17182 4.90785 6.21066 4.90785 6.24174C4.94925 6.45925 5.04241 6.70784 5.18733 6.9875C5.31154 7.23608 5.50303 7.53905 5.76181 7.89639C6.02058 8.25374 6.38805 8.66544 6.8642 9.13154C7.32999 9.60805 7.74403 9.97829 8.10632 10.2425C8.46853 10.5065 8.7714 10.7008 9.01465 10.8251C9.2579 10.9494 9.44422 11.0244 9.57358 11.0503L9.76761 11.0891C9.78832 11.0891 9.82203 11.0813 9.86856 11.0658C9.91514 11.0503 9.94878 11.0322 9.96951 11.0114L10.8545 10.1103C11.0409 9.9446 11.2582 9.86174 11.5066 9.86174C11.6827 9.86174 11.8223 9.89278 11.9258 9.95496H11.9413L14.9379 11.7261C15.1554 11.8609 15.2847 12.0317 15.3262 12.2389Z"
                fill={secondaryColor}
              />
            </svg>
            {formatPhoneNumber(phone)}
          </div>
        )}
      </div>
    </Grid>
  );
};

export default Tab;
