import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import useStore from '@hooks/useStore';
import Loading from '@components/Loading';

import { COLLECT_ROUTES as routes } from '@constants/routes';
import Ticket from './Ticket';

interface RefundTicketProps {
  id: string;
}

const RefundTicket: FC<RefundTicketProps> = ({ id }) => {
  const { paymentLink, deposits } = useStore();
  const { t } = useTranslation();
  const { paymentOrder, getPaymentOrder, flags } = deposits;
  const { location } = useHistory();
  const { openToast } = useToast();
  const { closeDrawer } = useDrawer();

  const shouldDisplayLoader =
    !paymentOrder ||
    !paymentLink.balance ||
    flags.isLoadingOrder ||
    paymentOrder?.paymentOrderId !== id ||
    paymentLink.flags.isRefunding;

  const onRefundPress = async () => {
    try {
      await paymentLink.refundPaymentOrder(id);
      if (location.pathname === routes.DASHBOARD) paymentLink.refreshLinks();
      if (location.pathname.startsWith(routes.LINK_DETAILS)) paymentLink.refreshOrders();
      openToast({
        type: 'success',
        title: t('collect.refund.ticket.toast.success.title'),
        message: t('collect.refund.ticket.toast.success.message'),
      });
    } catch {
      openToast({
        type: 'error',
        title: t('collect.refund.ticket.toast.error.title'),
        message: t('collect.refund.ticket.toast.error.message'),
      });
    } finally {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (paymentOrder?.paymentOrderId !== id) getPaymentOrder(id);
  }, [id]);

  return shouldDisplayLoader ? (
    <Loading />
  ) : (
    <Ticket
      paymentOrder={paymentOrder}
      onRefund={onRefundPress}
      balance={paymentLink?.balance?.balanceFormatted}
    />
  );
};

export default observer(RefundTicket);
