export default {
  sectionTitle: 'LOTE - ID {{batchId}}',
  createdAt: 'Creado: {{createdAt}}',
  csv: 'CSV',
  downloadReport: 'Pudes descargar un reporte en formato csv',
  resume: {
    status: 'ESTATUS',
    totalSuccessAmount: 'TOTAL PAGO EXITOSO:',
    totalFailAmount: 'TOTAL PAGO NO EXITOSOS:',
  },
  listTitle: 'DISPERSIONES REALIZADAS',
  batchDetailList: {
    emptyStateLabel: 'No hay información para mostrar',
    loadingLabel: 'Estamos recuperando tu información...',
    headers: {
      createdAt: 'Fecha',
      businessName: 'Beneficiario',
      clabe: 'CLABE',
      status: 'Estado',
      amount: 'Monto',
      details: 'Detalles',
      trackingId: 'Clave de rastreo',
    },
    doesNotApply: 'N/A',
    seeCEP: 'Ver CEP',
  },
  toast: {
    errors: {
      title: 'Error',
      unknown: 'Intenta de nuevo',
      notFound: 'No se encontró información del lote seleccionado',
    },
    success: {
      title: 'Listo',
      completed: 'Operación completada',
      report: 'Tu archivo csv {{name}} fue generado con éxito',
    },
  },
};
