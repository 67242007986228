import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';

type MakeCheckoutUrl = (orderId: string) => string;

const useMakeCheckoutUrl = (): MakeCheckoutUrl => {
  const variables = useEnvironmentVars();

  return (orderId: string) => `${variables.paymentCheckoutUrl}/${orderId}`;
};

export default useMakeCheckoutUrl;
