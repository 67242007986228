import { Instance, types } from 'mobx-state-tree';

export const emptyItem = {
  itemId: '',
  paymentItemCollectionId: '',
  name: '',
  quantity: '',
  price: '',
  total: '0.00',
};

export const defaultItem = {
  itemId: '',
  paymentItemCollectionId: '',
  name: '',
  quantity: 1,
  price: 0,
  total: 0,
};

const LinkItem = types
  .model({
    itemId: '',
    paymentItemCollectionId: '',
    name: '',
    quantity: types.optional(types.union(types.number, types.string), ''),
    price: types.optional(types.union(types.number, types.string), ''),
    total: types.optional(types.union(types.number, types.string), ''),
  })
  .views((self) => ({
    get viewableItems() {
      return {
        itemId: self.itemId,
        paymentItemCollectionId: self.paymentItemCollectionId,
        name: self.name,
        quantity: self.quantity,
        price: +self.price / 100,
        total: +self.total / 100,
      };
    },
  }));

export interface ILinkItemStore extends Instance<typeof LinkItem> {}
export default LinkItem;
