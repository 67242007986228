import { ReactElement } from 'react';

import VerticalFilter from '@yaydoo/react-components/lib/components/VerticalFilter';

import useStyles from './styles';
import { IFilterComponent, option } from './types';

export interface IDesktopFilters<T> extends IFilterComponent<T> {}

const DesktopFilters = <T,>({
  filterLabel,
  filterName,
  currentFilterValue,
  showDivider,
  filterOptions,
  onFilterChange,
}: IDesktopFilters<T>): ReactElement => {
  const css = useStyles();

  return (
    <div className={css.container} data-testid="desktop-filters">
      {showDivider && <hr />}
      <VerticalFilter
        filterLabel={filterLabel}
        filterName={filterName}
        onChange={({ target: { value } }, filter) => onFilterChange(value as T, filter)}
        filterValue={currentFilterValue as string | number}
        options={filterOptions as option<string | number>[]}
      />
    </div>
  );
};

export default DesktopFilters;
