import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.oysterTheme.oyster,
          opacity: 1,
          border: 'none',
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
    },
    track: {
      borderRadius: 24 / 2,
      backgroundColor: theme.oysterTheme.stone,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  }),
);

export default useStyles;
