import { FC } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { EnvironmentVariablesProvider } from '@yaydoo/react-components/lib/components/EnvironmentVarsWrapper';
import { ToastProvider } from '@yaydoo/react-components/lib/components/ToastWrapper/ToastContext';
import { DrawerProvider } from '@yaydoo/react-components/lib/components/DrawerWrapper/DrawerContext';
import { ModalProvider } from '@yaydoo/react-components/lib/components/ModalWrapper/ModalContext';
import Variables from '@yaydoo/react-components/lib/utils/Variables';

import { CommonStoreProvider } from '@components/CommonStoreWrapper';
import { ICommonStore } from '@store/index';
import ROUTES from '@constants/routes';

import UserProfile from './profile';

export const useHandleRoute = (): ((route: ROUTES) => string) => {
  const { path } = useRouteMatch();
  return (route: ROUTES) => `${path}${route}`;
};

export interface TrafficHandleProps extends ICommonStore {
  variables: Variables;
}

/* Wrap your routes inside the CommonStoreProvider,
 * since this will be passed from the container
 */

const TrafficHandler: FC<TrafficHandleProps> = ({ variables, ...storeProps }) => {
  const handleRoute = useHandleRoute();

  return (
    <ToastProvider>
      <EnvironmentVariablesProvider variables={variables}>
        <CommonStoreProvider value={storeProps}>
          <ModalProvider>
            <DrawerProvider>
              <Switch>
                <Route path={handleRoute(ROUTES.TEST)}>
                  <div data-testid="profilePage2">Test user profile page2</div>
                </Route>
                <Route path={handleRoute(ROUTES.PROFILE)}>
                  <UserProfile />
                </Route>
                <Redirect from="*" to={{ pathname: handleRoute(ROUTES.PROFILE) }} />
              </Switch>
            </DrawerProvider>
          </ModalProvider>
        </CommonStoreProvider>
      </EnvironmentVariablesProvider>
    </ToastProvider>
  );
};

export default observer(TrafficHandler);
