import moment from 'moment';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import { BATCH_ROUTES } from '@constants/routes';
import useStore from '@hooks/useStore';
import { IBatchResume } from '@pages/Details/BatchResume';
import { IDetailsList } from '@pages/Details/DetailsList';
import { IDownloadReport } from '@pages/Details/DownloadReport';
import { formattedAmount } from '@utils/format';

export interface IUseBatchDetails {
  batchId: string;
  createdAt: string;
  batchDetailsListProps: IDetailsList;
  batchResumeProps: IBatchResume;
  downloadReportProps: IDownloadReport;
  setAccountsPayableLinkId: Dispatch<string>;
  onGoBack: () => void;
}

function useBatchDetails(): IUseBatchDetails {
  const { t } = useTranslation();
  const history = useHistory();
  const { openToast } = useToast();
  const { batchDetails, batch } = useStore();
  const { flags, accountsPayable, accountsPayableListPagination, batchId } = batchDetails;
  const { isFetching } = flags;
  const [accountsPayableLinkId, setAccountsPayableLinkId] = useState<string>('');
  const [base64File, setBase64File] = useState('');
  const reportName = `${batchId} ${moment().format('DD/MM/YYYY').replace(/\//g, '')}`;

  const handleError = (error: { message: string }) => {
    const { message = '' } = error;
    openToast({
      type: 'error',
      title: t('batch.common.error.title'),
      message: message || t('batch.common.error.unknown'),
    });
  };

  const getBatchDetailsList = async () => {
    try {
      await batchDetails.getBatchDetailsList(accountsPayableLinkId);
      handleGetReport();
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const handleGoBack = () => {
    history.push(BATCH_ROUTES.DASHBOARD);
  };

  const handleGetReport = async () => {
    try {
      const data = await batch.getResume(accountsPayableLinkId);
      setBase64File(data.fileContent);
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const handleDownloadReport = () => {
    openToast({
      type: 'success',
      title: t('batch.dashboard.toast.success.title'),
      message: t('batch.dashboard.toast.success.report', {
        name: reportName,
      }),
    });
  };

  useEffect(() => {
    if (accountsPayableLinkId) {
      getBatchDetailsList();
    }
  }, [accountsPayableLinkId]);

  useEffect(() => {
    return () => {
      batchDetails.clearAccountsPayableList();
    };
  }, []);

  const handleGetMoreBatchDetails = async () => {
    try {
      await batchDetails.getMoreBatchDetails(accountsPayableLinkId);
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const batchDetailsListProps: IDetailsList = useMemo(() => {
    return {
      isFetching,
      accountsPayableList: accountsPayable,
      hasMore: accountsPayableListPagination.hasMore,
      getMoreDetails: handleGetMoreBatchDetails,
    };
  }, [isFetching, accountsPayable, accountsPayableListPagination.hasMore]);

  const batchResumeProps: IBatchResume = useMemo(
    () => ({
      status: batchDetails.status,
      totalSuccessAmount: `${formattedAmount(batchDetails.totalSuccessAmount, 'MXN')} MXN`,
      totalSuccess: batchDetails.totalSuccess || 0,
      totalFailAmount: `${formattedAmount(batchDetails.totalFailAmount, 'MXN')} MXN`,
      totalFail: batchDetails.totalFail || 0,
    }),
    [isFetching],
  );

  const downloadReportProps: IDownloadReport = useMemo(
    () => ({
      reportName,
      base64File,
      onDownloadReport: handleDownloadReport,
    }),
    [base64File, batchId],
  );

  return {
    batchId: batchDetails.batchId,
    createdAt: batchDetails.createdAt,
    batchDetailsListProps,
    batchResumeProps,
    downloadReportProps,
    setAccountsPayableLinkId,
    onGoBack: handleGoBack,
  };
}

export default useBatchDetails;
