import { useCallback } from 'react';

import { logEvent as log, EventParams } from 'firebase/analytics';
import { config, TFirebaseConfig } from '@utils/firebase';
import useInitFirebaseApp from './useInitFirebaseApp';

export interface IUseFirebaseAnalytics {
  logEvent: (eventName: string, eventParams?: EventParams) => void;
}

function useFirebaseAnalytics(
  useAnalytics?: boolean,
  customConfig?: TFirebaseConfig,
): IUseFirebaseAnalytics {
  const firebaseConfig = customConfig || config;
  const { initFirebaseApp, isFirebaseInit } = useInitFirebaseApp(useAnalytics);

  const logEvent = useCallback(
    async (eventName, eventParams = {}) => {
      if (!isFirebaseInit) {
        await initFirebaseApp(firebaseConfig);
      }

      log(window.__oyster_firebase.analytics, eventName, eventParams);
    },
    [isFirebaseInit],
  );

  return { logEvent };
}

export default useFirebaseAnalytics;
