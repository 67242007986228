import { Instance, types, cast, flow } from 'mobx-state-tree';
import download from 'downloadjs';

import Pagination from '@store/common/pagination';
import { IPaginationQueryParams, SortOrder } from '@store/common/pagination/types';

export interface IDepositDetailsQueryParams extends IPaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrder;
}

import { Deposit, DepositDetailsFilters, DepositDetails } from './models/Deposit';
import Flags from './models/Flags';

import DepositsService from './service';

const DepositsStore = types
  .model({
    deposits: types.array(Deposit),
    pagination: types.optional(Pagination, { pageSize: 10 }),
    depositDetailsPagination: types.optional(Pagination, { pageSize: 10 }),
    depositDetailsFilters: types.optional(DepositDetailsFilters, {}),
    depositDetails: types.maybeNull(DepositDetails),
    flags: types.optional(Flags, {}),
  })
  .views((self) => ({
    get service() {
      return new DepositsService();
    },
    get list() {
      return [...self.deposits];
    },
    get isLoadingDepositDetails() {
      return self.flags.isLoadingDepositDetails && !self.flags.isLoadingMoreOrders;
    },
  }))
  .actions((self) => ({
    getDeposits: flow(function* (params?: IPaginationQueryParams) {
      self.flags.isDepositFetched = false;
      self.flags.isLoadingDeposits = true;
      try {
        const paginationParams = {
          pageSize: self.pagination.pageSize,
          page: params?.page || self.pagination.page,
        };

        const { data } = yield self.service.fetchGetDeposits(paginationParams);
        const deposits = data.deposits ?? [];

        self.deposits = cast(deposits);
        self.pagination.setData(data.pagination);
        self.flags.isDepositFetched = true;
      } finally {
        self.flags.isLoadingDeposits = false;
      }
    }),
    getDetails: flow(function* (
      id: string,
      params?: IDepositDetailsQueryParams,
      getMoreOrders?: boolean,
    ) {
      if (self.flags.isLoadingDepositDetails) return;
      if (getMoreOrders) {
        self.flags.isLoadingMoreOrders = true;
      } else {
        self.flags.isLoadingDepositDetails = true;
      }

      const paginationParams: IDepositDetailsQueryParams = {
        page: self.depositDetailsPagination.page,
        pageSize: self.depositDetailsPagination.pageSize,
        sortField: self.depositDetailsFilters.sortField,
        sortOrder: self.depositDetailsFilters.sortOrder,
        ...params,
      };

      try {
        const { data } = yield self.service.fetchGetDeposit(id, paginationParams);
        self.depositDetails = data.deposit;
        self.depositDetailsPagination.setData(data.pagination);
      } finally {
        self.flags.isLoadingDepositDetails = false;
        self.flags.isLoadingMoreOrders = false;
      }
    }),
    downloadReceipt: flow(function* (transactionId: string) {
      if (self.flags.isDownloadingReceipt) return;

      self.flags.isDownloadingReceipt = true;
      try {
        const { data } = yield self.service.fetchGetPaymentReceipt(transactionId);
        download(data?.file?.dataUrl, `${transactionId}.pdf`);
      } finally {
        self.flags.isDownloadingReceipt = false;
      }
    }),
  }))
  .actions((self) => ({
    getMoreDeposits: flow(function* (page) {
      const { pageSize } = self.pagination;
      yield self.getDeposits({ page, pageSize });
    }),
  }));

export interface DepositsStoreType extends Instance<typeof DepositsStore> {}
export default DepositsStore;
