import { FC, ReactElement } from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import { IOysterTheme } from '@theme/index';
import clsx from 'clsx';

import useStyles from './styles';

export interface ITwoColumns {
  leftContentComp: ReactElement;
  rightContentComp?: ReactElement;
  containerClassName?: string;
  leftSize?: GridSize;
  leftRowPadless?: boolean;
  leftRowClassName?: string;
  rightSize?: GridSize;
  rightRowPadless?: boolean;
  rightRowClassName?: string;
  rightBg?: true | IOysterTheme;
  showMiddleSeparator?: boolean;
  hideRightContent?: boolean;
}

const TwoColumns: FC<ITwoColumns> = ({
  leftContentComp,
  rightContentComp,
  containerClassName,
  leftRowClassName,
  rightRowClassName,
  rightBg: bg,
  leftSize = 7,
  rightSize = 5,
  leftRowPadless = false,
  rightRowPadless = false,
  showMiddleSeparator = false,
  hideRightContent = false,
}) => {
  const rightBg = bg === true ? 'pearl' : bg;
  const css = useStyles({ rightRowPadless, rightBg, leftRowPadless, showMiddleSeparator });

  return (
    <Grid container className={clsx(css.twoColumnsContainer, containerClassName)}>
      <Grid item md={leftSize} xs={12} className={clsx(css.leftRow, leftRowClassName)}>
        {leftContentComp}
      </Grid>
      {!hideRightContent && (
        <Grid item md={rightSize} xs={12} className={clsx(css.rightRow, rightRowClassName)}>
          {rightContentComp}
        </Grid>
      )}
    </Grid>
  );
};

export default TwoColumns;
