import { Instance, types } from 'mobx-state-tree';

const Pagination = types
  .model({
    page: 1,
    pageSize: 100,
    nextPage: 0,
    prevPage: 0,
    totalRecords: 0,
    totalMatches: 0,
    firstPage: 0,
    lastPage: 0,
    totalPages: 0,
  })
  .views((self) => ({
    get hasMore() {
      return !!self.nextPage || self.page < self.lastPage;
    },
    get claculateNextPage() {
      return !!self.nextPage || self.page + 1 <= self.lastPage ? self.page + 1 : self.page;
    },
  }))
  .actions((self) => ({
    setData(data: Instance<typeof self>) {
      self.page = +data.page;
      self.pageSize = +data.pageSize;
      self.nextPage = +data.nextPage;
      self.prevPage = +data.prevPage;
      self.totalRecords = +data.totalRecords;
      self.totalMatches = +data.totalMatches;
      self.firstPage = +data.firstPage;
      self.lastPage = +data.lastPage;
      self.totalPages = +data.totalPages;
    },
  }));

export interface IPagination extends Instance<typeof Pagination> {}
export default Pagination;
