import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  cancel: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& button': {
        '&:hover': {
          color: theme.oysterTheme.cotton,
          backgroundColor: '#d5d5d5',
        },
      },
      '& > div > p:first-of-type': {
        fontSize: 30,
        maxWidth: ({ cancel }: StyleProps) => (cancel ? 570 : 530),
      },
      '& > div > p:last-of-type': {
        fontSize: 16,
        fontWeight: ({ cancel }: StyleProps) => (cancel ? 700 : 500),
        maxWidth: ({ cancel }: StyleProps) => (cancel ? 512 : 420),
      },
    },
  }),
);

export default useStyles;
