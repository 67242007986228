import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';
import endpoints from './endpoints';

interface loginWithSutBody {
  singleUseToken: string;
  sessionType: number;
  deviceType: number;
}

export default class AuthService extends OysterService {
  /**
   * Kill user session from backend
   * @return Promise<AxiosResponse | AxiosError>
   */
  logout(): Promise<AxiosResponse | AxiosError> {
    return this.client.delete(endpoints.logout);
  }

  /**
   * Updates the session cookie
   * @return Promise<AxiosResponse | AxiosError>
   */
  updateTTL(): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.updateTTL, {});
  }

  loginWithSut(data: loginWithSutBody): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.loginWithSut, data);
  }
}
