import { FC, ReactNode, ElementType, ChangeEvent } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { IconProps } from '@icons/types';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import theme from '@theme/index';

import useStyles from './styles';

export interface IChildren {
  label: string;
  icon: ElementType | FC<IconProps>;
  value: string | number;
}

export interface IVerticalFilter {
  filterLabel: string;
  filterName: string;
  filterValue: string | number;
  options: IChildren[];
  onChange: (event: ChangeEvent<HTMLInputElement>, filter: string) => void;
}

const customTheme = createTheme({
  ...theme,
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: theme.oysterTheme.shark,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        width: '100%',
      },
    },
  },
});

const VerticalFilter: FC<IVerticalFilter> = ({
  filterLabel,
  filterName,
  filterValue,
  options,
  onChange,
}) => {
  const css = useStyles();

  const labelIcon = (IconFilter: ReactNode, label: string) => {
    return (
      <div className={css.formControlLabelContainer}>
        {IconFilter} <span className={css.label}>{label}</span>
      </div>
    );
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box className={css.filterContainer}>
        <FormControl className={css.filterContainer} component="fieldset">
          <FormLabel className={css.formLabel} component="legend">
            {filterLabel}
          </FormLabel>
          <RadioGroup
            name={filterName}
            value={filterValue}
            onChange={(event) => onChange(event, filterName)}
          >
            {options.map(({ value, label, icon: CustomIcon }, index) => (
              <FormControlLabel
                className={css.formControlLabel}
                key={index}
                labelPlacement="start"
                value={value}
                control={<Radio color="primary" size="small" />}
                label={labelIcon(<CustomIcon className={css.labelIcon} />, label)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};

export default VerticalFilter;
