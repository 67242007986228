import { FC } from 'react';
import { IconProps, pine } from './types';

const LinkIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15.1892"
        y="2.4015"
        width="6.05319"
        height="8.24952"
        rx="2.25382"
        transform="rotate(37.3122 15.1892 2.4015)"
        stroke={defaultColor ? props.fill : 'currentColor'}
        strokeWidth="2"
      />
      <rect
        x="8.08894"
        y="11.7179"
        width="6.05319"
        height="8.24952"
        rx="2.25382"
        transform="rotate(37.3122 8.08894 11.7179)"
        stroke={defaultColor ? props.fill : 'currentColor'}
        strokeWidth="2"
      />
      <rect
        x="12.8807"
        y="9.15726"
        width="1.53703"
        height="6.41936"
        rx="0.65941"
        transform="rotate(37.3122 12.8807 9.15726)"
        fill={defaultColor ? props.fill : 'currentColor'}
        stroke={defaultColor ? props.fill : 'currentColor'}
        strokeWidth="0.904136"
        strokeLinecap="round"
      />
    </svg>
  );
};

LinkIcon.defaultProps = {
  fill: pine,
};

export default LinkIcon;
