import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AddIcon from '@yaydoo/react-components/lib/icons/AddPlain';
import OutlinedButton from '@yaydoo/react-components/lib/components/OutlinedButton';
import Button from '@yaydoo/react-components/lib/components/Button';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import useHasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasActionAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';

import { COLLECT_ROUTES } from '@constants/routes';
import useTicket from '@hooks/useTicket';
import useStyles from './styles';

interface TicketFooterProps {
  paymentOrderId?: string;
  isRefundable?: boolean;
}

const TicketFooter: FC<TicketFooterProps> = ({ paymentOrderId, isRefundable }) => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();
  const openRefundTicket = useTicket('refund');

  const onCreateButtonClick = () => {
    history.push(COLLECT_ROUTES.CREATE);
    closeDrawer();
  };

  const onRefund = () => {
    if (paymentOrderId) openRefundTicket(paymentOrderId);
  };

  const canCreatePaymentLink = useHasActionAccess({ action: allowedActions.PAYMENTS_CREATE_LINKS });

  return (
    <div data-testid="ticket-footer" className={css.container}>
      {isRefundable && (
        <Button
          data-testid="refund-button"
          variant="text"
          label={t('collect.layout.ticket.refund')}
          onClick={onRefund}
          color="primary"
          size="small"
          className={css.button}
        />
      )}
      {canCreatePaymentLink && (
        <OutlinedButton
          label={t('collect.layout.ticket.createLink')}
          leftIcon={() => <AddIcon />}
          onClick={onCreateButtonClick}
        />
      )}
    </div>
  );
};

export default TicketFooter;
