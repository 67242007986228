import { types } from 'mobx-state-tree';

const PaymentFlags = types.model({
  isMultiple: false,
  hasDueDate: false,
  isLimited: false,
  isRequesting: false,
  isSubmitting: false,
  isFetchingLinks: false,
  isDisplayingPaymentLink: false,
  isFetchingLinkOrders: false,
  isFetchingLinkDetails: false,
  isFetchingMoreOrders: false,
  isFetchingBalance: false,
  isRefunding: false,
});

export default PaymentFlags;
