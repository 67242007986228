import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.oysterTheme.charcoal,
      borderRadius: 8,
      '&:hover fieldset': {
        borderColor: `${theme.oysterTheme.oyster} !important`,
      },
    },
    rootAdornment: {
      color: theme.oysterTheme.oyster,
      fontSize: '1em',
      fontWeight: 500,
    },
    select: {
      '&:focus': {
        backgroundColor: theme.oysterTheme.cotton,
        outline: 0,
      },
    },
    inputAdornment: {},
    item: {
      color: theme.oysterTheme.charcoal,
      fontSize: '16px',
    },
  }),
);

export default useStyles;
