import { FC } from 'react';
import { formatMoneyNoSign } from '@yaydoo/react-components/lib/utils/common';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

interface TotalProps {
  amount: number | string;
}

const Total: FC<TotalProps> = ({ amount }) => {
  const css = useStyles();

  return (
    <Grid container className="total-container">
      <h1 className={`${css.price} ticket-total`}>{formatMoneyNoSign(+amount)}</h1>
    </Grid>
  );
};

export default Total;
