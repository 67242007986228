import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';
import Ticket from '@components/Tickets/Ticket';

import useAnalytics from '@hooks/useAnalytics';
import useGetUserBusinessData from '@hooks/useGetUserBusinessData';

import * as GA from '@constants/analytics';
import useStyles from './styles';
import useStore from '@hooks/useStore';
import Loading from '@components/Loading';
import TicketFooter from '../TicketFooter';
import { Persona } from '@constants/invoice';

interface PaymentLinkTicketProps {
  id: string;
}

const PaymentLinkTicket: FC<PaymentLinkTicketProps> = ({ id }) => {
  const { t } = useTranslation();
  const css = useStyles();
  const { name, persona } = useGetUserBusinessData();
  const { sendEvent } = useAnalytics();
  const {
    paymentLink: { link, flags, getPaymentLink },
  } = useStore();
  const shouldDisplayLink = link?.isMultiple || (!link?.isPaid && !link?.isRefunded);
  const isRefundable = link?.isRefundable && persona === Persona.MORAL;

  const onCopyLink = () => {
    sendEvent({
      action: GA.ACTIONS.CLICK,
      category: GA.CATEGORIES.DASHBOARD.CATEGORY,
      label: GA.CATEGORIES.DASHBOARD.LABELS.COPY_LINK,
    });
  };

  useEffect(() => {
    getPaymentLink(id);
  }, [id]);

  if (!link || flags.isFetchingLinkDetails) return <Loading />;

  return (
    <div className={css.root}>
      <Ticket
        details={{
          ...link,
          userFullName: name,
          ticketNumber: link.linkNumberFormatted,
          status: link.displayStatus,
          ticketItems: link.displayItems,
          total: link.totalAmount,
          paymentDate: link.lastPaidAt,
          refundedDate: link.lastRefundedAt,
          refundedAmount: link.metadata?.refundedAmountFormatted,
          refundFee: link.metadata?.refundFeeFormatted,
        }}
        showLink={shouldDisplayLink}
        link={shouldDisplayLink ? link.url : undefined}
        onCopyLink={onCopyLink}
      />
      {link.isPaid && (
        <Grid container className={css.footer}>
          <Grid item className={`${css.info} ${css.centered}`}>
            {link.isPaid && t('collect.payments.common.payInfo')}
          </Grid>
        </Grid>
      )}
      <div className={css.footer}>
        <TicketFooter
          isRefundable={isRefundable}
          paymentOrderId={link.paymentOrders?.[0]?.paymentOrderId}
        />
      </div>
    </div>
  );
};

export default observer(PaymentLinkTicket);
