export default {
  title: 'Crea un link de pago',
  link: {
    preferences: {
      description: 'Indica las características de tu link.',
      title: 'Preferencias',
      action: 'Siguiente',
      withExpirationDate: 'Con fecha de vencimiento',
      noExpirationDate: 'Sin fecha de vencimiento',
      expirationDate: 'Fecha de vencimiento',
      multipleUses: 'Múltiples usos',
      allowedPayments: 'Pagos permitidos (opcional)',
    },
    customer: {
      title: 'Cliente',
      description: 'Indica las características de tu cliente.',
      action: 'Siguiente',
      concept: 'Concepto',
      payeeName: 'Nombre del comprador {{optional}}',
      email: 'Correo (opcional)',
    },
    product: {
      label: 'Producto',
      description: 'Indica las características de tu producto o servicio.',
      productName: 'Nombre del producto',
      unitPrice: 'Precio Unitario',
      amount: 'Cantidad',
      subtotal: 'Subtotal',
      total: 'Total',
      title: 'Producto',
      add: 'Añadir otro producto',
      action: 'Crea tu link de pago',
    },
    total: 'Monto total',
    errors: {
      title: 'Hubo un error',
      create: 'Ocurrió un error al crear el link de pago. Intenta nuevamente.',
      total: 'El monto mínimo total es de {{amount}} MXN',
    },
  },
  stamped: {},
};
