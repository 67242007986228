import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckIcon from '@yaydoo/react-components/lib/icons/CheckboxFilled';
import useStyles from './styles';

export type onChangeType = (file: File | null) => void;
const MAX_FILE_SIZE = 1048576 * 5;
interface FileInputProps {
  label?: string;
  fileType?: string;
  onChange: onChangeType;
  ['data-testid']?: string;
  error?: boolean;
  hasFile?: boolean;
}

const FileInput: FC<FileInputProps> = ({
  label = 'Upload',
  fileType = '*',
  onChange,
  'data-testid': testId,
  error,
  hasFile,
}) => {
  const css = useStyles();
  const buttonCls = clsx(css.btn, { [css.error]: error, [css.success]: hasFile });
  const [file, setFile] = useState<File | null>(null);
  const onDrop = (files: File[]) => {
    setFile(files[0]);
    onChange(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    onDrop,
    noDrag: false,
    minSize: 0,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
  });

  return (
    <div {...getRootProps()} className={css.root}>
      <input {...getInputProps()} data-testid={testId} />
      <label htmlFor="raised-button-file">
        <Button component="span" className={buttonCls}>
          <div className={css.checkIconContainer}>
            {hasFile && <CheckIcon defaultColor className={css.checkIcon} />}
            <span>{file?.name || label}</span>
          </div>
          <AttachFileIcon className={css.icon} />
        </Button>
      </label>
    </div>
  );
};

export default FileInput;
