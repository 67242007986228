import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import OperationsResume from '@components/OperationsResume';
import Button from '@yaydoo/react-components/lib/components/FormButton';

import useStyles from './styles';

export interface IOperationInProcess {
  successDate: string;
  successTotalAmount: string;
  successOperations: number;
  errorDate: string;
  errorTotalAmount: string;
  errorOperations: number;
  onDownloadReport?: () => void;
  onSeeDetails: () => void;
  base64File?: string;
  reportName?: string;
}

const OperationInProcess: FC<IOperationInProcess> = ({
  successDate,
  successTotalAmount,
  successOperations,
  errorDate,
  errorTotalAmount,
  errorOperations,
  onDownloadReport,
  onSeeDetails,
  base64File,
  reportName,
}) => {
  const { t } = useTranslation();
  const css = useStyles();
  const hasErrors = errorDate || errorOperations;

  return (
    <div className={css.infoActionsContainer}>
      <div>
        <OperationsResume
          success
          title={t('batch.dashboard.proccessingBatch.stepper.operationInProcess.success')}
          totalOperations={successOperations}
          date={successDate}
          totalAmount={successTotalAmount}
        />
        {!!hasErrors && (
          <>
            <p className={css.optionalText} />
            <OperationsResume
              title={t('batch.dashboard.proccessingBatch.stepper.operationInProcess.error')}
              totalOperations={errorOperations}
              date={errorDate}
              totalAmount={errorTotalAmount}
            />
          </>
        )}
      </div>
      <div className={css.buttonsContainer}>
        {base64File && (
          <>
            <a href={base64File} download={reportName}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                label={t(
                  'batch.dashboard.proccessingBatch.stepper.operationCompleted.downloadReport',
                )}
                onClick={onDownloadReport}
              />
            </a>
            <br />
            <br />
          </>
        )}

        <Button
          className={css.seeDetailsButton}
          variant="outlined"
          color="secondary"
          fullWidth
          label={t('batch.common.labels.seeDetails')}
          onClick={onSeeDetails}
        />
      </div>
    </div>
  );
};

export default OperationInProcess;
