import { FC } from 'react';
import { IconProps, pine } from './types';

const AttachIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.804 9.82717C18.5435 9.5675 18.1225 9.56866 17.8635 9.8297L9.86948 17.8872C8.83236 18.927 7.14791 18.927 6.10885 17.8858C5.07057 16.8444 5.07057 15.1557 6.10898 14.1147L14.3396 5.81992C14.9871 5.17081 16.0398 5.17081 16.6892 5.82132C17.3384 6.47215 17.3384 7.52739 16.6891 8.17841L9.87073 15.0139C9.87029 15.0143 9.86992 15.0148 9.86948 15.0153C9.60973 15.2742 9.18993 15.2739 8.93071 15.014C8.67104 14.7537 8.67104 14.3318 8.93071 14.0715L12.2219 10.7713C12.4815 10.5109 12.4815 10.0888 12.2218 9.82848C11.962 9.56816 11.541 9.56819 11.2813 9.82857L7.99018 13.1287C7.21116 13.9096 7.21116 15.1758 7.99024 15.9568C8.7693 16.7378 10.0323 16.7378 10.8113 15.9568C10.8123 15.9559 10.813 15.9549 10.8138 15.954L17.6295 9.12118C18.7983 7.94947 18.7983 6.04999 17.6295 4.87827C16.4606 3.70724 14.566 3.70724 13.3979 4.87827L5.16727 13.1731C3.61072 14.7336 3.61072 17.2663 5.16839 18.8287C6.72709 20.3905 9.25346 20.3905 10.8112 18.8288L18.8065 10.77C19.0655 10.5089 19.0643 10.0868 18.804 9.82717Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

AttachIcon.defaultProps = {
  fill: pine,
};

export default AttachIcon;
