import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { IOysterTheme } from '@theme/index';

interface IUseStyles {
  rightRowPadless?: boolean;
  rightBg?: false | IOysterTheme;
  leftRowPadless?: boolean;
  showMiddleSeparator?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    twoColumnsContainer: {
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      '& ::-webkit-scrollbar': {
        width: 4,
      },
      '& ::-webkit-scrollbar-track': {
        background: theme.oysterTheme.stone,
      },
      '& ::-webkit-scrollbar-thumb': {
        background: '#bdbdbd60',
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: '#bdbdbd60',
      },
    },
    leftRow: (props: IUseStyles) => ({
      padding: !props.leftRowPadless ? theme.spacing(3) : 0,
      borderRight: props.showMiddleSeparator ? `1px solid ${theme.oysterTheme.stone}` : 'none',
      maxHeight: '100vh',
      overflow: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
        overflow: 'auto',
        height: 'auto',
        maxHeight: 'none',
      },
    }),
    rightRow: (props: IUseStyles) => ({
      padding: !props.rightRowPadless ? theme.spacing(3) : 0,
      backgroundColor: props.rightBg
        ? theme.oysterTheme[props.rightBg as IOysterTheme]
        : 'transparent',
      maxHeight: '100vh',
      overflow: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
        overflow: 'auto',
        height: 'auto',
        maxHeight: 'none',
      },
    }),
  }),
);

export default useStyles;
