import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    list: {
      width: '100%',
    },
    item: {
      width: '100%',
      lineHeight: 1.3,
    },
    itemTitle: {
      color: theme.oysterTheme.charcoal,
      fontSize: 16,
      [theme.breakpoints.up('md')]: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    itemCreatedAt: {
      display: 'block',
    },
    itemTotal: {
      display: 'block',
      textAlign: 'right',
      fontSize: 16,
      fontWeight: 700,
    },
    operations: { color: theme.oysterTheme.shark, fontSize: 12, marginBottom: theme.spacing(1) },
    alignRight: {
      display: 'flex',
      alignItems: 'end',
    },
    customRow: {
      '& > div:first-child': { margin: 0, alignSelf: 'start' },
    },
  });

export default makeStyles(styles);
