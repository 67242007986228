import { Instance, types } from 'mobx-state-tree';

export const Webhook = types.model({
  businessId: types.string,
  isActive: types.maybeNull(types.boolean),
  method: types.string,
  url: types.string,
  webhookSubscriptionId: types.string,
});

export interface TWebhook extends Instance<typeof Webhook> {}
