import loadable from '@loadable/component';
import PageLoader from '@yaydoo/react-components/lib/components/AppLoaders/PageLoader';
import hasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/hasActionAccess';
import RedirectTo from '@yaydoo/react.ui-access-manager/lib/access/redirectTo';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';
import { COLLECT_ROUTES } from '@constants/routes';

const Page = loadable(
  () =>
    import(
      /* webpackChunkName: "CreateLink" */
      './createLink'
    ),
  {
    fallback: <PageLoader type="icon" />,
  },
);

export default hasActionAccess({
  Component: Page,
  ErrorComponent: () => <RedirectTo pathName={COLLECT_ROUTES.DASHBOARD} />,
  action: allowedActions.PAYMENTS_CREATE_LINKS,
});
