import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

import useStyles from './styles';

interface ActionContainerProps extends HTMLAttributes<HTMLDivElement> {}

export const ActionContainer: FC<ActionContainerProps> = ({ className, children, ...props }) => {
  const css = useStyles();

  return (
    <div {...props} className={clsx(css.action, className)}>
      {children}
    </div>
  );
};

export default ActionContainer;
