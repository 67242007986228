import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return createStyles({
    item: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    avatarWrapper: {
      display: 'flex',
    },
    avatar: {
      borderRadius: 5,
    },
    itemContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(0.5),
    },
    line: {
      flex: 1,
      display: 'flex',
      borderRadius: 5,
      maxHeight: 13,
      '&.short': {
        width: '70%',
      },
    },
  });
});

export default useStyles;
