import { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';
import Loading from '@components/Loading';

interface LoadableProps extends CircularProgressProps {
  isLoading?: boolean;
  loader?: 'small' | 'big';
}

const Loadable: FC<LoadableProps> = ({ isLoading, loader = 'small', children, ...props }) => {
  const Loader =
    loader === 'small' ? (
      <CircularProgress data-testid="circular-progress" size="2em" thickness={6} {...props} />
    ) : (
      <Loading />
    );

  return isLoading ? Loader : <>{children}</>;
};

export default Loadable;
