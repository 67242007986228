import { useState } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { fetchAndActivate, getRemoteConfig, RemoteConfig } from 'firebase/remote-config';
import { getMessaging, Messaging } from 'firebase/messaging';
import { config, TFirebaseConfig } from '@utils/firebase';

export interface IUseInitFirebaseApp {
  initFirebaseApp: (firebaseConfig?: TFirebaseConfig) => Promise<boolean>;
  isFirebaseInit: boolean;
}

export interface IFirebaseApp {
  app: FirebaseApp;
  analytics: Analytics;
  messaging: Messaging;
  remoteConfig: RemoteConfig;
  isConfigFetched: boolean;
  initialized: boolean;
}

export interface UseFirebaseServices {
  useMessaging?: boolean;
}

function useInitFirebaseApp(
  useAnalytics?: boolean,
  useServices?: UseFirebaseServices,
): IUseInitFirebaseApp {
  const [isFirebaseInit, setIsFirebaseInit] = useState(false);
  const firebase = window.__oyster_firebase;

  const initFirebaseApp = async (firebaseConfig?: TFirebaseConfig) => {
    if (!firebase.initialized) {
      const app = initializeApp(firebaseConfig || config);
      const remoteConfig = getRemoteConfig(app);

      firebase.app = app;
      firebase.remoteConfig = remoteConfig;
      if (useAnalytics) firebase.analytics = getAnalytics(app);
      if (useServices?.useMessaging) firebase.messaging = getMessaging(app);

      firebase.isConfigFetched = await fetchAndActivate(remoteConfig);
      firebase.initialized = true;
    }

    setIsFirebaseInit(firebase.initialized);
    return Promise.resolve(firebase.initialized);
  };

  return { initFirebaseApp, isFirebaseInit };
}

// Window property setup
declare global {
  interface Window {
    __oyster_firebase: IFirebaseApp;
  }
}

if (!window.__oyster_firebase) {
  Object.defineProperty(window, '__oyster_firebase', {
    value: {
      app: null,
      analytics: null,
      messaging: null,
      remoteConfig: null,
      isConfigFetched: false,
      initialized: false,
    },
  });
}

export default useInitFirebaseApp;
