import { FC } from 'react';
import { IconProps, obsidian } from './types';

const GoodsIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8787 6.95082H26.5V4H34V11.377H31V9.03738L24.0607 15.8629C23.574 16.3417 22.8185 16.4331 22.2283 16.0848L15.6654 12.2116L8.48778 18.389C7.86432 18.9256 6.91668 18.8635 6.37116 18.2503C5.82563 17.637 5.88881 16.7049 6.51226 16.1683L14.5123 9.28308C15.0024 8.86124 15.7133 8.7987 16.2718 9.12829L22.7675 12.9618L28.8787 6.95082ZM7.5 23.1803C6.94772 23.1803 6.5 23.628 6.5 24.1803V33C6.5 33.5523 6.94772 34 7.5 34H10.5C11.0523 34 11.5 33.5523 11.5 33V24.1803C11.5 23.628 11.0523 23.1803 10.5 23.1803H7.5ZM13.5 19.2623C13.5 18.71 13.9477 18.2623 14.5 18.2623H17.5C18.0523 18.2623 18.5 18.71 18.5 19.2623V33C18.5 33.5523 18.0523 34 17.5 34H14.5C13.9477 34 13.5 33.5523 13.5 33V19.2623ZM21.5 20.2295C20.9477 20.2295 20.5 20.6772 20.5 21.2295V33C20.5 33.5523 20.9477 34 21.5 34H24.5C25.0523 34 25.5 33.5523 25.5 33V21.2295C25.5 20.6772 25.0523 20.2295 24.5 20.2295H21.5ZM27.5 18.2787C27.5 17.7264 27.9477 17.2787 28.5 17.2787H31.5C32.0523 17.2787 32.5 17.7264 32.5 18.2787V33C32.5 33.5523 32.0523 34 31.5 34H28.5C27.9477 34 27.5 33.5523 27.5 33V18.2787Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <circle cx="34" cy="34" r="6" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 38C36.2091 38 38 36.2091 38 34C38 31.7909 36.2091 30 34 30C31.7909 30 30 31.7909 30 34C30 36.2091 31.7909 38 34 38ZM34.4642 33.5556H36V34.4444H34.4642V36H33.5358V34.4444H32V33.5556H33.5358V32H34.4642V33.5556Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

GoodsIcon.defaultProps = {
  fill: obsidian,
};

export default GoodsIcon;
