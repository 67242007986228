import { FC, useRef } from 'react';
import SlickSlider from 'react-slick';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ISlider } from './types';
import useStyles from './styles';

const Slider: FC<ISlider> = ({
  children,
  classes,
  showPrevArrow,
  showNextArrow,
  controlsPosition,
  prevButtonLabel,
  nextButtonLabel,
  arrows,
  dots,
  ...props
}) => {
  const css = useStyles();
  const ref = useRef<SlickSlider | null>(null);

  const hasControls = arrows || dots;

  const rootClasses = clsx(
    css.root,
    {
      [css.hasControlsAtTop]: hasControls && controlsPosition === 'top',
      [css.hasControlsAtBottom]: hasControls && controlsPosition === 'bottom',
    },
    classes?.root,
  );
  const dotsClasses = clsx(css.dots, classes?.dots);
  const prevButtonClasses = clsx(css.controlButton, css.prevButton, classes?.prevButton);
  const nextButtonClasses = clsx(css.controlButton, css.nextButton, classes?.nextButton);

  const onNext = (): void => {
    if (ref?.current) {
      ref.current.slickNext();
    }
  };

  const onPrev = (): void => {
    if (ref?.current) {
      ref.current.slickPrev();
    }
  };

  return (
    <div data-testid="slider-container" className={rootClasses}>
      <SlickSlider ref={ref} {...props} dots={dots} arrows={false} dotsClass={dotsClasses}>
        {children}
      </SlickSlider>

      {arrows && showPrevArrow && (
        <Button
          data-testid="slider-prev-button"
          className={prevButtonClasses}
          onClick={onPrev}
          variant="text"
          color="primary"
          startIcon={<ChevronLeftIcon fontSize="small" />}
        >
          {prevButtonLabel || 'Previous'}
        </Button>
      )}

      {arrows && showNextArrow && (
        <Button
          data-testid="slider-next-button"
          className={nextButtonClasses}
          onClick={onNext}
          variant="text"
          color="primary"
          endIcon={<ChevronRightIcon fontSize="small" />}
        >
          {nextButtonLabel || 'Next'}
        </Button>
      )}
    </div>
  );
};

Slider.defaultProps = {
  controlsPosition: 'bottom',
  showPrevArrow: true,
  showNextArrow: true,
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default Slider;
