import { FC } from 'react';
import { IconProps, oyster } from './types';

const ClabeIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9515 22.2411C12.2085 22.7131 12.6995 23.0061 13.2335 23.0061H19.6205C20.2155 23.0061 20.7465 22.6491 20.9735 22.0961C20.9845 22.0711 20.9925 22.0441 20.9985 22.0171L22.5185 15.3381C22.5265 15.3021 22.5305 15.2641 22.5305 15.2271C22.5305 14.5241 22.1615 13.9561 21.6105 13.7851L16.5305 11.9631V6.9771C16.5305 6.0831 16.0405 5.5061 15.2805 5.5061H13.8325C13.0665 5.5061 12.5305 6.1111 12.5305 6.9771V14.8191L11.7045 13.8301C11.4325 13.4851 10.9775 13.2711 10.5055 13.2711L9.51548 13.2901C8.99448 13.2901 8.50748 13.5671 8.24448 14.0121C7.98148 14.4591 7.96948 15.0161 8.21648 15.4681L11.9515 22.2411Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53091 1.00623H2.53091C1.97891 1.00623 1.53091 1.45423 1.53091 2.00623C1.53091 2.55823 1.97891 3.00623 2.53091 3.00623H4.53091C5.08291 3.00623 5.53091 2.55823 5.53091 2.00623C5.53091 1.45423 5.08291 1.00623 4.53091 1.00623Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53091 5.00623H2.53091C1.97891 5.00623 1.53091 5.45423 1.53091 6.00623C1.53091 6.55823 1.97891 7.00623 2.53091 7.00623H4.53091C5.08291 7.00623 5.53091 6.55823 5.53091 6.00623C5.53091 5.45423 5.08291 5.00623 4.53091 5.00623Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53091 9.00623H2.53091C1.97891 9.00623 1.53091 9.45423 1.53091 10.0062C1.53091 10.5582 1.97891 11.0062 2.53091 11.0062H4.53091C5.08291 11.0062 5.53091 10.5582 5.53091 10.0062C5.53091 9.45423 5.08291 9.00623 4.53091 9.00623Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53082 1.00623H7.53082C6.97882 1.00623 6.53082 1.45423 6.53082 2.00623C6.53082 2.55823 6.97882 3.00623 7.53082 3.00623H9.53082C10.0828 3.00623 10.5308 2.55823 10.5308 2.00623C10.5308 1.45423 10.0828 1.00623 9.53082 1.00623Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53082 5.00623H7.53082C6.97882 5.00623 6.53082 5.45423 6.53082 6.00623C6.53082 6.55823 6.97882 7.00623 7.53082 7.00623H9.53082C10.0828 7.00623 10.5308 6.55823 10.5308 6.00623C10.5308 5.45423 10.0828 5.00623 9.53082 5.00623Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53082 11.0062H9.53082C10.0828 11.0062 10.5308 10.5582 10.5308 10.0062C10.5308 9.45423 10.0828 9.00623 9.53082 9.00623H7.53082C6.97882 9.00623 6.53082 9.45423 6.53082 10.0062C6.53082 10.5582 6.97882 11.0062 7.53082 11.0062Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.531 1.00623C11.979 1.00623 11.531 1.45423 11.531 2.00623C11.531 2.55823 11.979 3.00623 12.531 3.00623H14.531C15.083 3.00623 15.531 2.55823 15.531 2.00623C15.531 1.45423 15.083 1.00623 14.531 1.00623H12.531Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

ClabeIcon.defaultProps = {
  fill: oyster,
};

export default ClabeIcon;
