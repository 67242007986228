import { useEffect, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import WebDashboard from '@layouts/WebDashboard';
import { COLLECT_ROUTES } from '@constants/routes';
import Loading from '@components/Loading';
import useStore from '@hooks/useStore';
import Link from './Link';

const LinkDetails: FC = () => {
  const { paymentLink } = useStore();
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const { openToast } = useToast();
  const history = useHistory();

  const shouldDisplayLoader =
    paymentLink.isFetchingDetails || paymentLink.link?.paymentLinkId !== uuid;

  useEffect(() => {
    const getDetails = async () => {
      try {
        await paymentLink.getPaymentLinkData(uuid);
      } catch {
        openToast({
          type: 'error',
          title: t('collect.layout.error.title'),
          message: t('collect.layout.error.msg'),
        });
        history.push(COLLECT_ROUTES.DASHBOARD);
      }
    };

    getDetails();
  }, []);

  useEffect(() => {
    return () => {
      paymentLink.clearPaymentLinkData();
    };
  }, []);

  return (
    <WebDashboard withCha withDefaultHeader>
      {shouldDisplayLoader ? <Loading /> : <Link />}
    </WebDashboard>
  );
};

export default observer(LinkDetails);
