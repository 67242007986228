import { FC } from 'react';

import Cha from '@components/Cha';
import { MainLayoutProps } from '..';
import useStyles from './styles';

const MainLayout: FC<MainLayoutProps> = ({ children, withCha, header, className }) => {
  const css = useStyles({ withCha });

  return (
    <div className={`${css.root} ${className}`} data-testid="main-layout">
      {header && <header>{header}</header>}
      {withCha && <Cha className={css.cha} />}
      <main className={css.content}>{children}</main>
    </div>
  );
};

export default MainLayout;
