import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');
  return createStyles({
    root: {
      '& hr': {
        border: 'unset',
        borderTop: `1px solid ${theme.oysterTheme.stone}`,
      },
      overflow: 'hidden',
      gridTemplateRows: 'minmax(max-content, 1fr) auto',
    },
    title: { fontWeight: 700, fontSize: 28, lineHeight: '24px' },
    createdAt: { color: theme.oysterTheme.shark, fontSize: 18 },
    pageHeader: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(3),
      padding: 0,
    },
    resumeContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
    listHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(7),
      paddingLeft: 0,
      paddingRight: 0,
    },
    listTitle: {
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      fontSize: 12,
      fontWeight: 600,
      color: theme.oysterTheme.shark,
    },
    listContainer: {
      [upScale]: {
        overflowY: 'auto',
        '& ::-webkit-scrollbar': {
          width: 4,
        },
        '& ::-webkit-scrollbar-track': {
          background: theme.oysterTheme.stone,
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '#bdbdbd60',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          background: '#bdbdbd60',
        },
      },
    },
  });
});

export default styles;
