import { FC, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';

import SectionHeader from '@yaydoo/react-components/lib/components/Typography/SectionHeaderV2';
import FormButton from '@yaydoo/react-components/lib/components/FormButton';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import FormContainer from '@components/FormContainer';
import Loading from '@components/Loading';
import useStore from '@hooks/useStore';

import Assets from './Assets';
import Support from './Support';
import Preview from './Preview';
import useStyles from './styles';
import Theme from './Theme';
import { CobrandingData } from '@constants/cobranding';
import Separator from '@components/Separator';

const Cobranding: FC = () => {
  const css = useStyles();
  const { t } = usePaymentToolsTranslation();
  const { openToast } = useToast();
  const methods = useForm<CobrandingData>();
  const {
    settings: { fetchCobranding, saveCobranding, cobranding },
  } = useStore();
  const { flags } = cobranding;

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await saveCobranding(values);
      openToast({
        type: 'success',
        message: t('tools.cobranding.success.title'),
      });
    } catch {
      openToast({
        type: 'error',
        title: t('tools.cobranding.error.title'),
        message: t('tools.cobranding.error.message'),
      });
    }
  });

  useEffect(() => {
    fetchCobranding();
  }, []);

  useLayoutEffect(() => {
    if (flags.isFetched) {
      methods.setValue('assets.logo', cobranding.assets.logo);
      methods.setValue('assets.favicon', cobranding.assets.favicon);
      methods.setValue('theme.primaryColor', cobranding.theme.primaryColor);
      methods.setValue('theme.secondaryColor', cobranding.theme.secondaryColor);
      methods.setValue('contactInfo.email', cobranding.contactInfo.email);
      methods.setValue('contactInfo.phone', cobranding.contactInfo.phone);
    }
  }, [flags.isFetched]);

  if (flags.isFetching) return <Loading />;

  return (
    <FormContainer>
      <FormProvider {...methods}>
        <SectionHeader
          isBoldTitle
          id="tools-cobranding-title"
          title={t('tools.cobranding.title')}
          description={t('tools.cobranding.description')}
        />
        <Assets />
        <Separator />
        <Theme />
        <Separator />
        <Preview />
        <Separator />
        <Support />
        <Grid className={clsx(css.wrapper, css.noBorder, css.actionButton)}>
          <FormButton
            label={t('tools.cobranding.save')}
            data-testid="save-button"
            color="primary"
            onClick={onSubmit}
          />
        </Grid>
      </FormProvider>
    </FormContainer>
  );
};

export default observer(Cobranding);
