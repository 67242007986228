import { FC, ElementType } from 'react';

import List from '@material-ui/core/List';

import CustomRow from '@components/CustomRow';
import SectionHeaderV2 from '@components/Typography/SectionHeaderV2';
import { formatPhoneNumber } from '@utils/common';

import useStyles from './styles';

interface ChaOptions {
  title: string;
  description: string;
  key: string | number;
  onClick?: () => void;
  icon: ElementType | string;
  phoneNumber?: number;
  id?: string;
}

export interface HelpChaProps {
  options: ChaOptions[];
  title?: string;
  footer?: string;
  className?: string;
}

interface CustomDescriptionProps {
  description: string;
  phoneNumber?: number;
}

const CustomDescription: FC<CustomDescriptionProps> = ({ description, phoneNumber }) => {
  const css = useStyles();

  return phoneNumber ? (
    <>
      <span>{description}</span> <br />
      <small className={css.phoneNumber}>{formatPhoneNumber(phoneNumber)}</small>
    </>
  ) : (
    <span>{description}</span>
  );
};

const HelpCha: FC<HelpChaProps> = ({ options = [], title, footer, className }) => {
  const css = useStyles();

  return (
    <div className={className}>
      {title && <SectionHeaderV2 title={title} id="hepl-cha" />}
      <List className={css.helpChaContainer}>
        {options?.map(({ icon: Icon, ...item }) => {
          return (
            <CustomRow
              key={item.key}
              className={css.option}
              onClick={item.onClick}
              leftIcon={() => <Icon className={css.icon} />}
              primaryText={item.title}
              secondaryTextComponent={
                <CustomDescription description={item.description} phoneNumber={item.phoneNumber} />
              }
              id={item.id}
            />
          );
        })}
      </List>
      {footer && <span className={css.footer}>{footer}</span>}
    </div>
  );
};

export default HelpCha;
