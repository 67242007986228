import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    toast: {
      fontSize: '14px',
    },
    user: {
      fontWeight: 600,
      fontSize: '18px',
      letterSpacing: '0.04em',
      margin: 0,
      padding: 0,
    },
    location: {
      fontWeight: 600,
      fontSize: '12px',
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      marginTop: '2em',
    },
    returnIcon: {
      color: theme.oysterTheme.oyster,
      fontSize: 20,
    },
  }),
);

export default useStyles;
