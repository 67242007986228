export default {
  title: 'Reporte de depósitos',
  return: 'Atrás',
  routes: {
    report: 'Reporte',
    default: '',
    deposits: 'Depósitos',
  },
  error: {
    title: 'Error',
    msg: 'Hemos encontrado un error',
  },
};
