import { FC } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import { IConfirmDialogProps } from './types';

const IdleModal: FC<IConfirmDialogProps> = ({
  open,
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
  disableBackdropClick = true,
  ...rest
}) => {
  return (
    <Dialog
      {...rest}
      open={open}
      disableBackdropClick={disableBackdropClick}
      maxWidth="xs"
      onBackdropClick={onConfirm}
      onClose={onConfirm}
    >
      <DialogTitle data-testid="title">{title}</DialogTitle>
      <DialogContent>
        <Typography data-testid="message">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-button" onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button data-testid="confirm-button" color="primary" onClick={onConfirm}>
          <strong>{confirmButtonText}</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleModal;
