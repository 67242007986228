import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const ChatIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86766 15.4C8.47615 20.3667 13.4845 22.3667 17.9919 21.3667L22.4992 22.8667L21.4976 19.3667C22.4992 17.8667 23 17.0702 23 15.3667C23 12.0024 20.2247 9 16.184 9C12.1433 9 8.86766 12.0357 8.86766 15.4Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7585 8.0501C17.8536 7.69666 16.8479 7.5 15.7672 7.5C11.324 7.5 7.54196 10.8969 7.52222 14.9131C7.50673 15.1229 7.49944 15.3284 7.50003 15.5296C7.22575 15.4382 6.92949 15.3302 6.66224 15.2329C6.3958 15.1358 6.15821 15.0492 6 15L2.00163 17L2.50324 12.5C2.39808 12.3393 2.2982 12.1897 2.20368 12.0481C1.41597 10.8681 1 10.2449 1 8.39733C1 4.312 4.97693 1 9.88408 1C14.6504 1 18.5407 4.12597 18.7585 8.0501Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

ChatIcon.defaultProps = {
  fill: defaultIconColor,
};

export default ChatIcon;
