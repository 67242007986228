import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Select from '@yaydoo/react-components/lib/components/Select';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import FormButton from '@yaydoo/react-components/lib/components/FormButton';
import Button from '@yaydoo/react-components/lib/components/Button';
import CancelIcon from '@yaydoo/react-components/lib/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import SectionHeader from '@yaydoo/react-components/lib/components/Typography/SectionHeaderV2';

import { RemindersType } from '@store/settings/models/Notification';
import FormContainer from '@components/FormContainer';
import Switch from '@components/common/Switch';
import Loading from '@components/Loading';
import Form from '@components/common/Form';
import useStore from '@hooks/useStore';
import ActionContainer from '@components/ActionContainer';
import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import useStyles from './styles';

const Reminders = () => {
  const { t } = usePaymentToolsTranslation();
  const { openToast } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const {
    settings: {
      notification,
      addReminder,
      selectReminder,
      removeReminder,
      toggleReminders,
      fetchReminderSettings,
      saveReminderSettings,
      getDropDownOptions,
    },
  } = useStore();
  const { availableReminders, hasConfigChanges } = notification;
  const css = useStyles();

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await saveReminderSettings();
      openToast({
        type: 'success',
        message: 'Se ha guardado exitosamente',
      });
    } catch (error) {
      openToast({
        type: 'error',
        title: 'Error',
        message: t('tools.notifications.error.message'),
      });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchReminderSettings();
  }, []);

  const reminders = notification.reminders.map((reminder, index) => {
    return (
      <div key={reminder} className={css.selectContainer}>
        <Select
          data-testid="select-reminder"
          className={css.select}
          options={getDropDownOptions(reminder).map((r) => ({
            label: t(`tools.notifications.reminders.${r}`),
            value: r,
          }))}
          value={reminder}
          onChange={({ target }) => selectReminder(target.value as RemindersType, index)}
        />
        <IconButton
          data-testid="remove-reminder-btn"
          className={css.removeBtn}
          onClick={() => removeReminder(reminder)}
        >
          <CancelIcon />
        </IconButton>
      </div>
    );
  });

  return (
    <FormContainer>
      <div>
        <SectionHeader
          isBoldTitle
          id="tools-notifications-title"
          title={t('tools.notifications.title')}
          description={t('tools.notifications.description')}
        />
        <div className={css.container}>
          {notification.isLoading ? (
            <Loading title={t('common.labels.loading')} />
          ) : (
            <Form onSubmit={() => null}>
              <FormControlLabel
                control={
                  <Switch
                    data-testid="switch-reminders"
                    onChange={toggleReminders}
                    checked={notification.enabled}
                  />
                }
                label={t('tools.notifications.sendReminders')}
                className={css.switch}
              />
              {notification.enabled && (
                <>
                  <div className={css.remindersContainer} data-testid="reminders-container">
                    {reminders}
                  </div>
                  <Button
                    disabled={availableReminders.length === 0}
                    className={css.addReminderBtn}
                    onClick={addReminder}
                    color="primary"
                    variant="text"
                    data-testid="add-reminder-btn"
                    label={t('tools.notifications.add')}
                  />
                </>
              )}
            </Form>
          )}
        </div>
        <ActionContainer className={css.actionContainer}>
          <FormButton
            data-testid="save-reminders-btn"
            onClick={handleSave}
            color="primary"
            label={t('tools.notifications.save')}
            disabled={notification.isLoading || isSaving || !hasConfigChanges}
          />
        </ActionContainer>
      </div>
    </FormContainer>
  );
};

export default observer(Reminders);
