import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      backgroundColor: 'white',
      width: '300px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      padding: '10px 30px',
      borderRadius: '4px',
      position: 'relative',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    modalContainerHeader: {
      color: theme.oysterTheme.pine,
      fontWeight: 600,
    },
    modalContainerText: {
      fontSize: '14px',
    },
    modalButtons: {
      marginTop: '20px',
      alignSelf: 'flex-end',
      width: 'fit-content',
    },
    modalButton: {
      borderRadius: '4px',
      border: `0.5px solid ${theme.oysterTheme.dolphin}`,
      fontSize: '12px',
    },
    modalButtonRed: {
      backgroundColor: theme.oysterTheme.ferrari,
      color: theme.oysterTheme.cotton,
      marginLeft: '10px',
      '&:hover': {
        backgroundColor: theme.oysterTheme.ferrari,
      },
    },
  }),
);

export default useStyles;
