import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useGetUserBusinessData from '@hooks/useGetUserBusinessData';
import useTicket from '@hooks/useTicket';
import MoreMenu from '@components/MoreMenu';
import { TPaymentOrder } from '@store/common/order';
import { Persona } from '@constants/invoice';
import useHasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasActionAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';

const LinkOptions: FC<{ order: TPaymentOrder }> = ({ order }) => {
  const { t } = useTranslation();
  const openRefundTicket = useTicket('refund');
  const businessData = useGetUserBusinessData();
  const canRefundPaymentLink = useHasActionAccess({
    action: allowedActions.PAYMENTS_REFUND_LINKS,
  });

  const onRefund = () => {
    openRefundTicket(order.paymentOrderId);
  };

  const options = [
    {
      key: 'option-refund',
      label: t('collect.payments.links.optionsMenu.refund'),
      onClick: onRefund,
      disabled:
        !order.isRefundable || businessData.persona !== Persona.MORAL || !canRefundPaymentLink,
    },
  ].filter((option) => !option.disabled);

  if (options.length === 0) return <p style={{ width: 40 }} />;

  return <MoreMenu options={options} />;
};

export default LinkOptions;
