import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import BackPageButton from '@yaydoo/react-components/lib/components/BackPageButton';

import useStore from '@hooks/useStore';
import Greetings from '@components/Greetings';

import useStyles from './styles';

import APP, {
  HIDE_ROUTES,
  HEADER_ROUTES_PARENT as routes,
  CUSTOM_DESKTOP_LABEL_ROUTES as customRoutes,
} from '@constants/routes';

const ReturnButton = ({ parentRoute = APP.COLLECT }: { parentRoute?: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleReturn = () => {
    history.push(parentRoute);
  };

  return <BackPageButton onClick={handleReturn} buttonText={t('collect.layout.return')} />;
};

interface HeaderMainProps {
  hideTitle?: boolean;
  className?: string;
}

const HeaderMain: FC<HeaderMainProps> = ({ hideTitle, className }) => {
  const { app } = useStore();
  const css = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = routes.find((val) => location?.pathname?.includes(val.ROUTE));
  const customRoute = customRoutes.find((val) => location?.pathname?.includes(val.ROUTE));

  const label = customRoute?.LABEL || currentRoute?.LABEL || 'default';
  const isChildRoute = location?.pathname !== currentRoute?.ROUTE;

  const shouldHideRoute =
    hideTitle || HIDE_ROUTES.some((route) => location?.pathname?.match(route));

  if (!app.displayHeader) return null;

  return (
    <div className={clsx(className)} data-testid="header-main">
      {isChildRoute ? <ReturnButton parentRoute={currentRoute?.ROUTE} /> : <Greetings />}
      {!shouldHideRoute && (
        <span className={css.location}>{t(`collect.layout.routes.${label}`)}</span>
      )}
    </div>
  );
};

export default observer(HeaderMain);
