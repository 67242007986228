import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& thead > tr > th': {
        borderBottom: `1px solid ${theme.oysterTheme.stone}`,
      },
      '& thead > tr > th:first-child': {
        paddingLeft: 0,
      },
      '& tbody > tr > td:first-child': {
        paddingLeft: 0,
      },
    },
    columnItem: { color: theme.oysterTheme.shark, fontWeight: 500, fontSize: 14 },
    commonCell: { color: theme.oysterTheme.shark, fontSize: 12, fontWeight: 400 },
    businessName: {
      fontWeight: 600,
      fontSize: 12,
      LineHeight: '15.62px',
    },
    wordBreak: { wordBreak: 'break-all' },
    amount: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '14.63px',
    },
    dispersionFail: { textDecorationLine: 'line-through', opacity: 0.5 },
    error: {
      color: theme.oysterTheme.ferrari,
      fontWeight: 400,
      fontSize: '12px',
      wordBreak: 'break-all',
    },
    link: {
      color: theme.oysterTheme.oyster,
      textDecoration: 'none',
      fontWeight: 700,
      fontSize: 14,
      display: 'block',
      width: 'max-content',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });

export default makeStyles(styles);
