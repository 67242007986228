import { Instance, types } from 'mobx-state-tree';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';

const PaymentLinkFilter = types
  .model({
    status: types.optional(
      types.enumeration<PaymentLinkFilterStatus>(Object.values(PaymentLinkFilterStatus)),
      PaymentLinkFilterStatus.ALL,
    ),
    page: 1,
    pageSize: 10,
  })
  .views((self) => ({
    get isPaid() {
      return self.status === PaymentLinkFilterStatus.PAID;
    },
  }));

export interface IPaymentLinkFilter extends Instance<typeof PaymentLinkFilter> {}
export default PaymentLinkFilter;
