import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    sectionName: {
      fontWeight: 600,
      fontSize: '1.5rem',
      letterSpacing: '0.04em',
      margin: 0,
      padding: 0,
    },
  }),
);

export default useStyles;
