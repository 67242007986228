export default {
  tips: {
    title: 'Consejos y respuestas',
    description: 'Soluciona tus dudas al instante',
  },
  chat: {
    title: 'Chat Oyster',
    description: 'Habla con un agente',
  },
  label: 'Lunes a Viernes de 9:00 a 18:00 hrs.',
  title: 'Ayuda',
};
