import { FC } from 'react';
import { ReactComponent as Warning } from '@assets/images/warning.svg';

import useStyles from './styles';

export interface ICancelIcon {}

const CancelIcon: FC<ICancelIcon> = () => {
  const css = useStyles();
  return <Warning className={css.image} />;
};

export default CancelIcon;
