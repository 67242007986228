import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import AddIcon from '@yaydoo/react-components/lib/icons/AddPlain';
import ActionButton from '@yaydoo/react-components/lib/components/OutlinedButton';
import hasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/hasActionAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';
import PaymentLinkCapabilities from '@components/PaymentLinkCapabilities';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';
import PaymentLinkOptions from '@components/PaymentLinkOptions';
import HeaderMain from '@components/HeaderMain';
import Balance from '@components/Balance';

import { COLLECT_ROUTES } from '@constants/routes';
import WebDashboard from '@layouts/WebDashboard';
import useBreakpoints from '@hooks/useBreakpoints';
import { TypeOptions } from './Filter/types';
import Links from './Links';
import Deposits from './Deposits';
import Filter from './Filter';
import useStyles from './styles';

const listResolver = (type: TypeOptions, linkStatus: PaymentLinkFilterStatus) => {
  switch (type) {
    case TypeOptions.DEPOSITS:
      return Deposits;
    case TypeOptions.PAYMENT_LINKS:
    default:
      return () => <Links filter={linkStatus} />;
  }
};

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const css = useStyles();
  const [type, setType] = useState(TypeOptions.PAYMENT_LINKS);
  const [linkStatus, setLinkStatus] = useState(PaymentLinkFilterStatus.ALL);
  const { isTouchScreen } = useBreakpoints();
  const List = listResolver(type, linkStatus);

  const AllowedActionButton = hasActionAccess({
    Component: () => (
      <ActionButton
        onClick={() => history.push(COLLECT_ROUTES.CREATE)}
        label={t('collect.payments.action')}
        leftIcon={() => <AddIcon />}
      />
    ),
    action: allowedActions.PAYMENTS_CREATE_LINKS,
  });

  return (
    <WebDashboard
      rightElement={
        <Filter
          onChangeLinkStatus={setLinkStatus}
          onChangeType={setType}
          currentType={type}
          currentLinkStatus={linkStatus}
        />
      }
      hideRightElement={isTouchScreen}
    >
      <div className={css.root}>
        {isTouchScreen && (
          <Filter
            onChangeLinkStatus={setLinkStatus}
            onChangeType={setType}
            currentType={type}
            currentLinkStatus={linkStatus}
          />
        )}
        <div>
          {!isTouchScreen && (
            <div className={css.header}>
              <HeaderMain />
            </div>
          )}
          <div className={css.pageHeader}>
            <Balance />
            <AllowedActionButton />
          </div>
          <PaymentLinkCapabilities />
          {isTouchScreen && <hr className={css.divider} />}
          <div className={css.listHeader}>
            <span className={css.listTitle}>{t('collect.payments.listTitle')}</span>
            <PaymentLinkOptions />
          </div>
          <hr className={css.divider} />
        </div>
        <div data-testid="list-container" className={css.listContainer} id="list-container">
          <List />
        </div>
      </div>
    </WebDashboard>
  );
};

export default Dashboard;
