import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      gap: 15,
    },
    container: {
      maxWidth: 745,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: '1em',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    submitContainer: {
      padding: theme.spacing(2),
      width: 'fit-content',
    },
    switch: {
      '& span': {
        fontSize: 14,
      },
    },
    addReminderBtn: {
      display: 'flex',
      justifyContent: 'left',
      fontSize: 14,
    },
    remindersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
    },
    selectContainer: {
      display: 'flex',
      justifyItems: 'center',
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
      '&:last-child': {
        marginBottom: theme.spacing(2),
      },
    },
    select: {
      maxWidth: '80%',
      width: 275,
      backgroundColor: theme.oysterTheme.cotton,
      '& svg': {
        color: theme.oysterTheme.oyster,
      },
      '& .MuiSelect-select': {
        color: theme.oysterTheme.dolphin,
      },
    },
    removeBtn: {
      color: theme.oysterTheme.dolphin,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.oysterTheme.shark,
      },
    },
    actionContainer: {
      marginTop: theme.spacing(2),
    },
  });
};

export default makeStyles(styles);
