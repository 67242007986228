import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import Badge from '@components/Badge';
import getBadgeColor from '@utils/getBadgeColor';

export interface IAccountsPayableStatus {
  status: AccountsPayableLinkStatus;
  className?: string;
}

const AccountsPayableStatus: FC<IAccountsPayableStatus> = ({ status, className }) => {
  const { t } = useTranslation();
  const root = 'batch.common.status';
  const batchStatusLabels = {
    [`${AccountsPayableLinkStatus.ACTIVE}`]: t(`${root}.pending`),
    [`${AccountsPayableLinkStatus.CONFIRMED}`]: t(`${root}.pending`),
    [`${AccountsPayableLinkStatus.FUNDED}`]: t(`${root}.pending`),
    [`${AccountsPayableLinkStatus.ON_DISPERSION}`]: t(`${root}.pending`),
    [`${AccountsPayableLinkStatus.DISPERSION_SUCCESS}`]: t(`${root}.success`),
    [`${AccountsPayableLinkStatus.PARTIALLY_DISPERSED}`]: t(`${root}.partiallyDispersed`),
    [`${AccountsPayableLinkStatus.DISPERSION_ERROR}`]: t(`${root}.error`),
    [`${AccountsPayableLinkStatus.CANCELED}`]: t(`${root}.canceled`),
    [`${AccountsPayableLinkStatus.DISPERSION_FAIL}`]: t(`${root}.fail`),
    [AccountsPayableLinkStatus.RETURNED]: t(`${root}.returned`),
    [AccountsPayableLinkStatus.REFUNDED]: t(`${root}.returned`),
  };
  return (
    <Badge className={className} label={batchStatusLabels[status]} color={getBadgeColor(status)} />
  );
};

export default AccountsPayableStatus;
