import { FC } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import useBreakpoints from '@hooks/useBreakpoints';
import { defaultTheme } from '@constants/cobranding';
import OysterLogo from '@assets/OysterLogo';
import { isFile } from '@utils/validators';

import Tab from './Tab';
import Body from './Body';
import Support from './Support';
import Toolbar from './Toolbar';
import useStyles from '../styles';
import MobilePreview from './Mobile';

const Preview: FC = () => {
  const css = useStyles();
  const { watch } = useFormContext();
  const { isExtraSmallScreen } = useBreakpoints();
  const { t } = usePaymentToolsTranslation();

  const logo = watch('assets.logo');
  const favicon = watch('assets.favicon');
  const primaryColor = watch('theme.primaryColor');
  const secondaryColor = watch('theme.secondaryColor');
  const email = watch('contactInfo.email');
  const phone = watch('contactInfo.phone');

  const faviconUrl = isFile(favicon) ? URL.createObjectURL(favicon as File) : favicon;

  return (
    <div className={css.previewContainer}>
      <span className={css.previewTitle}>{t('tools.cobranding.preview')}</span>
      {isExtraSmallScreen ? (
        <MobilePreview
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          email={email}
          phone={phone}
        />
      ) : (
        <div className={`${css.wrapper} ${css.preview}`}>
          <Tab favicon={favicon ? (faviconUrl as string) : undefined} />
          <Toolbar />
          <div className={css.previewLogoWrapper}>
            {logo ? (
              <img
                src={isFile(logo) ? URL.createObjectURL(logo as File) : (logo as string)}
                alt="logo"
              />
            ) : (
              <OysterLogo />
            )}
          </div>
          <div className={css.body}>
            <Body
              primaryColor={primaryColor || defaultTheme.primary}
              secondaryColor={secondaryColor || defaultTheme.secondary}
            />
            <Support email={email} phone={phone} secondaryColor={secondaryColor} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Preview);
