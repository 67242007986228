import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Ticket from '@components/Tickets/Ticket';
import Loading from '@components/Loading';

import { TicketStatus } from '@constants/paymentOrder';
import useGetUserBusinessData from '@hooks/useGetUserBusinessData';
import TicketFooter from '@components/Tickets/TicketFooter';
import useStore from '@hooks/useStore';
import useStyles from './styles';
import { Persona } from '@constants/invoice';

interface DepositTicketProps {
  id: string;
}

const DepositTicket: FC<DepositTicketProps> = ({ id }) => {
  const css = useStyles();
  const { name } = useGetUserBusinessData();
  const { deposits } = useStore();
  const businessData = useGetUserBusinessData();
  const { paymentOrder } = deposits;

  const isRefundable = paymentOrder?.isRefundable && businessData.persona === Persona.MORAL;
  const shouldDisplayLoader =
    deposits.flags.isLoadingOrder || !paymentOrder || paymentOrder?.paymentOrderId !== id;

  useEffect(() => {
    if (deposits.paymentOrder?.paymentOrderId !== id) deposits.getPaymentOrder(id);
  }, [id]);

  if (shouldDisplayLoader) return <Loading />;

  return (
    <div className={css.root}>
      <div className={css.container}>
        <Ticket
          details={{
            userFullName: name,
            status: paymentOrder.isRefunded ? TicketStatus.REFUNDED : TicketStatus.SETTLED,
            title: paymentOrder.title,
            total: paymentOrder.totalAmount,
            fee: paymentOrder.feeAmount,
            payeeName: paymentOrder.paymentFullName,
            payeeEmail: paymentOrder.paymentEmail,
            ticketItems: paymentOrder.displayItems,
            paymentDate: paymentOrder.paymentDate,
            createdAt: paymentOrder.createdAt,
            totalNetAmount: paymentOrder.totalNetAmount,
            ticketNumber: paymentOrder.linkNumberFormatted,
            refundedAmount: paymentOrder.refundedAmountFormatted,
            refundFee: paymentOrder.refundFeeFormatted,
            refundedDate: paymentOrder.refundedAt,
          }}
        />
        <TicketFooter paymentOrderId={paymentOrder.paymentOrderId} isRefundable={isRefundable} />
      </div>
    </div>
  );
};

export default observer(DepositTicket);
