import { Instance, types } from 'mobx-state-tree';

import PaymentStatus, {
  PaymentLinkStatus,
  TicketStatus,
  PaymentLinkType,
  PaymentLinkFilterStatus,
} from '@constants/paymentLink';
import LinkItem, { emptyItem } from './Item';
import PaymentOrder from '@store/common/order';
import castPaymentLink from '@utils/castPaymentLink';
import { PaymentLink as PaymentLinkData } from '@store/links/types';
import { PaymentOrderPaymentMethods } from '@constants/paymentOrder';

export const PaymentLinkMetadata = types
  .model({
    totalAmountPaid: types.optional(types.union(types.number, types.string), '0'),
    totalRefundedAmount: types.optional(types.union(types.number, types.string), '0'),
    totalRefundFeesAmount: types.optional(types.union(types.number, types.string), '0'),
  })
  .views((self) => ({
    get refundedAmountFormatted() {
      return +self.totalRefundedAmount / 100;
    },
    get refundFeeFormatted() {
      return +self.totalRefundFeesAmount / 100;
    },
    get totalPaid() {
      return +self.totalAmountPaid / 100;
    },
  }));

const PaymentLink = types
  .model({
    paymentLinkId: '',
    userId: '',
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    businessId: '',
    linkNumber: '',
    title: '',
    status: '',
    total: types.optional(types.union(types.number, types.string), '0.00'),
    payeeName: '',
    payeeEmail: '',
    createdAt: '',
    lastPaidAt: '',
    linkItems: types.optional(types.array(LinkItem), [emptyItem]),
    type: types.optional(types.string, PaymentLinkType.SINGLE_PAYMENT),
    currency: '',
    useLimit: types.optional(types.union(types.number, types.string), '0'),
    paidCount: types.optional(types.union(types.number, types.string), '0'),
    dueDate: '',
    refundCount: types.optional(types.union(types.number, types.string), '0'),
    lastRefundedAt: '',
    metadata: types.optional(PaymentLinkMetadata, {}),
    paymentOrders: types.array(PaymentOrder),
  })
  .views((self) => ({
    get userFullName() {
      const { userFirstName, userMiddleName, userLastName } = self;
      return [userFirstName, userMiddleName, userLastName].filter(Boolean).join(' ');
    },
    get linkNumberFormatted() {
      const sequence = self.linkNumber.padStart(9, '0');
      return self.linkNumber && `#${sequence}`;
    },
    get mappedStatus() {
      const status = self.status || PaymentLinkStatus.UNKNOWN;
      return PaymentStatus[`${status}`] || TicketStatus.PAID;
    },
    get totalFormatted() {
      const total = (+self.total / 100).toFixed(2);
      return `$${total}`;
    },
    get showPaymentMethodBadge() {
      return (
        self.type === PaymentLinkType.SINGLE_PAYMENT && self.status === PaymentLinkStatus.CLOSED
      );
    },
    get paymentMethod() {
      return self.paymentOrders?.[0].paymentMethod as PaymentOrderPaymentMethods;
    },
    get totalAmount() {
      return +self.total / 100;
    },
    get isPending() {
      return self.status === PaymentLinkStatus.ACTIVE;
    },
    get displayStatus() {
      const isPaid = +self.paidCount >= +self.useLimit && +self.paidCount > 0;
      const status = self.status || PaymentLinkStatus.UNKNOWN;
      const paymentStatus = PaymentStatus[status] || TicketStatus.PAID;

      if (self.type === PaymentLinkType.SINGLE_PAYMENT && this.settledDate) {
        return TicketStatus.SETTLED;
      } else if (this.isUnlimited) {
        return TicketStatus.PENDING;
      } else if (isPaid && !this.isRefunded) {
        return TicketStatus.PAID;
      } else if (this.isRefunded) {
        return TicketStatus.REFUNDED;
      }
      return paymentStatus;
    },
    get settledDate() {
      if (self.type === PaymentLinkType.SINGLE_PAYMENT) {
        return self.paymentOrders?.[0]?.disbursedAt || '';
      }
      return '';
    },
    get paymentOrderId() {
      return self.paymentOrders?.[0]?.paymentOrderId || '';
    },
    get isMultiple() {
      return self.type === PaymentLinkType.MULTI_PAYMENT;
    },
    get isUnlimited() {
      return +self.useLimit === 0;
    },
    get isClosed() {
      const status = self.status || PaymentLinkStatus.UNKNOWN;
      return PaymentStatus[status] === TicketStatus.CLOSED;
    },
    get hasPayments() {
      return +self.paidCount > 0;
    },
    get isPaid() {
      const shouldEnforce = !this.isUnlimited && +self.paidCount >= +self.useLimit;

      if (shouldEnforce && !this.isRefunded) {
        return true;
      }
      const status = self.status || PaymentLinkStatus.UNKNOWN;
      return PaymentStatus[status] === TicketStatus.PAID;
    },
    get isRefunded() {
      return !this.isUnlimited && self.refundCount > 0 && self.refundCount === self.paidCount;
    },
    get shouldDisplayAmountOfRefunds() {
      return self.refundCount > 0 && this.isMultiple && !this.isRefunded;
    },
    get url() {
      return self.paymentLinkId;
    },
    get parsed(): PaymentLinkData {
      return castPaymentLink(self as IPaymentLink);
    },
    get displayItems() {
      return self.linkItems.map((item) => item.viewableItems);
    },
    get isRefundable() {
      const [paymentOrder] = self.paymentOrders;

      return paymentOrder.isRefundable;
    },
  }))
  .actions((self) => ({
    markAsPaid() {
      self.status = PaymentLinkStatus.CLOSED;
      self.paidCount = 1;
    },
  }));

export const LinkStatusStore = types.enumeration<PaymentLinkFilterStatus>(
  Object.values(PaymentLinkFilterStatus),
);

export interface IPaymentLinkMetadata extends Instance<typeof PaymentLinkMetadata> {}
export interface IPaymentLink extends Instance<typeof PaymentLink> {}
export default PaymentLink;
