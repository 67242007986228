import { flow, Instance, types } from 'mobx-state-tree';

import CommonStore from '../common';
import CommonService from './service';
import { API_STATUS, errors } from '../constants';
import ProductTypes from '../constants/productType';
import { KycStatus } from '../constants/enum';

export interface IVolatileState {
  service: null | CommonService;
}

export const CreditBalanceDetail = types
  .model({
    balance: 0,
    productCode: '',
    creditLimit: 0,
    locBalance: 0,
    openToBuy: 0,
    min_amt: 0,
    signUpBillingCycle: '',
    repaymentDuration: '',
  })
  .views((self) => ({
    get isDueAmount() {
      return self.balance > 0;
    },
  }));

export const SubscribeLoanItem = types.model({
  productCode: '',
  productType: '',
  status: '',
});

const CommonCreditStore = types
  .compose(
    CommonStore,
    types.model({
      loading: false,
      icDetail: types.optional(types.maybe(CreditBalanceDetail), {}),
      locDetail: types.optional(types.maybe(CreditBalanceDetail), {}),
      ilDetail: types.optional(types.maybe(CreditBalanceDetail), {}),
      isData: false,
      sideBar: '',
      isDrawer: false,
      subscribeLoan: types.optional(types.array(SubscribeLoanItem), []),
      kycStatus: 0,
      kycLoading: false,
      isKycFetched: false,
      isSubscribeLoanFetched: false,
      businessStatus: false,
      pepStatus: false,
    }),
  )
  .volatile(
    (): IVolatileState => ({
      service: null,
    }),
  )
  .views((self) => ({
    get isKycRequired() {
      return self.kycStatus === KycStatus.ADDITIONAL_KYC_REQUIRED;
    },
    get isKycCompleted() {
      return self.kycStatus === KycStatus.KYC_COMPLETED;
    },
  }))
  .views((self) => ({
    get isKycPending() {
      return !self.isKycRequired && !self.isKycCompleted;
    },
  }))
  .views((self) => ({
    get getIc() {
      return self.icDetail;
    },
    get getLoc() {
      return self.locDetail;
    },
    get subscribeIcV1() {
      return self.subscribeLoan?.length
        ? self.subscribeLoan.filter((obj) => obj.productType === ProductTypes.instantCredit)
        : [];
    },
    get subscribeIcV2() {
      return self.subscribeLoan?.length
        ? self.subscribeLoan.filter((obj) => obj.productType === ProductTypes.instantCreditV2)
        : [];
    },
    get subscribeLoc() {
      return self.subscribeLoan?.length
        ? self.subscribeLoan.filter((obj) => obj.productType === ProductTypes.loc)
        : [];
    },
    get subscribeInstalmentLoan() {
      return self.subscribeLoan?.length
        ? self.subscribeLoan.filter((obj) => obj.productType === ProductTypes.instalmentLoan)
        : [];
    },
  }))
  .views((self) => ({
    get recentSubscribedIcV1ProductCode() {
      return self.subscribeIcV1?.[0]?.productCode || null;
    },
    get recentSubscribedIcV2ProductCode() {
      return self.subscribeIcV2?.[0]?.productCode || null;
    },
    get recentSubscribedLocProductCode() {
      return self.subscribeLoc?.[0]?.productCode || null;
    },
    get recentSubscribedInstalmentLoanProductCode() {
      return self.subscribeInstalmentLoan?.[0]?.productCode || null;
    },
  }))
  .actions((self) => ({
    updateService: flow(function* () {
      self.service = new CommonService();
      yield Promise.resolve('ok');
    }),
  }))
  .actions((self) => ({
    updateSideBar: (value: string) => {
      self.sideBar = value;
      self.isDrawer = true;
    },
    resetSideBar: () => {
      self.sideBar = '';
      self.isDrawer = false;
    },
  }))
  .actions((self) => ({
    fetchBalance: flow(function* () {
      self.loading = true;
      self.isData = false;
      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);
        const { data: res } = yield self.service.fetchBalance();
        const { meta, data } = res;

        if (API_STATUS?.SUCCESS === meta?.status) {
          self.isData = true;
          self.icDetail = data.icDetail;
          self.locDetail = data.locDetail;
          self.ilDetail = data.ilDetail;
        }

        return res;
      } finally {
        self.loading = false;
      }
    }),
    getSubscribedLoan: flow(function* () {
      self.loading = true;
      self.isData = false;
      self.isSubscribeLoanFetched = false;
      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);
        const { data: res } = yield self.service.getSubscribedLoan();
        const { meta, data } = res;

        if (API_STATUS?.SUCCESS === meta?.status) {
          self.isData = true;
          self.subscribeLoan = data;
        }

        return res;
      } finally {
        self.isSubscribeLoanFetched = true;
        self.loading = false;
      }
    }),
    getKycStatus: flow(function* (productType: string) {
      self.kycLoading = true;
      self.isKycFetched = false;
      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);

        const {
          data: { status, businessStatus, pepStatus },
        } = yield self.service.fetchKycStatus(productType);
        if (KycStatus[status]) {
          self.kycStatus = KycStatus[status] as unknown as number;
        } else {
          self.kycStatus = KycStatus.UNKNOWN_KYC_STATUS;
        }
        self.pepStatus = pepStatus;
        self.businessStatus = businessStatus;
      } finally {
        self.isKycFetched = true;
        self.kycLoading = false;
      }
    }),
  }));

export type CommonCreditStoreType = Instance<typeof CommonCreditStore>;
export type TCreditBalanceDetail = Instance<typeof CreditBalanceDetail>;
export type TSubscribeLoanItem = Instance<typeof SubscribeLoanItem>;

export default CommonCreditStore;
