import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

export default class AccessService extends OysterService {
  /**
   * Get user access role
   * @return Promise<AxiosResponse>
   */
  getUserRole(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/user/role');
  }
}
