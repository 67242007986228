export enum BusinessType {
  UNKNOWN_BUSINESS_TYPE = 0,
  PERSONA_FISICA = 1,
  PERSONA_MORALE = 2,
  PERSONA_MORAL = 2,
}

export enum VerificationStatus {
  UNKNOWN_VERIFICATION_STATUS = 0,
  COMPLETED = 2,
  VERIFIED = 3,
  FORMAT_VERIFIED = 4,
  KYC_CHECK_INITIATED = 14,
  VERIFICATION_PENDING = 15,
  EXTERNAL_SERVICE_VERIFIED = 16,
  EXTERNAL_SERVICE_REJECTED = 17,
  CHA_VERIFIED = 18,
  CHA_REJECTED = 19,
  KYC_INFO_CAUTIONED = 25,
  KYC_INFO_SUSPECTED = 26,
}

export enum OverallStatus {
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ADDITIONAL_INFO_REQUIRED = 'ADDITIONAL_INFO_REQUIRED',
}

export enum OverallStatusProductTypes {
  UNKNOWN_PRODUCT_TYPE = 0,
  PRODUCT_TYPE_PAYMENT_LINK = 1,
}
