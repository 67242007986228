import { FC } from 'react';
import { ReactComponent as Send } from '@assets/images/send.svg';

import useStyles from './styles';

export interface ICancelIcon {}

const CancelIcon: FC<ICancelIcon> = () => {
  const css = useStyles();
  return <Send className={css.image} />;
};

export default CancelIcon;
