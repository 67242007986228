import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import Button, { FormButtonProps } from '@yaydoo/react-components/lib/components/FormButton';
import Uploader from '@yaydoo/react-components/lib/components/Uploader';
import {
  OnDeleteEvent,
  OnDrop,
  UploadState,
} from '@yaydoo/react-components/lib/components/Uploader/types';
import DownloadIcon from '@yaydoo/react-components/lib/icons/Arrows/Download';

import useStyles from './styles';

export interface IUploadBatch {
  buttonProps: FormButtonProps;
  batchTemplateLink: string;
  file: File | undefined;
  uploadState: UploadState;
  onUpload: (values: OnDrop) => void;
  onUploadedFileRemove: (e: OnDeleteEvent) => void;
  maxFileSize?: number;
}

const UploadBatch: FC<IUploadBatch> = ({
  buttonProps,
  batchTemplateLink,
  file,
  uploadState,
  onUpload,
  onUploadedFileRemove,
  maxFileSize,
}) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <div className={css.root}>
      <span className={css.header}>{t('batch.dashboard.createBatch.title')}</span>
      <div className={css.container}>
        <Typography variant="body2">
          <Trans i18nKey="batch.dashboard.createBatch.instructions">
            <strong />
            <a className={css.linkText} href={batchTemplateLink} />
          </Trans>
        </Typography>
        <br />
        <Typography className={css.notice} variant="body2">
          {t('batch.dashboard.createBatch.notice')}
        </Typography>
      </div>
      <div className={css.fileInputContainer}>
        <Uploader
          accept="text/csv"
          onUpload={onUpload}
          onUploadedFileRemove={onUploadedFileRemove}
          label={t('batch.dashboard.createBatch.input')}
          uploadState={uploadState}
          multiple={false}
          controlledUpload={false}
          file={file}
          maxSize={maxFileSize}
        />
      </div>
      <div className={css.footer}>
        <Button className={css.submit} {...buttonProps} />
        <p className={css.optionalText}>
          <span>ó</span>
        </p>
        <Typography className={css.help} variant="body2">
          {t('batch.dashboard.createBatch.help')}
        </Typography>
        <Button
          variant="text"
          href={batchTemplateLink}
          label={t('batch.dashboard.createBatch.download')}
          className={css.download}
          startIcon={<DownloadIcon className={css.downloadIcon} />}
        />
      </div>
    </div>
  );
};

export default UploadBatch;
