import { types } from 'mobx-state-tree';

import { GlobalStore as BaseGlobalStore } from '@yaydoo/react-mobx-stores/lib';
import AccessStore, { TAccessStore } from '@yaydoo/react-mobx-stores/lib/access';
import AccountStore, { TAccountStore } from '@yaydoo/react-mobx-stores/lib/account';
import AccountsPayableLinkStore, {
  IAccountsPayableLinkStore,
} from '@yaydoo/react-mobx-stores/lib/accountsPayableLink';
import AddressStore from '@yaydoo/react-mobx-stores/lib/address';
import { AddressType } from '@yaydoo/react-mobx-stores/lib/address/enums';
import AuthStore from '@yaydoo/react-mobx-stores/lib/auth';
import BusinessStore, { PhoneModel } from '@yaydoo/react-mobx-stores/lib/business';
import { IGlobalStore as IBaseGlobalStore } from '@yaydoo/react-mobx-stores/lib/globalStore';
import StoreResolver from '@yaydoo/react-mobx-stores/lib/storeResolver';
import UserStore from '@yaydoo/react-mobx-stores/lib/user';

// Import your stores here
import AppStore, { AppStoreType } from './app';
import BatchStore, { BatchStoreType } from './batch';
import BatchDetailsStore, { BatchDetailsStoreType } from './batchDetails';

/* Common store setup - only change if a new common store is added in react-mobx-stores */
export interface ICommonStore extends IBaseGlobalStore {
  account: TAccountStore;
  access: TAccessStore;
}

export const CommonStore = types.compose(
  BaseGlobalStore,
  types.model({
    account: AccountStore,
    access: AccessStore,
  }),
);

export const commonStore: ICommonStore = CommonStore.create({
  auth: AuthStore.create({}),
  account: AccountStore.create({}),
  user: UserStore.create({
    phone: PhoneModel.create({}),
  }),
  business: BusinessStore.create({
    businessAddress: AddressStore.create({
      type: AddressType.LEGAL,
    }),
    personalAddress: AddressStore.create({
      type: AddressType.PERSONAL,
    }),
  }),
  access: AccessStore.create({}),
});
/* End common stores */

/* Project stores, add here new stores exclusive to your project */
export interface IGlobalStore extends IBaseGlobalStore {
  batch: BatchStoreType;
  batchDetails: BatchDetailsStoreType;
  account: TAccountStore;
  access: TAccessStore;
  app: AppStoreType;
  accountsPayableLink: IAccountsPayableLinkStore;
}

export const GlobalStore = types.compose(
  BaseGlobalStore,
  types.model({
    account: AccountStore,
    access: AccessStore,
    // add project stores here
    batch: BatchStore,
    batchDetails: BatchDetailsStore,
    app: AppStore,
    accountsPayableLink: AccountsPayableLinkStore,
  }),
);

export type TPartialGlobalStore = {
  [K in keyof IGlobalStore]?: Partial<IGlobalStore[K]>;
};

/**
 * Common Stores here won't be accessible with useStore hooks.
 * To access these you will require to use useCommonStore hook.
 */
export const store: IGlobalStore = GlobalStore.create({
  auth: AuthStore.create({}),
  account: AccountStore.create({}),
  user: UserStore.create({
    phone: PhoneModel.create({}),
  }),
  business: BusinessStore.create({
    businessAddress: AddressStore.create({
      type: AddressType.LEGAL,
    }),
    personalAddress: AddressStore.create({
      type: AddressType.PERSONAL,
    }),
  }),
  access: AccessStore.create({}),
  // Initiate project stores here
  batch: BatchStore.create({}),
  batchDetails: BatchDetailsStore.create({}),
  app: AppStore.create({}),
  accountsPayableLink: AccountsPayableLinkStore.create({}),
});

StoreResolver.bind(store);
export default store;
