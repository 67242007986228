export const config = {
  apiKey: 'AIzaSyA46rW3eq0LO8ujJpskYIfxL20h6ZZI4ho',
  authDomain: 'push-notification-ios-d4273.firebaseapp.com',
  databaseURL: 'https://push-notification-ios-d4273.firebaseio.com',
  projectId: 'push-notification-ios-d4273',
  storageBucket: 'push-notification-ios-d4273.appspot.com',
  messagingSenderId: '1026459652799',
  appId: '1:1026459652799:web:84e10f7b3c0da4125bac0a',
  measurementId: 'G-V9L3JK4XPC',
};

export type TFirebaseConfig = typeof config;
