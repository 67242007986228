import { types } from 'mobx-state-tree';

export const LinkItemErrors = types.model({
  itemId: false,
  paymentItemCollectionId: false,
  name: false,
  quantity: false,
  price: false,
  total: false,
});

export const PaymentLinkErrors = types.model({
  paymentLinkId: false,
  userId: false,
  userFirstName: false,
  userMiddleName: false,
  userLastName: false,
  businessId: false,
  linkNumber: false,
  title: false,
  status: false,
  total: false,
  payeeName: false,
  payeeEmail: false,
  createdAt: false,
  lastPaidAt: false,
  linkItems: types.array(LinkItemErrors),
  currency: false,
  type: false,
  useLimit: false,
  paidCount: false,
  dueDate: false,
});

const Errors = types.model({
  fetchingLinks: false,
  fetchingLink: false,
  creatingPaymentLink: false,
  paymentLink: types.optional(PaymentLinkErrors, {}),
});

export default Errors;
