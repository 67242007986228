export enum DocumentType {
  PROOF_OF_ADDRESS = 6,
  UNKNOWN_DOCUMENT = 0,
  PASSPORT = 1,
  NATIONAL_IDENTITY_CARD = 3,
  PHOTO = 4,
  ART_OF_INCORPORATION = 7,
  NOTARY_POWERS = 15,
  PROOF_OF_FIEL = 16,
  PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY = 17,
  FISCAL_IDENTIFICATION = 18,
  EXTERNAL_ACCOUNT_STATEMENT = 25,
  VOTER_ID = 5,
  APPLICANT_SELFIE_VIDEO = 11,
  KYC_PDF_REPORT = 10,
  CLUNI = 19,
  CACAO_LETTER_FOR_KYC_REAL_OWNER = 20,
  LETTER_FOR_PROOF_OF_ADDRESS = 21,
  LETTER_FOR_BENEFICIAL_OWNERSHIP = 22,
  LETTER_FOR_INTERNAL_GOVERNANCE_STRUCTURE = 23,
  OTHER_BYLAWS = 24,
}

export enum DocumentFileType {
  DOCUMENT = 1,
  IMAGE = 2,
}

export enum DocumentSides {
  front = 'front',
  back = 'back',
}

export enum DocumentStatus {
  CHA_VERIFIED = 'CHA_VERIFIED',
  EXTERNAL_SERVICE_VERIFIED = 'EXTERNAL_SERVICE_VERIFIED',
  EXTERNAL_SERVICE_REJECTED = 'EXTERNAL_SERVICE_REJECTED',
  CHA_REJECTED = 'CHA_REJECTED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  NOT_PROVIDED = 'NOT_PROVIDED',
}
