import { Instance, types } from 'mobx-state-tree';

import PaymentStatus, {
  PaymentMethods,
  PaymentOrderPaymentMethods,
  PaymentOrderRefundableStatus,
  PaymentOrderStatus,
  TicketStatus,
} from '@constants/paymentOrder';

import OrderItem, { emptyItem, TOrderItem } from './Item';

const PaymentOrder = types
  .model({
    paymentOrderId: '',
    userId: '',
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    businessId: '',
    orderNumber: '',
    title: '',
    status: '',
    disbursedAmount: types.optional(types.union(types.number, types.string), '0.00'),
    total: types.optional(types.union(types.number, types.string), '0.00'),
    fee: types.optional(types.union(types.number, types.string), '0.00'),
    currency: '',
    payeeName: '',
    payeeEmail: '',
    createdAt: '',
    paymentMethod: '',
    paymentDate: '',
    useLimit: types.optional(types.union(types.number, types.string), ''),
    paymentFirstName: '',
    paymentLastName: '',
    paymentEmail: '',
    paymentPhone: '',
    paymentZipCode: '',
    linkNumber: '',
    disbursedAt: '',
    refundedAmount: types.optional(types.union(types.number, types.string), '0.00'),
    refundFee: types.optional(types.union(types.number, types.string), '0.00'),
    refundedAt: '',
  })
  .views((self) => ({
    get userFullName() {
      const { userFirstName, userMiddleName, userLastName } = self;
      return [userFirstName, userMiddleName, userLastName].filter(Boolean).join(' ');
    },
    get paymentFullName() {
      const { paymentFirstName, paymentLastName } = self;
      return [paymentFirstName, paymentLastName].filter(Boolean).join(' ');
    },
    get orderNumberFormatted() {
      const sequence = self.orderNumber.padStart(9, '0');
      return `#${sequence}`;
    },
    get mappedStatus() {
      const status = self.status || PaymentOrderStatus.UNKNOWN;
      return PaymentStatus[`${status}`] || TicketStatus.PAID;
    },
    get isMarkedAsPaid() {
      return PaymentOrderStatus.MARKED_AS_PAID === self.status;
    },
    get totalAmount() {
      return +self.total / 100;
    },
    get feeAmount() {
      return +self.fee / 100;
    },
    get totalNetAmount() {
      return (+self.total - +self.fee) / 100;
    },
    get isPending() {
      return self.status === PaymentOrderStatus.CREATED;
    },
    get isPaid() {
      const status = self.status || PaymentOrderStatus.UNKNOWN;
      return PaymentStatus[status] === TicketStatus.PAID;
    },
    get url() {
      return self.paymentOrderId;
    },
    get displayPaymentMethod() {
      return PaymentMethods[`${self.paymentMethod}`] || PaymentMethods.UNKNOWN;
    },
    get refundedAmountFormatted() {
      return +self.refundedAmount / 100;
    },
    get refundFeeFormatted() {
      return +self.refundFee / 100;
    },
    get isRefunded() {
      const status = self.status || PaymentOrderStatus.UNKNOWN;
      return PaymentStatus[status] === TicketStatus.REFUNDED;
    },
    get isRefundable() {
      return (
        Object.values(PaymentOrderRefundableStatus).includes(self.status) &&
        self.paymentMethod === PaymentOrderPaymentMethods.CARD_PAYMENT
      );
    },
  }));

export const PaymentOrderDetail = types
  .compose(
    PaymentOrder,
    types.model({
      orderItems: types.optional(types.array(OrderItem), [emptyItem]),
    }),
  )
  .views((self) => ({
    get displayItems() {
      return self?.orderItems?.map((item: TOrderItem) => item.parsedItems);
    },
    get linkNumberFormatted() {
      const sequence = self.linkNumber.padStart(9, '0');
      return `#${sequence}`;
    },
  }));

export interface TPaymentOrder extends Instance<typeof PaymentOrder> {}
export interface TPaymentOrderDetails extends Instance<typeof PaymentOrderDetail> {}
export default PaymentOrder;
