import { Instance, types } from 'mobx-state-tree';

export const Balance = types
  .model({
    amount: types.optional(types.union(types.number, types.string), '0'),
    balanceId: '',
    businessId: '',
    currency: '',
    createdAt: '',
    updatedAt: '',
  })
  .views((self) => ({
    get balanceFormatted() {
      return +self.amount / 100;
    },
  }));

export interface BalanceStore extends Instance<typeof Balance> {}
export default Balance;
