import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import useModal from '@yaydoo/react-components/lib/hooks/useModal';
import useHasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasActionAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';

import useStore from '@hooks/useStore';
import useTicket from '@hooks/useTicket';
import * as GA from '@constants/analytics';
import MoreMenu from '@components/MoreMenu';
import useAnalytics from '@hooks/useAnalytics';
import { IPaymentLink } from '@store/links/models/Link';
import ConfirmModal from '@components/modals/ConfirmModal';
import useMakeCheckoutUrl from '@hooks/useMakeCheckoutUrl';
import useGetUserBusinessData from '@hooks/useGetUserBusinessData';
import { Persona } from '@constants/invoice';

const LinkOptions: FC<{ paymentLink: IPaymentLink }> = ({ paymentLink }) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { sendEvent } = useAnalytics();
  const { showModal } = useModal();
  const getCheckoutUrl = useMakeCheckoutUrl();
  const businessData = useGetUserBusinessData();
  const { paymentLink: paymentLinkStore } = useStore();
  const openRefundTicket = useTicket('refund');
  const canDeletePaymentLink = useHasActionAccess({
    action: allowedActions.PAYMENTS_DELETE_LINKS,
  });
  const canRefundPaymentLink = useHasActionAccess({
    action: allowedActions.PAYMENTS_REFUND_LINKS,
  });
  const canMarkAsPaidPaymentLink = useHasActionAccess({
    action: allowedActions.PAYMENTS_MARK_AS_PAID_LINKS,
  });

  const handleOnCopy = (success: boolean) => {
    if (success) {
      openToast({
        title: t('collect.payments.links.optionsMenu.copied'),
        message: t('collect.payments.links.optionsMenu.copySuccess'),
        type: 'success',
      });
      return;
    }

    openToast({
      title: t('collect.layout.error.title'),
      message: t('collect.layout.error.msg'),
      type: 'error',
    });
  };

  const onDelete = () => {
    const onConfirmDelete = async () => {
      try {
        await paymentLinkStore.deletePaymentLink(paymentLink.paymentLinkId);

        openToast({
          title: t('collect.payments.links.optionsMenu.deleted'),
          message: t('collect.payments.links.optionsMenu.deleteSuccess'),
          type: 'success',
        });

        sendEvent({
          action: GA.ACTIONS.CLICK,
          category: GA.CATEGORIES.DASHBOARD.CATEGORY,
          label: GA.CATEGORIES.DASHBOARD.LABELS.DELETE_LINK,
        });
      } catch {
        openToast({
          type: 'error',
          title: t('collect.layout.error.title'),
          message: t('collect.layout.error.msg'),
        });
      }
    };

    showModal({
      component: () => (
        <ConfirmModal
          onConfirm={onConfirmDelete}
          title={t('collect.layout.modals.delete.PromptHeader')}
          message={t('collect.layout.modals.delete.PromptText')}
          confirmLabelButton={t('collect.layout.modals.delete.deleteButtonText')}
          cancelLabelButton={t('collect.layout.modals.delete.cancelButtonText')}
        />
      ),
    });
  };

  const onMarkAsPaid = () => {
    const onConfirmMarkAsPaid = async () => {
      try {
        await paymentLinkStore.markPaymentLinkAsPaid(paymentLink.paymentLinkId);

        openToast({
          title: t('collect.payments.links.optionsMenu.markedAsPaid'),
          message: t('collect.payments.links.optionsMenu.markAsPaidSuccess'),
          type: 'success',
        });
      } catch {
        openToast({
          type: 'error',
          title: t('collect.layout.error.title'),
          message: t('collect.layout.error.msg'),
        });
      }
    };
    showModal({
      component: () => (
        <ConfirmModal
          onConfirm={onConfirmMarkAsPaid}
          title={t('collect.layout.modals.markAsPaid.PromptHeader')}
          message={t('collect.layout.modals.markAsPaid.PromptText')}
          confirmLabelButton={t('collect.layout.modals.markAsPaid.confirmButtonText')}
          cancelLabelButton={t('collect.layout.modals.markAsPaid.cancelButtonText')}
        />
      ),
    });
  };

  const onRefund = () => {
    openRefundTicket(paymentLink.paymentOrders[0].paymentOrderId);
  };

  const options = [
    {
      key: 'option-copy',
      label: t('collect.payments.links.optionsMenu.copy'),
      onClick: () => {
        const url = getCheckoutUrl(paymentLink.paymentLinkId);
        handleOnCopy(copy(url));
      },
      disabled: paymentLink.isPaid || (paymentLink.isRefunded && !paymentLink.isUnlimited),
    },
    {
      key: 'option-delete',
      label: t('collect.payments.links.optionsMenu.delete'),
      onClick: onDelete,
      disabled: paymentLink.hasPayments || !canDeletePaymentLink,
    },
    {
      key: 'option-refund',
      label: t('collect.payments.links.optionsMenu.refund'),
      onClick: onRefund,
      disabled:
        !paymentLink.isRefundable ||
        businessData.persona !== Persona.MORAL ||
        !canRefundPaymentLink,
    },
    {
      key: 'option-mark-as-paid',
      label: t('collect.payments.links.optionsMenu.markAsPaid'),
      onClick: onMarkAsPaid,
      disabled:
        paymentLink.isMultiple ||
        paymentLink.isPaid ||
        paymentLink.isRefunded ||
        !canMarkAsPaidPaymentLink,
    },
  ].filter((option) => !option.disabled);

  if (options.length === 0) return <p style={{ width: 42 }} />;

  return <MoreMenu options={options} />;
};

export default LinkOptions;
