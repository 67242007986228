import { Instance, types } from 'mobx-state-tree';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

const AccountsPayableBatchListItem = types.model({
  accountsPayableLinkId: '',
  id: '',
  amount: types.optional(types.union(types.number, types.string), ''),
  currency: '',
  totalAccountsPayable: types.optional(types.union(types.number, types.string), ''),
  status: types.optional(
    types.enumeration<AccountsPayableLinkStatus>(Object.values(AccountsPayableLinkStatus)),
    AccountsPayableLinkStatus.UNKNOWN,
  ),
  createdAt: '',
  accountsPayableLinkUrl: '',
});

export interface IAccountsPayableBatchListItem
  extends Instance<typeof AccountsPayableBatchListItem> {}

export default AccountsPayableBatchListItem;
