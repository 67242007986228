import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      fontSize: 14,
      boxShadow: 'none',
    },
  }),
);

export default useStyles;
