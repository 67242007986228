import { FC, forwardRef } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
  CalendarContainer as ReactCalendarContainer,
} from 'react-datepicker';
import es from 'date-fns/locale/es';

import { TextFieldProps } from '@material-ui/core/TextField';
import Input from '@yaydoo/react-components/lib/components/Input';

import useBreakpoints from '@hooks/useBreakpoints';
import { DATE_FORMAT_FORM } from '@constants/date';
import useStyles from './styles';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);
type Locale = 'es' | 'en';

/*
  This component is translation agnostic, but will be compatible with es | en locale,
  component contains custom labels as well.
  */
const getLabels = (locale: Locale) => {
  const labels = {
    es: {
      calendar: 'Calendario',
      today: 'Hoy',
    },
    en: {
      calendar: 'Calendar',
      today: 'Today',
    },
  };

  return labels[locale];
};

interface DatePickerProps extends Omit<ReactDatePickerProps, 'value' | 'dateFormat'> {
  value?: Date | null;
  dateFormat?: string;
  error?: boolean;
  inputProps?: TextFieldProps;
  label: string;
  todayLabel?: string;
  calendarLabel?: string;
  reactive?: boolean;
  locale?: Locale;
  displayToday?: boolean;
}

// Required as described here https://github.com/Hacker0x01/react-datepicker/issues/862
const InputComponent = forwardRef((props, ref) => (
  <Input {...props} inputRef={ref} />
)) as FC<TextFieldProps>;

const CalendarContainer: FC<{ calendarLabel?: string }> = ({ children, calendarLabel }) => {
  const css = useStyles();

  return (
    <ReactCalendarContainer className={css.calendar}>
      <div className={css.header} data-testid="calendar">
        <span>{calendarLabel}</span>
      </div>
      {children}
    </ReactCalendarContainer>
  );
};

const DatePicker: FC<DatePickerProps> = ({
  reactive = true,
  dateFormat = DATE_FORMAT_FORM,
  locale = 'es',
  todayLabel,
  displayToday,
  calendarLabel,
  inputProps,
  error,
  label,
  value,
  ...props
}) => {
  const css = useStyles();
  const { isTouchScreen } = useBreakpoints();
  const labels = getLabels(locale);

  return (
    <div className={css.container}>
      <ReactDatePicker
        adjustDateOnChange
        calendarStartDay={0}
        selected={value}
        minDate={new Date()}
        openToDate={new Date()}
        dateFormat={dateFormat}
        locale={locale}
        formatWeekDay={(Weekday) => Weekday.charAt(0)}
        {...props}
        todayButton={
          displayToday ? (
            <span className={css.todayButton}>{todayLabel || labels.today}</span>
          ) : undefined
        }
        calendarContainer={(props) => (
          <CalendarContainer {...props} calendarLabel={calendarLabel || labels.calendar} />
        )}
        withPortal={reactive && isTouchScreen}
        customInput={
          <InputComponent
            {...inputProps}
            label={label}
            inputProps={{ 'data-testid': 'date-picker', ...inputProps?.inputProps }}
            autoComplete="off"
            fullWidth
            error={error}
          />
        }
      />
    </div>
  );
};

export default DatePicker;
