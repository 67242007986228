import { types } from 'mobx-state-tree';

const Flags = types.model({
  isLoadingDepositDetails: false,
  isLoadingDeposits: false,
  isLoadingOrder: false,
  isDownloadingReceipt: false,
  isLoadingMoreOrders: false,
  isDepositFetched: false,
});

export default Flags;
