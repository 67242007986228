export default {
  greeting: {
    morning: 'Buenos días',
    afternoon: 'Buenas tardes',
    night: 'Buenas noches',
  },
  resume: 'Resumen',
  send: 'Pagar',
  request: 'Cobrar',
  fund: 'Fondear',
  balance: 'Saldo total',
  help: {
    title: 'Ayuda',
    footer: 'Lunes a Viernes de 9:00 a 18:00 hrs.',
    chat: {
      title: 'Chat Oyster',
      desc: 'Habla con un agente',
    },
    faq: {
      title: 'Consejos y respuestas',
      desc: 'Conoce la información al instante',
      link: 'https://oyster-yaydoo.zendesk.com/hc/es-419',
    },
  },
  nonCacao: {
    inactiveText: 'Proximamente',
    title: 'Oyster, la plataforma financiera para poner tus ideas en movimiento',
    content:
      'Nos encanta que estés con nosotros. Ahora, comienza a explorar las soluciones que ya puedes empezar a utilizar desde hoy.',
    pl: {
      title: 'Crea links de pago',
      detail: 'Cobra con Oyster Link y acepta pagos con tarjetas, efectivo y transferencias.',
    },
    invoice: {
      title: 'Paga tus facturas',
      detail: 'Conecta tu cuenta del SAT y programa los pagos de todas tus facturas.',
    },
    invoiceStamp: {
      title: 'Emite Facturas',
      detail: 'Crea y certifica todas tus facturas con el timbrado automático de Oyster.',
    },
    tips: {
      title: 'Sugerencias',
      pl: {
        title: 'Tip',
        text: 'Ocupa Oyster Link incluso dentro de tu negocio. Genera un QR code y cobra a través de una liga de pago al instante.',
        action: '> Crea un link',
        link: 'https://oyster.io/blog/tips-emprender/como-funcionan-los-links-de-pago-a-distancia/',
      },
      sales: {
        title: 'Crecer ventas',
        text: 'Gracias al Internet, se ha abierto la puerta a una infinidad de oportunidades para que un negocio pueda cobrar a distancia.',
        action: '> Sigue leyendo',
        link: 'https://oyster.io/blog/finanzas-negocios/ideas-para-crecer-las-ventas-de-tu-negocio-2021/',
      },
    },
  },
  statementDrawer: {
    title: 'Sube tu estado de cuenta',
    info: 'Verifica que la CLABE sea correcta y adjunta un estado de cuenta para verificar que es tuya.',
    form: {
      clabe: 'Clabe',
      bank: 'Banco',
      file: 'Selecciona o arrastra tus archivos',
      other: 'Otro',
      send: 'Enviar',
      helpFile: 'Archivos permitidos: .jpg, .png, .pdf. Máximo 5 MB.',
      help: {
        title: '¿Necesitas ayuda?',
        info: 'Si tienes alguna duda, escríbenos y con gusto te ayudaremos.',
        cta: 'Chatea con un agente',
      },
      errors: {
        clabe: 'La CLABE debe ser de 18 números.',
        clabeFormat: 'La CLABE es incorrecta',
        bank: 'Selecciona un banco',
      },
      success: {
        title: 'Éxito',
        msg: 'Tu estado de cuenta se ha subido con éxito',
      },
    },
  },
  acctStmtBanner: {
    buttonUpload: 'Sube tu estado de cuenta',
    rejected: {
      title: 'Ocurrió un error',
      text: 'Tu estado de cuenta o la CLABE no coinciden; no te preocupes, puedes subir los documentos de nuevo.',
    },
    pending: {
      title: '¡Listo, estamos revisando tus documentos!',
      text: 'Recibimos toda tu información y estamos listos para revisar tu cuenta. En cuanto tengamos noticias, te avisaremos vía correo electrónico sobre el estado de tu solicitud.',
    },
    notProvided: {
      title: 'Estás a un paso de usar links de pago',
      text: 'Adjunta un estado de cuenta para asegurarnos de que la CLABE es tuya; al completar este paso, recibirás correctamente los pagos de tus links.',
    },
  },
  salesPayments: {
    options: {
      currentMonth: 'Mes actual',
      lastMonth: 'Último mes',
      lastThreeMonth: 'Últimos tres meses',
      lastSixMonth: 'Últimos 6 meses',
      lastYear: 'Último año',
    },
  },
  salesSummary: {
    title: 'Resumen de ventas',
    grossSale: 'Pagos brutos',
    netSale: 'Pagos netos',
    netSaleHelper: 'Tus ingresos estimados después de aplicar las comisiones.',
    grossSaleHelper: 'Tus ingresos estimados antes de las comisiones.',
    payment: 'pagos',
  },
  paymentSummary: {
    title: 'Detalles de tus cobros',
    linkIssued: 'Oyster Link emitidos',
    linkPending: 'Oyster Link pendientes',
    link: 'Oyster Link',
    gotoCollect: 'Ir a Cobros',
    linkIssuedHelper: 'Total de Oyster Link creados en este periodo (excepto uso ilimitado).',
    linkPendingHelper:
      'Monto y número de Oyster Link que aún no te han pagado en este periodo (excepto uso ilimitado).',
  },
  viewAll: 'Ver todo',
  latestTransactions: 'Últimas transacciones',
  emptyTransactions: 'Aún no tienes transacciones',
  noRFC:
    'Para habilitar esta función, debes registrar tu RFC en Oyster. Contáctanos mediante el chat y con gusto te ayudaremos.',
};
