import { ComponentType } from 'react';
import usePermissions from './usePermissions';

export interface HasActionAccessParams<T> {
  action?: string;
  actions?: string[];
  Component: ComponentType<T>;
  ErrorComponent?: ComponentType;
}

export default function hasActionAccess<T>({
  Component,
  action = '',
  actions = [],
  ErrorComponent,
}: HasActionAccessParams<T>) {
  return (hocProps: T) => {
    const { accessActions } = usePermissions();
    if (actions?.length > 0) {
      if (actions.some((a) => accessActions.has(a))) {
        return <Component {...hocProps} />;
      }
    }
    if (accessActions.has(action)) {
      return <Component {...hocProps} />;
    }
    if (ErrorComponent) {
      return <ErrorComponent />;
    }
    return <></>;
  };
}
