import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';
import endpoints from './endpoints';
import { IOverallStatusQueryParams } from './types';

export default class BusinessService extends OysterService {
  /**
   * Get all user businesses
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchBusiness(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.business);
  }

  /**
   * Get all user addresses
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchAddresses(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.addresses);
  }

  /**
   * Gets business external clabe
   * @return Promise<AxiosResponse | AxiosError>
   */
  getExternalClabe(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.externalClabe);
  }

  /**
   * Updates an existing external clabe
   * @return Promise<AxiosResponse | AxiosError>
   */
  updateExternalClabe({
    oldClabe,
    newClabe,
    businessId,
  }: {
    oldClabe: string;
    newClabe: string;
    businessId: string;
  }): Promise<AxiosResponse | AxiosError> {
    return this.client.patch(endpoints.externalClabe, {
      oldClabe,
      newClabe,
      businessId,
    });
  }

  /**
   * Saves a new external clabe for business
   * @return Promise<AxiosResponse | AxiosError>
   */
  saveExternalClabe(clabe: string): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.externalClabe, { clabe });
  }

  /**
   * Get bank name by clabe number
   * @return Promise<AxiosResponse | AxiosError>
   */
  bankNameByClabe(clabe: string): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.bankNameByClabe, { clabe });
  }

  /**
   * Get overall status by product type
   * @param {IOverallStatusQueryParams} params
   */
  getOverallStatus(params: IOverallStatusQueryParams): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.overallStatus, { params });
  }
}
