import { FC } from 'react';
import { IconProps, oyster } from '../types';

const DownloadIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C11.4477 5.03815e-08 11 0.447715 11 1L11 16.5858L4.70711 10.2929C4.31658 9.90237 3.68342 9.90237 3.29289 10.2929C2.90237 10.6834 2.90237 11.3166 3.29289 11.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L20.7071 11.7071C21.0976 11.3166 21.0976 10.6834 20.7071 10.2929C20.3166 9.90237 19.6834 9.90237 19.2929 10.2929L13 16.5858L13 1C13 0.447715 12.5523 -5.03815e-08 12 0ZM2 18C2 17.4477 1.55228 17 1 17C0.447715 17 0 17.4477 0 18V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V18C24 17.4477 23.5523 17 23 17C22.4477 17 22 17.4477 22 18V19C22 20.6568 20.6569 22 19 22H5C3.34315 22 2 20.6568 2 19V18Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

DownloadIcon.defaultProps = {
  fill: oyster,
};

export default DownloadIcon;
