export const CATEGORIES = {
  DASHBOARD: {
    CATEGORY: 'PaymentLink',
    LABELS: {
      CREATE_LINK: 'Create Link',
      ADD_PRODUCT: 'Add Product',
      COPY_LINK: 'Copy Link',
      DELETE_LINK: 'Delete Link',
      EXIT_LINK_CREATION: 'Exit Link Creation',
      FILTER_PENDING: 'Filter Pending',
      FILTER_PAID: 'Filter Paid',
      FILTER_DEPOSITED: 'Filter Deposited',
      VIEW_LINK_DETAILS: 'View Link Details',
    },
  },
} as const;

export const ACTIONS = {
  START: 'Start',
  FINISH: 'Finish',
  FILTER: 'Filter',
  SUBMIT: 'Submit',
  CLICK: 'Click',
} as const;
