import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerBankElement: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
    },
    firstElement: {
      paddingTop: 0,
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 14,
      color: theme.oysterTheme.dolphin,
    },
    referenceData: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: 16,
      color: theme.oysterTheme.crow,
      display: 'flex',
      textAlign: 'right',
    },
    copyButton: {
      color: theme.oysterTheme.dolphin,
      marginLeft: 10,
      padding: 0,
    },
  }),
);

export default useStyles;
