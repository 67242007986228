import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RECONCILIATION_ROUTES } from '@constants/routes';

import Report from './Report';
import Deposit from './Deposit';

const TrafficHandler: FC = () => {
  return (
    <Switch>
      <Route path={`${RECONCILIATION_ROUTES.DEPOSIT_DETAILS}/:uuid`}>
        <Deposit />
      </Route>
      <Route path={RECONCILIATION_ROUTES.REPORTS}>
        <Report />
      </Route>
      <Redirect from="*" to={{ pathname: RECONCILIATION_ROUTES.REPORTS }} />
    </Switch>
  );
};

export default observer(TrafficHandler);
