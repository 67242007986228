import Grid from '@material-ui/core/Grid';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import { defaultTheme } from '@constants/cobranding';
import useStyles from '../styles';
import ColorPicker from '@components/ColorPicker';

const Theme: FC = () => {
  const { t } = usePaymentToolsTranslation();
  const { watch, setValue } = useFormContext();
  const css = useStyles();

  const primaryColor = watch('theme.primaryColor');
  const secondaryColor = watch('theme.secondaryColor');

  const handleChangeComplete = (tool: number) => (color: string) => {
    setValue(tool === 1 ? 'theme.primaryColor' : 'theme.secondaryColor', color);
  };

  const handleReset = () => {
    setValue('theme.primaryColor', defaultTheme.primary);
    setValue('theme.secondaryColor', defaultTheme.secondary);
  };

  return (
    <Grid className={css.wrapper}>
      <Grid item xs={12} className={css.sectionTitle}>
        {t('tools.cobranding.colorPicker.title')}
        <span onClick={handleReset} className={css.reset}>
          {t('tools.cobranding.resetColor')}
        </span>
      </Grid>
      <Grid container className={css.pickerWrapper}>
        <Grid item container xs={12} md={6} alignItems="center">
          <Grid item xs={5} md={4} className={css.themeLabel}>
            {t('tools.cobranding.colorPicker.principalColor')}
          </Grid>
          <ColorPicker
            color={primaryColor}
            onChange={handleChangeComplete(1)}
            Input={({ onClick }) => (
              <Grid item xs={4} md={6} className={css.picker} onClick={onClick}>
                <div style={{ background: primaryColor }} className={css.colorIcon} />
                <span>{primaryColor}</span>
              </Grid>
            )}
          />
        </Grid>
        <Grid item container xs={12} md={6} alignItems="center">
          <Grid item xs={5} md={4} className={css.themeLabel}>
            {t('tools.cobranding.colorPicker.accentColor')}
          </Grid>
          <ColorPicker
            color={secondaryColor}
            onChange={handleChangeComplete(2)}
            Input={({ onClick }) => (
              <Grid item xs={4} md={6} className={css.picker} onClick={onClick}>
                <div style={{ background: secondaryColor }} className={css.colorIcon} />
                <span>{secondaryColor}</span>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Theme;
