import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import VerticalFilter from '@yaydoo/react-components/lib/components/VerticalFilter';

import { PaymentLinkFilterStatus } from '@constants/paymentLink';
import { FilterComponentProps } from './types';
import useStyles from './styles';

const DesktopFilters: FC<FilterComponentProps> = ({
  currentLinkStatus,
  linkStatusOptions,
  onChangeLinkStatus,
}) => {
  const { t } = useTranslation();
  const css = useStyles();

  const handleFilterStatusChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChangeLinkStatus(target.value as PaymentLinkFilterStatus);
  };

  return (
    <div className={css.container} data-testid="desktop-filters">
      <span className={css.header}>{t('collect.payments.filters.title')}</span>
      <hr />
      <VerticalFilter
        onChange={handleFilterStatusChange}
        filterLabel={t('collect.payments.filters.titles.status')}
        filterName="status"
        filterValue={currentLinkStatus}
        options={linkStatusOptions}
      />
    </div>
  );
};

export default DesktopFilters;
