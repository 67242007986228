import { Instance, flow, types, cast, getSnapshot } from 'mobx-state-tree';
import { errors } from '../constants';

import Common from '../common';
import AccountService from './service';

export const AccountBalance = types.model({
  accountId: '',
  amount: 0,
  balanceId: '',
  balanceType: types.enumeration('Currency', ['MXN', 'USD']),
  businessId: '',
  currency: types.enumeration('Currency', ['MXN', 'USD']),
  createdAt: '',
  updatedAt: '',
});
export type TAccountBalance = Instance<typeof AccountBalance>;

const AccountStore = types
  .compose(
    Common,
    types.model({
      clabe: '',
      balance: types.maybeNull(AccountBalance),
    }),
  )
  .views((self) => ({
    get service() {
      return new AccountService();
    },
    get accountBalance() {
      if (self.balance) {
        return getSnapshot(self.balance);
      }
      return null;
    },
  }))
  .actions((self) => ({
    setBalance: (accountBalance: TAccountBalance) => {
      self.balance = cast({
        ...accountBalance,
        amount: accountBalance.amount / 100,
      });
    },
  }))
  .actions((self) => ({
    getClabe: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield self.service.getClabe();
        self.clabe = data?.account?.clabe ?? self.clabe;

        return data;
      } catch {
        self.setError({
          type: errors.CLABE,
        });
      } finally {
        self.submitting = false;
      }
    }),
    getBalance: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield self.service.getBalance();
        const { accountBalance } = data;

        if (accountBalance.accountId) {
          self.setBalance(accountBalance);
        }

        return data;
      } catch {
        self.setError({
          type: errors.BALANCE,
        });
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TAccountStore = Instance<typeof AccountStore>;
export default AccountStore;
