import { FC, useCallback } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';

import Close from '@icons/Close';
import Attach from '@icons/Attach';
import Warning from '@icons/Warning';
import { oysterTheme } from '@theme/index';
import CheckboxFilled from '@icons/CheckboxFilled';

import { UploaderProps, UploadState, OnDeleteEvent } from './types';
import useStyles from './styles';

const MIN_SIZE = 0;
const MAX_SIZE = 1048576 * 5;

const Uploader: FC<UploaderProps> = ({
  uploadState = UploadState.default,
  accept = 'image/jpeg,image/gif,image/png,application/pdf',
  minSize = MIN_SIZE,
  maxSize = MAX_SIZE,
  file,
  label,
  deleteFile,
  onUploadedFileRemove,
  onFileRemove,
  onUpload,
  onDrop,
  Component,
  multiple = false,
  className,
  atachIcon: AtachIcon,
}) => {
  const noDrag = !multiple && [UploadState.uploaded, UploadState.uploaded].includes(uploadState);
  const css = useStyles();

  const isUploadState = useCallback(
    (checkState: UploadState) => {
      return uploadState === checkState;
    },
    [uploadState],
  );

  const hideDeleteIcon = !file?.name && !isUploadState(UploadState.uploaded);

  const onFileDrop = (files: File[], rejectedFiles?: FileRejection[]) => {
    if (onDrop) {
      onDrop({ files, rejectedFiles });
    } else if (onUpload) {
      onUpload({ files, rejectedFiles });
    }
  };

  const onDelete = (e: OnDeleteEvent) => {
    if (!isUploadState(UploadState.uploaded) && onFileRemove) {
      onFileRemove(e);
    } else {
      if (onUploadedFileRemove) {
        onUploadedFileRemove(e);
        return;
      }
      deleteFile && deleteFile(e);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: onFileDrop,
    noDrag,
    minSize,
    maxSize,
    multiple,
  });

  if (Component) {
    return (
      <div {...getRootProps()} className={`${css.customUpload} ${className}`}>
        <input {...getInputProps()} disabled={noDrag} />
        {Component}
      </div>
    );
  }

  return (
    <div
      className={`${css.fileUpload} ${className} ${
        isUploadState(UploadState.error) ? css.error : ''
      }`}
      {...getRootProps()}
      data-testid="file-input"
    >
      <input {...getInputProps()} disabled={noDrag} />
      <span className={css.startAdornment}>
        {isUploadState(UploadState.uploading) && <CircularProgress size={20} />}
        {isUploadState(UploadState.error) && <Warning fontSize={24} />}
        {isUploadState(UploadState.uploaded) && (
          <CheckboxFilled color={oysterTheme.oyster} fontSize={24} />
        )}
      </span>
      <span className={css.fileName}>{file?.name || label || 'Upload'}</span>
      <span className={css.endAdornment}>
        {hideDeleteIcon ? (
          AtachIcon ? (
            <AtachIcon />
          ) : (
            <Attach fontSize={24} />
          )
        ) : (
          <button
            type="button"
            onClick={onDelete}
            data-testid="deleteFile"
            disabled={isUploadState(UploadState.uploading)}
          >
            <Close fontSize={16} color={oysterTheme.dolphin} />
          </button>
        )}
      </span>
    </div>
  );
};

export default Uploader;
