import { ElementType, FC } from 'react';
import { IconProps } from '@icons/types';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

export interface IOutlinedButton extends ButtonProps {
  label: string;
  leftIcon: ElementType | FC<IconProps>;
}

const OutlinedButton: FC<IOutlinedButton> = ({ label, leftIcon: LeftIcon, disabled, ...props }) => {
  const css = useStyles();

  return (
    <Button
      {...props}
      variant="outlined"
      disabled={disabled}
      size="large"
      startIcon={disabled ? <LeftIcon className={css.iconDisabled} /> : <LeftIcon />}
      className={css.button}
      disableElevation
    >
      <Typography className={disabled ? css.btnLabelDisabled : css.btnLabel} variant="h5">
        {label}
      </Typography>
    </Button>
  );
};

export default OutlinedButton;
