import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => {
  return {
    mobile: {
      display: 'flex',
      justifyContent: 'center',
      width: 'fit-content',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
    },
  };
});

export default styles;
