import { FC } from 'react';
import { IconProps, obsidian } from './types';

const RequestMoneyIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0762 7.48575C15.5093 7.48575 14.2393 6.25786 14.2393 4.74288C14.2393 3.22789 15.5093 2 17.0762 2C18.6432 2 19.9132 3.22789 19.9132 4.74288C19.9132 6.25786 18.6432 7.48575 17.0762 7.48575ZM17.5762 3.82872C17.5762 3.55258 17.3523 3.32872 17.0762 3.32872C16.8 3.32872 16.5762 3.55258 16.5762 3.82872V5.6573C16.5762 5.93344 16.8 6.1573 17.0762 6.1573C17.3523 6.1573 17.5762 5.93344 17.5762 5.6573V3.82872ZM9.98405 11.143C9.98405 12.6579 11.2541 13.8858 12.821 13.8858C14.388 13.8858 15.658 12.6579 15.658 11.143C15.658 9.62798 14.388 8.40009 12.821 8.40009C11.2541 8.40009 9.98405 9.62798 9.98405 11.143ZM12.8213 9.72882C13.0975 9.72882 13.3213 9.95268 13.3213 10.2288V12.0574C13.3213 12.3335 13.0975 12.5574 12.8213 12.5574C12.5452 12.5574 12.3213 12.3335 12.3213 12.0574V10.2288C12.3213 9.95268 12.5452 9.72882 12.8213 9.72882ZM1 13.8859C1 13.6334 1.21169 13.4287 1.47283 13.4287H5.25544C5.51657 13.4287 5.72827 13.6334 5.72827 13.8859V21.2386C5.72827 21.4911 5.51657 21.6957 5.25544 21.6957H1.47283C1.21169 21.6957 1 21.4911 1 21.2386V13.8859ZM8.48881 20.7411C7.94302 20.558 7.34172 20.3561 6.67383 20.1366V14.343H8.56513C10.7903 14.343 12.3477 15.7145 12.8206 16.1716H15.6575C16.8625 16.1716 17.1042 17.3402 16.3827 17.809L20.8586 16.6287C21.4729 16.4594 22.0014 16.4706 22.5388 16.7089C23.1745 16.991 23.0289 17.8655 22.4053 18.1737C21.0749 18.8311 19.9739 19.3972 19.0408 19.8769L19.0408 19.877C16.4113 21.2289 15.1154 21.8952 13.7767 21.9886C12.4757 22.0794 11.1344 21.6292 8.48967 20.7414L8.48881 20.7411Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

RequestMoneyIcon.defaultProps = {
  fill: obsidian,
};

export default RequestMoneyIcon;
