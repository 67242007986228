import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface IUseBreakPoints {
  isTouchScreen: boolean;
  isExtraSmallScreen: boolean;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
  isExtraLargeScreen: boolean;
}

function useBreakpoints(): IUseBreakPoints {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'), { noSsr: true });

  return {
    isTouchScreen: isSmallScreen,
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  };
}

export default useBreakpoints;
