export type IZendesk = (widget: string, identifier: string, parameters?: unknown) => void;

declare global {
  interface Window {
    zE: IZendesk | undefined;
  }
}

class ZendeskWrapper {
  get zE() {
    return window.zE ? window.zE : () => null;
  }
}

export interface IUseZendesk {
  openZendesk: () => void;
  closeZendesk: () => void;
  setOnOpenZendesk: (callback: () => void) => void;
  setOnCloseZendesk: (callback: () => void) => void;
  setLocaleZendesk: (locale?: string) => void;
  hideZendeskIcon: () => void;
  showZendeskIcon: () => void;
}

function useZendesk(): IUseZendesk {
  const zendeskWrapper = new ZendeskWrapper();

  const showZendeskIcon = (): void => {
    zendeskWrapper.zE('messenger:set', 'cookies', true);
  };

  const hideZendeskIcon = (): void => {
    zendeskWrapper.zE('messenger:set', 'cookies', false);
  };

  const openZendesk = (): void => {
    zendeskWrapper.zE('messenger', 'open');
  };

  const closeZendesk = (): void => {
    zendeskWrapper.zE('messenger', 'close');
  };

  const setOnOpenZendesk = (callback: () => void): void => {
    zendeskWrapper.zE('messenger:on', 'open', callback);
  };

  const setOnCloseZendesk = (callback: () => void): void => {
    zendeskWrapper.zE('messenger:on', 'close', callback);
  };

  const setLocaleZendesk = (locale = 'es-mx'): void => {
    zendeskWrapper.zE('messenger:set', 'locale', locale);
  };

  return {
    showZendeskIcon,
    hideZendeskIcon,
    openZendesk,
    closeZendesk,
    setOnOpenZendesk,
    setOnCloseZendesk,
    setLocaleZendesk,
  };
}
export default useZendesk;
