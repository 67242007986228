const allowedRoles = {
  ADMIN: 'admin',
  ANALYST: 'analyst',
  ACCOUNTANT: 'accountant',
  DEVELOPER: 'developer',
  OWNER: 'owner',
  GUEST: 'guest',
} as const;

enum allowedRolesDisplayEs {
  admin = 'Administrador',
  admin_contpaqi = 'Administrador',
  developer = 'Desarrollador',
  accountant = 'Contador',
  analyst = 'Analista',
  guest = 'Invitado',
}

export { allowedRoles, allowedRolesDisplayEs };
