import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 440,
      width: '100%',
      marginTop: theme.spacing(7),
      padding: `0 ${theme.spacing(3)}px`,
    },
    header: {
      display: 'block',
      fontSize: 22,
      fontWeight: 700,
      color: theme.oysterTheme.pine,
      marginBottom: theme.spacing(5),
    },
    // Container
    container: {
      marginBottom: theme.spacing(5),
    },
    linkText: {
      fontWeight: 500,
      color: theme.oysterTheme.oyster,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    notice: {
      color: theme.oysterTheme.pine,
      fontWeight: 500,
      fontStyle: 'italic',
    },
    //Bottom
    footer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignSelf: 'center',
    },
    submit: {
      backgroundColor: theme.oysterTheme.pine,
      color: theme.oysterTheme.cotton,
      fontWeight: 600,
      padding: theme.spacing(1.2),
    },
    download: {
      color: theme.oysterTheme.oyster,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    downloadIcon: {
      height: 16,
      width: 16,
    },
    fileInputContainer: {
      textAlign: 'center',
    },
    optionalText: {
      marginTop: theme.spacing(5),
      marginBottom: 12,
      color: '#677685',
      width: '100%',
      textAlign: 'center',
      borderBottom: '1px solid #DFE1E8',
      lineHeight: ' 0.1em',
      margin: '10px 0 20px',
      '& span': {
        background: '#ffffff',
        padding: '0 23px',
        fontWeight: 500,
        fontSize: '16px',
      },
    },
    help: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
  });
});

export default useStyles;
