import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // CopyLink
    icon: {
      color: theme.oysterTheme.oyster,
    },
    // CopyLink
    copyLinkItem: {
      '& .title': {
        fontSize: 14,
        textTransform: 'uppercase',
      },
      '& .MuiTypography-displayBlock': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    // Total & TicketItem
    price: {
      margin: 0,
      lineHeight: 1,
      '&::before': {
        width: 30,
        position: 'absolute',
        content: '"$"',
        display: 'block',
        fontWeight: 400,
      },
      '&.ticket-total': {
        fontSize: 32,
        paddingLeft: 20,
        '&::before': {
          marginLeft: -20,
          fontSize: 26,
        },
      },
      '&.item-total': {
        fontSize: 18,
        '&::before': {
          marginLeft: -15,
          fontSize: 18,
        },
      },
    },
    // CopyLink
    tooltip: {
      maxWidth: 100,
      backgroundColor: theme.oysterTheme.pine,
      '& .MuiTooltip-arrow::before': {
        backgroundColor: theme.oysterTheme.pine,
      },
    },
    // CopyLink
    tooltipMessage: {
      display: 'flex',
      '& .tooltip-icon': {
        marginLeft: 5,
        alignSelf: 'center',
        width: 15,
        height: 15,
      },
    },
  }),
);

export default useStyles;
