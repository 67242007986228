import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const upScale = theme.breakpoints.down('sm');

  return {
    heading: {
      padding: 16,
      fontWeight: 600,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      fontSize: 12,
    },
    menuItem: {
      textDecoration: 'none',
      '&:hover': {
        background: theme.oysterTheme.pearl,
      },
    },
    leftContentContainer: {
      marginTop: theme.spacing(5),
      paddingLeft: theme.spacing(3),
      [upScale]: {
        marginTop: theme.spacing(0),
        paddingLeft: theme.spacing(0),
      },
    },
    active: {
      backgroundColor: theme.oysterTheme.pearl,
    },
    caret: {
      fontSize: 12,
      color: theme.oysterTheme.oyster,
    },
  };
});

export default useStyles;
