import layout from './layout';
import report from './report';

export const reconciliation = {
  layout,
  report,
};

export default {
  translation: {
    reconciliation,
  },
};
