import { FC, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';

interface FormProps {
  className?: string;
  onSubmit: () => void;
}

const Form: FC<FormProps> = ({ children, className, onSubmit }) => {
  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    onSubmit();
  };
  return (
    <form className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export const FormComp = Form;
export default observer(Form);
