import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import { TDeposit } from '@store/deposits/models/Deposit';
import MoreMenu from '@components/MoreMenu';
import useStore from '@hooks/useStore';

const DepositOptions: FC<{ deposit: TDeposit }> = ({ deposit }) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { deposits: depositStore } = useStore();

  const onDownloadReceipt = async () => {
    try {
      await depositStore.downloadReceipt(deposit.oysterSettlementTransactionId);
    } catch {
      openToast({
        type: 'error',
        title: t('collect.payments.deposits.errorDownloadTitle'),
        message: t('collect.payments.deposits.errorDownloadMsg'),
      });
    }
  };

  const options = [
    {
      key: 'option-download-receipt',
      label: t('collect.payments.deposits.optionsMenu.downloadReceipt'),
      onClick: onDownloadReceipt,
    },
  ];

  return <MoreMenu options={options} />;
};

export default DepositOptions;
