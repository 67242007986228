import { FC } from 'react';
import { IconProps, obsidian } from './types';

const RetreatsIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8H33C33.5523 8 34 7.55228 34 7C34 6.44772 33.5523 6 33 6H8ZM4 11C4 10.4477 4.44772 10 5 10H36C36.5523 10 37 10.4477 37 11C37 11.5523 36.5523 12 36 12H5C4.44772 12 4 11.5523 4 11ZM2 14H38V32.9474H2V14ZM25.6842 23.4737C25.6842 26.613 23.1393 29.1579 20 29.1579C16.8607 29.1579 14.3158 26.613 14.3158 23.4737C14.3158 20.3344 16.8607 17.7895 20 17.7895C23.1393 17.7895 25.6842 20.3344 25.6842 23.4737ZM31.3684 25.3684C32.4149 25.3684 33.2632 24.5201 33.2632 23.4737C33.2632 22.4272 32.4149 21.5789 31.3684 21.5789C30.322 21.5789 29.4737 22.4272 29.4737 23.4737C29.4737 24.5201 30.322 25.3684 31.3684 25.3684ZM10.5263 23.4737C10.5263 24.5201 9.67801 25.3684 8.63158 25.3684C7.58514 25.3684 6.73684 24.5201 6.73684 23.4737C6.73684 22.4272 7.58514 21.5789 8.63158 21.5789C9.67801 21.5789 10.5263 22.4272 10.5263 23.4737ZM20.3399 23.0566C21.1342 23.2864 22 23.5843 22 24.7673C22 25.6353 21.4633 26.3843 20.3399 26.5204V27.4736H19.7531V26.529C19.0519 26.4779 18.3721 26.2055 18 25.8226L18.322 24.963C18.6726 25.3034 19.2093 25.5502 19.7531 25.6098V23.9077C18.9589 23.6779 18.1073 23.3715 18.1073 22.1885C18.1073 21.3204 18.6369 20.563 19.7531 20.4353V19.4736H20.3399V20.4183C20.8694 20.4524 21.4061 20.6396 21.7925 20.9375L21.4991 21.797C21.1199 21.5332 20.7191 21.3885 20.3399 21.3375V23.0566ZM19.7531 21.363C19.2451 21.4566 19.0304 21.763 19.0304 22.1375C19.0304 22.546 19.3381 22.7332 19.7531 22.8779V21.363ZM20.3399 24.0864V25.5928C20.8623 25.4992 21.0841 25.2098 21.0841 24.8438C21.0841 24.4268 20.7692 24.2311 20.3399 24.0864Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

RetreatsIcon.defaultProps = {
  fill: obsidian,
};

export default RetreatsIcon;
