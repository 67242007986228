import { FC, ReactElement } from 'react';
import TwoColumns from '@yaydoo/react-components/lib/layouts/Content/TwoColumns';
import { GridSize } from '@material-ui/core/Grid';

import HeaderMain from '@components/HeaderMain';
import Cha from '@components/Cha';
import useStyles from './styles';
import useBreakpoints from '@hooks/useBreakpoints';
import MobileNavigation from '@components/MobileNavigation';

interface WebDashboardProps {
  rightElement?: ReactElement;
  withCha?: boolean;
  hideRightElement?: boolean;
  withDefaultHeader?: boolean;
  leftSize?: GridSize;
  rightSize?: GridSize;
  rightBg?: true;
}

const WebDashboard: FC<WebDashboardProps> = ({
  children,
  withCha,
  rightElement,
  hideRightElement,
  withDefaultHeader,
  ...props
}) => {
  const { isTouchScreen } = useBreakpoints();
  const css = useStyles();
  const rightContent = hideRightElement || !rightElement ? <div /> : rightElement;

  const defaultHeader = isTouchScreen ? (
    <div className={css.mobileHeader}>
      <MobileNavigation />
    </div>
  ) : (
    <HeaderMain className={css.header} />
  );

  return (
    <TwoColumns
      rightBg
      rightSize={4}
      leftSize={8}
      hideRightContent={hideRightElement}
      leftContentComp={
        <>
          {withDefaultHeader && defaultHeader}
          {children}
        </>
      }
      rightContentComp={withCha && !isTouchScreen ? <Cha /> : rightContent}
      rightRowClassName={css.rightComponent}
      leftRowClassName={css.leftComponent}
      containerClassName={css.container}
      {...props}
    />
  );
};
export default WebDashboard;
