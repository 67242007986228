import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      position: 'relative',
    },
    refreshWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: theme.oysterTheme.shark,
    },
    refresh: {
      color: theme.oysterTheme.dolphin,
    },
    loader: {
      height: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .circularProgress': {
        margin: 0,
      },
    },
  });
});

export default useStyles;
