enum MODAL_TYPES {
  //Confirmation modals
  INVITE_USER = 'INVITE_USER',
  UPDATE_USER_ROLE = 'UPDATE_USER_ROLE',
  SUSPEND_ASSOCIATED_USER = 'SUSPEND_ASSOCIATED_USER',
  REVOKE_SUSPEND_ASSOCIATED_USER = 'REVOKE_SUSPEND_ASSOCIATED_USER',
  REMOVE_ASSOCIATED_USER = 'REMOVE_ASSOCIATED_USER',
  CANCEL_INVITE = 'CANCEL_INVITE',
  RESEND_USER_INVITE = 'RESEND_USER_INVITE',
  //Fetch success or error
  INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS',
  INVITE_USER_ERROR = 'INVITE_USER_ERROR',
  UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS',
  UPDATE_USER_ROLE_ERROR = 'UPDATE_USER_ROLE_ERROR',
  CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS',
  CANCEL_INVITE_ERROR = 'CANCEL_INVITE_ERROR',
  SUSPEND_ASSOCIATED_USER_SUCCESS = 'SUSPEND_ASSOCIATED_USER_SUCCESS',
  SUSPEND_ASSOCIATED_USER_ERROR = 'SUSPEND_ASSOCIATED_USER_ERROR',
  REVOKE_SUSPEND_ASSOCIATED_USER_SUCCESS = 'REVOKE_SUSPEND_ASSOCIATED_USER_SUCCESS',
  REVOKE_SUSPEND_ASSOCIATED_USER_ERROR = 'REVOKE_SUSPEND_ASSOCIATED_USER_ERROR',
  REMOVE_ASSOCIATED_USER_SUCCESS = 'REMOVE_ASSOCIATED_USER_SUCCESS',
  REMOVE_ASSOCIATED_USER_ERROR = 'REMOVE_ASSOCIATED_USER_ERROR',
  RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS',
  RESEND_USER_INVITE_ERROR = 'RESEND_USER_INVITE_ERROR',
}

export default MODAL_TYPES;
