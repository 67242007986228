import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');

  return createStyles({
    container: {
      width: '100%',
      fontSize: 14,
      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: 'minmax(max-content, 1fr) auto',
    },
    panel: {
      backgroundColor: theme.oysterTheme.pearl,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& hr': {
        borderTop: `1px solid ${theme.oysterTheme.stone}`,
      },
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    customRow: {
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
    type: {
      color: theme.oysterTheme.shark,
      fontWeight: 500,
    },
    title: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(3),
      display: 'block',
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      color: theme.oysterTheme.shark,
      fontWeight: 600,
      fontSize: 12,
    },
    icon: {
      fontSize: 24,
      color: theme.oysterTheme.charcoal,
    },
    paymentsAmount: {
      marginLeft: theme.spacing(1),
      color: theme.oysterTheme.oyster,
    },
    rightComponent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      gap: theme.spacing(0.5),
    },
    list: {
      [upScale]: {
        overflowY: 'auto',
        paddingBottom: theme.spacing(10),
        '& ::-webkit-scrollbar': {
          width: 4,
        },
        '& ::-webkit-scrollbar-track': {
          background: theme.oysterTheme.stone,
        },
        '& ::-webkit-scrollbar-thumb': {
          background: '#bdbdbd60',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          background: '#bdbdbd60',
        },
      },
    },
    alignRight: {
      display: 'flex',
      alignItems: 'end',
    },
  });
});

export default useStyles;
