import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';
import { IPaginationQueryParams } from '@store/common/pagination/types';

export default class DepositsService extends OysterService {
  /**
   * Get deposit list
   * @return Promise<typeof AxiosResponse>
   */

  fetchGetDeposits(params?: IPaginationQueryParams): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/payment-links/deposits', { params });
  }

  /**
   * Get deposit detail
   * @return Promise<typeof AxiosResponse>
   */

  fetchGetDeposit(
    id: string,
    params?: IPaginationQueryParams,
  ): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/payment-links/deposits/${id}`, { params });
  }

  /**
   * Get deposit receipt
   * @return Promise<typeof AxiosResponse>
   */

  fetchGetPaymentReceipt(transactionId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/deposits/transaction/${transactionId}/cep`);
  }
}
