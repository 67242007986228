import { FC } from 'react';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import TwoColumns from '@yaydoo/react-components/lib/layouts/Content/TwoColumns';
import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';
import CaretRight from '@yaydoo/react-components/lib/icons/CaretRight';
import hasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/hasActionAccess';
import useHasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasActionAccess';
import useHasRoleAccess from '@yaydoo/react.ui-access-manager/lib/access/useHasRoleAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';
import { allowedRoles } from '@yaydoo/react.ui-access-manager/lib/constants/allowedRoles';

import { TOOLS_ROUTE } from '@constants/routes';
import useBreakpoints from '@hooks/useBreakpoints';
import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import useStyles from './styles';

const MenuItems = (t: TFunction) => [
  {
    title: t('tools.notifications.title'),
    subtitle: t('tools.notifications.subtitle'),
    link: TOOLS_ROUTE.NOTIFICATION,
    action: allowedActions.TOOLS_PAYMENTS_CONFIG_REMINDERS,
  },
  {
    title: t('tools.cobranding.title'),
    subtitle: t('tools.cobranding.subtitle'),
    link: TOOLS_ROUTE.COBRANDING,
    action: allowedActions.TOOLS_COBRANDING,
  },
];

const DevItems = (t: TFunction) => [
  {
    title: t('tools.webhooks.title'),
    subtitle: t('tools.webhooks.subtitle'),
    link: TOOLS_ROUTE.WEBHOOKS,
    action: allowedActions.TOOLS_WEBHOOKS,
  },
];

const ToolsMenu: FC = ({ children }) => {
  const history = useHistory();
  const { t } = usePaymentToolsTranslation();
  const { isTouchScreen } = useBreakpoints();
  const css = useStyles();
  const isAdmin = useHasRoleAccess({
    role: allowedRoles.ADMIN,
  });
  const isDeveloper = useHasRoleAccess({
    role: allowedRoles.DEVELOPER,
  });
  const hasWebhooksAccess = useHasActionAccess({ action: allowedActions.TOOLS_WEBHOOKS });
  const hasDevItems = hasWebhooksAccess;

  const handleMenuClick = (route: string) => () => {
    setTimeout(() => {
      history.push(route);
    }, 100);
  };

  const menuItems = MenuItems(t).map((item) => {
    const SecuredItem = hasActionAccess({
      Component: () => (
        <CustomRow
          primaryText={item.title}
          secondaryText={item.subtitle}
          className={css.menuItem}
          variant="big"
          rightComponent={<CaretRight className={css.caret} />}
          onClick={handleMenuClick(item.link)}
          selected={history.location.pathname.includes(item.link)}
        />
      ),
      action: item.action,
    });
    return <SecuredItem key={item.title} />;
  });

  const devItems = DevItems(t).map((item) => {
    const SecuredItem = hasActionAccess({
      Component: () => (
        <CustomRow
          primaryText={item.title}
          secondaryText={item.subtitle}
          className={css.menuItem}
          variant="big"
          rightComponent={<CaretRight className={css.caret} />}
          onClick={handleMenuClick(item.link)}
          selected={history.location.pathname.includes(item.link)}
        />
      ),
      action: item.action,
    });
    return <SecuredItem key={item.title} />;
  });

  return (
    <TwoColumns
      leftContentComp={
        <div className={css.leftContentContainer}>
          {Boolean(menuItems.length) && <div className={css.heading}>{t('tools.heading')}</div>}
          {menuItems}
          {hasDevItems && (isAdmin || isDeveloper) && (
            <div className={css.heading}>{t('tools.developmentHeading')}</div>
          )}
          {devItems}
        </div>
      }
      rightContentComp={<>{children}</>}
      rightSize={8}
      leftSize={4}
      showMiddleSeparator
      leftRowPadless
      rightRowPadless={isTouchScreen}
    />
  );
};

export default observer(ToolsMenu);
