import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    header: {
      height: 380,
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      display: 'block',
      margin: 'auto',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      display: 'block',
      fontSize: 24,
      fontWeight: 700,
      color: theme.oysterTheme.pine,
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignSelf: 'center',
      padding: `0 ${theme.spacing(3)}px`,
    },
    createdDate: {
      marginTop: theme.spacing(2),
      color: '#8F9499',
    },
    description: { marginTop: theme.spacing(2), whiteSpace: 'break-spaces' },
    button: { marginTop: theme.spacing(7) },
  });
};

export default makeStyles(styles);
