import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 40,
      color: theme.oysterTheme.pine,
      boxShadow: 'none',
      border: `1px solid ${theme.oysterTheme.stone}`,
    },
    iconDisabled: {
      color: theme.oysterTheme.shark,
    },
    btnLabel: {
      color: theme.oysterTheme.pine,
      fontWeight: 600,
      fontSize: 14,
    },
    btnLabelDisabled: {
      color: theme.oysterTheme.shark,
      fontWeight: 600,
      fontSize: 14,
    },
  }),
);

export default useStyles;
