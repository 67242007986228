import { FC, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import MainLayout from './main';

export type MainLayoutProps = {
  withCha?: boolean;
  header?: ReactElement;
  className?: string;
};

const LayoutHandler: FC<MainLayoutProps> = ({ children, withCha, header, className }) => {
  return (
    <MainLayout withCha={withCha} header={header} className={className}>
      {children}
    </MainLayout>
  );
};

export default observer(LayoutHandler);
