import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiButtonBase-root': {
        color: theme.oysterTheme.dolphin,
        '&:hover, &:focus': {
          background: 'none',
          color: theme.oysterTheme.brandGreen,
        },
      },
    },
    menuItem: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      fontSize: 12,
      color: theme.oysterTheme.obsidian,
      '&.MuiListItem-divider': {
        borderColor: theme.oysterTheme.stone,
      },
    },
  }),
);

export default useStyles;
