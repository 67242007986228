import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import ListItemSkeleton from '@components/ListItemSkeleton';
import { IEmptyListSkeletonProps } from './types';
import useStyles from './styles';
import clsx from 'clsx';

const EmptyListSkeleton: FC<IEmptyListSkeletonProps> = ({
  label,
  itemsLength = 3,
  loadingLabel,
  animation = 'pulse',
  isLoading,
}) => {
  const css = useStyles();
  const items = [...Array(itemsLength).keys()];
  const opacityDecreaseSize = 1 / itemsLength;

  const anim = isLoading ? animation : false;

  return (
    <div className={css.itemsWrapper}>
      {items.map((key) => {
        const percentage = (1 - opacityDecreaseSize * key) * 100;
        const rowClasses = clsx(css.row, {
          [css.opacity100]: percentage === 100,
          [css.opacity80]: percentage >= 80 && percentage < 100,
          [css.opacity50]: percentage >= 50 && percentage < 80,
          [css.opacity20]: percentage >= 0 && percentage < 50,
        });

        return (
          <Grid key={`row-${key}`} container direction="row" role="row" className={rowClasses}>
            <Grid item className={clsx(css.col, css.colLeft)}>
              <ListItemSkeleton animation={anim} />
            </Grid>
            <Grid item className={clsx(css.col, css.colCenter)}>
              <Skeleton className={css.line} variant="rect" animation={anim} />
            </Grid>
            <Grid item className={clsx(css.col, css.colRight)}>
              <Skeleton variant="rect" className={css.actions} animation={anim} />
            </Grid>
          </Grid>
        );
      })}
      <div className={css.labelWrapper}>
        <span role="label">{isLoading ? loadingLabel : label}</span>
      </div>
    </div>
  );
};

export default EmptyListSkeleton;
