import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@yaydoo/react-components/lib/components/Slider';
import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactComponent as MobileGuy } from '@assets/images/upsell/mobile-guy.svg';
import { ReactComponent as SelectPoa } from '@assets/images/upsell/select-poa.svg';
import { ReactComponent as CheckGirl } from '@assets/images/upsell/check-girl.svg';

import useStyles from './UpsellGuide.styles';

const UpsellGuide: FC = () => {
  const { upsellUrl } = useEnvironmentVars();
  const { t } = useTranslation();
  const css = useStyles();

  const slides = [
    {
      key: 'upsell-slide-selfie',
      title: t('collect.payments.capabilities.upsell.steps.selfie.title'),
      description: t('collect.payments.capabilities.upsell.steps.selfie.description'),
      image: MobileGuy,
    },
    {
      key: 'upsell-slide-poa',
      title: t('collect.payments.capabilities.upsell.steps.poa.title'),
      description: t('collect.payments.capabilities.upsell.steps.poa.description'),
      image: SelectPoa,
    },
    {
      key: 'upsell-slide-rfc',
      title: t('collect.payments.capabilities.upsell.steps.rfc.title'),
      description: t('collect.payments.capabilities.upsell.steps.rfc.description'),
      image: CheckGirl,
    },
  ];

  return (
    <div className={css.root}>
      <Box py={1} px={2}>
        <Typography variant="caption">
          {t('collect.payments.capabilities.upsell.description')}
        </Typography>
      </Box>

      <Slider
        classes={{ root: css.slider, dots: css.dots }}
        controlsPosition="top"
        showPrevArrow={false}
        nextButtonLabel={t('collect.payments.capabilities.upsell.next')}
      >
        {slides.map(({ image: Image, title, key, description }) => (
          <div data-testid={key} key={key} className={css.slide}>
            <Grid container justifyContent="space-between">
              <Grid item xs className={css.slideLeft}>
                <strong>{title}</strong>
                <p>{description}</p>
              </Grid>

              <Grid item xs className={css.slideRight}>
                <Image />
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>

      <Button
        className={css.callToActionButton}
        variant="text"
        color="primary"
        size="small"
        endIcon={<ChevronRightIcon />}
        href={`${upsellUrl}?upsellType=2`}
        target="_blank"
        rel="noreferrer"
      >
        {t('collect.payments.capabilities.upsell.callToActionButtonLabel')}
      </Button>
    </div>
  );
};

export default UpsellGuide;
