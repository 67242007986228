import { AxiosError } from 'axios';
import { flow, Instance, types } from 'mobx-state-tree';

import Common from '../common';
import {
  AccountsPayableLinkStatus,
  accountsPayableLinkStep,
  AccountsPayableLinkUpdateStatus,
  locales,
} from '../constants/accountsPayableLink';

import AccountsPayable from './models/AccountsPayable';
import Errors from './models/Errors';
import Flags from './models/Flags';
import FundInfo from './models/FundInfo';
import AccountsPayableLinkService from './service';

interface IErrors extends AxiosError {
  error: boolean;
  msg: string;
  errorCode: string;
}

const AccountsPayableLinkStore = types
  .compose(
    Common,
    types.model({
      accountsPayableLinkId: '',
      businessId: '',
      createdAt: '',
      batchId: '',
      status: types.optional(
        types.enumeration<AccountsPayableLinkStatus>(Object.values(AccountsPayableLinkStatus)),
        AccountsPayableLinkStatus.UNKNOWN,
      ),
      fundInfo: types.optional(FundInfo, {}),
      accountsPayable: types.optional(AccountsPayable, {}),
      flags: types.optional(Flags, {}),
      errors: types.optional(Errors, {}),
    }),
  )
  .views(() => ({
    get service() {
      return new AccountsPayableLinkService();
    },
  }))
  .views((self) => ({
    get getCurrentStep() {
      return accountsPayableLinkStep[self.status];
    },
    get getAmountCanProcess() {
      const { currency } = self.fundInfo;
      const total = self.accountsPayable.canProcess.reduce(
        (total, { amount }) => total + +amount,
        0,
      );
      const formattedAmount = Intl.NumberFormat(locales[currency], {
        style: 'currency',
        currency,
      }).format(+total);
      return `${formattedAmount} ${self.fundInfo.currency}`;
    },
    get getAmountCanNotProcess() {
      const { currency } = self.fundInfo;
      const total = self.accountsPayable.canNotProcess.reduce(
        (total, { amount }) => total + +amount,
        0,
      );
      const formattedAmount = Intl.NumberFormat(locales[currency], {
        style: 'currency',
        currency,
      }).format(+total);
      return `${formattedAmount} ${self.fundInfo.currency}`;
    },
  }))
  .actions((self) => ({
    setCurrentStatus: (status: AccountsPayableLinkStatus) => {
      self.status = status;
    },
    getAccountsPayableLink: flow(function* (accountsPayableLinkId: string) {
      if (self.flags.isRequesting) return;

      try {
        self.flags.isRequesting = true;
        self.errors.fetchingAccountsPayableLink = false;

        const result = yield self.service.getAccountsPayableLink(accountsPayableLinkId);
        const { data } = result?.data;
        self.accountsPayableLinkId = data.accountsPayableLinkId;
        self.businessId = data.businessId;
        self.createdAt = data.createdAt;
        self.status = data.status;
        self.fundInfo = data.fundInfo;
        self.accountsPayable.updateAccountsPayable(data.accountsPayable);
      } catch (error) {
        self.errors.fetchingAccountsPayableLink = true;
        throw error;
      } finally {
        self.flags.isRequesting = false;
      }
    }),
    updateStatus: flow(function* (status: AccountsPayableLinkUpdateStatus) {
      self.flags.isSubmitting = true;
      const payload = {
        accountsPayableLinkId: self.accountsPayableLinkId,
        status,
      };

      try {
        yield self.service.updateStatus(payload);
      } catch (error) {
        const { response, msg } = error as IErrors;
        throw new Error(msg || response?.data.msg);
      } finally {
        self.flags.isSubmitting = false;
      }
    }),
    requestFunds: flow(function* (requestEmails: string[]) {
      self.flags.isSubmitting = true;
      const payload = {
        accountsPayableLinkId: self.accountsPayableLinkId,
        requestEmails,
      };

      try {
        yield self.service.requestFunds(payload);
      } catch (error) {
        const { response, msg } = error as IErrors;
        throw new Error(msg || response?.data.msg);
      } finally {
        self.flags.isSubmitting = false;
      }
    }),
  }));

export type IAccountsPayableLinkStore = Instance<typeof AccountsPayableLinkStore>;
export default AccountsPayableLinkStore;
