import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';

import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';
import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import DisbursementIcon from '@yaydoo/react-components/lib/icons/Disbursement';
import PayIcon from '@yaydoo/react-components/lib/icons/PayCard';
import ClabeIcon from '@yaydoo/react-components/lib/icons/Clabe';

import { TPaymentOrder } from '@store/common/order';
import { PaymentOrderPaymentMethods } from '@constants/paymentOrder';
import InfiniteScroll from '@components/InfiniteScroll';
import useTicket from '@hooks/useTicket';
import useStore from '@hooks/useStore';
import useStyles from './styles';
import Badge from '@components/Badge';
import LinkOptions from './LinkOptions';

const getPaymentMethodIcon = (paymentMethod: PaymentOrderPaymentMethods) => {
  switch (paymentMethod) {
    case PaymentOrderPaymentMethods.CARD_PAYMENT:
      return PayIcon;
    case PaymentOrderPaymentMethods.SPEI_PAYMENT:
      return ClabeIcon;
    case PaymentOrderPaymentMethods.CASH_PAYMENT:
    default:
      return DisbursementIcon;
  }
};

const DepositRow: FC<{ order: TPaymentOrder }> = ({ order }) => {
  const css = useStyles();
  const { t } = useTranslation();
  const showTicket = useTicket('deposit');
  const Icon = getPaymentMethodIcon(order.paymentMethod as PaymentOrderPaymentMethods);

  return (
    <CustomRow
      leftIcon={() => (
        <Icon className={css.icon} data-testid={`${order.paymentMethod}-${order.paymentOrderId}`} />
      )}
      onClick={() => showTicket(order.paymentOrderId)}
      primaryText={`${t('collect.payments.details.payment')} #${order.orderNumber}`}
      className={css.customRow}
      secondaryText={order.paymentFullName}
      rightComponent={
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <Grid container direction="column" className={css.alignRight}>
              <span>{formatMoney(+order.totalAmount)}</span>
              {order.isRefunded && (
                <Badge label={t('collect.payments.links.status.refunded')} color="ferrari" />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <LinkOptions order={order} />
          </Grid>
        </Grid>
      }
    />
  );
};

const Deposit: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();

  const {
    paymentLink: { link, linkOrders, getMoreOrders, linkOrderFilters },
  } = useStore();

  return (
    <div className={css.container}>
      <div>
        <span className={css.title}>
          {t('collect.payments.details.title')} {link?.linkNumberFormatted}
        </span>
        <div className={css.panel}>
          <div className={css.panelRow}>
            <span className={css.type}>{t('collect.payments.details.paymentsNumber')}</span>
            <span>{link?.paidCount}</span>
          </div>
          <div className={css.panelRow}>
            <span className={css.type}>{t('collect.payments.details.paidAmount')}</span>
            <span>
              {formatMoney(link?.metadata?.totalPaid || 0)} {link?.currency}
            </span>
          </div>
        </div>
      </div>
      <div className={css.list} id="link-list">
        <InfiniteScroll
          next={getMoreOrders}
          hasMore={linkOrderFilters?.hasMore}
          dataLength={linkOrders.length}
          scrollableTarget="link-list"
        >
          <List>
            {linkOrders.map((order) => (
              <DepositRow key={order.paymentOrderId} order={order} />
            ))}
          </List>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default observer(Deposit);
