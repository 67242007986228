import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');
  return createStyles({
    row: {
      marginBottom: theme.spacing(3),
    },
    col: {
      flex: 1,
      display: 'flex',
    },
    colLeft: {
      paddingRight: theme.spacing(8),
      [upScale]: {
        paddingRight: 0,
      },
    },
    colCenter: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      display: 'none',
      [upScale]: {
        display: 'flex',
      },
    },
    colRight: {
      justifyContent: 'end',
      maxWidth: 100,
    },
    itemsWrapper: {
      position: 'relative',
    },
    line: {
      flex: 1,
      display: 'flex',
      borderRadius: 5,
      maxHeight: 13,
    },
    actions: {
      flex: 1,
      display: 'flex',
      borderRadius: 5,
      maxWidth: 100,
    },
    opacity100: {
      opacity: 1,
    },
    opacity80: {
      opacity: 0.8,
    },
    opacity50: {
      opacity: 0.5,
    },
    opacity20: {
      opacity: 0.2,
    },
    labelWrapper: {
      flex: 1,
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.oysterTheme.shark,
      fontSize: '0.875rem',
    },
  });
});

export default useStyles;
