import { FC } from 'react';
import { IconProps, pine } from './types';

const UnpaidIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35146 20.7681C4.04229 21.0773 3.54104 21.0773 3.23187 20.7681C2.92271 20.459 2.92271 19.9577 3.23187 19.6485L20.6485 2.23187C20.9577 1.92271 21.459 1.92271 21.7681 2.23187C22.0773 2.54104 22.0773 3.04229 21.7681 3.35146L4.35146 20.7681ZM12.8134 7.62427C13.1842 7.66704 13.5522 7.74013 13.9175 7.84353L15.9022 5.85886C15.0309 5.46038 14.0014 5.21285 12.8134 5.11627V3.04527H10.9134V5.15427C9.46944 5.31893 8.36111 5.78127 7.58844 6.54127C6.82844 7.2886 6.44844 8.18793 6.44844 9.23927C6.44844 10.0879 6.65744 10.7719 7.07544 11.2913C7.49344 11.7979 8.01278 12.1843 8.63344 12.4503C8.78663 12.5159 8.94831 12.58 9.11848 12.6426L12.8134 8.94763V7.62427ZM14.792 11.4474L10.9134 15.326V16.3453C10.6146 16.3176 10.3195 16.273 10.0279 16.2115L7.98468 18.2547C8.06629 18.2839 8.14954 18.3124 8.23444 18.3403C9.08311 18.6189 9.97611 18.7836 10.9134 18.8343V20.9053H12.8134V18.7773C14.2448 18.5999 15.3404 18.1439 16.1004 17.4093C16.8604 16.6619 17.2404 15.7689 17.2404 14.7303C17.2404 13.8943 17.0314 13.2229 16.6134 12.7163C16.1954 12.1969 15.6761 11.8106 15.0554 11.5573C14.9704 11.52 14.8825 11.4834 14.792 11.4474ZM9.50744 9.08727C9.50744 9.40394 9.62778 9.6636 9.86845 9.86627C10.1091 10.0689 10.4574 10.2399 10.9134 10.3793V7.68127C10.4321 7.79527 10.0774 7.97893 9.84944 8.23227C9.62144 8.47294 9.50744 8.75794 9.50744 9.08727ZM12.8134 16.2693C13.2694 16.1679 13.6114 16.0033 13.8394 15.7753C14.0674 15.5473 14.1814 15.2749 14.1814 14.9583C14.1814 14.6416 14.0611 14.3819 13.8204 14.1793C13.5924 13.9766 13.2568 13.7993 12.8134 13.6473V16.2693Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

UnpaidIcon.defaultProps = {
  fill: pine,
};

export default UnpaidIcon;
