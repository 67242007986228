import { makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles(({ oysterTheme, spacing }: Theme) => ({
  customUploader: {
    padding: spacing(1),
    alignItems: 'center',
    display: 'flex',
  },
  addIcon: {
    border: `dashed 1px ${oysterTheme.dolphin}`,
    color: oysterTheme.dolphin,
    height: 48,
    width: 48,
    marginLeft: spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  imgWrapper: {
    display: 'flex',
    maxHeight: 48,
  },
  closeWrapper: {
    position: 'relative',
    cursor: 'pointer',
    border: `solid 1px ${oysterTheme.stone}`,
    zIndex: 1,
    '& img': {
      height: 48,
      maxWidth: 100,
      objectFit: 'contain',
    },
  },
  close: {
    color: oysterTheme.dolphin,
    position: 'absolute',
    top: -8,
    right: -8,
    zIndex: 2,
    '&:hover': {
      color: oysterTheme.ferrari,
    },
  },
  title: {
    fontSize: 14,
    maxWidth: 135,
    minWidth: 135,
    marginRight: spacing(2),
  },
}));

export default styles;
