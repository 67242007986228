import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from '@yaydoo/react-components/lib/components/OutlinedButton';
import DownloadIcon from '@yaydoo/react-components/lib/icons/Arrows/Download';

import useStyles from './styles';

export interface IDownloadReport {
  reportName: string;
  base64File: string;
  onDownloadReport: () => void;
}

const DownloadReport: FC<IDownloadReport> = ({ reportName, base64File, onDownloadReport }) => {
  const { t } = useTranslation();
  const css = useStyles();
  return (
    <>
      {base64File && (
        <a className={css.downloadLink} href={base64File} download={reportName}>
          <ActionButton
            leftIcon={DownloadIcon}
            label={t('batch.details.csv')}
            title={t('batch.details.downloadReport')}
            onClick={onDownloadReport}
          />
        </a>
      )}
    </>
  );
};

export default DownloadReport;
