export default {
  labels: {
    seeDetails: 'Ver detalles',
    cancelOperation: 'Cancelar operación',
    batch: 'Batch',
    details: 'detalles',
  },
  error: { title: 'Error', unknown: 'Ocurrió un error, vuelve a intentarlo' },
  success: {
    title: 'Listo',
    sendEmail: 'Solicitud enviada',
    copyUrl: 'Enlace copiado',
    copyText: 'Texto copiado',
  },
  status: {
    pending: 'Pendiente',
    partiallyDispersed: 'Parcialmente dispersado',
    success: 'Dispersado',
    canceled: 'Cancelado',
    error: 'Error',
    fail: 'No dispersado',
    returned: 'Devuelto',
  },
};
