import { FC, useState, useRef } from 'react';
import { ChromePicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@yaydoo/react-components/lib/components/Popover';

interface ColorPickerProps {
  color: string;
  onComplete?: (color: string) => void;
  onChange: (color: string) => void;
  Input: FC<{
    onClick: () => void;
  }>;
}

const ColorPicker: FC<ColorPickerProps> = ({ color, onComplete, onChange, Input }) => {
  const [_color, setColor] = useState(color);
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = useRef(null);

  const handleComplete = () => {
    onComplete && onComplete(_color);
  };

  const handleOnChange = (color: string) => {
    onChange(color);
    setColor(color);
  };

  const handleOpenPicker = () => {
    setIsOpen((isOpen) => {
      if (!isOpen) handleComplete();

      return !isOpen;
    });
  };

  return (
    <ClickAwayListener onClickAway={() => isOpen && setIsOpen(false)}>
      <div ref={targetRef}>
        <Input onClick={handleOpenPicker} />
        {isOpen && (
          <Popover
            arrow
            open={isOpen}
            targetRef={targetRef}
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <ChromePicker
              disableAlpha
              color={_color}
              onChange={(color) => handleOnChange(color.hex)}
              styles={{
                default: {
                  picker: {
                    boxShadow: 'none',
                    marginTop: '4px',
                    borderRadius: '8em',
                  },
                },
              }}
            />
          </Popover>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ColorPicker;
