import { FC, SetStateAction, useEffect, useState } from 'react';
import mergeWith from 'lodash.mergewith';
import clsx from 'clsx';

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Popper from '@material-ui/core/Popper';
import { IPopover } from './types';
import useStyles from './styles';

const Popover: FC<IPopover> = ({
  children,
  targetRef,
  open,
  showBackdrop,
  classes,
  onClose,
  title,
  arrow,
  modifiers,
  placement,
}) => {
  const [arrowRef, setArrowRef] = useState<SetStateAction<HTMLDivElement> | null>(null);

  const css = useStyles();
  const rootClasses = clsx(css.root, classes?.root, {
    [css.hasArrow]: arrow,
    [css.isActive]: open,
  });
  const backdropClasses = clsx(css.backdrop, classes?.backdrop);
  const paperClasses = clsx(css.paper, classes?.paper);
  const titleClasses = clsx(css.title, classes?.title);
  const headerClasses = clsx(css.header, classes?.header);

  const toggleActiveRefClass = (): void => {
    if (!targetRef?.current) return;

    if (open) {
      targetRef.current.classList.add(css.isActiveRef);
      return;
    }

    targetRef.current.classList.remove(css.isActiveRef);
  };

  useEffect(() => {
    toggleActiveRefClass();
  }, [open, targetRef]);

  const modifiersMerged = mergeWith(
    {
      flip: {
        enabled: true,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: 'scrollParent',
      },
      arrow: {
        enabled: arrow,
        element: arrowRef,
      },
    },
    modifiers,
  );

  return (
    <Backdrop
      className={backdropClasses}
      open={Boolean(showBackdrop && open)}
      transitionDuration={0}
    >
      <Popper
        placement={placement}
        modifiers={modifiersMerged}
        className={rootClasses}
        open={open}
        anchorEl={targetRef?.current}
        transition
      >
        <div data-testid="popover-container">
          {arrow && <div className={css.arrow} ref={setArrowRef} />}

          <Paper className={paperClasses}>
            <Box
              className={headerClasses}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={titleClasses}>{title}</div>
              <IconButton
                data-testid="popover-close-button"
                size="small"
                aria-label="close"
                className={css.closeButton}
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            {children}
          </Paper>
        </div>
      </Popper>
    </Backdrop>
  );
};

Popover.defaultProps = {
  showBackdrop: false,
  arrow: false,
};

export default Popover;
