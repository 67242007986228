import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
      width: '100%',
      position: 'relative',
      '&.hideBorder': {
        borderBottom: 'none',
      },
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: theme.oysterTheme.shark,
      letterSpacing: '0.08em',
      fontSize: 12,
      lineHeight: '14px',
      '&.boldTitle': {
        color: theme.oysterTheme.charcoal,
      },
      '&.lightTitle': {
        fontWeight: 400,
        color: theme.oysterTheme.charcoal,
      },
    },
    description: {
      marginTop: theme.spacing(1),
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      color: theme.oysterTheme.charcoal,
    },
    actionCont: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    actionBtn: {
      padding: 0,
      color: theme.oysterTheme.oyster,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '14px',
      '& small': {
        paddingLeft: theme.spacing(1),
        display: 'inherit',
        justifyContent: 'inherit',
        alignItems: 'inherit',
      },
      '&.menu svg': {
        transform: 'rotate(90deg)',
      },
      '&:disabled': {
        color: theme.oysterTheme.dolphin,
      },
    },
    menu: {
      padding: 0,
      minWidth: 160,
      marginTop: theme.spacing(5),
      '& ul': {
        padding: '0 !important',
      },
    },
    menuItem: {
      color: theme.oysterTheme.shark,
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
      fontSize: 12,
      lineHeight: '14px',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  }),
);

export default useStyles;
