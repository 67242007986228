import { FC, ElementType, ReactElement, useState } from 'react';
import classNames from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useModal from '@hooks/useModal';
import FormButton from '@components/FormButton';

import useStyles from './styles';

export interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  message?: string;
  componentMessage?: ElementType | ReactElement;
  confirmLabelButton: string;
  cancelLabelButton?: string;
  titleClassName?: string;
  showSubmitLoader?: boolean;
  submittingBtnClass?: string;
  customIcon?: ElementType | ReactElement;
  wide?: boolean;
}

const ConfirmationModal: FC<ConfirmModalProps> = ({
  onConfirm,
  onCancel,
  title,
  message,
  componentMessage,
  confirmLabelButton,
  cancelLabelButton,
  titleClassName,
  showSubmitLoader = false,
  submittingBtnClass,
  customIcon,
  wide = false,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const shouldWide = wide && !isSmallScreen;
  const css = useStyles({ wide: shouldWide });
  const { closeModal } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnConfirm = async () => {
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
    closeModal();
  };

  const handleCancel = async () => {
    onCancel && (await onCancel());
    closeModal();
  };

  return (
    <div className={css.modalContainer}>
      {customIcon}
      <Typography className={classNames(css.modalContainerHeader, titleClassName)}>
        {title}
      </Typography>
      {message && <Typography className={css.modalContainerText}>{message} </Typography>}
      {componentMessage}
      <Grid container className={css.modalButtons}>
        <Grid item xs={12}>
          <FormButton
            disabled={showSubmitLoader && isSubmitting}
            isSubmitting={showSubmitLoader && isSubmitting}
            size="large"
            className={classNames(css.btn, css.btnSubmitting, submittingBtnClass)}
            color="primary"
            onClick={handleOnConfirm}
            data-testid="confirm-button"
            label={confirmLabelButton}
          />
        </Grid>
        {cancelLabelButton && (
          <Grid className={css.cancelBtnContainer} item xs={12}>
            <FormButton
              disabled={showSubmitLoader && isSubmitting}
              size="large"
              className={classNames(css.btn, css.cancelBtn)}
              onClick={handleCancel}
              data-testid="cancel-button"
              label={cancelLabelButton}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ConfirmationModal;
