import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    tableContainer: {
      width: '100%',
    },
    tableHeaderCell: {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.oysterTheme.pine,
      borderBottom: `1px solid ${theme.oysterTheme.dolphin}`,
    },
    tableBodyCell: {
      fontSize: 14,
      border: 0,
      color: theme.oysterTheme.charcoal,
    },
    tableCellOpen: {
      backgroundColor: theme.oysterTheme.brandGreen,
    },
    filledBgCell: {
      backgroundColor: theme.oysterTheme.pearl,
    },
    collapsibleTableRow: {
      cursor: 'pointer',
      '&:nth-child(4n - 1)': {
        backgroundColor: theme.oysterTheme.pearl,
      },
    },
    collapsibleTableRowNoCursor: {
      '&:nth-child(4n - 1)': {
        backgroundColor: theme.oysterTheme.pearl,
      },
    },
    onclickTableRow: {
      cursor: 'pointer',
      '&:nth-child(even)': {
        backgroundColor: theme.oysterTheme.pearl,
      },
    },
    tableRow: {
      '&:nth-child(even)': {
        backgroundColor: theme.oysterTheme.pearl,
      },
    },
    tableBodyCellCollapsible: {
      fontSize: 14,
      border: 0,
      color: theme.oysterTheme.charcoal,
      padding: 0,
    },
    collapseContainer: {
      width: '100%',
      borderTop: 0,
    },
    tableHeaderRowOpen: {
      cursor: 'pointer',
      border: `4px solid ${theme.oysterTheme.oyster}`,
      borderBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableBodyRowOpen: {
      border: `4px solid ${theme.oysterTheme.oyster}`,
      borderTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    skeleton: {
      borderRadius: 4,
      width: '90%',
      height: 20,
    },
  });
});

export default styles;
