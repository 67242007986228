import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const UserIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="48" cy="48" r="48" fill="#EBEEF2" />
      <path
        d="M71.0171 69.876C70.153 66.06 65.2208 64.2 63.5167 63.6C61.6446 62.94 58.9805 62.784 57.2644 62.4C56.2804 62.184 54.8523 61.644 54.3723 61.068C53.8922 60.492 54.1803 55.152 54.1803 55.152C54.1803 55.152 55.0683 53.772 55.5483 52.548C56.0283 51.336 56.5564 48 56.5564 48C56.5564 48 57.5404 48 57.8884 46.272C58.2605 44.388 58.8485 43.656 58.7765 42.252C58.7045 40.872 57.9484 40.908 57.9484 40.908C57.9484 40.908 58.6805 38.904 58.7645 34.752C58.8725 29.82 55.0083 24.96 48.012 24.96C40.9196 24.96 37.1394 29.82 37.2474 34.752C37.3434 38.904 38.0515 40.908 38.0515 40.908C38.0515 40.908 37.2954 40.872 37.2234 42.252C37.1514 43.656 37.7394 44.388 38.1115 46.272C38.4475 48 39.4435 48 39.4435 48C39.4435 48 39.9716 51.336 40.4516 52.548C40.9316 53.772 41.8196 55.152 41.8196 55.152C41.8196 55.152 42.1077 60.492 41.6276 61.068C41.1476 61.644 39.7195 62.184 38.7355 62.4C37.0194 62.784 34.3553 62.94 32.4832 63.6C30.7791 64.2 25.8469 66.06 24.9828 69.876C24.8508 70.476 25.3068 71.04 25.9309 71.04H70.081C70.6931 71.04 71.1491 70.476 71.0171 69.876Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

UserIcon.defaultProps = {
  fill: defaultIconColor,
};

export default UserIcon;
