import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';
import { SETTINGS_TYPES } from '@constants/settings';
import { InvoiceSettingsData } from './types';

/**
 * Services can extends from abstract class Service for clean configs
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/index.ts
 *
 * or can can extends from OysterService to use default base config for oyster endpoints that include some default interceptors
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/OysterService.ts
 *
 * @param @optional connection string Connection name to get axios config default is 'api'
 * @param @optional requestInterceptors RequestInterceptor[] interceptors to be applied before each request
 * @param @optional requestInterceptors ResponseInterceptor[] interceptors to be applied after each response
 */

export default class SettingsService extends OysterService {
  getPaymentLinkReminders(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`settings/${SETTINGS_TYPES.PAYMENT_LINK_REMINDERS}`);
  }
  saveReminderSettings(body: Record<string, unknown>): Promise<AxiosResponse | AxiosError> {
    return this.client.put('settings', body);
  }
  saveCobrandingSettings(body: Record<string, unknown>): Promise<AxiosResponse | AxiosError> {
    return this.client.put('settings', body);
  }

  getCobrandingSettings(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`settings/${SETTINGS_TYPES.COBRANDING}`);
  }

  //Invoices TODO: Add endpoints
  getInvoiceSettings(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('');
  }
  postInvoiceSettings(data: InvoiceSettingsData): Promise<AxiosResponse | AxiosError> {
    return this.client.post('', data);
  }
}
