import { useState, useEffect } from 'react';
import { fetchAndActivate } from 'firebase/remote-config';

import { config, TFirebaseConfig } from '@utils/firebase';
import EnvironmentVars, { Environments } from '@utils/Variables';

import useInitFirebaseApp from './useInitFirebaseApp';

export interface IUseEnvVars {
  environmentVars: EnvironmentVars;
  areEnvironmentVarsLoaded: boolean;
  isFirebaseInit: boolean;
}

const environmentVars = new EnvironmentVars();

const useInitEnvironmentVars = (
  environment: Environments,
  useAnalytics?: boolean,
  customConfig?: TFirebaseConfig,
): IUseEnvVars => {
  const firebaseConfig = customConfig || config;
  const [areEnvironmentVarsLoaded, setAreEnvironmentVarsLoaded] = useState(false);
  const { initFirebaseApp, isFirebaseInit } = useInitFirebaseApp(useAnalytics);

  useEffect(() => {
    if (!isFirebaseInit) {
      initFirebaseApp(firebaseConfig);
      return;
    }

    const remoteConfigSetup = async () => {
      const { remoteConfig, isConfigFetched } = window.__oyster_firebase;
      if (!isConfigFetched) await fetchAndActivate(remoteConfig);
      environmentVars.init(environment);
      setAreEnvironmentVarsLoaded(true);
    };

    if (!areEnvironmentVarsLoaded) {
      remoteConfigSetup();
    }
  }, [isFirebaseInit]);

  return { environmentVars, areEnvironmentVarsLoaded, isFirebaseInit };
};

export default useInitEnvironmentVars;
