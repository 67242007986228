import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 300,
      padding: '0.5em',
    },
    select: {
      textDecoration: 'none',
      boxShadow: '0px 8px 24px rgba(1, 70, 81, 0.08)',
      width: '100%',
      borderRadius: 8,
      zIndex: 2,
      position: 'relative',
      '& .Mui-disabled': {
        color: theme.oysterTheme.crow,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 8,
        background: 'linear-gradient(rgb(241, 240, 243) 0%, rgb(234, 245, 247) 100%)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        zIndex: 1,
      },
      '& > div': {
        zIndex: 2,
        padding: '0.5em 0.7em 0.5em 0.5em',
        borderRadius: '8px !important',
        background: 'linear-gradient(92.16deg, #FFFFFF -26.14%, rgba(255, 255, 255, 0.39) 102.6%)',
      },
      '& svg': {
        zIndex: 2,
      },
    },
    option: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      height: 40,
      width: 40,
      fontSize: 40,
      marginLeft: '0.3em',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1em',
      fontSize: 14,
    },
    label: {
      fontWeight: 600,
      letterSpacing: 0.4,
    },
    amount: {
      color: theme.oysterTheme.shark,
    },
    extraButton: {
      backgroundColor: theme.oysterTheme.oyster,
      width: '100%',
      padding: '1em 1em 0.5em 1em',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 14,
      color: theme.oysterTheme.cotton,
      fontWeight: 600,
      height: 50,
      borderRadius: '0 0 8px 8px',
      marginTop: '-8px',
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        color: theme.oysterTheme.oyster,
        justifyContent: 'center',
        padding: '0.3em 1em',
        height: 35,
      },
    },
    rightArrow: {
      color: theme.oysterTheme.cotton,
      fontSize: '1.8em',
      justifySelf: 'flex-end',
    },
    disabled: {
      display: 'none',
    },
  });

export default makeStyles(useStyles);
