import { useState, useCallback } from 'react';
// import useStore from '@hooks/useStore';

export interface IUseUpdateStores {
  areStoresUpdated: boolean;
  toggleAreStoresUpdate: (value: boolean) => void;
  updateStores: () => void;
}

function useUpdateStores(): IUseUpdateStores {
  const [areStoresUpdated, toggleAreStoresUpdate] = useState(false);
  // Add here stores that need to be updated that use volatile state

  const updateStores = useCallback(async () => {
    /* Add here all new stores that use mobx volatile state
     * and need to be updated when env vars are loaded
     */
    toggleAreStoresUpdate(false);
    try {
      await Promise.all([
        // Add your store updateService here
      ]);
    } catch {
      //
    }

    toggleAreStoresUpdate(true);
  }, []);

  return { areStoresUpdated, toggleAreStoresUpdate, updateStores };
}

export default useUpdateStores;
