import usePermissions from './usePermissions';

export interface useHasFinancialInstrumentAccessParams {
  financialInstrument?: string;
  financialInstruments?: string[];
}

export default function useHasFinancialInstrumentAccess({
  financialInstrument = '',
  financialInstruments = [],
}: useHasFinancialInstrumentAccessParams): boolean {
  const { accessFinancialInstruments } = usePermissions();

  if (financialInstruments?.length > 0) {
    return !!financialInstruments.some((element) => !!accessFinancialInstruments[element]);
  }
  return !!accessFinancialInstruments[financialInstrument];
}
