import { FC } from 'react';
import { IconProps, obsidian } from './types';

const SalesIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11H38V29.9474H2V11ZM25.6842 20.4737C25.6842 23.613 23.1393 26.1579 20 26.1579C16.8607 26.1579 14.3158 23.613 14.3158 20.4737C14.3158 17.3344 16.8607 14.7895 20 14.7895C23.1393 14.7895 25.6842 17.3344 25.6842 20.4737ZM31.3684 22.3684C32.4149 22.3684 33.2632 21.5201 33.2632 20.4737C33.2632 19.4272 32.4149 18.5789 31.3684 18.5789C30.322 18.5789 29.4737 19.4272 29.4737 20.4737C29.4737 21.5201 30.322 22.3684 31.3684 22.3684ZM10.5263 20.4737C10.5263 21.5201 9.67801 22.3684 8.63158 22.3684C7.58514 22.3684 6.73684 21.5201 6.73684 20.4737C6.73684 19.4272 7.58514 18.5789 8.63158 18.5789C9.67801 18.5789 10.5263 19.4272 10.5263 20.4737ZM20.3399 20.0566C21.1342 20.2864 22 20.5843 22 21.7673C22 22.6353 21.4633 23.3843 20.3399 23.5204V24.4736H19.7531V23.529C19.0519 23.4779 18.3721 23.2055 18 22.8226L18.322 21.963C18.6726 22.3034 19.2093 22.5502 19.7531 22.6098V20.9077C18.9589 20.6779 18.1073 20.3715 18.1073 19.1885C18.1073 18.3204 18.6369 17.563 19.7531 17.4353V16.4736H20.3399V17.4183C20.8694 17.4524 21.4061 17.6396 21.7925 17.9375L21.4991 18.797C21.1199 18.5332 20.7191 18.3885 20.3399 18.3375V20.0566ZM19.7531 18.363C19.2451 18.4566 19.0304 18.763 19.0304 19.1375C19.0304 19.546 19.3381 19.7332 19.7531 19.8779V18.363ZM20.3399 21.0864V22.5928C20.8623 22.4992 21.0841 22.2098 21.0841 21.8438C21.0841 21.4268 20.7692 21.2311 20.3399 21.0864Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

SalesIcon.defaultProps = {
  fill: obsidian,
};

export default SalesIcon;
