import { flow, Instance, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import Common from '../common';
import AuthService from './service';
import AuthCookie from '../http/cookies/AuthCookie';
import CacaoOnboardedCookie from '../http/cookies/CacaoOnboardedCookie';
import SecurityProfileCookie from '../http/cookies/SecurityProfileCookie';

export interface IFinancialInstrument {
  debitAccount: boolean;
}

export interface ILoginResponse {
  token: string;
  user: {
    userId?: string;
  };
  securityAuthProfile: {
    ivHash: string;
    newSecurityProfileHash: string;
    originalSalt: string;
    version?: string;
  };
}

const AuthStore = types
  .compose(
    Common,
    types.model({
      isAuthenticated: false,
      token: '',
      deviceId: '',
      isCacaoOnboarded: false,
    }),
  )
  .views(() => ({
    get service() {
      return new AuthService();
    },
  }))
  .actions((self) => ({
    setToken: (token: string) => {
      self.token = token;
      self.isAuthenticated = !!token;
    },
  }))
  .actions((self) => ({
    generateDeviceId: (forceGeneration?: boolean) => {
      const deviceIdCookie = AuthCookie.getDeviceIdCookie();
      if (forceGeneration || !deviceIdCookie) {
        const deviceId = uuidv4();
        self.deviceId = deviceId;
        AuthCookie.setDeviceIdCookie(deviceId);
      } else {
        self.deviceId = deviceIdCookie;
      }
      return self.deviceId;
    },
    handleSuccessLogin: (
      loginResponse: ILoginResponse,
      financialInstrument: IFinancialInstrument,
    ) => {
      const { user, securityAuthProfile, token } = loginResponse;
      const securityProfile = {
        ...securityAuthProfile,
        userId: user.userId,
        newSecurityProfileHash: null,
      };
      const isCacaoOnboarded = !!financialInstrument?.debitAccount;
      self.isCacaoOnboarded = isCacaoOnboarded;
      CacaoOnboardedCookie.setCookie(String(isCacaoOnboarded), AuthCookie.deviceIdExpMins);
      SecurityProfileCookie.setCookie(JSON.stringify(securityProfile));
      self.setToken(token);
      AuthCookie.setCookie(token);
    },
  }))
  .actions((self) => ({
    loginWithSut: flow(function* (singleUseToken: string, sessionType: number) {
      try {
        self.submitting = true;
        self.generateDeviceId();
        const { data } = yield self.service.loginWithSut({
          singleUseToken,
          sessionType: sessionType,
          deviceType: 4,
        });

        const { loginResponse, financialInstrument } = data;
        if (loginResponse) {
          self.handleSuccessLogin(loginResponse, financialInstrument);
        }
        return data;
      } catch (error) {
        return error;
      } finally {
        self.submitting = false;
      }
    }),
    logout: flow(function* () {
      try {
        yield self.service.logout();
      } catch {
        //
      } finally {
        AuthCookie.deleteCookie();
        SecurityProfileCookie.deleteCookie();
        CacaoOnboardedCookie.deleteCookie();
        self.reset();
      }
    }),
    updateTTL: flow(function* () {
      try {
        yield self.service.updateTTL();
      } catch {
        //
      }
    }),
  }));

export type TAuthStore = Instance<typeof AuthStore>;
export default AuthStore;
