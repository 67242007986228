import { AxiosError, AxiosResponse } from 'axios';

import { AccountsPayableLinkUpdateStatus } from '../constants/accountsPayableLink';
import OysterService from '../http/services/OysterService';
import endpoints from './endpoints';

export default class AccountsPayableLinkService extends OysterService {
  /**
   * Get getAccountsPayableLink data
   * @param accountsPayableLinkId string
   * @return Promise<AxiosResponse | AxiosError>
   */
  getAccountsPayableLink(accountsPayableLinkId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.accountsPayableLink(accountsPayableLinkId));
  }

  /**
   * Update accountsPayableLink status
   * @param params { status: AccountsPayableLinkUpdateStatus, accountsPayableLinkId: string }
   * @return Promise<AxiosResponse | AxiosError>
   */
  updateStatus(params: {
    status: AccountsPayableLinkUpdateStatus;
    accountsPayableLinkId: string;
  }): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.updateAccountsPayableLinkStatus, params);
  }

  /**
   * Request funds
   * @param params { accountsPayableLinkId: string, requestEmails: string[]; }
   * @return Promise<AxiosResponse | AxiosError>
   */
  requestFunds(params: {
    accountsPayableLinkId: string;
    requestEmails: string[];
  }): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.requestFunds, params);
  }
}
