import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(4),
    },
    button: {
      width: 'fit-content',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: 20,
      margin: 0,
      minWidth: 'unset',
      fontWeight: 600,
      fontSize: 14,
    },
  }),
);

export default useStyles;
