import { useContext } from 'react';
import { CommonStoreContext } from '@components/CommonStoreWrapper';
import { ICommonStore } from '@store/index';

const useCommonStore = (): ICommonStore => {
  const { value } = useContext(CommonStoreContext);

  return value;
};

export default useCommonStore;
