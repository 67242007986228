import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import AllIcon from '@yaydoo/react-components/lib/icons/Sales';
import PendingIcon from '@yaydoo/react-components/lib/icons/Unpaid';
import PaidIcon from '@yaydoo/react-components/lib/icons/Arrows/Incoming';
import RefundIcon from '@yaydoo/react-components/lib/icons/Arrows/Refund';

import useGetUserBusinessData from '@hooks/useGetUserBusinessData';
import useBreakpoints from '@hooks/useBreakpoints';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';
import { Persona } from '@constants/invoice';
import { FilterProps } from './types';
import MobileFilters from './Mobile';
import DesktopFilters from './Desktop';

const Filter: FC<FilterProps> = ({
  currentLinkStatus,
  currentType,
  onChangeLinkStatus,
  onChangeType,
}) => {
  const { t } = useTranslation();
  const { isTouchScreen } = useBreakpoints();
  const { persona } = useGetUserBusinessData();

  const statusOptions = [
    {
      label: t('collect.payments.filters.links.all'),
      icon: AllIcon,
      value: PaymentLinkFilterStatus.ALL,
    },
    {
      label: t('collect.payments.filters.links.pending'),
      icon: PendingIcon,
      value: PaymentLinkFilterStatus.PENDING,
    },
    {
      label: t('collect.payments.filters.links.paid'),
      icon: PaidIcon,
      value: PaymentLinkFilterStatus.PAID,
    },
    {
      label: t('collect.payments.filters.links.refunded'),
      icon: RefundIcon,
      value: PaymentLinkFilterStatus.REFUNDED,
      hide: persona === Persona.FISICA,
    },
  ].filter(({ hide }) => !hide);

  return isTouchScreen ? (
    <MobileFilters
      currentLinkStatus={currentLinkStatus}
      currentType={currentType}
      linkStatusOptions={statusOptions}
      onChangeLinkStatus={onChangeLinkStatus}
      onChangeType={onChangeType}
    />
  ) : (
    <DesktopFilters
      currentLinkStatus={currentLinkStatus}
      currentType={currentType}
      linkStatusOptions={statusOptions}
      onChangeLinkStatus={onChangeLinkStatus}
      onChangeType={onChangeType}
    />
  );
};

export default observer(Filter);
