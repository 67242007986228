import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Typography } from '@material-ui/core';
import BackPageButton from '@yaydoo/react-components/lib/components/BackPageButton';

import useBatchDetails from '@hooks/useBatchDetails';
import MainLayout from '@layouts/mainLayout';

import BatchResume from './BatchResume';
import DetailsList from './DetailsList';
import DownloadReport from './DownloadReport';
import useStyles from './styles';

const Details: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const { accountsPayableLinkId } = useParams<{ accountsPayableLinkId: string }>();
  const {
    batchId,
    createdAt,
    batchResumeProps,
    downloadReportProps,
    batchDetailsListProps,
    setAccountsPayableLinkId,
    onGoBack,
  } = useBatchDetails();

  useEffect(() => {
    setAccountsPayableLinkId(accountsPayableLinkId);
  }, []);

  return (
    <MainLayout header={<BackPageButton buttonText="Atrás" onClick={onGoBack} />}>
      <div className={css.root}>
        <div>
          <div className={css.pageHeader}>
            <Typography className={css.title} variant="h3">
              {t('batch.details.sectionTitle', { batchId })}
            </Typography>
            <Typography variant="caption" className={css.createdAt}>
              {t('batch.details.createdAt', { createdAt })}
            </Typography>
          </div>
          <div className={css.resumeContainer}>
            <BatchResume {...batchResumeProps} />
            <DownloadReport {...downloadReportProps} />
          </div>
          <div className={css.listHeader}>
            <span className={css.listTitle}>{t('batch.details.listTitle')}</span>
          </div>
          <hr className={css.divider} />
        </div>
        <div data-testid="list-container" className={css.listContainer} id="list-container">
          <DetailsList {...batchDetailsListProps} />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Details);
