import { ReactElement, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import { DataPropsItemType, TableColumn } from './types';
import TableHeader from './tableHeader';
import TableCustomRow from './tableCustomRow';
import useStyles from './styles';

export interface ICustomTable<T extends DataPropsItemType> {
  columns: TableColumn<T>[];
  data: T[];
  isCollapsibleRow?: boolean;
  isOnclickRow?: boolean;
  collapsibleRowComponent?: (item: T) => ReactElement | null;
  onOpenRow?: (item: T) => void;
  onClickRow?: (item: T) => void;
  isFetching?: boolean;
  emptyFetchingRows?: number;
  oddColorRows?: boolean;
}

const CustomTable = <T extends DataPropsItemType>({
  columns,
  data,
  isCollapsibleRow = false,
  isOnclickRow = false,
  collapsibleRowComponent,
  onOpenRow,
  onClickRow,
  emptyFetchingRows = 10,
  isFetching = false,
  oddColorRows = true,
}: ICustomTable<T>): ReactElement => {
  const css = useStyles();
  const [openRow, setOpenRow] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState('');

  const handleInternalOpenRow = (open: boolean) => {
    setOpenRow(open);
  };

  const handleSetInternalIndexOpenRow = (index: string) => {
    setOpenRowIndex(index);
  };

  let rowData = [];
  const fetchingData = [] as T[];

  if (isFetching) {
    for (let i = 0; i < emptyFetchingRows; i++) {
      fetchingData.push({ test: '' } as T);
    }
  }

  rowData = isFetching ? fetchingData : data;

  return (
    <Table className={css.tableContainer}>
      <TableHeader columns={columns} />
      <TableBody>
        {rowData.map((item, idx) => {
          return (
            <TableCustomRow
              key={idx}
              index={idx.toString()}
              columns={columns}
              item={item}
              isCollapsibleRow={isCollapsibleRow}
              isOnclickRow={isOnclickRow}
              collapsibleRowComponent={collapsibleRowComponent}
              onOpenRow={onOpenRow}
              onClickRow={onClickRow}
              handleInternalIsOpenRow={handleInternalOpenRow}
              internalIsOpenRow={openRow}
              handleSetInternalIndexOpenRow={handleSetInternalIndexOpenRow}
              internalOpenRowIndex={openRowIndex}
              isFetching={isFetching}
              oddColorRows={oddColorRows}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
