import { cast, flow, getSnapshot, Instance, types } from 'mobx-state-tree';

import { DocumentFileType, DocumentType, DocumentSides, DocumentStatus } from './enums';
import Common from '../common';
import KYCDocumentService from './service';
import { errors } from '../constants';

const PM_BUSINESS_FILETYPES = [
  DocumentType.ART_OF_INCORPORATION,
  DocumentType.FISCAL_IDENTIFICATION,
  DocumentType.PROOF_OF_FIEL,
  DocumentType.PUBLIC_REGISTRY_OF_PROPERTY_AND_COMMERCE_PROPERTY,
];

export const Document = types.model({
  name: '',
  size: 0,
  type: '',
});
export interface TDocument extends Instance<typeof Document> {}

export interface IVolatileState {
  service: null | KYCDocumentService;
}

export interface IUserDocument {
  documentContentType: string[];
  files: Array<{
    originalName: string;
    mimeType: string;
    size: string;
  }>;
}

export const DocumentTypeEnum = types.enumeration(Object.keys(DocumentType));

export const DocumentStatusEnum = types.enumeration([
  DocumentStatus.CHA_REJECTED,
  DocumentStatus.CHA_VERIFIED,
  DocumentStatus.EXTERNAL_SERVICE_VERIFIED,
  DocumentStatus.EXTERNAL_SERVICE_REJECTED,
  DocumentStatus.NOT_PROVIDED,
  DocumentStatus.VERIFICATION_PENDING,
]);

export const IDocumentStatus = types.model({
  type: DocumentTypeEnum,
  documentStatus: types.maybeNull(DocumentStatusEnum),
});

export const TDocumentStatus = typeof IDocumentStatus;

const KycDocumentStore = types
  .compose(
    Common,
    types.model({
      documentId: '',
      documentHref: '',
      documentContentId: '',
      document: Document,
      documentType: DocumentType.UNKNOWN_DOCUMENT,
      uploading: false,
      uploaded: false,
      submitting: false,
      side: types.optional(types.string, DocumentSides.front),
      isMainContent: false,
      documentStatus: types.array(IDocumentStatus),
      loadingStatus: false,
    }),
  )
  .views((self) => ({
    get documentSnapshot() {
      return getSnapshot(self.document);
    },
    get docType() {
      return DocumentType[self.documentType];
    },
    get isPmType() {
      return PM_BUSINESS_FILETYPES.includes(self.documentType);
    },
    get documentsStatus() {
      return getSnapshot(self.documentStatus);
    },
    get currentDocumentStatus() {
      const document = self.documentStatus.find(
        (document) =>
          DocumentType[document.type as keyof typeof DocumentType] === self.documentType,
      );
      return document ? document.documentStatus : DocumentStatus.NOT_PROVIDED;
    },
  }))
  .volatile(
    (): IVolatileState => ({
      service: null,
    }),
  )
  .actions((self) => ({
    updateService: flow(function* () {
      self.service = new KYCDocumentService();
      yield Promise.resolve('ok');
    }),
    getUplodType: (type: string) => {
      if (
        type === 'application/pdf' ||
        self.documentType === DocumentType.EXTERNAL_ACCOUNT_STATEMENT
      ) {
        return DocumentFileType.DOCUMENT;
      }
      return DocumentFileType.IMAGE;
    },
  }))
  .actions((self) => ({
    update: (doc: typeof self & IUserDocument) => {
      self.side = doc.side;
      self.uploaded = true;
      self.documentId = doc.documentId;
      self.documentHref = doc.documentHref;
      self.documentContentId = doc.documentContentId;
      self.document = cast({
        name: doc.files[0].originalName,
        type: doc.files[0].mimeType,
        size: +doc.files[0].size,
      });
    },
    setMainContent: (flag: boolean) => (self.isMainContent = flag),
    setDocumentStatus: (documentStatus: Instance<typeof self.documentStatus>) => {
      self.documentStatus = cast(documentStatus);
    },
  }))
  .actions((self) => ({
    uploadFile: flow(function* (file: File, side?: DocumentSides, isMainContent?: boolean) {
      self.resetError();
      self.uploaded = false;
      self.uploading = true;

      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);

        const formData = new FormData();
        /* Note: all documents that are related to business
         * have the uploadType of ART_OF_INCORPORATION
         */
        const uploadType = String(
          self.isPmType ? DocumentType.ART_OF_INCORPORATION : self.documentType,
        );

        formData.append('file', file);
        formData.append('side', side || DocumentSides.front);
        formData.append('type', uploadType);
        formData.append('documentContentType', String(self.documentType));
        formData.append('documentUploadType', String(self.getUplodType(file.type)));

        if (isMainContent) {
          formData.append('isMainContent', String(self.isMainContent));
        }

        const { data } = yield self.service.uploadDocument(formData);

        const { kycOfficialDocument: doc } = data;

        self.isMainContent = !!isMainContent;
        self.update(doc);
        self.uploaded = true;

        return data;
      } catch (error) {
        self.setError({
          type: `${self.docType}_ERROR`,
        });
      } finally {
        self.uploading = false;
      }
    }),
    getDocument: flow(function* () {
      self.submitting = true;
      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);

        const { data } = yield self.service.getDocument(self.documentType);
        const { kycOfficialDocuments: docs } = data;

        if (docs) {
          const doc = docs.find((row: { type: string; documentContentType: [string] }) => {
            if (!self.isPmType) {
              return row.type === self.docType;
            }
            return row.documentContentType[0] === self.docType;
          });
          self.update(doc);
        }

        return docs;
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    deleteDocument: flow(function* () {
      const canDeleteBE =
        self.documentType === DocumentType.ART_OF_INCORPORATION &&
        self.documentId &&
        self.documentContentId;

      self.uploading = true;
      self.uploaded = false;

      if (canDeleteBE) {
        self.submitting = true;
        const body = {
          type: self.documentType,
          documentId: self.documentId,
          documentContentId: self.documentContentId,
        };

        try {
          if (!self.service) return Promise.reject(errors.SERVICE_ERROR);

          yield self.service.deleteDocument(body);
        } catch (error) {
          //
        } finally {
          self.submitting = false;
          self.uploading = false;
        }
      } else {
        self.uploading = false;
      }
      return true;
    }),
    getDocumentStatus: flow(function* () {
      self.loadingStatus = true;
      try {
        if (!self.service) return Promise.reject('Error');
        const { data } = yield self.service.getDocumentStatus();
        const { kycOfficialDocuments } = data;
        self.setDocumentStatus(kycOfficialDocuments);
      } catch (error) {
        //
      } finally {
        self.loadingStatus = false;
      }
    }),
  }));

export interface TKycDocumentStore extends Instance<typeof KycDocumentStore> {}
export default KycDocumentStore;
