import { env as environment } from '@constants/variables';

export interface EventParams {
  action: string;
  category: string;
  label: string;
}

export interface EventValues {
  name: string;
  eventParams: Record<string, unknown>;
}

export type GetEventValues = (params: EventParams) => EventValues;

const getEventValues: GetEventValues = ({ action, category, label }) => ({
  name: `${category} ${label} ${action}`,
  eventParams: {
    environment,
    eventCategory: category,
    eventLabel: label,
    eventAction: action,
  },
});

export default getEventValues;
