import { AxiosRequestConfig } from 'axios';

export interface Headers {
  [name: string]: unknown;
}

export interface HttpConnections {
  [name: string]: AxiosRequestConfig;
}
export interface THeaders {
  headers?: Headers;
}

export interface HttpConfig extends Partial<THeaders> {
  default: keyof HttpConnections;
  defaultOptions: Partial<AxiosRequestConfig>;
  connections: HttpConnections;
}

export interface IGlobalConfig {
  config: HttpConfig;
  isConfigured: boolean;
}

export type CustomHttpConfig = Partial<HttpConfig>;

const config: HttpConfig = {
  default: 'api',
  defaultOptions: {
    timeout: 30000,
  },
  connections: {
    api: {
      baseURL: '/api',
    },
  },
};

if (!window.__oyster_client_factory) {
  Object.defineProperty(window, '__oyster_client_factory', {
    value: {
      config: config,
      isConfigured: false,
    },
  });
}

export const getOysterApiHeaders = (version?: string): THeaders => {
  if (version) {
    return { headers: { 'x-oyster-api': version } };
  }
  return {};
};

export default Object.freeze(config);
