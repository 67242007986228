import { FC } from 'react';
import { IconProps, oyster } from '../types';

const UploadIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C11.4477 20 11 19.5523 11 19L11 3.41423L4.70711 9.70711C4.31658 10.0976 3.68342 10.0976 3.29289 9.70711C2.90237 9.31658 2.90237 8.68342 3.29289 8.29289L11.2929 0.292894C11.6834 -0.0976303 12.3166 -0.0976321 12.7071 0.292894L20.7071 8.29289C21.0976 8.68342 21.0976 9.31658 20.7071 9.70711C20.3166 10.0976 19.6834 10.0976 19.2929 9.70711L13 3.4142L13 19C13 19.5523 12.5523 20 12 20ZM2 18C2 17.4477 1.55228 17 1 17C0.447715 17 0 17.4477 0 18V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V18C24 17.4477 23.5523 17 23 17C22.4477 17 22 17.4477 22 18V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V18Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

UploadIcon.defaultProps = {
  fill: oyster,
};

export default UploadIcon;
