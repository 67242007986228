import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import BankData from '@yaydoo/react-components/lib/components/BankData';
import Button from '@yaydoo/react-components/lib/components/FormButton';
import SendDataInput from '@yaydoo/react-components/lib/components/SendDataInput';
import ShareLink from '@yaydoo/react-components/lib/components/ShareLink';

import useStyles from './styles';

export interface IFundingRequest {
  totalAmount: string;
  reference: string;
  clabe: string;
  beneficiaryName: string;
  bank: string;
  onCopyBankInfo: () => void;
  url: string;
  sendButtonDisabled: boolean;
  sendButtonSubmitting: boolean;
  onCopyShareLink: () => void;
  onChangeEmailData: (value: string) => void;
  onSendEmail: () => void;
  emailsError: boolean;
  onSeeDetails: () => void;
  onCancel: () => void;
}

const FundingRequest: FC<IFundingRequest> = ({
  totalAmount,
  reference,
  clabe,
  beneficiaryName,
  bank,
  onCopyShareLink,
  url,
  sendButtonDisabled,
  sendButtonSubmitting,
  onCopyBankInfo,
  onChangeEmailData,
  onSendEmail,
  emailsError,
  onSeeDetails,
  onCancel,
}) => {
  const { t } = useTranslation();
  const css = useStyles();

  const bankDataList = [
    {
      label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.reference'),
      value: reference,
      onCopy: onCopyBankInfo,
    },
    {
      label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.clabe'),
      value: clabe,
      onCopy: onCopyBankInfo,
    },
    {
      label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.beneficiaryName'),
      value: beneficiaryName,
      onCopy: onCopyBankInfo,
    },
    {
      label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.bank'),
      value: bank,
      onCopy: onCopyBankInfo,
    },
  ];

  return (
    <div className={`${css.infoActionsContainer} ${css.fundingRequestContainer}`}>
      <div>
        <Typography className={css.totalAmountLabel} variant="h3">
          {t('batch.dashboard.proccessingBatch.stepper.fundingRequest.totalAmount')}
          <span>{totalAmount}</span>
        </Typography>
        <BankData className={css.bankData} bankDataList={bankDataList} />
        <p className={css.optionalText} />
        <Typography className={css.sharingLabel} variant="body2">
          {t('batch.dashboard.proccessingBatch.stepper.fundingRequest.shareLink')}
        </Typography>
        <ShareLink url={url} onCopy={onCopyShareLink} />
        <Typography className={css.sharingLabel} variant="body2">
          {t('batch.dashboard.proccessingBatch.stepper.fundingRequest.sendEmail')}
        </Typography>
        <Typography className={css.helpEmailFormat} variant="caption">
          <Trans i18nKey="batch.dashboard.proccessingBatch.stepper.fundingRequest.helpEmailFormat">
            <i />
          </Trans>
        </Typography>
        <SendDataInput
          inputProps={{
            label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.emailPlaceholder'),
            fullWidth: true,
            className: css.sendDataInput,
            onChange: (event) => onChangeEmailData(event.target.value),
            error: emailsError,
          }}
          buttonProps={{
            label: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.send'),
            disabled: sendButtonDisabled,
            onClick: onSendEmail,
            className: css.sendEmailButton,
            isSubmitting: sendButtonSubmitting,
          }}
        />
      </div>
      <div className={css.buttonsContainer}>
        <Button
          className={css.seeDetailsButton}
          variant="outlined"
          color="secondary"
          fullWidth
          label={t('batch.common.labels.seeDetails')}
          onClick={onSeeDetails}
        />
        <br />
        <br />
        <Button
          className={css.cancelButton}
          variant="text"
          fullWidth
          label={t('batch.common.labels.cancelOperation')}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default FundingRequest;
