import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const i18nInit = (): void => {
  if (i18n.isInitialized) return;

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'es',
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
      },
    });
};

export const addI18nNamespace = (lng: string, ns: string, resources: unknown): void => {
  if (i18n.hasResourceBundle(lng, ns)) {
    console.warn('Namespace already exists.');
    return;
  }
  i18n.addResourceBundle(lng, ns, resources);
};

export default i18nInit;
