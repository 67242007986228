import { locales } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

export const formatDate = (date: string): string => {
  if (date && date.trim() !== '') {
    if (date.split(' ').length === 2) {
      return date.split(' ')[0].split('-').reverse().join('-');
    }
  }
  return date;
};

export const formattedAmount = (value: number | string, currency: string): string =>
  Intl.NumberFormat(locales[currency], {
    style: 'currency',
    currency,
  }).format(+value);
