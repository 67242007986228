import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReturnButton from '@yaydoo/react-components/lib/components/BackPageButton';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';

import { ReactComponent as Success } from '@assets/images/csvGuide/success.svg';
import useStyles from './styles';

const FileUploadSuccess: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  return (
    <>
      <div className={css.header}>
        <ReturnButton
          onClick={closeDrawer}
          variant="dolphin"
          fontSize={20}
          className={css.return}
        />
        <Success className={css.image} />
      </div>
      <span className={css.title}>{t('collect.payments.csv.success')}</span>
      <span className={css.description}>{t('collect.payments.csv.successMsg')}</span>
    </>
  );
};

export default FileUploadSuccess;
