import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactElement, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Radio from '@yaydoo/react-components/lib/components/Radio';
import RightArrowIcon from '@yaydoo/react-components/lib/icons/CaretRight';

import useStyles from './styles';
import { IFilterComponent } from './types';

export interface IMobileFilters<T> extends IFilterComponent<T> {}

const MobileFilters = <T,>({
  filterLabel,
  filterName,
  currentFilterValue,
  filterOptions,
  onFilterChange,
}: IMobileFilters<T>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const css = useStyles();
  const arrowCls = clsx({ [css.arrowDown]: isOpen }, css.arrow);

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleOnChange =
    (callback: () => void, toggle = true) =>
    () => {
      toggle && toggleOpen();
      callback();
    };

  const linkFilters = (
    <div>
      {filterOptions.map(({ label, icon: Icon, value }) => (
        <Radio
          size="small"
          key={label}
          className={css.innerFilter}
          checked={currentFilterValue === value}
          onClick={handleOnChange(() => onFilterChange(value, filterName))}
          label={
            <span className={css.innerFilterOption}>
              <Icon className={css.icon} />
              {label}
            </span>
          }
          labelPlacement="end"
        />
      ))}
    </div>
  );

  return (
    <Accordion
      expanded={isOpen}
      className={css.mobileContainer}
      data-testid="mobile-filters"
      square
      elevation={0}
    >
      <AccordionSummary onClick={toggleOpen} classes={{ root: css.accordionSummary }}>
        <div className={css.filtersButton}>
          {filterLabel}
          <RightArrowIcon className={arrowCls} defaultColor />
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: css.accordionDetails }}>
        <div className={css.filtersMenu}>
          <div className={css.filtersOptions}>{linkFilters}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default observer(MobileFilters);
