import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CustomDrawer from '@components/CustomDrawer';
import StepperDrawer from '@components/StepperDrawer';

import FundingRequest, { IFundingRequest } from './FundingRequest';
import OperationInProcess, { IOperationInProcess } from './OperationInProcess';
import Validation, { IValidation } from './Validation';
import useStyles from './styles';

export interface IProcessingBatch extends IValidation, IFundingRequest, IOperationInProcess {
  batchReference: string;
  step: number | undefined;
  successDate: string;
  successTotalAmount: string;
  successOperations: number;
  errorDate: string;
  errorTotalAmount: string;
  errorOperations: number;
  onSeeDetails: () => void;
  onValidate: () => void;
  onCancel: () => void;
  totalAmount: string;
  reference: string;
  clabe: string;
  beneficiaryName: string;
  bank: string;
  url: string;
  sendButtonDisabled: boolean;
  sendButtonSubmitting: boolean;
  onCopyBankInfo: () => void;
  onCopyShareLink: () => void;
  onChangeEmailData: (value: string) => void;
  emailsError: boolean;
  onSendEmail: () => void;
  onDownloadReport: () => void;
  base64File: string;
  reportName: string;
  isSubmitting: boolean;
}

const ProcessingBatch: FC<IProcessingBatch> = ({ batchReference, step, ...restProps }) => {
  const css = useStyles();
  const { t } = useTranslation();
  const {
    successDate,
    successTotalAmount,
    successOperations,
    errorDate,
    errorTotalAmount,
    errorOperations,
    onSeeDetails,
    onValidate,
    onCancel,
    totalAmount,
    reference,
    clabe,
    beneficiaryName,
    bank,
    url,
    onCopyBankInfo,
    onCopyShareLink,
    onChangeEmailData,
    onSendEmail,
    emailsError,
    onDownloadReport,
    base64File,
    sendButtonDisabled,
    sendButtonSubmitting,
    isSubmitting,
    reportName,
  } = restProps;

  const Components = [
    {
      component: (
        <Validation
          successDate={successDate}
          successTotalAmount={successTotalAmount}
          successOperations={successOperations}
          errorDate={errorDate}
          errorTotalAmount={errorTotalAmount}
          errorOperations={errorOperations}
          onSeeDetails={onSeeDetails}
          onValidate={onValidate}
          onCancel={onCancel}
          isSubmitting={isSubmitting}
        />
      ),
    },
    {
      component: (
        <FundingRequest
          totalAmount={totalAmount}
          reference={reference}
          clabe={clabe}
          beneficiaryName={beneficiaryName}
          bank={bank}
          url={url}
          sendButtonDisabled={sendButtonDisabled}
          sendButtonSubmitting={sendButtonSubmitting}
          onCopyBankInfo={onCopyBankInfo}
          onCopyShareLink={onCopyShareLink}
          onChangeEmailData={onChangeEmailData}
          onSendEmail={onSendEmail}
          emailsError={emailsError}
          onSeeDetails={onSeeDetails}
          onCancel={onCancel}
        />
      ),
    },
    {
      component: (
        <OperationInProcess
          successDate={successDate}
          successTotalAmount={successTotalAmount}
          successOperations={successOperations}
          errorDate={errorDate}
          errorTotalAmount={errorTotalAmount}
          errorOperations={errorOperations}
          onSeeDetails={onSeeDetails}
        />
      ),
    },
    {
      component: (
        <OperationInProcess
          successDate={successDate}
          successTotalAmount={successTotalAmount}
          successOperations={successOperations}
          errorDate={errorDate}
          errorTotalAmount={errorTotalAmount}
          errorOperations={errorOperations}
          onDownloadReport={onDownloadReport}
          base64File={base64File}
          onSeeDetails={onSeeDetails}
          reportName={reportName}
        />
      ),
    },
  ];

  return (
    <CustomDrawer>
      <div className={css.drawerContent}>
        <Typography className={css.detailsLabel} variant="caption">
          {t('batch.dashboard.proccessingBatch.details')}
        </Typography>
        <Typography className={css.batchLabel} variant="h3">
          {t('batch.dashboard.proccessingBatch.batch')} <span>{batchReference}</span>
        </Typography>
        {step !== undefined && (
          <>
            <StepperDrawer
              className={css.stepperDrawer}
              steps={[
                { title: t('batch.dashboard.proccessingBatch.stepper.validation.title') },
                { title: t('batch.dashboard.proccessingBatch.stepper.fundingRequest.title') },
                { title: t('batch.dashboard.proccessingBatch.stepper.operationInProcess.title') },
                { title: t('batch.dashboard.proccessingBatch.stepper.operationCompleted.title') },
              ]}
              activeStep={step}
            />
            {Components[step].component}
          </>
        )}
      </div>
    </CustomDrawer>
  );
};

export default ProcessingBatch;
