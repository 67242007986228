import { Instance, flow, types } from 'mobx-state-tree';
import { AddressType } from './enums';
import Common from '../common';
import AddressService from './service';

const Colony = types.model({ colony: '' });

const AddressStore = types
  .compose(
    Common,
    types.model({
      addressId: '',
      type: types.union(
        types.literal(AddressType.UNKNOWN_ADDRESS),
        types.literal(AddressType.PERSONAL),
        types.literal(AddressType.HOME),
        types.literal(AddressType.LEGAL),
      ),
      city: '',
      state: '',
      country: '',
      line1: '',
      zipCode: '',
      municipality: '',
      colony: '',
      refId: '',
      formattedAddress: '',
      numeroInterior: '',
      numeroExterior: '',
      street: '',
      fetchedFromZipcode: false,
      colonyOptions: types.maybeNull(types.array(Colony)),
    }),
  )
  .views((self) => ({
    get service() {
      return new AddressService();
    },
    get getFormattedAddress() {
      return `${self.street} ${self.numeroExterior}, ${self.zipCode}, ${self.city}, ${self.state}`;
    },
    get getLine1() {
      return `${self.street} ${self.numeroExterior}`;
    },
    get canSubmit() {
      return (
        [
          self.street,
          self.zipCode,
          self.numeroExterior,
          self.city,
          self.state,
          self.municipality,
        ].every((field) => field?.trim().length) && self.zipCode.length === 5
      );
    },
  }))
  .actions((self) => ({
    update: (data: Partial<typeof self>) => {
      self.street = data.street || self.street;
      self.zipCode = data.zipCode || self.zipCode;
      self.numeroExterior = data.numeroExterior || self.numeroExterior;
      self.numeroInterior = data.numeroInterior || self.numeroInterior;
      self.colony = data.colony || self.colony;
      self.municipality = data.municipality || self.municipality;
      self.city = data.city || self.city;
      self.state = data.state || self.state;
      self.country = data.country || self.country;
      self.line1 = data.line1 || self.line1;
      self.formattedAddress = data.formattedAddress || self.formattedAddress;
      self.addressId = data.addressId || self.addressId;
    },
    clearZipFields: () => {
      self.colony = '';
      self.colonyOptions = null;
      self.municipality = '';
      self.state = '';
      self.city = '';
      self.fetchedFromZipcode = false;
    },
  }))
  .actions((self) => ({
    fetchAddressFromZip: flow(function* (postalCode) {
      try {
        self.clearZipFields();
        const { data } = yield self.service.fetchAddress({ postalCode });
        const addresses = data.addresses;

        if (addresses && addresses.length) {
          self.update(addresses[0]);
          self.fetchedFromZipcode = true;
          self.colonyOptions = addresses.map((adr: { colony: string }) => ({ colony: adr.colony }));
        }
      } catch (error) {
        //
      }
    }),
  }));

export type TAddressStore = Instance<typeof AddressStore>;
export interface IGetAddress extends Omit<TAddressStore, 'type'> {
  type: string | number;
}
export default AddressStore;
