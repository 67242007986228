import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';

import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';

import EmptyListSkeleton from '@components/EmptyListSkeleton';
import InfiniteScroll from '@components/InfiniteScroll';
import { IAccountsPayableBatchListItem } from '@store/batch/models/AccountsPayableBatchListItem';
import { formatDate } from '@utils/format';

import RightComponent from './RightComponent';
import useStyles from './styles';

const SecondaryText: FC<{ batch: IAccountsPayableBatchListItem }> = ({ batch }) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <span className={css.item}>
      <Box display="flex" flex={1} component={Grid}>
        <span data-testid="batch-title" className={css.itemTitle}>
          {t('batch.dashboard.batchList.batch', { batch: batch.id })}
        </span>
      </Box>
      <span data-testid="batch-createdAt" className={css.itemCreatedAt}>
        {t('batch.dashboard.batchList.createdAt', { createdAt: formatDate(batch.createdAt) })}
      </span>
    </span>
  );
};

export interface IBatchList {
  isFetching: boolean;
  batchList: IAccountsPayableBatchListItem[];
  hasMore: boolean;
  onClickBatch: (batchItem: IAccountsPayableBatchListItem) => void;
  onCancel: (batchItem: IAccountsPayableBatchListItem) => void;
  onSeeDetails: (batchItem: IAccountsPayableBatchListItem) => void;
  getMoreBatchs: () => void;
}

const BatchList: FC<IBatchList> = ({
  isFetching,
  batchList,
  hasMore,
  onClickBatch,
  onCancel,
  onSeeDetails,
  getMoreBatchs,
}) => {
  const css = useStyles();
  const { t } = useTranslation();
  const isEmptyList = !batchList.length;

  if (isEmptyList) {
    return (
      <EmptyListSkeleton
        label={t('batch.dashboard.batchList.emptyStateLabel')}
        loadingLabel={t('batch.dashboard.batchList.loadingLabel')}
        isLoading={isFetching}
      />
    );
  }

  return (
    <div className={css.root}>
      <InfiniteScroll
        next={getMoreBatchs}
        hasMore={hasMore}
        dataLength={batchList?.length}
        scrollableTarget="list-container"
      >
        <List className={css.list}>
          {batchList.map((batchItem) => (
            <CustomRow
              className={css.customRow}
              data-testid="batch-list-item"
              key={batchItem.id}
              onClick={() => onClickBatch(batchItem)}
              secondaryTextComponent={<SecondaryText batch={batchItem} />}
              rightComponent={
                <RightComponent
                  batch={batchItem}
                  onCancel={() => onCancel(batchItem)}
                  onSeeDetails={() => onSeeDetails(batchItem)}
                />
              }
            />
          ))}
        </List>
      </InfiniteScroll>
    </div>
  );
};

export default BatchList;
