import { changeFileToBase64 } from '@utils/changeFileToBase64';
import { types, flow } from 'mobx-state-tree';
import { CobrandingData, defaultTheme } from '@constants/cobranding';
import { removeEmptyKey } from '@utils/removeEmptyKey';

const Flags = types.model({
  isSaving: false,
  isFetching: false,
  isFetched: false,
});

const Cobranding = types
  .model({
    contactInfo: types.optional(
      types.model({
        email: types.maybe(types.string),
        phone: types.maybe(types.string),
      }),
      {},
    ),
    theme: types.optional(
      types.model({
        primaryColor: defaultTheme.primary,
        secondaryColor: defaultTheme.secondary,
      }),
      {},
    ),
    assets: types.optional(
      types.model({
        logo: types.maybe(types.string),
        favicon: types.maybe(types.string),
      }),
      {},
    ),
    flags: types.optional(Flags, {}),
  })
  .actions(() => ({
    toJson: flow(function* (data: CobrandingData) {
      const logo =
        data?.assets?.logo instanceof File
          ? yield changeFileToBase64(data.assets.logo)
          : data.assets.logo;

      const favicon =
        data?.assets?.favicon instanceof File
          ? yield changeFileToBase64(data.assets.favicon)
          : data.assets.favicon;

      return JSON.stringify({
        contactInfo: removeEmptyKey(data.contactInfo),
        theme: removeEmptyKey(data.theme),
        assets: removeEmptyKey({
          logo,
          favicon,
        }),
      });
    }),
  }));

export default Cobranding;
