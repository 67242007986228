import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginLeft: '1em',
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      color: theme.oysterTheme.pine,
      '& svg': {
        marginLeft: '0.5em',
        color: theme.oysterTheme.brandGreen,
      },
    },
    arrow: {
      transition: 'transform 0.2s',
    },
    arrowDown: {
      transform: 'rotate(90deg)',
    },
    arrowUp: {
      transform: 'rotate(-90deg)',
    },
    arrowLeft: {
      transform: 'rotate(180deg)',
    },
    menu: {
      overflowY: 'visible',
      overflowX: 'visible',
    },
    dropdownArrow: {
      position: 'absolute',
      fontSize: 7,
      margin: 'auto',
      display: 'block',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderWidth: '1em',
      width: '1em',
      height: '1em',
    },
    bottom: {
      top: 0,
      left: 0,
      right: 0,
      height: '3em',
      marginTop: '-3em',
      borderTopWidth: 0,
      borderBottomColor: theme.oysterTheme.cotton,
    },
    top: {
      bottom: 0,
      left: 0,
      right: 0,
      height: '3em',
      marginBottom: '-3em',
      borderBottomWidth: 0,
      borderTopColor: theme.oysterTheme.cotton,
    },
    left: {
      bottom: 0,
      top: 0,
      right: 0,
      width: '3em',
      marginRight: '-3em',
      borderRightWidth: 0,
      borderLeftColor: theme.oysterTheme.cotton,
    },
    right: {
      bottom: 0,
      top: 0,
      left: 0,
      width: '3em',
      marginLeft: '-3em',
      borderLeftWidth: 0,
      borderRightColor: theme.oysterTheme.cotton,
    },
  });

export default makeStyles(styles);
