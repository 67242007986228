import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ConfirmationModal from '@yaydoo/react-components/lib/components/ConfirmationModal';
import OutlinedButton from '@yaydoo/react-components/lib/components/OutlinedButton';
import SecurityOptions from '@yaydoo/react-components/lib/icons/SecurityOptions';
import CheckboxFilled from '@yaydoo/react-components/lib/icons/CheckboxFilled';

import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import useModal from '@yaydoo/react-components/lib/hooks/useModal';

import useStore from '@hooks/useStore';

import useStyles from '../styles';

const EnableDisableWebhook: FC = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { showModal } = useModal();
  const { webhooks } = useStore();
  const { businessWebhooks, updateWebhook, flags } = webhooks;
  const { enableSuccess, disableSuccess, updateField } = flags;

  const css = useStyles();

  const isWebhookActive = businessWebhooks[0]?.isActive;

  const handleEnabledDisabledWebhook = async () => {
    await updateWebhook(
      {
        webhookSubscriptionId: businessWebhooks[0]?.webhookSubscriptionId,
        isActive: !isWebhookActive,
        fieldMask: 'isActive',
      },
      isWebhookActive ? 'disabling' : 'enabling',
    );
  };

  useEffect(() => {
    if (enableSuccess) {
      openToast({
        type: 'success',
        title: t('paymentTools:tools.webhooks.enableDisable.enableSuccess.title'),
        message: t('paymentTools:tools.webhooks.enableDisable.enableSuccess.msg'),
      });
      updateField('enableSuccess', false);
    }

    if (disableSuccess) {
      openToast({
        type: 'success',
        title: t('paymentTools:tools.webhooks.enableDisable.disableSuccess.title'),
        message: t('paymentTools:tools.webhooks.enableDisable.disableSuccess.msg'),
        customIcon: () => <SecurityOptions className={css.disableIcon} />,
      });
      updateField('disableSuccess', false);
    }
  }, [enableSuccess, disableSuccess]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={css.text}>
          {t('paymentTools:tools.webhooks.enableDisable.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OutlinedButton
          label={
            isWebhookActive
              ? t('paymentTools:tools.webhooks.enableDisable.disable')
              : t('paymentTools:tools.webhooks.enableDisable.enable')
          }
          leftIcon={isWebhookActive ? SecurityOptions : CheckboxFilled}
          onClick={() =>
            showModal({
              component: () => (
                <ConfirmationModal
                  showSubmitLoader
                  onConfirm={() => handleEnabledDisabledWebhook()}
                  title={
                    isWebhookActive
                      ? t('paymentTools:tools.webhooks.enableDisable.disableConfirmation.title')
                      : t('paymentTools:tools.webhooks.enableDisable.enableConfirmation.title')
                  }
                  message={
                    isWebhookActive
                      ? t('paymentTools:tools.webhooks.enableDisable.disableConfirmation.msg')
                      : t('paymentTools:tools.webhooks.enableDisable.enableConfirmation.msg')
                  }
                  confirmLabelButton={
                    isWebhookActive
                      ? t('paymentTools:tools.webhooks.enableDisable.disable')
                      : t('paymentTools:tools.webhooks.enableDisable.enable')
                  }
                  cancelLabelButton={t(
                    'paymentTools:tools.webhooks.enableDisable.disableConfirmation.cancel',
                  )}
                />
              ),
            })
          }
        />
      </Grid>
    </Grid>
  );
};

export default observer(EnableDisableWebhook);
