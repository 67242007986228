export enum TicketStatus {
  UNKNOWN = ' ',
  PENDING = 'PENDING',
  PAID = 'PAID',
  SETTLED = 'SETTLED',
  REFUNDED = 'REFUNDED',
  ERROR = 'ERROR',
}

export enum PaymentOrderStatus {
  UNKNOWN = 'UNKNOWN',
  CREATED = 'CREATED',
  PAYMENT_CONFIRMATION_RECEIVED = 'PAYMENT_CONFIRMATION_RECEIVED',
  AWAITING_DISBURSEMENT = 'AWAITING_DISBURSEMENT',
  SETTLED = 'SETTLED',
  REFUNDED = 'REFUNDED',
  PAID = 'PAID',
  ERROR = 'ERROR',
  MARKED_AS_PAID = 'MARKED_AS_PAID',
}

export enum PaymentOrderFilterStatus {
  ALL = '',
  PAID = 'PAID',
}

export enum PaymentOrderPaymentMethods {
  CARD_PAYMENT = 'CARD_PAYMENT',
  CASH_PAYMENT = 'CASH_PAYMENT',
  SPEI_PAYMENT = 'SPEI_PAYMENT',
  UNKNOWN = '',
}

// This conversion is done in react-mobx-stores creditCommon
export enum KycStatus {
  UNKNOWN_KYC_STATUS,
  KYC_COMPLETED,
  ADDITIONAL_KYC_REQUIRED,
  NO_KYC_FOUND,
  KYC_EXPIRED,
  KYC_VERIFICATION_PENDING,
}

export const PaymentMethods = {
  [`${PaymentOrderPaymentMethods.CARD_PAYMENT}`]: 'Tarjeta',
  [`${PaymentOrderPaymentMethods.CASH_PAYMENT}`]: 'Efectivo',
  [`${PaymentOrderPaymentMethods.SPEI_PAYMENT}`]: 'SPEI',
  [`${PaymentOrderPaymentMethods.UNKNOWN}`]: 'Unknown',
} as const;

export default {
  [`${PaymentOrderStatus.UNKNOWN}`]: TicketStatus.UNKNOWN,
  [`${PaymentOrderStatus.CREATED}`]: TicketStatus.PENDING,
  [`${PaymentOrderStatus.PAYMENT_CONFIRMATION_RECEIVED}`]: TicketStatus.PAID,
  [`${PaymentOrderStatus.AWAITING_DISBURSEMENT}`]: TicketStatus.PAID,
  [`${PaymentOrderStatus.SETTLED}`]: TicketStatus.SETTLED,
  [`${PaymentOrderStatus.REFUNDED}`]: TicketStatus.REFUNDED,
  [`${PaymentOrderStatus.ERROR}`]: TicketStatus.ERROR,
  [`${PaymentOrderStatus.MARKED_AS_PAID}`]: TicketStatus.PAID,
} as const;

export const PaymentOrderRefundableStatus = {
  AWAITING_DISBURSEMENT: 'AWAITING_DISBURSEMENT',
  PAYMENT_CONFIRMATION_RECEIVED: 'PAYMENT_CONFIRMATION_RECEIVED',
  SETTLED: 'SETTLED',
};
