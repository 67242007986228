import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = () => {
  return createStyles({
    loadingContainer: {
      display: 'flex',
      maxWidth: '100vw',
      padding: '0 2em',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1em',
      fontSize: '14px',
      '& h3': {
        fontSize: '16px',
      },
    },
    progress: {
      marginTop: '100px',
    },
  });
};

export default makeStyles(styles);
