export const DATE_FORMAT_V1 = 'DD/MM/YYYY';
export const DATE_FORMAT_V2 = 'DD/MM/YY';
export const DATE_FORMAT_V3 = 'YYYY-MM-DD';
export const DATE_FORMAT_FORM = 'dd/MM/yyyy';
export const DATE_FORMAT_FORM_V2 = 'yyyy-MM-dd';
export const DATE_FORMAT_24h = 'DD/MM/yyyy HH:mm';
export const DATE_FORMAT_12H = 'DD/MM/YYYY, hh:mm A';
export const TIME_FORMAT = 'HH:mm';

export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
