import { FC, MouseEvent, useCallback, useState, ReactNode } from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CaretRightIcon from '@icons/CaretRight';
import useStyles from './styles';

export interface ISectionOption {
  label: string;
  value: string | number;
  id?: string | number;
}

export interface ISectionHeaderV2 {
  title: string;
  description?: string;
  isBoldTitle?: boolean;
  isLightTitle?: boolean;
  actionMode?: 'menu' | 'button' | 'custom';
  actionDisabled?: boolean;
  options?: ISectionOption[];
  actionLabel?: string;
  hideBorder?: boolean;
  customActionElement?: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>, value?: string | number) => void;
  id: string;
  buttonId?: string;
}

const SectionHeaderV2: FC<ISectionHeaderV2> = ({
  id,
  title,
  description,
  onClick,
  options,
  actionLabel,
  customActionElement,
  actionMode,
  buttonId,
  actionDisabled = false,
  isBoldTitle = false,
  isLightTitle = false,
  hideBorder = false,
}) => {
  const css = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuMode = actionMode === 'menu';
  const isOpen = Boolean(anchorEl);

  const onActionClick = (event: MouseEvent<HTMLElement>, value?: string | number) => {
    onClick && onClick(event, value);
  };

  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = (event: MouseEvent<HTMLElement>, value: string | number) => {
    onActionClick(event, value);
    setAnchorEl(null);
  };

  const renderMenuItems = useCallback(() => {
    return options?.map((item) => (
      <MenuItem
        data-testid={`${id}-header-menu-${item.value}`}
        key={`${id}-header-menu-${item.value}`}
        className={css.menuItem}
        onClick={(event) => onMenuClose(event, item.value)}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    ));
  }, [options]);

  const actionComponent = useCallback(() => {
    switch (actionMode) {
      case 'menu':
      case 'button':
        return (
          <Button
            data-testid={`${id}-header-btn`}
            aria-controls={`${id}-header-menu`}
            aria-haspopup="true"
            onClick={isMenuMode ? onMenuClick : (event) => onActionClick(event)}
            className={clsx(css.actionBtn, isMenuMode && 'menu')}
            disabled={actionDisabled}
            id={buttonId}
          >
            {actionLabel}{' '}
            <small>
              <CaretRightIcon />
            </small>
          </Button>
        );
      case 'custom':
        return customActionElement;
      default:
        return null;
    }
  }, [id, customActionElement, actionLabel, actionDisabled, actionMode, onClick, buttonId]);

  return (
    <Grid
      id={id}
      data-testid="section-header-cont"
      className={clsx(css.container, hideBorder && 'hideBorder')}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={actionMode ? 8 : 12} data-testid="title-section">
        <Typography
          className={clsx(css.title, isBoldTitle && 'boldTitle', isLightTitle && 'lightTitle')}
          variant="h3"
        >
          {title}
        </Typography>
        {description && <Typography className={css.description}>{description}</Typography>}
      </Grid>
      {actionMode && (
        <Grid item xs={4} className={css.actionCont} data-testid="action-section">
          {actionComponent()}
        </Grid>
      )}
      {/* keeping this here so the menu is rendered before the action component, to find anchor Element */}
      {isMenuMode && options?.length && (
        <Menu
          data-testid={isOpen ? `${id}-header-menu-open` : `${id}-header-menu`}
          id={`${id}-header-menu`}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onMenuClose}
          TransitionComponent={Fade}
          classes={{ paper: css.menu }}
          keepMounted
        >
          {renderMenuItems()}
        </Menu>
      )}
    </Grid>
  );
};

export default SectionHeaderV2;
