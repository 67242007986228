import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    header: {
      height: 380,
      marginBottom: theme.spacing(2),
      backgroundColor: theme.oysterTheme.pearl,
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      display: 'block',
      margin: 'auto',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      display: 'block',
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: theme.spacing(2.5),
    },
    description: {
      fontSize: 14,
      display: 'block',
      textAlign: 'center',
    },
    return: {
      paddingTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2.3),
      width: 'fit-content',
    },
  });
};

export default makeStyles(styles);
