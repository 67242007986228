import { useReducer } from 'react';

export const getCurrentTime = (): number => new Date().getTime() / 1000;

export const initialState = {
  modal: false,
  interval: undefined,
  timeout: undefined,
  userInactive: false,
  seconds: 1,
  logoutTimestamp: getCurrentTime(),
};

interface ActivityTrackerState {
  modal: boolean;
  interval?: number;
  timeout?: number;
  userInactive: boolean;
  seconds: number;
  logoutTimestamp: number;
}

enum ActionTypes {
  SET_MODAL = 'SET_MODAL',
  SET_INTERVAL = 'SET_INTERVAL',
  CLEAR_INTERVAL = 'CLEAR_INTERVAL',
  SET_TIMEOUT = 'SET_TIMEOUT',
  CLEAR_TIMEOUT = 'CLEAR_TIMEOUT',
  SET_USER_INACTIVE = 'SET_USER_INACTIVE',
  SET_SECONDS = 'SET_SECONDS',
  SET_LOGOUT_TIMESTAMP = 'SET_LOGOUT_TIMESTAMP',
}

interface SetModalAction {
  type: ActionTypes.SET_MODAL;
  payload: boolean;
}

interface SetIntervalAction {
  type: ActionTypes.SET_INTERVAL;
  payload: number;
}

interface ClearIntervalAction {
  type: ActionTypes.CLEAR_INTERVAL;
}

interface SetUserInactiveAction {
  type: ActionTypes.SET_USER_INACTIVE;
  payload: boolean;
}

interface SetSecondsAction {
  type: ActionTypes.SET_SECONDS;
  payload: number;
}

interface SetLogoutTimestampAction {
  type: ActionTypes.SET_LOGOUT_TIMESTAMP;
  payload: number;
}

interface SetTimeoutAction {
  type: ActionTypes.SET_TIMEOUT;
  payload: number;
}

interface ClearTimeoutAction {
  type: ActionTypes.CLEAR_TIMEOUT;
}

type Action =
  | SetModalAction
  | SetIntervalAction
  | ClearIntervalAction
  | SetUserInactiveAction
  | SetSecondsAction
  | SetLogoutTimestampAction
  | SetTimeoutAction
  | ClearTimeoutAction;

function reducer(state: ActivityTrackerState, action: Action): ActivityTrackerState {
  switch (action.type) {
    case ActionTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case ActionTypes.SET_INTERVAL:
      return {
        ...state,
        interval: action.payload,
      };
    case ActionTypes.CLEAR_INTERVAL:
      return {
        ...state,
        interval: undefined,
      };
    case ActionTypes.SET_USER_INACTIVE:
      return {
        ...state,
        userInactive: action.payload,
      };
    case ActionTypes.SET_SECONDS:
      return {
        ...state,
        seconds: action.payload,
      };
    case ActionTypes.SET_LOGOUT_TIMESTAMP:
      return {
        ...state,
        logoutTimestamp: action.payload,
      };
    case ActionTypes.SET_TIMEOUT:
      return {
        ...state,
        timeout: action.payload,
      };
    case ActionTypes.CLEAR_TIMEOUT:
      return {
        ...state,
        timeout: undefined,
      };
    default:
      return state;
  }
}

interface UseATStateState {
  state: ActivityTrackerState;
  setModal: (state: boolean) => void;
  resetInterval: () => void;
  setIntervalId: (intervalId: number) => void;
  setUserInactive: (state: boolean) => void;
  setSeconds: (seconds: number) => void;
  setLogoutTimestamp: (seconds: number) => void;
  resetTimeout: () => void;
  setTimeoutId: (intervalId: number) => void;
}

export default function useATState(): UseATStateState {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setModal = (state: boolean) => dispatch({ type: ActionTypes.SET_MODAL, payload: state });
  const setIntervalId = (intervalId: number) =>
    dispatch({ type: ActionTypes.SET_INTERVAL, payload: intervalId });
  const resetInterval = () => dispatch({ type: ActionTypes.CLEAR_INTERVAL });
  const setUserInactive = (state: boolean) =>
    dispatch({ type: ActionTypes.SET_USER_INACTIVE, payload: state });
  const setSeconds = (seconds: number) =>
    dispatch({ type: ActionTypes.SET_SECONDS, payload: seconds });
  const setLogoutTimestamp = (seconds: number) =>
    dispatch({ type: ActionTypes.SET_LOGOUT_TIMESTAMP, payload: seconds });
  const resetTimeout = () => dispatch({ type: ActionTypes.CLEAR_TIMEOUT });
  const setTimeoutId = (intervalId: number) =>
    dispatch({ type: ActionTypes.SET_TIMEOUT, payload: intervalId });

  return {
    state,
    resetInterval,
    setModal,
    setIntervalId,
    setUserInactive,
    setSeconds,
    setLogoutTimestamp,
    resetTimeout,
    setTimeoutId,
  };
}
