import { FC } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

export interface IOperationsResume {
  success?: boolean;
  title: string;
  date: string;
  totalOperations: string | number;
  totalAmount: string;
}

const OperationsResume: FC<IOperationsResume> = ({
  success = false,
  title,
  totalOperations,
  date,
  totalAmount,
}) => {
  const css = useStyles({ success });
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h3" className={css.title}>
        {title}
      </Typography>
      <div className={css.dataContainer}>
        <div className={css.item}>
          <Typography variant="caption" className={css.header}>
            {t('batch.dashboard.proccessingBatch.totalOperations')}
          </Typography>
          <Typography variant="caption" className={css.value}>
            {totalOperations}
          </Typography>
        </div>
        <div className={css.item}>
          <Typography variant="caption" className={css.header}>
            {t('batch.dashboard.proccessingBatch.date')}
          </Typography>
          <Typography variant="caption" className={css.value}>
            {date}
          </Typography>
        </div>
        <div className={css.item}>
          <Typography variant="caption" className={css.header}>
            {t('batch.dashboard.proccessingBatch.totalAmount')}
          </Typography>
          <Typography variant="caption" className={css.totalAmount}>
            {totalAmount}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default OperationsResume;
