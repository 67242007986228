import { Instance, types } from 'mobx-state-tree';

const CanProcessStore = types.model({
  accountPayableId: '',
  paymentId: '',
  refNumber: '',
  businessName: '',
  amount: types.optional(types.union(types.number, types.string), ''),
  currency: '',
  clabe: '',
});

export interface TCanProcessStore extends Instance<typeof CanProcessStore> {}
export default CanProcessStore;
