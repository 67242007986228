import { format, parse } from 'date-fns';

import { PaymentLink, PaymentLinkForm } from '@store/links/types';
import { IPaymentLink } from '@store/links/models/Link';
import { DATE_FORMAT_FORM, DATE_FORMAT_FORM_V2 } from '@constants/date';
import { PaymentLinkType } from '@constants/paymentLink';

const castPaymentLink = (data: PaymentLink | IPaymentLink, useCents = true): PaymentLink => {
  const base = useCents ? 100 : 1;
  const body: PaymentLink = {
    title: data.title,
    total: Math.trunc(+data.total * base),
    payeeName: data.payeeName,
    payeeEmail: data.payeeEmail,
    type: data.type as PaymentLinkType,
    useLimit: +data.useLimit,
    linkItems: data.linkItems.map((item) => ({
      name: item.name,
      quantity: +item.quantity,
      price: item.price ? Math.trunc(+item.price * base) : 0,
    })),
  };

  if (data.dueDate) {
    body.dueDate = format(parse(data.dueDate, DATE_FORMAT_FORM, new Date()), DATE_FORMAT_FORM_V2);
  }

  return body;
};

export const castPaymentLinkForm = (data: PaymentLinkForm): PaymentLink => {
  return castPaymentLink({
    ...data,
    dueDate: data.hasDueDate ? format(data.dueDate, DATE_FORMAT_FORM) : null,
    useLimit: data.type === PaymentLinkType.SINGLE_PAYMENT ? 1 : data.useLimit || 0,
  });
};

export default castPaymentLink;
