import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      marginTop: 14,
      display: 'flex',
      alignItems: 'center',
    },
    urlInput: {
      '& fieldset': { border: `1px solid ${theme.oysterTheme.stone} !important` },
    },
    iconContainer: {
      marginLeft: '10px',
      minWidth: '48px',
      height: '48px',
      border: `1px solid ${theme.oysterTheme.stone}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
    },
    iconButton: {
      color: theme.oysterTheme.pine,
    },
  }),
);

export default useStyles;
