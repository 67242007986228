export enum TicketStatus {
  UNKNOWN = 'UNKNOWN',
  PENDING = 'PENDING',
  PAID = 'PAID',
  SETTLED = 'SETTLED',
  CLOSED = 'CLOSED',
  REFUNDED = 'REFUNDED',
  ERROR = 'ERROR',
}

export enum PaymentLinkFilterStatus {
  ALL = '',
  PENDING = 'PENDING',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  // TODO: Check if this filter exists and remove comment
  DEPOSITED = 'DEPOSITED',
}

export enum PaymentLinkType {
  SINGLE_PAYMENT = 'SINGLE_PAYMENT',
  MULTI_PAYMENT = 'MULTI_PAYMENT',
}

export enum PaymentLinkStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  PAYMENT_CONFIRMATION_RECEIVED = 'PAYMENT_CONFIRMATION_RECEIVED',
  AWAITING_DISBURSEMENT = 'AWAITING_DISBURSEMENT',
  SETTLED = 'SETTLED',
  REFUNDED = 'REFUNDED',
  ERROR = 'ERROR',
}

export const KYC_PRODUCT_TYPE = 'paymentLink';

export default {
  [`${PaymentLinkStatus.UNKNOWN}`]: TicketStatus.UNKNOWN,
  [`${PaymentLinkStatus.ACTIVE}`]: TicketStatus.PENDING,
  [`${PaymentLinkStatus.CLOSED}`]: TicketStatus.CLOSED,
  [`${PaymentLinkStatus.PAYMENT_CONFIRMATION_RECEIVED}`]: TicketStatus.PAID,
  [`${PaymentLinkStatus.AWAITING_DISBURSEMENT}`]: TicketStatus.PAID,
  [`${PaymentLinkStatus.SETTLED}`]: TicketStatus.SETTLED,
  [`${PaymentLinkStatus.REFUNDED}`]: TicketStatus.REFUNDED,
  [`${PaymentLinkStatus.ERROR}`]: TicketStatus.ERROR,
} as const;

export const PAYMENT_LINK_CARD_CAP = 100;
export const PAYMENT_LINK_MIN_ALLOWED_AMOUNT = 40;
export const PAYMENT_LINK_MIN_ALLOWED_AMOUNT_CENTS = 4000;
export const CSV_MAX_MONTH_RANGE_EXPORT = 3;
