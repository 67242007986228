import { ReactElement } from 'react';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { TableColumn, DataPropsItemType } from './types';
import useStyles from './styles';

export interface TableHeaderProps<T extends DataPropsItemType> {
  columns: TableColumn<T>[];
}

const TableHeader = <T extends DataPropsItemType>({
  columns,
}: TableHeaderProps<T>): ReactElement => {
  const css = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => {
          const { name, align, label = 'left' } = column;
          return (
            <TableCell key={`${name}-${idx}`} className={css.tableHeaderCell} align={align}>
              {label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
