import { types, Instance } from 'mobx-state-tree';

export const InvitedUserType = types.model({
  email: types.string,
  firstName: types.string,
  middleName: types.string,
  lastName: types.string,
  role: types.string,
  expirationDate: types.string,
  status: types.string,
  createdAt: types.string,
  updatedAt: types.string,
});

export interface IInvitedUser extends Instance<typeof InvitedUserType> {}

export const AssociatedUserType = types.model({
  userId: types.string,
  email: types.string,
  firstName: types.string,
  middleName: types.string,
  lastName: types.string,
  role: types.string,
  active: types.maybe(types.boolean),
  createdAt: types.string,
  updatedAt: types.string,
});

export interface IAssociatedUser extends Instance<typeof AssociatedUserType> {}

export const UserFormType = types.model({
  email: types.string,
  firstName: types.string,
  middleName: types.string,
  lastName: types.string,
  role: types.string,
});

export interface IUserForm extends Instance<typeof UserFormType> {}
