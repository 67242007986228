import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      padding: '20px 0',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      padding: '10px 0',
      flexDirection: 'column',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: 14,
      width: '100%',
      display: 'flex',
      padding: '0 25px',
      flexDirection: 'column',
    },
    body: {
      fontSize: 16,
      width: '100%',
      display: 'flex',
      padding: '32px 25px',
      flexDirection: 'column',
    },
    footer: {
      width: '100%',
      margin: '8px 0',
      display: 'flex',
      padding: '0 25px',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.oysterTheme.pearl,
    },
    footerContainer: {
      fontSize: 16,
      width: '100%',
      display: 'flex',
      padding: '13px 0',
      flexDirection: 'column',
    },
    bodyInfoRow: {
      padding: '8px 0',
    },
    footerRow: {
      padding: '13px 0',
    },
    footerTitle: {
      fontSize: 14,
    },
    amountInfoContainer: {
      padding: '8px 0',
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
    },
    totalContainer: {
      padding: '8px 0',
    },
    dateContainer: {
      display: 'flex',
      padding: '8px 0',
    },
    amountContainer: {
      display: 'flex',
    },
    memoContainer: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.oysterTheme.dolphin,
    },
    currencySymbol: {
      fontSize: 24,
    },
    amountText: {
      fontSize: 32,
      fontWeight: 700,
    },
    memoText: {
      fontWeight: 500,
    },
    footerName: {
      fontSize: 12,
    },
    paidLabel: {
      fontSize: 12,
      color: theme.oysterTheme.water,
    },
    textMargin: {
      marginRight: 8,
    },
    alignRight: {
      textAlign: 'right',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
    },
    insufficientBalanceText: {
      fontSize: 14,
      color: theme.oysterTheme.shark,
    },
    errorText: {
      color: theme.oysterTheme.ferrari,
    },
    buttonContainer: {
      marginTop: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
    },
  });
};

export default makeStyles(styles);
