import { types, cast, Instance, flow } from 'mobx-state-tree';
import debug from 'debug';

import { SETTINGS_TYPES, REMINDERS } from '@constants/settings';
import { ISetting } from './types';

import Errors from './models/Errors';
import Notification, { RemindersType } from './models/Notification';
import Invoices from './models/Invoices';
import SettingsService from './service';
import Cobranding from './models/Cobranding';
import { CobrandingData } from '@constants/cobranding';

const error = debug('PaymentSettingsStore:error');

const SettingsStore = types
  .model({
    notification: types.optional(Notification, {}),
    invoices: types.optional(Invoices, {}),
    cobranding: types.optional(Cobranding, {}),
    errors: types.optional(Errors, {}),
  })
  .volatile((): { service: SettingsService | null } => ({ service: null }))
  .views((self) => ({
    get SettingsService() {
      if (!self.service) throw new Error('No service found');
      return self.service;
    },
  }))
  .actions((self) => ({
    updateService: () => {
      self.service = new SettingsService();
    },
    setSettings(data: ISetting) {
      const setting = JSON.parse(data.value) || {};
      const reminders: RemindersType[] = cast(setting.config || []);
      self.notification.enabled = cast(setting.enabled || false);
      self.notification.reminders = cast(reminders);
      self.notification.stringValue = data.value;
    },
  }))
  .actions((self) => ({
    fetchReminderSettings: flow(function* () {
      try {
        self.notification.isLoading = true;

        const { data } = yield self.SettingsService.getPaymentLinkReminders();

        self.setSettings(data.setting);
      } catch (e) {
        error(e);
      } finally {
        self.notification.isLoading = false;
      }
    }),
    toggleReminders: () => {
      self.notification.enabled = !self.notification.enabled;
    },
    getDropDownOptions(currentReminder: RemindersType) {
      return REMINDERS.filter((reminder) =>
        [...self.notification.availableReminders, currentReminder].includes(reminder),
      );
    },
    addReminder: () => {
      if (self.notification.availableReminders.length > 0) {
        const availableReminder = self.notification.availableReminders[0];
        self.notification.reminders = cast([...self.notification.reminders, availableReminder]);
      }
    },
    removeReminder: (reminderToRemove: RemindersType) => {
      self.notification.reminders = cast(
        self.notification.reminders.filter((reminder) => reminder !== reminderToRemove),
      );
    },
    selectReminder: (newReminder: RemindersType, index: number) => {
      const tmpReminders = [...self.notification.reminders];
      tmpReminders.splice(index, 1, newReminder);
      self.notification.reminders = cast(tmpReminders);
    },
    saveReminderSettings: flow(function* () {
      const body = {
        type: SETTINGS_TYPES.PAYMENT_LINK_REMINDERS,
        value: JSON.stringify({
          enabled: self.notification.enabled,
          config: self.notification.reminders,
        }),
      };

      const { data } = yield self.SettingsService.saveReminderSettings(body);

      if (data.error) {
        throw new Error();
      }

      self.setSettings(data.setting);
    }),
    saveCobranding: flow(function* (data: CobrandingData) {
      const body = {
        type: SETTINGS_TYPES.COBRANDING,
        value: yield self.cobranding.toJson(data),
      };

      yield self.SettingsService.saveCobrandingSettings(body);
    }),
    fetchCobranding: flow(function* () {
      try {
        self.cobranding.flags.isFetched = false;
        self.cobranding.flags.isFetching = true;
        const { data } = yield self.SettingsService.getCobrandingSettings();

        self.cobranding = data?.setting ? JSON.parse(data?.setting?.value) : {};
        self.cobranding.flags.isFetched = true;
      } catch (e) {
        error(e);
      } finally {
        self.cobranding.flags.isFetching = false;
      }
    }),
  }));

export interface SettingsStoreType extends Instance<typeof SettingsStore> {}
export default SettingsStore;
