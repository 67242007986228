import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OutlinedButton from '@yaydoo/react-components/lib/components/OutlinedButton';
import Marketing from '@yaydoo/react-components/lib/icons/Marketing';
import CircularProgress from '@material-ui/core/CircularProgress';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import useCommonStore from '@hooks/useCommonStore';
import useStore from '@hooks/useStore';

import useStyles from '../styles';
import SecurityOptions from '@yaydoo/react-components/lib/icons/SecurityOptions';

const SendNotificationToWebhook: FC = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { business, user } = useCommonStore();
  const { webhooks } = useStore();
  const { businessWebhooks, sendNotificationWebhook, flags } = webhooks;
  const {
    isSendingNotification,
    sendingNotificationSuccess,
    sendingNotificationError,
    updateField,
  } = flags;

  const css = useStyles();

  const isWebhookActive = businessWebhooks[0]?.isActive;

  const handleSendNotification = () => {
    sendNotificationWebhook({
      businessId: business.businessId,
      title: 'Oyster test notification',
      message: 'This is a test notification',
    });
  };

  useEffect(() => {
    if (sendingNotificationSuccess) {
      openToast({
        type: 'success',
        title: t('paymentTools:tools.webhooks.sendNotification.sendSuccess.title'),
        message: t('paymentTools:tools.webhooks.sendNotification.sendSuccess.msg'),
      });
      updateField('sendingNotificationSuccess', false);
    }

    if (sendingNotificationError) {
      openToast({
        type: 'error',
        title: t('paymentTools:tools.webhooks.sendNotification.sendError.title'),
        message: t('paymentTools:tools.webhooks.sendNotification.sendError.msg'),
      });
      updateField('sendingNotificationError', false);
    }
  }, [sendingNotificationSuccess, sendingNotificationError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={css.text}>
          {t('paymentTools:tools.webhooks.sendNotification.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OutlinedButton
          disabled={!isWebhookActive}
          label={t('paymentTools:tools.webhooks.sendNotification.sendTest')}
          leftIcon={
            isSendingNotification ? () => <CircularProgress size="22px" thickness={6} /> : Marketing
          }
          onClick={() => handleSendNotification()}
        />
      </Grid>
    </Grid>
  );
};

export default observer(SendNotificationToWebhook);
