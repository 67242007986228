import { FC } from 'react';
import { IconProps, obsidian } from '../types';

const RefundIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9714 9.75511C17.9427 9.69648 17.9141 9.6525 17.8712 9.60853L13.6202 5.12302C13.563 5.06438 13.4914 5.02041 13.4055 5.00575C13.3197 4.99109 13.2481 5.00575 13.1622 5.03507C13.0906 5.06438 13.0191 5.12302 12.9761 5.21097C12.9332 5.28426 12.9046 5.37221 12.9046 5.46016V7.55633H12.0481C4.43362 7.55633 4.09011 17.6561 10.5596 18.99C10.9603 19.0779 11.2037 18.5649 10.8888 18.301C10.0729 17.5828 9.11396 16.4541 9.11396 15.0762C9.11396 13.7422 10.4427 12.335 12.9046 12.335V14.4312C12.9046 14.5191 12.9332 14.6071 12.9761 14.695C13.0191 14.7683 13.0906 14.827 13.1622 14.8709C13.2338 14.9003 13.3197 14.9149 13.4055 14.9003C13.4914 14.8856 13.563 14.8416 13.6202 14.783L17.8712 10.2975C17.9141 10.2535 17.9427 10.2095 17.9714 10.1509C18 10.0923 18 10.0336 18 9.97499C18 9.91636 18 9.81375 17.9714 9.75511Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

RefundIcon.defaultProps = {
  fill: obsidian,
};

export default RefundIcon;
