import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 310,
    },
    dots: {
      marginLeft: theme.spacing(2),
      textAlign: 'left',
    },
    slider: {
      '& .slick-list': {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    slide: {
      background: theme.oysterTheme.stone,
    },
    slideLeft: {
      fontSize: 12,
      padding: theme.spacing(2),
    },
    slideRight: {
      height: 160,
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(4),
      '& img': {
        margin: '0 auto',
      },
    },
    callToActionButton: {
      width: '100%',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      fontWeight: 600,
      '&, &:hover': {
        background: 'transparent',
      },
    },
  }),
);

export default useStyles;
