import { createContext, FC, useMemo } from 'react';

export const PermissionsContext = createContext({
  accessActions: new Set<string>(),
  accessPages: new Set<string>(),
  accessRole: '',
  accessFinancialInstruments: {} as Record<string, boolean>,
});

export interface PermissionsProviderProps {
  accessActions: string[];
  accessPages: string[];
  accessRole: string;
  accessFinancialInstruments: Record<string, boolean>;
}

export const PermissionsProvider: FC<PermissionsProviderProps> = ({
  accessActions,
  accessPages,
  accessRole,
  accessFinancialInstruments,
  children,
}) => {
  const value = useMemo(() => {
    return {
      accessActions: new Set(accessActions),
      accessPages: new Set(accessPages),
      accessRole,
      accessFinancialInstruments,
    };
  }, [accessActions, accessPages, accessRole, accessFinancialInstruments]);
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
