import { Instance, types } from 'mobx-state-tree';

const CanNotProcessStore = types.model({
  accountPayableId: '',
  paymentId: '',
  refNumber: '',
  businessName: '',
  amount: types.optional(types.union(types.number, types.string), ''),
  currency: '',
  clabe: '',
  error: '',
});

export interface TCanNotProcessStore extends Instance<typeof CanNotProcessStore> {}
export default CanNotProcessStore;
