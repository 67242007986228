import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    badge: {
      width: 'fit-content',
      marginTop: theme.spacing(1),
    },
    amount: {
      fontWeight: 600,
      fontSize: 24,
    },
  });
});

export default styles;
