import { FC, ReactElement } from 'react';
import CheckIcon from '@yaydoo/react-components/lib/icons/Check';

import useStyles from './styles';

export interface IStep {
  title: string | ReactElement;
}

export interface IStepperDrawer {
  steps: IStep[];
  activeStep: number;
  className?: string;
}

const StepperDrawer: FC<IStepperDrawer> = ({ steps, activeStep, className }) => {
  const css = useStyles();
  return (
    <ul className={`${css.root} ${className}`}>
      {steps.map(({ title }, index) => {
        const isCurrentStep = activeStep === index;
        const isCompleted = index < activeStep;
        return (
          <li key={index} className={`${css.steps} ${isCurrentStep && css.stepActive}`}>
            <span className={`${css.stepNumber} ${isCurrentStep && css.stepNumberActive}`}>
              {isCompleted ? <CheckIcon className={css.checkIcon} /> : index + 1}
            </span>
            {isCurrentStep && <div className={css.stepTitle}>{title}</div>}
          </li>
        );
      })}
    </ul>
  );
};

export default StepperDrawer;
