import useCommonStore from '@hooks/useCommonStore';
import { Persona } from '@constants/invoice';

interface UseGetUserBusinessDataReturn {
  persona: Persona;
  name: string;
  taxId: string;
  fullName: string;
  businessName: string;
  dba?: string;
}

const useGetUserBusinessData = (): UseGetUserBusinessDataReturn => {
  const {
    user: { taxId, fullName },
    business: { businessName, isPM, dba },
  } = useCommonStore();

  const persona = isPM ? Persona.MORAL : Persona.FISICA;
  const comercialName = dba ?? businessName ?? fullName;
  const name = isPM ? comercialName : fullName;

  return {
    name,
    taxId,
    persona,
    fullName,
    businessName,
    dba,
  };
};

export default useGetUserBusinessData;
