import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 380,
      fontSize: 15,
      '& .ticket': {
        boxShadow: '0 0px 20px rgba(0, 0, 0, 0.09)',
        '&::before': {
          display: 'block',
          content: '" "',
          backgroundImage: 'url(/assets/ticket-effect.svg)',
          backgroundRepeat: 'repeat-x',
          backgroundPosition: 'bottom',
          height: 7,
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&::after': {
          display: 'block',
          content: '" "',
          backgroundImage: 'url(/assets/ticket-effect.svg)',
          backgroundRepeat: 'repeat-x',
          backgroundPosition: 'bottom',
          height: 7,
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          transform: 'rotate(180deg)',
        },
      },
    },
    header: {
      flex: 1,
      display: 'flex',
      padding: '35px 24px 10px 24px',
      '& .ticket-header-info-wrapper': {
        flex: 1,
        '& .ticket-header-info': {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          '& .ticket-header-info-col': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        },
        '&.secondary-view': {
          '& .ticket-title, .ticket-number, .ticket-total': {
            textAlign: 'start',
            margin: '5px 0 0 0',
          },
          '& .ticket-total': {
            margin: '10px 0 5px 0',
          },
        },
      },
      '& .ticket-title': {
        fontSize: 14,
        textAlign: 'right',
        fontWeight: 600,
      },
      '& .ticket-number': {
        fontSize: 14,
        textAlign: 'right',
      },
      '& .ticket-status': {
        fontSize: 12,
        '& .pending': {
          color: theme.oysterTheme.pineapple,
        },
        '& .paid': {
          color: theme.oysterTheme.water,
        },
        '& .settled': {
          color: theme.oysterTheme.brandGreen,
          textDecoration: 'underline',
        },
        '& .refunded': {
          color: theme.oysterTheme.ferrari,
        },
      },
    },
    body: {
      '& .payee-info': {
        padding: '20px 24px 24px 20px',
        '& .payee-info-row': {
          marginBottom: 25,
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
      '& .ticket-list-collapse': {
        borderColor: theme.oysterTheme.stone,
        borderStyle: 'solid',
        borderWidth: '1px 0px 1px 0px',
        marginBottom: theme.spacing(4),
        '& .item-quantity': {
          fontSize: 14,
        },
      },
    },
    footer: {
      padding: '15px 24px',
      display: 'flex',
      '& .ticket-date': {
        fontSize: 12,
        flex: 1,
        alignSelf: 'center',
        color: theme.oysterTheme.shark,
      },
      '& .oyster-logo': {
        height: 16,
      },
    },
    icon: {
      color: theme.oysterTheme.oyster,
    },
    text: {
      '&.muted': {
        color: theme.oysterTheme.dolphin,
      },
    },
    fee: {
      color: theme.oysterTheme.ferrari,
    },
    alignRight: {
      textAlign: 'right',
    },
    total: {
      marginTop: '1em',
      fontWeight: 600,
    },
    feeContainer: {
      paddingBottom: '1em',
      borderBottom: `1px solid ${theme.oysterTheme.stone}`,
    },
    refundAmount: {
      color: theme.oysterTheme.oyster,
    },
  }),
);

export default useStyles;
