import loadable from '@loadable/component';
import PageLoader from '@yaydoo/react-components/lib/components/AppLoaders/PageLoader';

const Deposit = loadable(
  () =>
    import(
      /* webpackChunkName: "Report" */
      './deposit'
    ),
  {
    fallback: <PageLoader type="icon" />,
  },
);

export default Deposit;
