import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { formatMoneyNoSign } from '@yaydoo/react-components/lib/utils/common';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { ITicketItemProps } from '../types';
import useStyles from './styles';

const TicketItem: FC<ITicketItemProps> = ({ item }) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <List data-testid="ticket-item" component="div" disablePadding>
      <ListItem button>
        <ListItemText
          primary={
            <strong className="item-quantity">
              {t('collect.checkout.ticket.items.quantity')} {item.quantity}
            </strong>
          }
          secondary={item.name}
        />
        <span className={`${css.price} item-total`}>{formatMoneyNoSign(+item.total)}</span>
      </ListItem>
    </List>
  );
};

export default TicketItem;
