import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as Success } from '@assets/images/upload-success.svg';
import Button from '@yaydoo/react-components/lib/components/FormButton';

import useStyles from './styles';
import { Typography } from '@material-ui/core';

export interface IUploadBatchSuccess {
  createdDate: string;
  onClick: () => void;
}

const UploadBatchSuccess: FC<IUploadBatchSuccess> = ({ createdDate, onClick }) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={css.header}>
        <Success className={css.image} />
      </div>
      <div className={css.content}>
        <Typography variant="h3" className={css.title}>
          {t('batch.dashboard.createBatch.success')}
        </Typography>
        <Typography variant="caption" className={css.createdDate}>
          <Trans i18nKey="batch.dashboard.createBatch.createdDate" values={{ date: createdDate }}>
            <strong />
          </Trans>
        </Typography>
        <Typography variant="body2" className={css.description}>
          <Trans i18nKey="batch.dashboard.createBatch.successMsg">
            <strong />
          </Trans>
        </Typography>

        <Button
          className={css.button}
          color="primary"
          label={t('batch.common.labels.seeDetails')}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default UploadBatchSuccess;
