import { FC, useMemo } from 'react';
import HelpChaLayout from '@yaydoo/react-components/lib/components/HelpCha';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useZendesk from '@yaydoo/react-components/lib/hooks/useZendesk';
import QuestionIcon from '@yaydoo/react-components/lib/icons/Question';
import ChatIcon from '@yaydoo/react-components/lib/icons/Chat';
import CHA_LINKS from '@constants/cha';
import useStyles from './styles';

interface ChaProps {
  className?: string;
}

const Cha: FC<ChaProps> = ({ className }) => {
  const { t } = useTranslation();
  const css = useStyles();
  const { openZendesk } = useZendesk();

  const onClick = (url: string) => {
    window.open(url, '_blank');
  };

  const chaOptions = useMemo(
    () => [
      {
        title: t('collect.cha.tips.title'),
        description: t('collect.cha.tips.description'),
        key: 'tips',
        icon: QuestionIcon,
        onClick: () => onClick(CHA_LINKS.FAQ),
      },
      {
        title: t('collect.cha.chat.title'),
        description: t('collect.cha.chat.description'),
        key: 'chat',
        icon: ChatIcon,
        onClick: openZendesk,
      },
    ],
    [],
  );

  return (
    <HelpChaLayout
      options={chaOptions}
      title={t('collect.cha.title')}
      footer={t('collect.cha.label')}
      className={clsx(css.root, className)}
    />
  );
};

export default Cha;
