import { FC } from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { DEFAULT_COLOR as defaultColor } from '@constants/layout';
import useStyles, { StyleProps } from './styles';

export interface BadgeProps {
  label: string;
  color?: StyleProps['colorName'];
  className?: string;
}

const Badge: FC<BadgeProps> = ({ label, className, color }) => {
  const css = useStyles({ colorName: color ?? defaultColor });

  return (
    <Typography data-testid="badge-status" className={clsx(css.itemBadge, className)} noWrap>
      {label}
    </Typography>
  );
};

export default Badge;
