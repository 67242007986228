import { ReactElement, FC } from 'react';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import {
  default as MaterialRadio,
  RadioProps as MaterialRadioProps,
} from '@material-ui/core/Radio';

import { useCheckBoxStyles, useLabelStyles } from './styles';

export interface RadioProps extends MaterialRadioProps {
  label: string | ReactElement;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

const Radio: FC<RadioProps> = ({ className, label, name, labelPlacement = 'start', ...props }) => {
  const classes = useLabelStyles();
  const checkboxClasses = useCheckBoxStyles();
  return (
    <FormControlLabel
      className={className}
      label={label}
      labelPlacement={labelPlacement}
      classes={classes}
      data-testid="radioLabel"
      control={
        <MaterialRadio
          name={name}
          classes={checkboxClasses}
          data-testid="radioControl"
          {...props}
        />
      }
    />
  );
};

export default Radio;
