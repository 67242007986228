import { createContext, FC } from 'react';
import { commonStore as store, ICommonStore as IMobxCommonStore } from '@store/index';

export interface ICommonStore {
  value: IMobxCommonStore;
}

export const CommonStoreContext = createContext<ICommonStore>({
  value: store,
});

export const CommonStoreProvider: FC<ICommonStore> = ({ value, children }) => {
  return <CommonStoreContext.Provider value={{ value }}>{children}</CommonStoreContext.Provider>;
};
