import MaterialTooltip from '@material-ui/core/Tooltip';
import { Theme, withStyles } from '@material-ui/core/styles';

const Tooltip = withStyles((theme: Theme) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.oysterTheme.cotton}`,
    },
    color: theme.oysterTheme.cotton,
  },
  tooltip: {
    boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 10%),
      0px 8px 10px 1px rgb(0 0 0 / 7%),
      0px 3px 14px 2px rgb(0 0 0 / 6%)`,
    backgroundColor: theme.oysterTheme.cotton,
    borderRadius: 8,
    padding: 10,
    fontWeight: 400,
    color: theme.oysterTheme.charcoal,
    fontSize: theme.typography.pxToRem(12),
  },
}))(MaterialTooltip);

export default Tooltip;
