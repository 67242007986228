const allowedActions = {
  DEBIT_ACCOUNT_PAGE: 'debit_account_page',
  DEBIT_ACCOUNT_VIEW_BALANCE: 'debit_account_view_balance',
  DEBIT_ACCOUNT_VIEW_TXNS: 'debit_account_view_txns',
  DEBIT_ACCOUNT_VIEW_BANK_STMT: 'debit_account_view_bank_stmt',
  DEBIT_ACCOUNT_UPLOAD_RECEIPTS: 'debit_account_upload_receipts',
  USER_MGMT_PAGE: 'user_mgmt_page',
  USER_MGMT_VIEW_USERS: 'user_mgmt_view_users',
  USER_MGMT_ADD_USER: 'user_mgmt_add_user',
  USER_MGMT_REMOVE_USER: 'user_mgmt_remove_user',
  USER_MGMT_EDIT_USER: 'user_mgmt_edit_user',
  ACCOUNTS_SETTINGS_PAGE: 'accounts_settings_page',
  ACCOUNTS_SETTINGS_VIEW_PERSONAL_INFO: 'accounts_settings_view_personal_info',
  ACCOUNTS_SETTINGS_VIEW_BANK_INFO: 'accounts_settings_view_bank_info',
  ACCOUNTS_SETTINGS_VIEW_LEGAL_DOCS: 'accounts_settings_view_legal_docs',
  ACCOUNTS_SETTINGS_VIEW_API_KEYS: 'accounts_settings_view_api_keys',
  ACCOUNTS_SETTINGS_SWITCH_ENVS: 'accounts_settings_switch_envs',
  ACCOUNTS_SETTINGS_CREATE_API_KEYS: 'accounts_settings_create_api_keys',
  ACCOUNTS_SETTINGS_DELETE_API_KEYS: 'accounts_settings_delete_api_keys',
  DEBIT_CARD_MGMT_PAGE: 'debit_card_mgmt_page',
  DEBIT_CARD_VIEW_DETAILS: 'debit_card_view_details',
  DEBIT_CARD_PERSONALIZE: 'debit_card_personalize',
  DEBIT_CARD_BLOCK_CARD: 'debit_card_block_card',
  DEBIT_CARD_REPLACE_CARD: 'debit_card_replace_card',
  PAYMENTS_PAGE: 'payments_page',
  PAYMENTS_VIEW_LINKS: 'payments_view_links',
  PAYMENTS_CREATE_LINKS: 'payments_create_links',
  PAYMENTS_CREATE_INVOICE: 'payments_create_invoice',
  PAYMENTS_VIEW_BALANCE: 'payments_view_balance',
  PAYMENTS_VIEW_DEPOSITS: 'payments_view_deposits',
  PAYMENTS_CONFIG_REMINDERS: 'payments_config_reminders',
  PAYMENTS_CONFIG_INVOICES: 'payments_config_invoices',
  PAYMENTS_BULK_PL_UPLOAD: 'payments_bulk_pl_upload',
  PAYMENTS_DOWNLOAD_REPORTS: 'payments_download_reports',
  PAYMENTS_DELETE_LINKS: 'payments_delete_links',
  PAYMENTS_REFUND_LINKS: 'payments_refund_links',
  PAYMENTS_MARK_AS_PAID_LINKS: 'payments_mark_as_paid_links',
  INVOICING_PAGE: 'invoicing_page',
  INVOICING_SAT_SYNC: 'invoicing_sat_sync',
  INVOICING_UPLOAD_INVOICE: 'invoicing_upload_invoice',
  INVOICING_VIEW_INVOICES: 'invoicing_view_invoices',
  INVOICING_APPROVE_INVOICES: 'invoicing_approve_invoices',
  INVOICING_PAY_INVOICES: 'invoicing_pay_invoices',
  INVOICING_ARCHIVE_INVOICES: 'invoicing_archive_invoices',
  INVOICING_VIEW_TRANSFERS: 'invoicing_view_transfers',
  INVOICING_MAKE_TRANSFERS: 'invoicing_make_transfers',
  CREDIT_PAGE: 'credit_page',
  TOOLS_PAGE: 'tools_page',
  TOOLS_COBRANDING: 'tools_cobranding',
  TOOLS_PAYMENTS_CONFIG_REMINDERS: 'tools_payments_config_reminders',
  TOOLS_WEBHOOKS: 'tools_webhooks',
  REPORTING_PAGE: 'reporting_page',
  REPORTING_VIEW_DEPOSITS: 'reporting_view_deposits',
  REPORTING_VIEW_MONTHLY_DEPOSITS: 'reporting_view_monthly_deposits',
  ACTIVITY_SUMMARY_VIEW_SALES_PAYMENT: 'activity_summary_view_sales_payment',
  FINANCIAL_PRODUCT_SWITCHER: 'financial_product_switcher',
  FINANCIAL_INTRO_PAYMENT_LINKS: 'financial_intro_payment_links',
  FINANCIAL_HELP_PAYMENT_LINKS: 'financial_help_payment_links',
  MOVEMENTS_PAGE: 'movements_page',
} as const;

export { allowedActions };
