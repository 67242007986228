import { Instance, types } from 'mobx-state-tree';
import { SortOrder } from '@store/common/pagination/types';
import PaymentOrder from '@store/common/order';

export const Deposit = types
  .model({
    settlementId: types.identifier,
    trackingId: '',
    settledAt: '',
    totalGrossAmount: types.optional(types.union(types.number, types.string), 0),
    totalTransactions: types.optional(types.union(types.number, types.string), 0),
    totalFee: types.optional(types.union(types.number, types.string), 0),
    totalNetAmount: types.optional(types.union(types.number, types.string), 0),
    downloadUrl: '',
    external: types.optional(types.boolean, false),
    oysterSettlementTransactionId: types.optional(types.string, ''),
  })
  .views((self) => ({
    get displaySettlementId() {
      return self.settlementId.toUpperCase().slice(0, 8);
    },
    get displayTotalGrossAmount() {
      return +self.totalGrossAmount / 100;
    },
    get displayTotalFee() {
      return +self.totalFee / 100;
    },
    get displayTotalNetAmount() {
      return +self.totalNetAmount / 100;
    },
  }));

export interface TDeposit extends Instance<typeof Deposit> {}

export const DepositDetailsFilters = types.model({
  sortField: 'link_number',
  sortOrder: types.optional(types.enumeration(Object.values(SortOrder)), SortOrder.DESC),
});

export interface TDepositDetailsFilters extends Instance<typeof DepositDetailsFilters> {}

export const DepositedPaymentOrder = types
  .compose(
    PaymentOrder,
    types.model({
      linkNumber: '',
    }),
  )
  .views((self) => ({
    get linkNumberFormatted() {
      const sequence = self.linkNumber.padStart(9, '0');
      return `#${sequence}`;
    },
  }));

export const DepositDetails = types
  .model({
    settlementId: types.identifier,
    status: '',
    totalGrossAmount: types.optional(types.union(types.number, types.string), 0),
    totalFee: types.optional(types.union(types.number, types.string), 0),
    totalNetAmount: types.optional(types.union(types.number, types.string), 0),
    totalTransactions: types.optional(types.union(types.number, types.string), 0),
    settledAt: '',
    downloadUrl: '',
    paymentOrders: types.array(DepositedPaymentOrder),
    external: types.optional(types.boolean, false),
    oysterSettlementTransactionId: types.optional(types.string, ''),
  })
  .views((self) => ({
    get list() {
      return [...self.paymentOrders];
    },
    get displaySettlementId() {
      return self.settlementId.toUpperCase().slice(0, 8);
    },
    get displayTotalGrossAmount() {
      return +self.totalGrossAmount / 100;
    },
    get displayTotalFee() {
      return +self.totalFee / 100;
    },
    get displayTotalNetAmount() {
      return +self.totalNetAmount / 100;
    },
  }));

export interface TDepositedPaymentOrder extends Instance<typeof DepositedPaymentOrder> {}
export interface TDepositDetails extends Instance<typeof DepositDetails> {}
