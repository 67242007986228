import { AxiosError, AxiosResponse } from 'axios';
import Service from '@yaydoo/react-mobx-stores/lib/http/services';
import {
  RequestInterceptor,
  ResponseInterceptor,
} from '@yaydoo/react-mobx-stores/lib/http/interceptors';

/**
 * Test service class
 *
 * Services can extends from abstract class Service for clean configs
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/index.ts
 *
 * or can can extends from OysterService to use default base config for oyster endpoints that include some default interceptors
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/OysterService.ts
 *
 * @param @optional connection string Connection name to get axios config default is 'api'
 * @param @optional requestInterceptors RequestInterceptor[] interceptors to be applied before each request
 * @param @optional requestInterceptors ResponseInterceptor[] interceptors to be applied after each response
 */
export default class TestService extends Service {
  constructor(
    protected connection: string = 'test',
    protected requestInterceptors: RequestInterceptor[] = [],
    protected responseInterceptors: ResponseInterceptor[] = [],
  ) {
    super(connection, requestInterceptors, responseInterceptors);
  }

  /**
   * Get a list of posts from json placeholder service
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchPosts(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/posts');
  }

  /**
   * Get a specific post from json placeholder service
   * @param id sting
   * @return Promise<AxiosResponse | AxiosError>
   */
  findPost(id: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/posts/${id}`);
  }
}
