import { flow, Instance, types, applySnapshot, cast } from 'mobx-state-tree';
import CommonStore from '@yaydoo/react-mobx-stores/lib/common';
import { allowedRoles } from '@yaydoo/react.ui-access-manager/lib/constants/allowedRoles';

import MODAL_TYPES from '@constants/modalTypes';
import UMService from './service';
import {
  AssociatedUserType,
  IAssociatedUser,
  IInvitedUser,
  InvitedUserType,
  IUserForm,
  UserFormType,
} from './types';

const TestStore = types
  .compose(
    CommonStore,
    types.model({
      fetching: false,
      loadingUsers: true,
      fetchSuccess: false,
      fetchError: false,
      confirmationType: '',
      associatedUsers: types.array(AssociatedUserType),
      invitedUsers: types.array(InvitedUserType),
      selectedInvitedUser: types.optional(InvitedUserType, {
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        role: '',
        expirationDate: '',
        status: '',
        createdAt: '',
        updatedAt: '',
      }),
      selectedAssociatedUser: types.optional(AssociatedUserType, {
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        role: '',
        userId: '',
        active: false,
        createdAt: '',
        updatedAt: '',
      }),
      userForm: types.optional(UserFormType, {
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        role: '',
      }),
      ownerUserData: types.optional(AssociatedUserType, {
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        role: '',
        userId: '',
        active: false,
        createdAt: '',
        updatedAt: '',
      }),
      hasAssociatedUsers: false,
      hasInvitedUsers: false,
      isOwnerUser: true,
    }),
  )
  .views(() => ({
    get service() {
      return new UMService();
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => ({
    setSelectedInvitedUser: (data: IInvitedUser) => {
      self.selectedInvitedUser = cast(data);
    },
    setSelectedAssociatedUser: (data: IAssociatedUser) => {
      self.selectedAssociatedUser = cast(data);
    },
    setUserForm: (data: IUserForm) => {
      self.userForm = cast(data);
    },
  }))
  .actions((self) => ({
    fetchUsers: flow(function* () {
      self.loadingUsers = true;
      const [associatedUsersData, invitedUsersData] = yield Promise.all([
        self.service.fetchAssociatedUsers(),
        self.service.fetchInvitedUsers(),
      ]);
      const associatedUsers = associatedUsersData?.data?.associatedUsers;
      const invitedUsers = invitedUsersData?.data?.invitedUsers;

      const hasAssociatedUsers = !!associatedUsers;
      self.hasAssociatedUsers = hasAssociatedUsers;
      self.associatedUsers = cast(associatedUsers);
      if (hasAssociatedUsers) {
        const ownerData = associatedUsers.filter(
          (user: IAssociatedUser) => user['role'] === allowedRoles.OWNER,
        );
        if (ownerData.length > 0) {
          self.isOwnerUser = false;
          self.ownerUserData = cast(ownerData[0]);
        }
      }

      self.hasInvitedUsers = !!invitedUsers;
      self.invitedUsers = cast(invitedUsers);
      self.loadingUsers = false;
    }),
  }))
  .actions((self) => ({
    fetchInviteUser: flow(function* (inviteUserForm, isResendInvite = false) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchInviteUser(inviteUserForm);
        if (data.success) {
          if (isResendInvite) {
            self.confirmationType = MODAL_TYPES.RESEND_USER_INVITE_SUCCESS;
          } else {
            self.confirmationType = MODAL_TYPES.INVITE_USER_SUCCESS;
          }
          self.fetchSuccess = true;
        } else {
          if (isResendInvite) {
            self.confirmationType = MODAL_TYPES.RESEND_USER_INVITE_ERROR;
          } else {
            self.confirmationType = MODAL_TYPES.INVITE_USER_ERROR;
          }
          self.fetchError = true;
        }
      } catch (error) {
        if (isResendInvite) {
          self.confirmationType = MODAL_TYPES.RESEND_USER_INVITE_ERROR;
        } else {
          self.confirmationType = MODAL_TYPES.INVITE_USER_ERROR;
        }
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
    fetchUpdateUserRole: flow(function* (updateUserRoleForm) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchUpdateUserRole(updateUserRoleForm);
        if (data.success) {
          self.confirmationType = MODAL_TYPES.UPDATE_USER_ROLE_SUCCESS;
          self.fetchSuccess = true;
          yield self.fetchUsers();
        } else {
          self.confirmationType = MODAL_TYPES.UPDATE_USER_ROLE_ERROR;
          self.fetchError = true;
        }
      } catch (error) {
        self.confirmationType = MODAL_TYPES.UPDATE_USER_ROLE_ERROR;
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
    fetchCancelUserInvite: flow(function* (email) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchCancelUserInvite(email);
        if (data.success) {
          self.confirmationType = MODAL_TYPES.CANCEL_INVITE_SUCCESS;
          self.fetchSuccess = true;
          yield self.fetchUsers();
        } else {
          self.confirmationType = MODAL_TYPES.CANCEL_INVITE_ERROR;
          self.fetchError = true;
        }
      } catch (error) {
        self.confirmationType = MODAL_TYPES.CANCEL_INVITE_ERROR;
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
    fetchSuspendAssociatedUser: flow(function* (userId) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchSuspendAssociatedUser(userId);
        if (data.success) {
          self.confirmationType = MODAL_TYPES.SUSPEND_ASSOCIATED_USER_SUCCESS;
          self.fetchSuccess = true;
          yield self.fetchUsers();
        } else {
          self.confirmationType = MODAL_TYPES.SUSPEND_ASSOCIATED_USER_ERROR;
          self.fetchError = true;
        }
      } catch (error) {
        self.confirmationType = MODAL_TYPES.SUSPEND_ASSOCIATED_USER_ERROR;
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
    fetchRevokeAssociatedUserSuspension: flow(function* (userId) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchRevokeAssociatedUserSuspension(userId);
        if (data.success) {
          self.confirmationType = MODAL_TYPES.REVOKE_SUSPEND_ASSOCIATED_USER_SUCCESS;
          self.fetchSuccess = true;
          yield self.fetchUsers();
        } else {
          self.confirmationType = MODAL_TYPES.REVOKE_SUSPEND_ASSOCIATED_USER_ERROR;
          self.fetchError = true;
        }
      } catch (error) {
        self.confirmationType = MODAL_TYPES.REVOKE_SUSPEND_ASSOCIATED_USER_ERROR;
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
    fetchRemoveAssociatedUser: flow(function* (userId) {
      self.fetching = true;
      self.fetchError = false;
      self.fetchSuccess = false;
      try {
        const { data } = yield self.service.fetchRemoveAssociatedUser(userId);
        if (data.success) {
          self.confirmationType = MODAL_TYPES.REMOVE_ASSOCIATED_USER_SUCCESS;
          self.fetchSuccess = true;
          yield self.fetchUsers();
        } else {
          self.confirmationType = MODAL_TYPES.REMOVE_ASSOCIATED_USER_ERROR;
          self.fetchError = true;
        }
      } catch (error) {
        self.confirmationType = MODAL_TYPES.REMOVE_ASSOCIATED_USER_ERROR;
        self.fetchError = true;
      } finally {
        self.fetching = false;
      }
    }),
  }));

export type UserManagementStoreType = Instance<typeof TestStore>;
export default TestStore;
