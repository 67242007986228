import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  wide: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      backgroundColor: 'white',
      maxWidth: ({ wide }: StyleProps) => (wide ? 808 : 330),
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      padding: theme.spacing(4),
      borderRadius: 20,
      alignItems: 'center',
    },
    modalContainerHeader: {
      color: theme.oysterTheme.emerald,
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: ({ wide }: StyleProps) => (wide ? '41.66px' : '32px'),
      maxWidth: '452px',
      textAlign: 'center',
      whiteSpace: ({ wide }: StyleProps) => (wide ? 'break-spaces' : 'normal'),
    },
    modalContainerText: {
      fontSize: 14,
      fontWeight: 'normal',
      paddingTop: theme.spacing(2),
      lineHeight: '23.44px',
      maxWidth: '452px',
      textAlign: 'center',
      whiteSpace: ({ wide }: StyleProps) => (wide ? 'break-spaces' : 'normal'),
    },
    modalButtons: {
      placeContent: 'space-evenly',
      flexDirection: 'row-reverse',
      marginTop: ({ wide }: StyleProps) => (wide ? theme.spacing(5.75) : theme.spacing(2.5)),
      '& div': { maxWidth: 'fit-content' },
    },
    cancelBtnContainer: {
      paddingTop: ({ wide }: StyleProps) => (wide ? 0 : theme.spacing(2)),
    },
    btn: {
      boxShadow: 'none',
    },
    btnSubmitting: {
      '&:disabled': {
        backgroundColor: theme.oysterTheme.pine,
        color: theme.oysterTheme.cotton,
      },
    },
    cancelBtn: {
      backgroundColor: theme.oysterTheme.cotton,
      color: theme.oysterTheme.pine,
      border: '2px solid rgba(191, 197, 204, 0.26)',
      '&:hover': { backgroundColor: theme.oysterTheme.cotton },
    },
  }),
);

export default useStyles;
