import { FC } from 'react';
import { IconProps, oyster } from './types';

const SettingIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.175 13.175c.052-.375.078-.763.078-1.175 0-.4-.026-.8-.09-1.175L23.77 8.85a.602.602 0 00.154-.762l-2.467-4.15c-.154-.276-.475-.363-.758-.276l-3.071 1.2a9.141 9.141 0 00-2.082-1.175L15.084.514A.617.617 0 0014.467 0H9.533a.604.604 0 00-.604.512l-.463 3.175c-.758.3-1.452.713-2.082 1.175l-3.071-1.2a.621.621 0 00-.758.276L.1 8.088c-.154.262-.103.587.154.762l2.61 1.975A7.143 7.143 0 002.746 12c0 .387.026.8.09 1.175L.23 15.15a.602.602 0 00-.154.762l2.467 4.15c.154.275.475.363.758.275l3.071-1.2a9.142 9.142 0 002.082 1.175l.463 3.175c.064.3.308.513.617.513h4.934a.594.594 0 00.604-.512l.463-3.175c.758-.3 1.452-.7 2.082-1.175l3.071 1.2c.283.1.604 0 .758-.276l2.468-4.15c.154-.275.09-.587-.155-.762l-2.583-1.975zM12 16.5c-2.544 0-4.626-2.025-4.626-4.5S9.456 7.5 12 7.5c2.544 0 4.626 2.025 4.626 4.5S14.544 16.5 12 16.5z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

SettingIcon.defaultProps = {
  fill: oyster,
};

export default SettingIcon;
