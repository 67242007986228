import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');
  return createStyles({
    action: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      [upScale]: {
        margin: 'unset',
        marginTop: 'auto',
        marginBottom: theme.spacing(6),
      },
    },
  });
});

export default styles;
