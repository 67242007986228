import { makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles(({ breakpoints, oysterTheme, spacing }: Theme) => {
  const upScale = breakpoints.up('sm');

  return {
    description: {
      fontSize: 14,
      color: oysterTheme.charcoal,
      marginBottom: spacing(1),
    },
    heading: {
      fontWeight: 600,
      fontSize: 12,
      marginTop: spacing(1),
      marginBottom: spacing(1),
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
    },
    wrapper: {
      width: '100%',
      maxWidth: 750,
      paddingLeft: spacing(1),
      [upScale]: {
        paddingBottom: spacing(2),
        paddingLeft: spacing(2),
      },
    },
    preview: {
      paddingBottom: spacing(2),
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
    },
    previewContainer: {
      width: '100%',
      maxWidth: 375,
      alignSelf: 'center',
      [upScale]: {
        maxWidth: 704,
        marginLeft: spacing(1),
        alignSelf: 'flex-start',
      },
    },
    previewTitle: {
      backgroundColor: oysterTheme.pearl,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 12,
      color: oysterTheme.dolphin,
      padding: spacing(1),
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
    },
    noBorder: {
      borderBottom: 'none',
    },
    sectionTitle: {
      color: oysterTheme.shark,
      fontWeight: 500,
      fontSize: 14,
      marginBottom: spacing(1),
    },
    reset: {
      marginLeft: 16,
      color: oysterTheme.oyster,
      fontWeight: 600,
    },
    colorIcon: {
      height: 24,
      width: 24,
      minWidth: 24,
    },
    pickerWrapper: {
      padding: '16px 0 24px',
    },
    picker: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& span': {
        border: `solid 1px ${oysterTheme.stone}`,
        padding: 2,
        marginLeft: 8,
      },
    },
    previewLogoWrapper: {
      marginBottom: spacing(2),
      marginTop: spacing(2),
      marginLeft: spacing(2),
      '& img, & svg': {
        height: 40,
      },
    },
    contactInfo: {
      alignItems: 'center',
      padding: 4,
      display: 'flex',
      fontSize: 10,
      '& svg': {
        marginRight: 12,
      },
    },
    themeLabel: {
      fontSize: 14,
    },
    body: {
      marginLeft: spacing(3),
    },
    uploaderContainer: {
      width: 'fit-content',
    },
    support: {
      display: 'flex',
      justifyContent: 'center',
      gap: spacing(2),
      marginBottom: spacing(4),
      [upScale]: {
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: 0,
      },
    },
    supportHeader: {
      fontSize: 10,
      marginLeft: spacing(6),
      [upScale]: {
        marginLeft: 0,
      },
    },
    actionButton: {
      textAlign: 'center',
      [upScale]: {
        textAlign: 'left',
      },
    },
  };
});

export default styles;
