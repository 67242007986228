export default {
  ticket: {
    title: 'Reembolsar pago',
    header: {
      on: 'el ',
      paid: 'PAGADO ',
      currencySymbol: '$',
    },
    body: {
      finalAmountLabel: 'Saldo final',
      totalBalanceLabel: 'Saldo disponible',
      refundAmountLabel: 'Monto a reembolsar',
      insufficientBalanceInfo: 'Actualmente no tienes saldo suficiente para realizar el reembolso',
    },
    footer: {
      to: 'Para',
      from: 'De',
    },
    oysterButtonLabel: 'Reembolsar',
    toast: {
      error: {
        title: 'Error',
        message: 'No pudimos reembolsar tu link.',
      },
      success: {
        title: 'Link reembolsado',
        message: 'Tu link se reembolsó correctamente.',
      },
    },
  },
};
