import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

export enum BatchFilterStatus {
  ALL = '',
  PENDING = 'PENDING',
  DISPERSED = 'DISPERSED',
  PARTIALLY_DISPERSED = 'PARTIALLY_DISPERSED',
}

export enum BatchFilterCreatedAt {
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
}

export const checkoutAppUrl = {
  local: 'https://dev-paymentcheckout.myoyster.mx',
  dev: 'https://dev-paymentcheckout.myoyster.mx',
  stage: 'https://dev-paymentcheckout.myoyster.mx',
  production: 'https://paymentcheckout.oyster.io',
};

export const validCancelStatus = [
  AccountsPayableLinkStatus.ACTIVE,
  AccountsPayableLinkStatus.CONFIRMED,
];

export const validSeeDetailsStatus = [
  AccountsPayableLinkStatus.PARTIALLY_DISPERSED,
  AccountsPayableLinkStatus.DISPERSION_SUCCESS,
  AccountsPayableLinkStatus.DISPERSION_ERROR,
  AccountsPayableLinkStatus.DISPERSION_FAIL,
];

export enum AccountsPayableLinkErrorCodes {
  NOT_FOUND = 'NOTFOUND',
}

export const AccountsPayableLinkErrorMsg = {
  DISPERSION_FAIL_REVIEW_BANK_ACCOUNT: 'Clabe invalida',
  INVALID_CURRENCY: 'Moneda invalida',
  INVALID_BANK_ACCOUNT: 'Clabe invalida',
  INVALID_CURRENCY_AND_BANK_ACCOUNT: 'Clabe y moneda invalidas',
  DISPERSION_FAIL: 'Dispersión fallida',
  RETURNED: 'Devuelto',
  REFUNDED: 'Devuelto',
  NOT_SUFFICIENT_FUNDS: 'Fondos insuficientes',
  SOURCE_ACCOUNT_IS_CLOSED: 'Clabe origen cerrada',
  DESTINATION_ACCOUNT_IS_NOT_AVAILABLE: 'Clabe destino no esta disponible',
  PAYMENT_PROCESSING_HAS_FAILED: 'El procesamiento del pago ha fallado',
  PAYMENT_RAIL_IS_NOT_AVAILABLE_AT_THIS_TIME: 'La vía de pago no está disponible en este momento',
  INVALID_CHARACTERS_IN_THE_PAYMENT_DESCRIPTION: 'Caracteres no válidos en la descripción del pago',
};
