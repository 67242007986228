import { FC } from 'react';

import UploadBatchSuccess, { IUploadBatchSuccess } from '@components/UploadBatchSuccess';
import CustomDrawer from '@components/CustomDrawer';
import UploadBatch, { IUploadBatch } from '@components/UploadBatch';

export interface IUploadBatchDrawer extends IUploadBatch, IUploadBatchSuccess {
  success: boolean;
}

export const UploadBatchDrawer: FC<IUploadBatchDrawer> = ({
  success,
  onClick,
  createdDate,
  ...uploadBatchProps
}) => {
  return (
    <CustomDrawer>
      {success ? (
        <UploadBatchSuccess onClick={onClick} createdDate={createdDate} />
      ) : (
        <UploadBatch {...uploadBatchProps} />
      )}
    </CustomDrawer>
  );
};

export default UploadBatchDrawer;
