import { useState, MouseEvent, Children, cloneElement, ReactElement } from 'react';
import clsx from 'clsx';

import RightArrowIcon from '@yaydoo/react-components/lib/icons/CaretRight';
import { MenuItemProps } from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import useStyles from './styles';
import { MenuProps } from './types';
import { getPosition } from './helper';

const DropdownMenu = ({
  title,
  children,
  className,
  iconClassName,
  customLeftIcon,
  customRightIcon,
  dropdownPosition = 'bottom',
  showMenuArrow,
  customButton: CustomButton,
}: MenuProps): ReactElement => {
  const css = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const arrowCls = clsx(
    { [css.arrowDown]: !!anchorEl && dropdownPosition === 'bottom' },
    { [css.arrowUp]: !!anchorEl && dropdownPosition === 'top' },
    { [css.arrowLeft]: !!anchorEl && dropdownPosition === 'left' },
    css.arrow,
    iconClassName,
  );
  const { anchor, transform } = getPosition(dropdownPosition);

  const onMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={clsx(css.container, className)} onClick={(e) => e.stopPropagation()}>
      {CustomButton ? (
        <CustomButton onClick={onMenuOpen} />
      ) : (
        <Button onClick={onMenuOpen} className={css.button} data-testid="menu-btn">
          {customLeftIcon}
          {title}
          {customRightIcon ?? <RightArrowIcon className={arrowCls} defaultColor />}
        </Button>
      )}
      <Menu
        variant="menu"
        open={Boolean(anchorEl)}
        onClick={onClick}
        onClose={onMenuClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={anchor}
        transformOrigin={transform}
        PaperProps={{ className: css.menu }}
      >
        {showMenuArrow && <div className={clsx(css.dropdownArrow, css[dropdownPosition])} />}
        {Children.map(children, (child) =>
          cloneElement(child as ReactElement<MenuItemProps>, {
            ...child.props,
            onClick: (event: MouseEvent<HTMLLIElement>) => {
              child.props.onClick && child.props.onClick(event);
              setAnchorEl(null);
            },
          }),
        )}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
