import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

export interface IQueryParams {
  postalCode?: string;
}

export default class AddressService extends OysterService {
  /**
   * Get business location by zip code
   * @param params
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchAddress(params: IQueryParams): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/business/location', { params });
  }
}
