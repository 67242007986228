import { useTranslation } from 'react-i18next';

import { IModalComponent } from '@yaydoo/react-components/lib/components/ModalWrapper/ModalContext';
import useModal from '@yaydoo/react-components/lib/hooks/useModal';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import useStore from '@hooks/useStore';
import AccountsPayableConfirmation, {
  IAccountsPayableConfirmation,
} from '@components/AccountsPayableConfirmation';
import {
  AccountsPayableLinkStatus,
  AccountsPayableLinkUpdateStatus,
} from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

export interface IUseCancelBatch {
  openCancelOperationModal: (onClose: () => void) => void;
}

const useCancelBatch = (): IUseCancelBatch => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { showModal } = useModal();
  const { accountsPayableLink, batch } = useStore();

  const showSuccessToast = ({ title, message }: { title: string; message: string }) => {
    openToast({
      type: 'success',
      title,
      message,
    });
  };

  const handleError = (error: { message: string }) => {
    const { message = '' } = error;
    openToast({
      type: 'error',
      title: t('batch.dashboard.toast.errors.title'),
      message: message || t('batch.dashboard.toast.errors.unknown'),
    });
  };

  const handleUpdateStatus = async (
    status: AccountsPayableLinkUpdateStatus,
    onClose: () => void,
  ) => {
    try {
      await accountsPayableLink.updateStatus(status);
      accountsPayableLink.setCurrentStatus(status as unknown as AccountsPayableLinkStatus);
      showSuccessToast({
        title: t('batch.dashboard.toast.success.title'),
        message: t('batch.dashboard.toast.success.completed'),
      });
      batch.setForceRefreshList(true);
      onClose();
    } catch (error) {
      handleError(error as { message: string });
    }
  };

  const openCancelOperationModal = (onClose: () => void) => {
    showModal({
      component: AccountsPayableConfirmation,
      props: {
        title: t('batch.dashboard.cancelModal.title'),
        message: t('batch.dashboard.cancelModal.description'),
        confirmLabelButton: t('batch.dashboard.cancelModal.confirmButton'),
        cancelLabelButton: t('batch.dashboard.cancelModal.cancelButton'),
        onConfirm: () => null,
        onCancel: () => handleUpdateStatus(AccountsPayableLinkUpdateStatus.CANCELED, onClose),
      } as IAccountsPayableConfirmation,
    } as unknown as IModalComponent);
  };

  return {
    openCancelOperationModal,
  };
};

export default useCancelBatch;
