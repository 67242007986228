import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import useStyles from './styles';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';
import AccountsPayableStatus from '@components/AccountsPayableStatus';

export interface IBatchResume {
  status: string;
  totalSuccessAmount: string | number;
  totalSuccess: string | number;
  totalFailAmount: string | number;
  totalFail: string | number;
}

const BatchResume: FC<IBatchResume> = ({
  status,
  totalSuccessAmount,
  totalSuccess,
  totalFailAmount,
  totalFail,
}) => {
  const { t } = useTranslation();
  const css = useStyles();
  return (
    <div className={css.root}>
      <div className={css.item}>
        <Typography variant="caption" className={css.title}>
          {t('batch.details.resume.status')}
        </Typography>
        <AccountsPayableStatus status={status as AccountsPayableLinkStatus} />
      </div>
      <div className={css.item}>
        <Typography variant="caption" className={css.title}>
          {t('batch.details.resume.totalSuccessAmount')} <strong>{totalSuccess}</strong>
        </Typography>
        <Typography variant="caption" className={`${css.totalAmount} ${css.success}`}>
          {totalSuccessAmount}
        </Typography>
      </div>
      <div>
        <Typography variant="caption" className={css.title}>
          {t('batch.details.resume.totalFailAmount')} <strong>{totalFail}</strong>
        </Typography>
        <Typography variant="caption" className={`${css.totalAmount} ${css.error}`}>
          {totalFailAmount}
        </Typography>
      </div>
    </div>
  );
};

export default BatchResume;
