import { Instance, cast, flow, types } from 'mobx-state-tree';

import { BusinessType, OverallStatus, VerificationStatus } from './enums';
import { DocumentType } from '../kycDocument/enums';
import { errors } from '../constants';

import Common from '../common';

import KycDocument, { Document } from '../kycDocument';
import Address, { IGetAddress, TAddressStore } from '../address';
import BusinessService from './service';
import { IOverallStatusQueryParams } from './types';

export interface IVolatileState {
  service: BusinessService;
}

export const ExternalClabe = types.model({
  businessExternalClabeId: types.maybeNull(types.string),
  businessId: types.maybeNull(types.string),
  clabe: types.maybeNull(types.string),
  isActive: types.optional(types.boolean, false),
  isDefault: types.optional(types.boolean, false),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export interface TExternalClabe extends Instance<typeof ExternalClabe> {}

export const PhoneModel = types
  .model({
    valid: types.maybeNull(types.boolean),
    countryCode: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    fullPhoneNumber: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setPhone: (phone: Partial<typeof self>) => {
      self.valid = phone.valid || false;
      self.phone = phone.phone || self.phone;
      self.countryCode = phone.countryCode || self.countryCode;
      self.fullPhoneNumber = phone.fullPhoneNumber || self.fullPhoneNumber;
    },
  }));

export type TPhoneModel = Instance<typeof PhoneModel>;

const BusinessStore = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      isFetching: false,
      businessId: '',
      country: '',
      businessType: BusinessType.UNKNOWN_BUSINESS_TYPE,
      businessName: '',
      taxId: '',
      businessAddress: Address,
      personalAddress: Address,
      status: VerificationStatus.UNKNOWN_VERIFICATION_STATUS,
      createdAt: '',
      taxIdAvailability: '',
      phone: types.maybeNull(PhoneModel),
      occupation: types.optional(types.string, ''),
      dba: types.maybe(types.string),
      defaultBusinessExternalClabe: types.maybeNull(ExternalClabe),
      businessExternalClabes: types.maybeNull(types.array(ExternalClabe)),
      externalAccountStatementDocument: types.maybeNull(KycDocument),
      overallStatus: OverallStatus.UNKNOWN,
      overallStatusLoading: false,
      isOverallStatusFetched: false,
    }),
  )
  .views((self) => ({
    get service() {
      return new BusinessService();
    },
    get businessTypeStr(): string {
      return self.businessType === BusinessType.PERSONA_MORALE ? 'pm' : 'pf';
    },
    get isPM(): boolean {
      return self.businessType === BusinessType.PERSONA_MORALE;
    },
    get isVerified() {
      return self.status === VerificationStatus.VERIFIED;
    },
    get isOverallStatusCompleted() {
      return self.overallStatus === OverallStatus.COMPLETED;
    },
  }))
  .actions((self) => ({
    afterCreate: () => {
      self.externalAccountStatementDocument = KycDocument.create({
        document: Document.create({}),
        documentType: DocumentType.EXTERNAL_ACCOUNT_STATEMENT,
      });
    },
  }))
  .actions((self) => ({
    updateService: flow(function* () {
      yield Promise.resolve('ok');
      self.externalAccountStatementDocument?.updateService();
    }),
  }))
  .actions((self) => ({
    update: (data: Partial<IGetBusiness>) => {
      const businessId = data.businessId || self.businessId;
      self.businessId = businessId;
      self.taxId = data.taxId || '';
      self.businessName = data.name || '';
      self.dba = data.dba;
      self.overallStatus = data.overallStatus || OverallStatus.UNKNOWN;

      if (data.businessType) {
        self.businessType = BusinessType[data.businessType] as unknown as number;
      }

      if (data.businessAddress) {
        self.businessAddress.update(data.businessAddress);
      }

      self.isFetched = data.isFetched || self.isFetched;
      self.occupation = data.service || self.occupation;

      if (data.status) {
        self.status = VerificationStatus[data.status] as unknown as number;
      }
    },
  }))
  .actions((self) => ({
    getBusiness: flow(function* () {
      try {
        const { data } = yield self.service.fetchBusiness();

        if (data.success && data.businesses) {
          self.update(data.businesses[0]);
          self.isFetched = true;
        }
      } catch {
        self.setError({
          type: errors.BUSINESS_DATA,
        });
      }
    }),
    getAddresses: flow(function* () {
      try {
        const { data } = yield self.service.fetchAddresses();

        data.addresses.forEach((addr: IGetAddress) => {
          if (addr.type === 'PERSONAL' || addr.type === 'HOME') {
            self.personalAddress.update(addr as TAddressStore);
          }

          if (addr.type === 'LEGAL') {
            self.businessAddress.update(addr as TAddressStore);
          }
        });
      } catch {
        self.setError({
          type: errors.BUSINESS_ADDRESS,
        });
      }
    }),
    getExternalClabe: flow(function* () {
      try {
        const { data } = yield self.service.getExternalClabe();

        const defaultBusinessExternalClabe =
          data?.businessExternalClabeResponse?.defaultBusinessExternalClabe;
        const businessExternalClabes = data?.businessExternalClabeResponse?.businessExternalClabes;

        if (defaultBusinessExternalClabe) {
          self.defaultBusinessExternalClabe = cast(defaultBusinessExternalClabe);
        }

        if (businessExternalClabes.length > 0) {
          self.businessExternalClabes = cast(businessExternalClabes);
        }

        return data;
      } catch {
        self.setError({
          type: errors.EXTERNAL_CLABE,
        });
      }
    }),
    saveExternalClabe: flow(function* (clabe: string) {
      try {
        const { data } = yield self.service.saveExternalClabe(clabe);

        const addedClabe = {
          clabe,
          isActive: true,
          isDefault: true,
        } as TExternalClabe;

        self.defaultBusinessExternalClabe = cast(addedClabe);

        if (self?.businessExternalClabes && self?.businessExternalClabes?.length > 0) {
          const newClabes = [...self.businessExternalClabes];
          newClabes.unshift(addedClabe);
          self.businessExternalClabes = cast(newClabes);
        } else {
          self.businessExternalClabes = cast([addedClabe]);
        }

        return data;
      } catch {
        self.setError({
          type: errors.EXTERNAL_CLABE_SAVE,
        });
      }
    }),
    updateExternalClabe: flow(function* (newClabe: string) {
      try {
        const request = {
          oldClabe: self.defaultBusinessExternalClabe?.clabe || '',
          newClabe,
          businessId: self.businessId,
        };

        const { data } = yield self.service.updateExternalClabe(request);

        const addedClabe = {
          clabe: newClabe,
          isActive: true,
          isDefault: true,
        } as TExternalClabe;

        self.defaultBusinessExternalClabe = cast(addedClabe);

        if (self?.businessExternalClabes && self?.businessExternalClabes?.length > 0) {
          const newClabes = [...self.businessExternalClabes];
          newClabes.unshift(addedClabe);
          self.businessExternalClabes = cast(newClabes);
        } else {
          self.businessExternalClabes = cast([addedClabe]);
        }

        return data;
      } catch {
        self.setError({
          type: errors.EXTERNAL_CLABE_SAVE,
        });
      }
    }),
    bankNameByClabe: flow(function* (clabe: string) {
      try {
        const { data } = yield self.service.bankNameByClabe(clabe);

        return data;
      } catch {
        self.setError({
          type: errors.BANKNAME_BY_CLABE,
        });
      }
    }),

    getOverallStatus: flow(function* (params: IOverallStatusQueryParams) {
      self.overallStatusLoading = true;
      self.isOverallStatusFetched = false;
      try {
        const { data } = yield self.service.getOverallStatus(params);
        const {
          businessOverallStatusResponse: { overallStatus },
        } = data;

        if (!overallStatus) {
          self.overallStatus = OverallStatus.UNKNOWN;
        }

        if (!OverallStatus[overallStatus as keyof typeof OverallStatus]) {
          self.overallStatus = OverallStatus.UNKNOWN;
        }

        self.overallStatus = OverallStatus[overallStatus as keyof typeof OverallStatus];
        return data;
      } catch {
        self.setError({
          type: errors.OVERALL_BUSINESS_STATUS,
        });
      } finally {
        self.isOverallStatusFetched = true;
        self.overallStatusLoading = false;
      }
    }),
  }));

export interface TBusinessStore extends Instance<typeof BusinessStore> {}
export interface IGetBusiness extends Omit<TBusinessStore, 'service'> {
  name: string;
  service?: string;
}
export default BusinessStore;
