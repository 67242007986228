import { FC } from 'react';
import { IconProps, obsidian } from './types';

const PayTicketIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H4.16337C4.35896 2.44225 4.5 3.08008 4.5 4V16V17L6.21428 16L7.5 17L9 16L10.5 17L12 16L13.5 17L14.7857 16L16.5 17V16V4C16.5 3.08008 16.359 2.44225 16.1634 2H19C19.5523 2 20 1.55228 20 1C20 0.447715 19.5523 0 19 0H1ZM12.5 10.9255C12.5 10.0255 11.6615 9.78936 10.8986 9.6234V8.5383C11.2423 8.57659 11.5997 8.6851 11.9502 8.87021L12.2938 8.0851C11.9158 7.8617 11.4141 7.73404 10.8986 7.69574V7H10.2113V7.70851C9.12543 7.8234 8.59622 8.40425 8.59622 9.08085C8.59622 9.99362 9.44845 10.2298 10.2113 10.4021V11.4681C9.71649 11.4234 9.22165 11.2511 8.878 11.0149L8.5 11.7936C8.878 12.0681 9.53092 12.266 10.2113 12.3043V13H10.8986V12.2851C11.9777 12.1638 12.5 11.5894 12.5 10.9255ZM9.70275 9.02979C9.70275 8.81915 9.84708 8.63404 10.2113 8.55744V9.46383C9.90893 9.37447 9.70275 9.25319 9.70275 9.02979ZM11.3935 11.0021C11.3935 11.2064 11.2423 11.3723 10.8986 11.4426L10.8986 10.5617C11.1942 10.6574 11.3935 10.7787 11.3935 11.0021Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

PayTicketIcon.defaultProps = {
  fill: obsidian,
};

export default PayTicketIcon;
