export enum KycStatus {
  UNKNOWN_KYC_STATUS = 0,
  KYC_COMPLETED = 1,
  ADDITIONAL_KYC_REQUIRED = 2,
  NO_KYC_FOUND = 3,
  KYC_EXPIRED = 4,
  KYC_VERIFICATION_PENDING = 5,
}

// log type in /mobile/log api
export enum LogType {
  UNKNOWN_LOG_TYPE = 0,
  INFO = 1,
  ERROR = 2,
}
