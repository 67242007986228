import { types, Instance, flow, cast, applySnapshot } from 'mobx-state-tree';
import debug from 'debug';

import WebhooksService, {
  SetWebhookParams,
  GetWebhookParams,
  UpdateWebhookParams,
  SendNotificationWebhookParams,
} from './service';
import Flags from './models/Flags';
import { Webhook } from './models/Webhook';

const error = debug('WebhooksStore:error');
const log = debug('WebhooksStore:log');

const WebhooksStore = types
  .model({
    webhookURL: types.optional(types.string, ''),
    flags: types.optional(Flags, {}),
    businessWebhooks: types.optional(types.array(Webhook), []),
  })
  .volatile((): { service: WebhooksService | null } => ({ service: null }))
  .views((self) => ({
    get WebhooksService() {
      if (!self.service) throw new Error('No service found');
      return self.service;
    },
  }))
  .actions((self) => ({
    updateService: () => {
      self.service = new WebhooksService();
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
    enableEditing: () => {
      self.webhookURL = '';
      self.flags.isEditEnabled = true;
      self.flags.isURLSavedSuccess = false;
    },
  }))
  .actions((self) => ({
    getWebhook: flow(function* (params: GetWebhookParams) {
      log('getWebhookURL', params);
      try {
        self.flags.isWebhookLoading = true;
        self.flags.isWebhookFetched = false;
        const response = yield self.WebhooksService.getWebhook(params);
        log('response: ', response.data);
        if (response?.data?.success) {
          self.businessWebhooks = cast(response.data?.webhooks);
          self.webhookURL = response.data?.webhooks[0].url;
        }
      } catch (e) {
        error(e);
      } finally {
        self.flags.isWebhookLoading = false;
        self.flags.isWebhookFetched = true;
      }
    }),
    setWebhook: flow(function* (params: SetWebhookParams) {
      log('setWebhook', params);
      try {
        self.flags.isUpdatingWebhook = true;
        self.flags.isURLSavedSuccess = false;
        self.flags.isURLSavedError = false;

        const response = yield self.WebhooksService.setWebhook(params);
        log('response: ', response.data);
        if (response?.data?.success) {
          self.flags.isURLSavedSuccess = true;
          self.webhookURL = params.url;
          self.businessWebhooks = cast(response.data?.webhooks);
        } else {
          self.flags.isURLSavedError = true;
        }
      } catch (e) {
        error(e);
        self.flags.isURLSavedError = true;
      } finally {
        self.flags.isUpdatingWebhook = false;
      }
    }),
    updateWebhook: flow(function* (params: UpdateWebhookParams, enableDisabled = '') {
      log('updateWebhook', params);
      try {
        self.flags.isUpdatingWebhook = true;
        self.flags.isURLSavedSuccess = false;
        self.flags.isURLSavedError = false;

        const response = yield self.WebhooksService.updateWebhook(params);
        log('response: ', response.data);
        if (response?.data?.success) {
          self.flags.isURLSavedSuccess = true;
          self.flags.isEditEnabled = false;
          self.businessWebhooks = cast(response.data?.webhooks);
          self.webhookURL = response?.data?.webhooks[0].url;

          if (enableDisabled === 'enabling') {
            self.flags.enableSuccess = true;
          }

          if (enableDisabled === 'disabling') {
            self.flags.disableSuccess = true;
          }
        } else {
          self.flags.isURLSavedError = true;
        }
      } catch (e) {
        error(e);
        self.flags.isURLSavedError = true;
      } finally {
        self.flags.isUpdatingWebhook = false;
      }
    }),
    sendNotificationWebhook: flow(function* (params: SendNotificationWebhookParams) {
      log('sendNotificationWebhook', params);
      try {
        self.flags.isSendingNotification = true;
        self.flags.sendingNotificationSuccess = false;
        self.flags.sendingNotificationError = false;

        const response = yield self.WebhooksService.sendNotificationWebhook(params);
        log('response: ', response.data);
        if (response?.data?.success) {
          self.flags.sendingNotificationSuccess = true;
        } else {
          self.flags.sendingNotificationError = true;
        }
      } catch (e) {
        error(e);
        self.flags.sendingNotificationError = true;
      } finally {
        self.flags.isSendingNotification = false;
      }
    }),
  }));

export interface WebhooksStoreType extends Instance<typeof WebhooksStore> {}
export default WebhooksStore;
