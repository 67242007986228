import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const QuestionIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0001 0C5.38328 0 0 5.38316 0 12C0 18.6168 5.38328 24 12.0001 24C18.617 24 24 18.6168 24 12C24 5.38316 18.617 0 12.0001 0Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8.5C9 7.76126 9.27084 7.16206 9.73517 6.73994C10.2052 6.3126 10.9497 6 12 6C14.0154 6 15 7.24262 15 8.5C15 9.48285 14.3962 10.0746 13.3415 10.9974L13.2576 11.0708C13.1008 11.2076 12.9338 11.3396 12.7641 11.4737C11.9143 12.1452 11 12.8676 11 14.5C11 15.0523 11.4477 15.5 12 15.5C12.5523 15.5 13 15.0523 13 14.5C13 13.7811 13.3231 13.5389 13.9094 13.0996C14.1246 12.9383 14.3753 12.7504 14.6585 12.5026L14.7424 12.4292C15.6842 11.6071 17 10.4582 17 8.5C17 6.00738 14.9846 4 12 4C10.5503 4 9.29475 4.4374 8.38983 5.26006C7.47916 6.08794 7 7.23874 7 8.5C7 9.05228 7.44772 9.5 8 9.5C8.55228 9.5 9 9.05228 9 8.5ZM12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20Z"
        fill="white"
      />
    </svg>
  );
};

QuestionIcon.defaultProps = {
  fill: defaultIconColor,
};

export default QuestionIcon;
