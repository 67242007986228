import { AxiosError, AxiosResponse } from 'axios';

import OysterService from '../http/services/OysterService';

import endpoints from './endpoints';

export default class ProfileContractService extends OysterService {
  /**
   * Get a list of posts from json placeholder service
   * @return Promise<AxiosResponse | AxiosError>
   */
  getProfileContract(prodCode: string): Promise<AxiosResponse | AxiosError> {
    const params = new URLSearchParams({ prodCode }).toString();

    return this.client.get(`${endpoints.profileContract}?${params}`);
  }
}
