import React from 'react';
import { ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';

import Skeleton from '@material-ui/lab/Skeleton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';

import { DataPropsItemType, TableColumn } from './types';
import useStyles from './styles';

export interface TableHeaderProps<T extends DataPropsItemType>
  extends Pick<TableColumn<T>, 'render' | 'emptyContent'> {
  index: string;
  columns: TableColumn<T>[];
  item: T;
  isCollapsibleRow?: boolean;
  isOnclickRow?: boolean;
  collapsibleRowComponent?: (item: T) => ReactElement | null;
  onOpenRow?: (item: T) => void;
  handleInternalIsOpenRow: (open: boolean) => void;
  internalIsOpenRow: boolean;
  internalOpenRowIndex: string;
  handleSetInternalIndexOpenRow: (index: string) => void;
  onClickRow?: (item: T) => void;
  isFetching?: boolean;
  oddColorRows?: boolean;
}

const TableCustomRow = <T extends DataPropsItemType>({
  index,
  columns,
  item,
  emptyContent = '',
  isCollapsibleRow,
  isOnclickRow,
  collapsibleRowComponent,
  onOpenRow,
  onClickRow,
  handleInternalIsOpenRow,
  internalIsOpenRow,
  internalOpenRowIndex,
  handleSetInternalIndexOpenRow,
  isFetching,
  oddColorRows,
}: TableHeaderProps<T>): ReactElement => {
  const css = useStyles();
  const [open, setOpen] = useState(false);

  const canOpenRow = index === internalOpenRowIndex;
  const isOpen = canOpenRow && internalIsOpenRow;
  const isHoverRow = isCollapsibleRow || isOnclickRow;
  const colorRows = oddColorRows ? css.tableRow : '';

  const handleOnclick = () => {
    if (isCollapsibleRow) {
      if (internalIsOpenRow && !canOpenRow) {
        setOpen(false);
        handleInternalIsOpenRow(false);
        handleSetInternalIndexOpenRow(internalOpenRowIndex);
        return;
      }

      setOpen(!open);
      handleInternalIsOpenRow(!open);
      handleSetInternalIndexOpenRow(index);
      if (typeof onOpenRow === 'function') {
        onOpenRow(item);
      }
      return;
    }
    if (isOnclickRow) {
      if (typeof onClickRow === 'function') {
        onClickRow(item);
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={
          isOnclickRow
            ? css.onclickTableRow
            : isOpen
            ? css.tableHeaderRowOpen
            : isCollapsibleRow
            ? css.collapsibleTableRow
            : colorRows
        }
        hover={!internalIsOpenRow && isHoverRow}
        onClick={() => handleOnclick()}
      >
        {columns.map((column, index) => {
          const { name, render } = column;
          let content: ReactNode = '';

          if (typeof render === 'function') {
            content = render(item);
          } else {
            if (typeof item === 'object') {
              content = (item as Record<string, string>)[name];
            } else {
              content = item as string;
            }
          }

          if ([null, undefined].includes(content as unknown as null | undefined)) {
            content = emptyContent;
          }

          return (
            <TableCell
              className={classNames(css.tableBodyCell, isOpen ? css.tableCellOpen : '')}
              key={`${name}-${index}`}
            >
              {!isFetching ? content : <Skeleton className={css.skeleton} variant="rect" />}
            </TableCell>
          );
        })}
      </TableRow>
      {isCollapsibleRow && (
        <TableRow className={isOpen ? css.tableBodyRowOpen : ''}>
          <TableCell className={css.tableBodyCellCollapsible} colSpan={columns.length}>
            <Collapse
              className={css.collapseContainer}
              in={canOpenRow && internalIsOpenRow}
              timeout="auto"
              unmountOnExit
            >
              {typeof collapsibleRowComponent === 'function' && collapsibleRowComponent(item)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default TableCustomRow;
