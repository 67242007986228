import { Instance, types } from 'mobx-state-tree';
import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

const AccountsPayableListItem = types.model({
  createdAt: '',
  businessName: '',
  clabe: '',
  amount: types.optional(types.union(types.number, types.string), ''),
  currency: '',
  status: types.optional(
    types.enumeration<AccountsPayableLinkStatus>(Object.values(AccountsPayableLinkStatus)),
    AccountsPayableLinkStatus.UNKNOWN,
  ),
  errMsg: '',
  trackingId: '',
  cepUrl: '',
});

export interface IAccountsPayableListItem extends Instance<typeof AccountsPayableListItem> {}

export default AccountsPayableListItem;
