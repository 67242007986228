import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      listStyleType: 'none',
      display: 'flex',
      margin: 0,
      padding: 0,
      justifyContent: 'space-between',
    },
    steps: { display: 'flex', padding: '0 8px' },
    stepNumber: {
      backgroundColor: theme.oysterTheme.shark,
      color: theme.oysterTheme.cotton,
      borderRadius: '50%',
      height: 20,
      minWidth: 20,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkIcon: { fontSize: 11 },
    stepTitle: { paddingLeft: 13, fontSize: 18, fontWeight: 700 },
    stepActive: {
      borderBottom: `3px solid ${theme.oysterTheme.oyster}`,
    },
    stepNumberActive: {
      backgroundColor: theme.oysterTheme.oyster,
      marginBottom: 8,
    },
  });
});

export default useStyles;
