import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';
import { DocumentType } from './enums';
import endpoints from './endpoints';

interface DeleteDocumentBody {
  type: DocumentType;
  documentId: string;
  documentContentId: string;
}

export default class KYCDocumentServie extends OysterService {
  /**
   * Get kyc document by documentTyoe
   * @return Promise<AxiosResponse | AxiosError>
   */
  getDocument(documentType: DocumentType): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.kycDocument, { params: { documentType } });
  }

  /**
   * Save new kyc document
   * @param formData
   * @returns Promise<AxiosResponse | AxiosError>
   */
  uploadDocument(formData: FormData): Promise<AxiosResponse | AxiosError> {
    return this.client.post(endpoints.kycDocument, formData);
  }

  /**
   * Save new kyc document
   * @param formData
   * @returns Promise<AxiosResponse | AxiosError>
   */
  deleteDocument(data: DeleteDocumentBody): Promise<AxiosResponse | AxiosError> {
    return this.client.delete(endpoints.kycDocument, { data });
  }
  /**
   * Get KYC documents status
   * @returns Promise<AxiosResponse | AxiosError>
   */
  getDocumentStatus(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.documentStatus);
  }
}
