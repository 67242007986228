import { FC } from 'react';
import { IconProps, pine } from './types';

const CalendarMonthIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6269 13.6637C9.6269 13.3787 9.39345 13.1478 9.10542 13.1478H7.28565C6.99794 13.1478 6.76445 13.3787 6.76445 13.6637V15.4656C6.76445 15.751 6.99794 15.982 7.28565 15.982H9.10542C9.39345 15.982 9.6269 15.751 9.6269 15.4656V13.6637Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M14.1747 13.6637C14.1747 13.3787 13.9412 13.1478 13.6538 13.1478H11.8338C11.5461 13.1478 11.3126 13.3787 11.3126 13.6637V15.4656C11.3126 15.751 11.5461 15.982 11.8338 15.982H13.6538C13.9412 15.982 14.1747 15.751 14.1747 15.4656V13.6637Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M18.7228 13.6637C18.7228 13.3787 18.4893 13.1478 18.2016 13.1478H16.3818C16.0938 13.1478 15.8604 13.3787 15.8604 13.6637V15.4656C15.8604 15.751 16.0938 15.982 16.3818 15.982H18.2016C18.4893 15.982 18.7228 15.751 18.7228 15.4656V13.6637Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M9.6269 18.168C9.6269 17.8826 9.39345 17.6519 9.10542 17.6519H7.28565C6.99794 17.6519 6.76445 17.8826 6.76445 18.168V19.9696C6.76445 20.2548 6.99794 20.4857 7.28565 20.4857H9.10542C9.39345 20.4857 9.6269 20.2547 9.6269 19.9696V18.168Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M14.1747 18.168C14.1747 17.8826 13.9412 17.6519 13.6538 17.6519H11.8338C11.5461 17.6519 11.3126 17.8826 11.3126 18.168V19.9696C11.3126 20.2548 11.5461 20.4857 11.8338 20.4857H13.6538C13.9412 20.4857 14.1747 20.2547 14.1747 19.9696V18.168Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M18.7228 18.168C18.7228 17.8826 18.4893 17.6519 18.2018 17.6519H16.3818C16.0938 17.6519 15.8604 17.8826 15.8604 18.168V19.9696C15.8604 20.2548 16.0938 20.4857 16.3818 20.4857H18.2018C18.4893 20.4857 18.7228 20.2547 18.7228 19.9696V18.168Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9767 6.39734V3.64513C23.0744 3.6779 23.9764 4.57739 23.9764 5.68298V21.8122C23.9764 22.9367 23.0528 23.8637 21.9171 23.8637H3.57019C2.43277 23.8637 1.51086 22.9386 1.51086 21.8122V5.68298C1.51086 4.57739 2.41286 3.6779 3.51056 3.64513V6.39734C3.51056 7.64131 4.52956 7.74037 5.78549 7.74037H7.22093C8.47681 7.74037 9.50932 7.64131 9.50932 6.39734V3.63525H15.9779V6.39734C15.9779 7.64131 17.0105 7.74037 18.2666 7.74037H19.7017C20.9577 7.74037 21.9767 7.64131 21.9767 6.39734ZM20.4199 21.6894C20.9116 21.6894 21.3102 21.2545 21.3102 20.7184L21.3101 11.5429C21.3101 11.0066 20.9116 10.5717 20.4198 10.5717H5.02817C4.53639 10.5717 4.13789 11.0066 4.13789 11.5429V20.7184C4.13789 21.2545 4.53644 21.6894 5.02817 21.6894H20.4199Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M5.78046 6.05649H7.20003C7.63091 6.05649 7.98027 5.71103 7.98027 5.28433V0.772436C7.98027 0.345692 7.63091 0 7.20003 0H5.78046C5.34953 0 5.00021 0.345692 5.00021 0.772436V5.28433C5.00021 5.71103 5.34953 6.05649 5.78046 6.05649Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M18.2483 6.05649H19.6679C20.0985 6.05649 20.4478 5.71103 20.4478 5.28433V0.772436C20.4479 0.345692 20.0985 0 19.6679 0H18.2483C17.8175 0 17.4681 0.345692 17.4681 0.772436V5.28433C17.4681 5.71103 17.8175 6.05649 18.2483 6.05649Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CalendarMonthIcon.defaultProps = {
  fill: pine,
};

export default CalendarMonthIcon;
