import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';
import LinkIcon from '@yaydoo/react-components/lib/icons/Link';
import ReusableLinkIcon from '@yaydoo/react-components/lib/icons/ReusableLink';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import { COLLECT_ROUTES } from '@constants/routes';
import useStore from '@hooks/useStore';
import useTicket from '@hooks/useTicket';
import PaymentStatusBadge from '@components/PaymentStatusBadge';
import EmptyListSkeleton from '@components/EmptyListSkeleton';
import InfiniteScroll from '@components/InfiniteScroll';
import { PaymentLinkFilterStatus } from '@constants/paymentLink';
import { IPaymentLink } from '@store/links/models/Link';
import { DATE_FORMAT_V1 } from '@constants/date';

import RightComponent from './RightComponent';
import useStyles from './styles';

const SecondaryText: FC<{ paymentLink: IPaymentLink }> = ({ paymentLink }) => {
  const css = useStyles();
  const { t } = useTranslation();

  const showPaymentMethodBadge = paymentLink.showPaymentMethodBadge && !!paymentLink.paymentMethod;

  return (
    <span className={css.item}>
      <Box display="flex" flex={1} component={Grid}>
        <span data-testid="link-title" className={css.itemTitle}>
          {paymentLink.title}
          {showPaymentMethodBadge && (
            <PaymentStatusBadge paymentMethod={paymentLink.paymentMethod} />
          )}
        </span>
      </Box>
      <span data-testid="link-number" className={css.itemLinkNumber}>
        {paymentLink.linkNumberFormatted}
      </span>
      <span data-testid="link-date" className={css.itemCreatedAt}>
        {t('collect.payments.links.createdAt', {
          createdAt: moment(paymentLink.createdAt).format(DATE_FORMAT_V1),
        })}
      </span>
    </span>
  );
};

const LeftIcon: FC<{ paymentLink: IPaymentLink }> = ({ paymentLink }) => {
  const css = useStyles();
  const CurrentLinkIcon = paymentLink.isMultiple ? ReusableLinkIcon : LinkIcon;

  return (
    <div className={css.iconContainer}>
      <CurrentLinkIcon className={css.icon} />
      {paymentLink.isMultiple && <span className={css.iconText}>{paymentLink.paidCount}</span>}
    </div>
  );
};

interface LinksProps {
  filter: PaymentLinkFilterStatus;
}

const Links: FC<LinksProps> = ({ filter }) => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const openTicket = useTicket('link');
  const openDepositTicket = useTicket('deposit');
  const { openToast } = useToast();
  const { paymentLink } = useStore();

  const { links, linksPagination, flags } = paymentLink;
  const { isFetchingLinks } = flags;
  const isEmptyList = !links.length;

  const handleClick = (link: IPaymentLink) => {
    if (link.isMultiple && link.hasPayments) {
      history.push(`${COLLECT_ROUTES.LINK_DETAILS}/${link.paymentLinkId}`);
    } else {
      if (link.settledDate && link.paymentOrderId) {
        openDepositTicket(link.paymentOrderId);
      } else {
        openTicket(link.paymentLinkId);
      }
    }
  };

  useEffect(() => {
    const getPaymentLinks = async () => {
      try {
        await paymentLink.getPaymentLinks({ status: filter });
      } catch {
        openToast({ message: t('collect.common.error.message'), type: 'error' });
      }
    };

    getPaymentLinks();
  }, [filter]);

  useEffect(() => {
    return () => {
      paymentLink.clearLinks();
    };
  }, []);

  if (isEmptyList) {
    return (
      <EmptyListSkeleton
        label={t('collect.payments.links.emptyStateLabel')}
        loadingLabel={t('collect.payments.links.loadingLabel')}
        isLoading={isFetchingLinks}
      />
    );
  }

  return (
    <div className={css.root}>
      <InfiniteScroll
        next={paymentLink.getMoreLinks}
        hasMore={linksPagination.hasMore}
        dataLength={links.length}
        scrollableTarget="list-container"
      >
        <List className={css.list}>
          {links.map((link) => (
            <CustomRow
              data-testid="payment-link-list-item"
              key={link.paymentLinkId}
              leftIcon={() => <LeftIcon paymentLink={link} />}
              onClick={() => handleClick(link)}
              secondaryTextComponent={<SecondaryText paymentLink={link} />}
              rightComponent={<RightComponent paymentLink={link} />}
            />
          ))}
        </List>
      </InfiniteScroll>
    </div>
  );
};

export default observer(Links);
