import { AxiosError } from 'axios';
import debug from 'debug';
import { Instance, cast, flow, types } from 'mobx-state-tree';

import { CommonStore } from '@yaydoo/react-mobx-stores/lib';

import Flags from '@store/common/Flags';
import Pagination from '@store/common/pagination';

import AccountsPayableBatchListItem from './models/AccountsPayableBatchListItem';
import LastBatchCreated from './models/LastBatchCreated';
import { BatchListQueryParams } from './models/types';
import BatchService from './service';

export interface IErrors extends AxiosError {
  error: boolean;
  msg: string;
  errorCode: string;
}

const log = debug('BatchStore:log');

const BatchStore = types
  .compose(
    CommonStore,
    types.model({
      flags: types.optional(Flags, {}),
      lastBatchCreated: types.optional(LastBatchCreated, {}),
      accountsPayableBatchList: types.optional(types.array(AccountsPayableBatchListItem), []),
      batchListPagination: types.optional(Pagination, { pageSize: 25 }),
      forceRefreshList: false,
    }),
  )
  .volatile((): { service: BatchService | null } => ({ service: null }))
  .views((self) => ({
    get BatchService() {
      if (!self.service) throw new Error('No service found');
      return self.service;
    },
  }))
  .actions((self) => ({
    updateService: () => {
      self.service = new BatchService();
    },
    clearBatchList: () => {
      self.batchListPagination.page = 1;
      self.accountsPayableBatchList = cast([]);
    },
    setForceRefreshList: (value: boolean) => {
      self.forceRefreshList = value;
    },
  }))
  .actions((self) => ({
    uploadBatchFile: flow(function* (fileContent: string) {
      try {
        self.flags.isSubmitting = true;
        self.lastBatchCreated.resetValues();
        log('trying to upload batch file');
        const result = yield self.BatchService.uploadBatchFile(fileContent);
        const data = result?.data;
        self.lastBatchCreated = data?.accountsPayableLink;
        return data;
      } catch (error) {
        const { response, msg, errorCode } = error as IErrors;
        const errorMessage = errorCode === 'INVALID' ? '' : msg || response?.data.msg;
        throw new Error(errorMessage);
      } finally {
        self.flags.isSubmitting = false;
      }
    }),
    getBatchList: flow(function* (params: BatchListQueryParams = {}) {
      const batchFilterParams = {
        page: self.batchListPagination.page,
        pageSize: self.batchListPagination.pageSize,
        ...params,
      };

      self.flags.isFetching = true;
      /* self.clearBatchList(); */

      try {
        const result = yield self.BatchService.getBatchList(batchFilterParams);
        const data = result?.data;

        const newListItems = data?.accountsPayableBatchList ? data.accountsPayableBatchList : [];
        const accountsPayableBatchList = [...self.accountsPayableBatchList, ...newListItems];

        self.accountsPayableBatchList = cast(accountsPayableBatchList);
        self.batchListPagination.setData(data?.pagination);
        return data;
      } catch (error) {
        const { response, msg } = error as IErrors;
        const errorMessage = response?.status === 500 ? '' : msg || response?.data.msg;
        throw new Error(errorMessage);
      } finally {
        self.flags.isFetching = false;
      }
    }),
    getResume: flow(function* (accountsPayableLinkId: string) {
      try {
        self.flags.isFetching = true;
        const result = yield self.BatchService.getResume(accountsPayableLinkId);
        const data = result?.data;
        return data;
      } catch (error) {
        const { response, msg } = error as IErrors;
        throw new Error(msg || response?.data.msg);
      } finally {
        self.flags.isFetching = false;
      }
    }),
  }))
  .actions((self) => ({
    getMoreBatchs: flow(function* (params: BatchListQueryParams = {}) {
      try {
        const { claculateNextPage: page, pageSize } = self.batchListPagination;
        if (page > self.batchListPagination.page) {
          yield self.getBatchList({ ...params, page, pageSize });
        }
      } catch (error) {
        const { message } = error as { message: string };
        throw new Error(message);
      }
    }),
  }));

export interface BatchStoreType extends Instance<typeof BatchStore> {}
export default BatchStore;
