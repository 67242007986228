import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';

export interface SetWebhookParams {
  url: string;
  businessId: string;
  method?: 'GET' | 'POST';
}

export interface GetWebhookParams {
  businessId: string;
}

export interface UpdateWebhookParams {
  webhookSubscriptionId: string;
  method?: string;
  url?: string;
  isActive?: boolean;
  fieldMask: string;
}

export interface SendNotificationWebhookParams {
  businessId: string;
  title: string;
  message: string;
}

/**
 * Services can extends from abstract class Service for clean configs
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/index.ts
 *
 * or can can extends from OysterService to use default base config for oyster endpoints that include some default interceptors
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/OysterService.ts
 *
 * @param @optional connection string Connection name to get axios config default is 'api'
 * @param @optional requestInterceptors RequestInterceptor[] interceptors to be applied before each request
 * @param @optional requestInterceptors ResponseInterceptor[] interceptors to be applied after each response
 */

export default class WebhooksService extends OysterService {
  /**
   * Get webhook by businessId
   * @return Promise<typeof AxiosResponse>
   */
  getWebhook({ businessId }: GetWebhookParams): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/merchant/webhooks/${businessId}`);
  }

  /**
   * Set webhook
   * @return Promise<typeof AxiosResponse>
   */
  setWebhook({
    method = 'POST',
    ...params
  }: SetWebhookParams): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/merchant/webhooks', { payload: { method, ...params } });
  }

  /**
   * Update webhook
   * @return Promise<typeof AxiosResponse>
   */
  updateWebhook({ ...params }: UpdateWebhookParams): Promise<AxiosResponse | AxiosError> {
    return this.client.patch('/merchant/webhooks', { payload: { ...params } });
  }

  /**
   * Sending notification webhook
   * @return Promise<typeof AxiosResponse>
   */
  sendNotificationWebhook({
    ...params
  }: SendNotificationWebhookParams): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/merchant/webhooks/test', {
      payload: {
        businessId: params.businessId,
        title: params.title,
        message: params.message,
      },
    });
  }
}
