import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';
import { PaymentLinkOrdersQueryParams, PaymentLinkQueryParams } from './types';
import { PaymentLink } from '@store/links/types';

/**
 * Services can extends from abstract class Service for clean configs
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/index.ts
 *
 * or can can extends from OysterService to use default base config for oyster endpoints that include some default interceptors
 * https://github.com/getOyster/react-mobx-stores/blob/main/src/http/services/OysterService.ts
 *
 * @param @optional connection string Connection name to get axios config default is 'api'
 * @param @optional requestInterceptors RequestInterceptor[] interceptors to be applied before each request
 * @param @optional requestInterceptors ResponseInterceptor[] interceptors to be applied after each response
 */
export default class PaymentLinkService extends OysterService {
  createPaymentLink(data: PaymentLink): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/payment-links', data);
  }
  getPaymentLinks(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/payment-links');
  }
  getPaymentLink(id: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/payment-links/${id}`);
  }
  markPaymentLinkAsPaid(id: string): Promise<AxiosResponse | AxiosError> {
    return this.client.put(`/payment-links/${id}/mark-as-paid`);
  }
  deletePaymentLink(id: string): Promise<AxiosResponse | AxiosError> {
    return this.client.delete(`payment-links/${id}`);
  }
  getPaymentLinkList(params: PaymentLinkQueryParams = {}): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/payment-links', { params });
  }
  uploadPaymentLinkBatchFile(fileContent: string): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/payment-links/batch', { fileContent });
  }
  getPaymentLinkOrders(
    id: string,
    params: PaymentLinkOrdersQueryParams = {},
  ): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/payment-links/${id}/payment-orders`, { params });
  }
  sendPaymentLinksReport(params: {
    from: string;
    to: string;
  }): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/payment-links/orders/reports', params);
  }
  refundPaymentOrder(id: string): Promise<AxiosResponse | AxiosError> {
    return this.client.post(`/payment-orders/${id}/refund`);
  }
  getBalance(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/payment-links/balance');
  }
}
