import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    returnButton: {
      paddingLeft: theme.spacing(2.3),
    },
    title: {
      fontSize: '1.5rem',
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(3),
      display: 'block',
      fontWeight: 700,
    },
  });
};

export default makeStyles(styles);
