import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
    },
    info: {
      fontSize: '0.75rem',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginLeft: 'unset',
        marginRight: 'unset',
      },
    },
    list: {
      width: '100%',
    },
    icon: {
      fontSize: 24,
      color: theme.oysterTheme.charcoal,
    },
    title: {
      color: theme.oysterTheme.obsidian,
      display: 'block',
    },
    textRight: {
      fontSize: 14,

      textAlign: 'right',
    },
    badge: {
      borderRadius: 4,
      fontSize: '0.625rem',
      fontWeight: 600,
      lineHeight: 1.2,
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
    },
    deposited: {
      '& $badge': {
        backgroundColor: alpha(theme.oysterTheme.pine, 0.1),
        color: theme.oysterTheme.pine,
      },
    },
  });
};

export default makeStyles(styles);
