import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

interface GreetingsProps {
  className?: string;
}

const Greetings: FC<GreetingsProps> = ({ className }) => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <div className={className}>
      <span className={css.sectionName}>{t('collect.layout.title')}</span>
    </div>
  );
};

export default Greetings;
