import { FC, useEffect } from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';

import useStore from '@hooks/useStore';
import useStyles from './styles';

export interface ILoading {
  title?: string;
  description?: string;
  hideHeader?: boolean;
  className?: string;
  circularProgresProps?: CircularProgressProps;
}
const Loading: FC<ILoading> = ({
  title,
  description,
  hideHeader,
  className,
  circularProgresProps,
}) => {
  const css = useStyles();
  const { app } = useStore();
  const cls = clsx(css.loadingContainer, className);

  useEffect(() => {
    if (hideHeader) {
      app.hideHeader();
      return () => {
        app.showHeader();
      };
    }
  }, []);

  return (
    <div className={cls} data-testid="loading-component">
      {title && <h3 className="title">{title}</h3>}
      {description && <span className="description">{description}</span>}
      <CircularProgress
        className={clsx(css.progress, 'circularProgress')}
        size="2em"
        thickness={6}
        data-testid="loading-circle"
        {...circularProgresProps}
      />
    </div>
  );
};

export default Loading;
