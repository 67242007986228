import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, setMonth, getMonth } from 'date-fns';

import FormButton from '@yaydoo/react-components/lib/components/FormButton';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import { ReactComponent as YellowShirtGuy } from '@assets/images/yellowShirt.svg';
import { CSV_MAX_MONTH_RANGE_EXPORT } from '@constants/paymentLink';

import DatePicker from '@components/common/DatePicker';
import useStore from '@hooks/useStore';
import { DATE_FORMAT_FORM_V2 } from '@constants/date';
import useStyles from './styles';

const today = new Date();
const limitDate = setMonth(today, getMonth(today) - CSV_MAX_MONTH_RANGE_EXPORT);

interface DownloadCsvProps {
  onSuccess?: () => void;
}

const DownloadCsv: FC<DownloadCsvProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const css = useStyles();
  const {
    paymentLink: { sendPaymentLinksReport },
  } = useStore();
  const { openToast } = useToast();
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!from || !to) {
      setFromError(!from);
      setToError(!to);
      return;
    }

    setIsSubmitting(true);
    try {
      await sendPaymentLinksReport({
        to: format(to, DATE_FORMAT_FORM_V2),
        from: format(from, DATE_FORMAT_FORM_V2),
      });

      openToast({
        type: 'success',
        message: t('collect.payments.downloadCsv.success.message'),
        title: t('collect.payments.downloadCsv.success.title'),
      });

      onSuccess && onSuccess();
    } catch {
      openToast({
        type: 'error',
        message: t('collect.payments.downloadCsv.error.message'),
        title: t('collect.payments.downloadCsv.error.title'),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className={css.header}>
        <YellowShirtGuy />
      </div>
      <div className={css.content}>
        <div className={css.titles}>
          <span className={css.title}>{t('collect.payments.downloadCsv.title')}</span>
          <span className={css.subtitle}>{t('collect.payments.downloadCsv.subtitle')}</span>
        </div>
        <div className={css.formContainer}>
          <DatePicker
            inputProps={{ inputProps: { 'data-testid': 'input-from' } }}
            label={t('collect.payments.downloadCsv.from')}
            onChange={(e) => {
              setFrom(e as Date);
              setFromError(false);
            }}
            value={from}
            maxDate={today}
            minDate={limitDate}
            openToDate={limitDate}
            error={fromError}
          />
          <DatePicker
            inputProps={{ inputProps: { 'data-testid': 'input-to' } }}
            label={t('collect.payments.downloadCsv.to')}
            onChange={(e) => {
              setTo(e as Date);
              setToError(false);
            }}
            value={to}
            maxDate={today}
            minDate={limitDate}
            error={toError}
            displayToday
          />
          <FormButton
            data-testid="submit-button"
            color="primary"
            onClick={onSubmit}
            label={t('collect.payments.downloadCsv.download')}
            className={css.button}
            disabled={isSubmitting}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadCsv;
