import { types, Instance } from 'mobx-state-tree';
import { REMINDERS } from '@constants/settings';
import isEqual from 'lodash.isequal';

const Reminders = types.enumeration([...REMINDERS]);

export type RemindersType = Instance<typeof Reminders>;

const Notification = types
  .model({
    enabled: false,
    reminders: types.array(Reminders),
    stringValue: '{}',
    isLoading: true,
    isSaving: false,
  })
  .views((self) => ({
    get availableReminders() {
      return REMINDERS.filter((reminder) => !self.reminders.includes(reminder));
    },
    get parsed() {
      return {
        config: self.reminders,
        enabled: self.enabled,
      };
    },
    get hasConfigs(): boolean {
      return self.reminders.length > 0;
    },
    get hasConfigChanges(): boolean {
      const originalValue = JSON.parse(self.stringValue) || {};
      return !isEqual(originalValue, this.parsed);
    },
  }));

export default Notification;
