import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import Uploader from '@yaydoo/react-components/lib/components/Uploader';

import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import { validFormatForAssets } from '@constants/cobranding';
import { isFile } from '@utils/validators';

import UploaderComponent from './CustomUpload';
import useStyles from '../styles';

const Assets: FC = () => {
  const css = useStyles();
  const { t } = usePaymentToolsTranslation();

  const { setValue, watch } = useFormContext();
  const logo = watch('assets.logo');
  const logoUrl = isFile(logo) ? URL.createObjectURL(logo as File) : logo;
  const favicon = watch('assets.favicon');
  const faviconUrl = isFile(favicon) ? URL.createObjectURL(favicon as File) : favicon;

  const onDelete = (handleDelete: () => void) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    handleDelete();
  };

  return (
    <Grid container className={css.wrapper}>
      <Grid item xs={12} className={css.sectionTitle}>
        {t('tools.cobranding.logoAndFavicon')}
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={css.uploaderContainer}>
          <Uploader
            onDrop={({ files }) => {
              setValue('assets.logo', files[0]);
            }}
            accept={validFormatForAssets}
            Component={
              <UploaderComponent
                title={
                  <Trans
                    i18nKey="paymentTools:tools.cobranding.assetText"
                    values={{ asset: 'Logo' }}
                    components={{ 1: <b /> }}
                  />
                }
                file={logoUrl}
                onDelete={onDelete(() => setValue('assets.logo', undefined))}
              />
            }
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={css.uploaderContainer}>
          <Uploader
            onDrop={({ files }) => {
              setValue('assets.favicon', files[0]);
            }}
            accept={validFormatForAssets}
            Component={
              <UploaderComponent
                title={
                  <Trans
                    i18nKey="paymentTools:tools.cobranding.assetText"
                    values={{ asset: 'Favicon' }}
                    components={{ 1: <b /> }}
                  />
                }
                file={faviconUrl}
                onDelete={onDelete(() => setValue('assets.favicon', undefined))}
              />
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Assets;
