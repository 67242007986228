import { FC } from 'react';
import { IconProps, pine } from './types';

const ReusableLinkIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  const fill = defaultColor ? props.fill : 'currentColor';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15.1892"
        y="2.4015"
        width="6.05319"
        height="8.24952"
        rx="2.25382"
        transform="rotate(37.3122 15.1892 2.4015)"
        stroke={fill}
        strokeWidth="2"
      />
      <rect
        x="8.08894"
        y="11.7179"
        width="6.05319"
        height="8.24952"
        rx="2.25382"
        transform="rotate(37.3122 8.08894 11.7179)"
        stroke={fill}
        strokeWidth="2"
      />
      <rect
        x="12.8807"
        y="9.15726"
        width="1.53703"
        height="6.41936"
        rx="0.65941"
        transform="rotate(37.3122 12.8807 9.15726)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.904136"
        strokeLinecap="round"
      />
      <path
        d="M22.0475 14.6957C21.2432 13.9957 20.1997 13.5652 19.0475 13.5652C17.2388 13.5652 15.6823 14.6174 14.9432 16.1435C14.804 16.4348 14.9606 16.7826 15.2692 16.887C15.5258 16.9739 15.804 16.8522 15.9214 16.6087C16.4866 15.4522 17.6736 14.6522 19.0475 14.6522C19.8953 14.6522 20.6692 14.9652 21.2736 15.4696L20.4432 16.3C20.1692 16.5739 20.3606 17.0435 20.7475 17.0435H23.1779C23.4171 17.0435 23.6127 16.8478 23.6127 16.6087V14.1783C23.6127 13.7913 23.1432 13.5957 22.8692 13.8696L22.0475 14.6957Z"
        fill={fill}
      />
      <path
        d="M16.4572 22.8696C17.2615 23.5696 18.305 24 19.4572 24C21.2659 24 22.8224 22.9478 23.5615 21.4217C23.7006 21.1304 23.5441 20.7826 23.2354 20.6783C22.9789 20.5913 22.7006 20.713 22.5832 20.9565C22.018 22.113 20.8311 22.913 19.4572 22.913C18.6093 22.913 17.8354 22.6 17.2311 22.0957L18.0615 21.2652C18.3354 20.9913 18.1441 20.5217 17.7572 20.5217L15.3267 20.5217C15.0876 20.5217 14.8919 20.7174 14.8919 20.9565L14.8919 23.387C14.8919 23.7739 15.3615 23.9696 15.6354 23.6957L16.4572 22.8696Z"
        fill={fill}
      />
    </svg>
  );
};

ReusableLinkIcon.defaultProps = {
  fill: pine,
};

export default ReusableLinkIcon;
