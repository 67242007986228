import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  const upScale = theme.breakpoints.up('md');

  return createStyles({
    root: { display: 'flex', flexDirection: 'column', [upScale]: { flexDirection: 'row' } },
    item: {
      paddingBottom: theme.spacing(2),
      [upScale]: {
        borderRight: `1px solid ${theme.oysterTheme.pine}`,
        paddingRight: 20,
        marginRight: 20,
        paddingBottom: 0,
      },
    },
    title: {
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      fontSize: 12,
      fontWeight: 600,
      color: theme.oysterTheme.shark,
    },
    totalAmount: {
      fontWeight: 700,
      fontSize: 16,
      display: 'block',
      [upScale]: { textAlign: 'right' },
    },
    success: {
      color: theme.oysterTheme.oyster,
    },
    error: {
      color: theme.oysterTheme.ferrari,
    },
  });
});

export default styles;
