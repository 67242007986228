import usePermissions from './usePermissions';

export interface UseHasRoleAccessParams {
  role: string;
}

export default function useHasRoleAccess({ role = '' }: UseHasRoleAccessParams): boolean {
  const { accessRole } = usePermissions();
  return accessRole === role || accessRole.includes(role);
}
