import { AxiosResponse, AxiosError } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';

export interface InviteUserParams {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: string;
}

export interface UpdateUserRoleParams {
  userId: string;
  role: string;
}

export default class UMService extends OysterService {
  /**
   * Get a list of invited users to a bussiness
   * @return Promise<typeof INVITED_USERS_DATA>
   */
  fetchInvitedUsers(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/invited/users');
  }

  /**
   * Get a list of invited users to a bussiness
   * @return Promise<typeof ASSOCIATED_USERS_DATA>
   */
  fetchAssociatedUsers(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/associated/users');
  }

  /**
   * Invite user
   * @return Promise<AxiosResponse>
   */
  fetchInviteUser(data: InviteUserParams): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/role/user/invite', data);
  }

  /**
   * Update user role
   * @return Promise<AxiosResponse>
   */
  fetchUpdateUserRole(data: UpdateUserRoleParams): Promise<AxiosResponse | AxiosError> {
    return this.client.put('/user/role', data);
  }

  /**
   * Cancel user invite
   * @return Promise<AxiosResponse>
   */
  fetchCancelUserInvite(email: string): Promise<AxiosResponse | AxiosError> {
    return this.client.patch(`/remove/invited/user/${email}`);
  }

  /**
   * Suspend associated user
   * @return Promise<AxiosResponse>
   */
  fetchSuspendAssociatedUser(userId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.patch(`/suspend/associated/user/${userId}`);
  }

  /**
   * Revoke suspend associated user
   * @return Promise<AxiosResponse>
   */
  fetchRevokeAssociatedUserSuspension(userId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.patch(`/revoke/suspension/associated/user/${userId}`);
  }

  /**
   * Remove associated user
   * @return Promise<AxiosResponse>
   */
  fetchRemoveAssociatedUser(userId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.patch(`/remove/associated/user/${userId}`);
  }
}
