import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from './styles';

interface CopyLinkProps {
  link: string;
  onCopyLink?: () => void;
}

const CopyLinkMessage = () => {
  const css = useStyles();
  const { t } = useTranslation();

  return (
    <div className={css.tooltipMessage}>
      {t('collect.checkout.ticket.copiedLink')}
      <CheckCircleIcon className="tooltip-icon" />
    </div>
  );
};

const CopyLink: FC<CopyLinkProps> = ({ link, onCopyLink }) => {
  const { paymentCheckoutUrl } = useEnvironmentVars();
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const css = useStyles();

  const copyLinkHandler = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    if (onCopyLink) onCopyLink();
  };

  return (
    <CopyToClipboard text={link ?? ''} onCopy={copyLinkHandler}>
      <ListItem button className={css.copyLinkItem} data-testid="ticket-copy-link-trigger">
        <ListItemText
          primary={<strong className="title">{t('collect.checkout.ticket.copyLink')}</strong>}
          secondary={<small>{paymentCheckoutUrl}</small>}
        />
        <Tooltip
          open={copied}
          arrow
          title={<CopyLinkMessage />}
          placement="top"
          classes={{ tooltip: css.tooltip }}
        >
          <FileCopyIcon fontSize="small" className={css.icon} />
        </Tooltip>
      </ListItem>
    </CopyToClipboard>
  );
};

export default CopyLink;
