import { ElementType, FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton, TextFieldProps } from '@material-ui/core';
import CopyVariant from '@icons/CopyVariant';
import { IconProps } from '@icons/types';

import Input from '@components/Input';
import useStyles from './styles';

export interface IShareLink {
  url: string;
  inputProps?: TextFieldProps;
  className?: string;
  icon?: ElementType | FC<IconProps>;
  onCopy?: () => void;
}

const ShareLink: FC<IShareLink> = ({
  url,
  inputProps,
  icon: Icon,
  className,
  onCopy = () => null,
}) => {
  const css = useStyles();

  return (
    <CopyToClipboard text={url} onCopy={onCopy}>
      <div className={`${css.inputContainer} ${className}`}>
        <Input
          className={css.urlInput}
          fullWidth
          autoComplete="off"
          disabled={true}
          value={url}
          {...inputProps}
        />
        <div className={css.iconContainer}>
          <IconButton size="small" className={css.iconButton}>
            {Icon ? <Icon /> : <CopyVariant />}
          </IconButton>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export default ShareLink;
