export default {
  sectionTitle: 'Dispersiones',
  createBatchButton: 'Crear lote',
  listTitle: 'LOTES GENERADOS',
  createBatch: {
    title: 'Carga tu archivo de dispersiones',
    instructions:
      'Para crear tu lote, adjunta tu <0>archivo con extensión .csv</0> siguiendo la <1>plantilla</1> que de la plataforma',
    notice: 'Es importante recordar no modificar o alterar los campos requeridos ni el orden.',
    help: '¿Necesitas ayuda para generar tu archivo .csv?',
    download: 'Descargar la plantilla',
    input: 'Adjunta tu archivo .csv...',
    success: 'Lote de dispersiones generado',
    successMsg:
      'Tu batch fue creado exitosamente, \nselecciona <0>ver detalles</0> para realizar los siguientes pasos',
    createdDate: 'Fecha de creación: {{date}}',
    fileSizeLimitExceeded: 'El archivo no puede ser mayor a {{maxFileSize}}',
    toast: {
      success: {
        title: 'Listo',
        msg: 'Lote de dispersiones generado',
      },
      error: {
        title: 'Error',
        msg: 'No se pudo cargar el archivo, verifica los datos e intenta nuevamente.',
      },
    },
  },
  batchList: {
    emptyStateLabel: 'Aún no tienes ningún lote',
    loadingLabel: 'Estamos recuperando tu información...',
    batch: 'Lote: {{batch}}',
    createdAt: 'Creado: {{createdAt}}',
    optionsMenu: {
      cancel: 'Cancelar',
    },
    totalAccountsPayable: '{{totalAccountsPayable}} operaciones',
    status: {
      pending: 'Pendiente',
      partiallyDispersed: 'Parcialmente dispersado',
      success: 'Dispersado',
      canceled: 'Cancelado',
      error: 'Error',
    },
  },
  filter: {
    status: {
      title: 'Estado de lote',
      all: 'Todos',
      pending: 'Pendiente',
      dispersed: 'Dispersado',
      partiallyDispersed: 'Parcialmente dispersado',
    },
    createdAt: {
      title: 'Estado por fecha',
      currentMonth: 'Mes actual',
      lastMonth: 'Últimos 30 días',
      lastThreeMonths: 'Últimos 3 meses',
      LastSixMonths: 'Últimos 6 meses',
    },
  },
  proccessingBatch: {
    details: 'Detalles',
    batch: 'Lote:',
    totalOperations: 'Total Facturas',
    date: 'Fecha',
    totalAmount: 'Monto Total',
    stepper: {
      validation: {
        title: 'Validación de pagos ',
        success: 'Pagos por procesar',
        error: 'Requieren verificar información',
        validate: 'Validar y continuar',
      },
      fundingRequest: {
        title: 'Solicitud y aprobación de fondeo',
        totalAmount: 'Monto total',
        reference: 'Referencia',
        clabe: 'CLABE',
        beneficiaryName: 'Beneficiario',
        bank: 'Banco',
        shareLink: 'Copia y comparte tu link',
        sendEmail: 'Envía por email',
        helpEmailFormat: 'Recuerda separar múltiples correos por comas <0>(,)</0>',
        emailPlaceholder: 'Ingresa e-mail',
        send: 'Enviar',
      },
      operationInProcess: {
        title: 'Operación en proceso',
        success: 'Dispersiones realizadas',
        error: 'Dispersiones no realizadas',
      },
      operationCompleted: {
        title: 'Operación completada',
        downloadReport: 'Descargar reporte',
      },
    },
  },
  toast: {
    errors: {
      title: 'Error',
      unknown: 'Intenta de nuevo',
      notFound: 'No se encontró información del lote seleccionado',
    },
    success: {
      title: 'Listo',
      sendEmail: 'Solicitud enviada',
      copyUrl: 'Enlace copiado',
      copyText: 'Texto copiado',
      completed: 'Operación completada',
      report: 'Tu archivo csv {{name}} fue generado con éxito',
    },
  },
  cancelModal: {
    title: '¿Estás seguro que deseas cancelar?',
    description:
      'Este proceso no se puede revertir, si cancelas, deberás comenzar el proceso nuevamente',
    confirmButton: 'Regresar',
    cancelButton: 'Cancelar operación',
  },
};
