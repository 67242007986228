import { useEffect, useState } from 'react';
import { Environments } from '@utils/Variables';

export type TEnvironment = Environments;

export interface IUseDebugger {
  isDebuggerInit: boolean;
}

function useDebugger(env: TEnvironment): IUseDebugger {
  const [isDebuggerInit, setDebuggerInit] = useState(false);

  useEffect(() => {
    if (env !== 'prod') {
      localStorage.setItem('debug', '*');
      setDebuggerInit(true);
    } else {
      localStorage.removeItem('debug');
      setDebuggerInit(false);
    }
  }, []);

  return { isDebuggerInit };
}

export default useDebugger;
