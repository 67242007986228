export enum AccountsPayableLinkStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  CONFIRMED = 'CONFIRMED',
  FUNDED = 'FUNDED',
  ON_DISPERSION = 'ON_DISPERSION',
  DISPERSION_SUCCESS = 'DISPERSION_SUCCESS',
  PARTIALLY_DISPERSED = 'PARTIALLY_DISPERSED',
  DISPERSION_ERROR = 'DISPERSION_ERROR',
  CANCELED = 'CANCELED',
  DISPERSION_FAIL = 'DISPERSION_FAIL',
  RETURNED = 'RETURNED',
  REFUNDED = 'REFUNDED',
}

export const accountsPayableLinkStep = {
  [`${AccountsPayableLinkStatus.ACTIVE}`]: 0,
  [`${AccountsPayableLinkStatus.CONFIRMED}`]: 1,
  [`${AccountsPayableLinkStatus.FUNDED}`]: 2,
  [`${AccountsPayableLinkStatus.ON_DISPERSION}`]: 2,
  [`${AccountsPayableLinkStatus.PARTIALLY_DISPERSED}`]: 3,
  [`${AccountsPayableLinkStatus.DISPERSION_SUCCESS}`]: 3,
  [`${AccountsPayableLinkStatus.DISPERSION_ERROR}`]: 3,
  [`${AccountsPayableLinkStatus.DISPERSION_FAIL}`]: 3,
};

export enum AccountsPayableLinkUpdateStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum Currencies {
  mxn = 'MXN',
  usd = 'USD',
}

export const locales = {
  [`${Currencies.mxn}`]: 'es-MX',
  [`${Currencies.usd}`]: 'en-US',
  '': 'es-MX',
};
