import { AccountsPayableLinkStatus } from '@yaydoo/react-mobx-stores/lib/constants/accountsPayableLink';

import { IBadge } from '@components/Badge';

export default function getBadgeColor(status: string): IBadge['color'] {
  const accountsPayableLinkStep = {
    [`${AccountsPayableLinkStatus.ACTIVE}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.CONFIRMED}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.FUNDED}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.ON_DISPERSION}`]: 'pineapple',
    [`${AccountsPayableLinkStatus.DISPERSION_SUCCESS}`]: 'oyster',
    [`${AccountsPayableLinkStatus.PARTIALLY_DISPERSED}`]: 'mango',
    [`${AccountsPayableLinkStatus.DISPERSION_ERROR}`]: 'ferrari',
    [`${AccountsPayableLinkStatus.CANCELED}`]: 'ferrari',
    [`${AccountsPayableLinkStatus.DISPERSION_FAIL}`]: 'ferrari',
    [AccountsPayableLinkStatus.RETURNED]: 'ferrari',
    [AccountsPayableLinkStatus.REFUNDED]: 'ferrari',
  };
  return accountsPayableLinkStep[status] as IBadge['color'];
}
