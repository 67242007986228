import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';

import useStyles from './styles';

interface FormContainerProps extends HTMLAttributes<HTMLDivElement> {}

const FormContainer: FC<FormContainerProps> = ({ children, className, ...props }) => {
  const css = useStyles();

  return (
    <div {...props} className={clsx(css.root, className)}>
      {children}
    </div>
  );
};

export default FormContainer;
