import { AxiosError } from 'axios';
import { Instance, cast, flow, types } from 'mobx-state-tree';
import moment from 'moment';

import { CommonStore } from '@yaydoo/react-mobx-stores/lib';

import Flags from '@store/common/Flags';
import Pagination from '@store/common/pagination';

import AccountsPayableListItem from './models/AccountsPayableListItem';
import { AccountsPayableQueryParams } from './models/types';
import BatchDetailsService from './service';

export interface IErrors extends AxiosError {
  error: boolean;
  msg: string;
  errorCode: string;
}

const BatchDetailsStore = types
  .compose(
    CommonStore,
    types.model({
      batchId: '',
      status: '',
      createdAt: '',
      totalSuccess: types.optional(types.union(types.number, types.string), ''),
      totalSuccessAmount: types.optional(types.union(types.number, types.string), ''),
      totalFail: types.optional(types.union(types.number, types.string), ''),
      totalFailAmount: types.optional(types.union(types.number, types.string), ''),
      flags: types.optional(Flags, {}),
      accountsPayable: types.optional(types.array(AccountsPayableListItem), []),
      accountsPayableListPagination: types.optional(Pagination, { pageSize: 25 }),
      forceRefreshList: false,
    }),
  )
  .volatile((): { service: BatchDetailsService | null } => ({ service: null }))
  .views((self) => ({
    get BatchDetailsService() {
      if (!self.service) throw new Error('No service found');
      return self.service;
    },
  }))
  .actions((self) => ({
    updateService: () => {
      self.service = new BatchDetailsService();
    },
    clearAccountsPayableList: () => {
      self.accountsPayableListPagination.page = 1;
      self.accountsPayable = cast([]);
    },
    setForceRefreshList: (value: boolean) => {
      self.forceRefreshList = value;
    },
    setData: (data: BatchDetailsStoreType) => {
      self.batchId = data.batchId;
      self.status = data.status;
      self.createdAt = data.createdAt;
      self.totalSuccess = data.totalSuccess;
      self.totalSuccessAmount = Number(data.totalSuccessAmount) / 100;
      self.totalFail = data.totalFail;
      self.totalFailAmount = Number(data.totalFailAmount) / 100;
    },
  }))
  .actions((self) => ({
    getBatchDetailsList: flow(function* (
      accountsPayableLinkId: string,
      params: AccountsPayableQueryParams = {},
    ) {
      const batchDetailsFilterParams = {
        page: self.accountsPayableListPagination.page,
        pageSize: self.accountsPayableListPagination.pageSize,
        ...params,
      };

      self.flags.isFetching = true;

      try {
        const result = yield self.BatchDetailsService.getBatchDetailsList(
          accountsPayableLinkId,
          batchDetailsFilterParams,
        );

        const data = result?.data;

        const newListItems = data?.accountsPayable
          ? data.accountsPayable.map((element: BatchDetailsStoreType) => {
              return {
                ...element,
                createdAt: moment(new Date(element.createdAt)).format('DD/MM/YYYY'),
              };
            })
          : [];
        const accountsPayableBatchList = [...self.accountsPayable, ...newListItems];
        self.setData(data);
        self.accountsPayable = cast(accountsPayableBatchList);
        self.accountsPayableListPagination.setData(data?.pagination);
        return data;
      } catch (error) {
        const { response, msg } = error as IErrors;
        const errorMessage = response?.status === 500 ? '' : msg || response?.data.msg;
        throw new Error(errorMessage);
      } finally {
        self.flags.isFetching = false;
      }
    }),
  }))
  .actions((self) => ({
    getMoreBatchDetails: flow(function* (
      accountsPayableLinkId: string,
      params: AccountsPayableQueryParams = {},
    ) {
      try {
        const { claculateNextPage: page, pageSize } = self.accountsPayableListPagination;
        if (page > self.accountsPayableListPagination.page) {
          yield self.getBatchDetailsList(accountsPayableLinkId, { ...params, page, pageSize });
        }
      } catch (error) {
        const { message } = error as { message: string };
        throw new Error(message);
      }
    }),
  }));

export interface BatchDetailsStoreType extends Instance<typeof BatchDetailsStore> {}
export default BatchDetailsStore;
