import { createStyles, makeStyles, alpha, Theme } from '@material-ui/core/styles';

export interface StyleProps {
  colorName: keyof Theme['oysterTheme'];
}

const styles = makeStyles<Theme, StyleProps>((theme) => {
  return createStyles({
    itemBadge: {
      display: 'block',
      borderRadius: 4,
      fontSize: '0.625rem !important',
      fontWeight: 600,
      textAlign: 'center',
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      minWidth: theme.spacing(10),
      backgroundColor: ({ colorName }) => alpha(theme.oysterTheme[colorName], 0.1),
      color: ({ colorName }) => theme.oysterTheme[colorName],
    },
  });
});

export default styles;
