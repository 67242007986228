import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  success: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
  return createStyles({
    dataContainer: { display: 'flex', justifyContent: 'space-between', marginBottom: 40 },
    item: { display: 'flex', flexDirection: 'column' },
    title: {
      textAlign: 'left',
      color: ({ success }) => (success ? theme.oysterTheme.emerald : theme.oysterTheme.crimson),
      marginBottom: 18,
    },
    header: { color: theme.oysterTheme.shark, fontSize: 13 },
    leftAlign: { textAlign: 'left' },
    rightAlign: { textAlign: 'right' },
    value: { fontSize: 16, fontWeight: 500, color: theme.oysterTheme.shark },
    totalAmount: {
      color: ({ success }) => (success ? theme.oysterTheme.oyster : theme.oysterTheme.ferrari),
      fontWeight: 500,
      fontSize: 18,
    },
  });
});

export default useStyles;
