import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const CheckIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 9 8"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46275 1.14253C8.72165 1.3943 8.7357 1.81713 8.49414 2.08697L4.1203 6.97275C4.0043 7.10233 3.84366 7.17858 3.67372 7.18471C3.50378 7.19085 3.33847 7.12637 3.21414 7.00547L0.870259 4.72619C0.611361 4.47442 0.597306 4.05159 0.838866 3.78175C1.08043 3.51192 1.48613 3.49727 1.74503 3.74903L3.62013 5.57246L7.55659 1.17525C7.79815 0.905419 8.20385 0.890769 8.46275 1.14253Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CheckIcon.defaultProps = {
  fill: defaultIconColor,
};

export default CheckIcon;
