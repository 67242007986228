import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@components/MoreMenu';
import { IAccountsPayableBatchListItem } from '@store/batch/models/AccountsPayableBatchListItem';
import { validCancelStatus, validSeeDetailsStatus } from '@constants/batch';

export interface IBatchOptions {
  batch: IAccountsPayableBatchListItem;
  onCancel: () => void;
  onSeeDetails: () => void;
}

const LinkOptions: FC<IBatchOptions> = ({ batch, onCancel, onSeeDetails }) => {
  const { t } = useTranslation();

  const options = [
    {
      key: 'option-cancel',
      label: t('batch.dashboard.batchList.optionsMenu.cancel'),
      onClick: onCancel,
      disabled: !validCancelStatus.includes(batch.status),
    },
    {
      key: 'option-see-details',
      label: t('batch.common.labels.seeDetails'),
      onClick: onSeeDetails,
      disabled: !validSeeDetailsStatus.includes(batch.status),
    },
  ].filter((option) => !option.disabled);

  if (options.length === 0) return <p style={{ width: 42 }} />;

  return <MoreMenu options={options} />;
};

export default LinkOptions;
