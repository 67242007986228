import { Instance, flow, types, cast } from 'mobx-state-tree';
import { errors } from '../constants';

import Common from '../common';
import AccessService from './service';
// import { userAccessData } from './mockData';

const FinancialInstrumentModel = types.model({
  debit: types.optional(types.boolean, false),
  credit: types.optional(types.boolean, false),
  api: types.optional(types.boolean, false),
  payment_links: types.optional(types.boolean, false),
  accounts_payable: types.optional(types.boolean, false),
});

const AccessStore = types
  .compose(
    Common,
    types.model({
      userRole: '',
      accessPages: types.array(types.string),
      accessActions: types.array(types.string),
      financialInstrument: types.optional(FinancialInstrumentModel, {}),
    }),
  )
  .views(() => ({
    get service() {
      return new AccessService();
    },
  }))
  .actions((self) => ({
    getUserAccess: flow(function* () {
      self.submitting = true;
      try {
        const { data } = yield self.service.getUserRole();
        if (data.success) {
          self.userRole = data?.role?.role;
          const userTags = JSON.parse(data?.role?.tags);
          const accessPages = Object.keys(userTags);
          const financialInstrument = JSON.parse(data?.financialInstrument);
          self.accessPages = cast(accessPages);
          const accessActions: string[] = [];
          accessPages.forEach((key) => {
            userTags[key].actions.forEach((action: string) => accessActions.push(action));
          });
          self.accessActions = cast(accessActions);
          self.financialInstrument = cast(financialInstrument);
        }
      } catch {
        self.setError({
          type: errors.ACCESS_USER_TAGS,
        });
      } finally {
        self.submitting = false;
      }
    }),
    hasAccessPages: (userTag: string) => {
      return self.accessPages.includes(userTag);
    },
    hasAccessActions: (userTag: string) => {
      return self.accessActions.includes(userTag);
    },
  }));

export type TAccessStore = Instance<typeof AccessStore>;
export default AccessStore;
