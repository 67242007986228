import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const LogoutIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
    >
      <path
        d="M6 3.33334V2.00001C6 1.26363 6.59695 0.666672 7.33333 0.666672H14C14.7364 0.666672 15.3333 1.26363 15.3333 2.00001V12.6667C15.3333 13.4031 14.7364 14 14 14H7.33333C6.59695 14 6 13.4031 6 12.6667V11.3333"
        stroke={defaultColor ? props.fill : 'currentColor'}
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M8.66626 3.60392C8.66626 3.32517 8.83968 3.07585 9.10102 2.97889L14.4344 1.00007C14.8698 0.838495 15.3329 1.16062 15.3329 1.6251V12.737C15.3329 13.0048 15.1727 13.2466 14.9261 13.351L9.59274 15.6079C9.15318 15.794 8.66626 15.4713 8.66626 14.994V3.60392Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        d="M2.66626 5.33328L0.66626 7.33331M0.66626 7.33331L2.66626 9.33328M0.66626 7.33331L5.99959 7.33333"
        stroke={defaultColor ? props.fill : 'currentColor'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LogoutIcon.defaultProps = {
  fill: defaultIconColor,
};

export default LogoutIcon;
