import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

export default class BeneficiariesService extends OysterService {
  /**
   * Get all business beneficiaries
   * @return Promise<AxiosResponse | AxiosError>
   */
  getBeneficiaries(): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/business/beneficiary');
  }
}
