const currencyFormatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const currencyFormatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

export const formatMoney = (amount: number): string => {
  return currencyFormatter.format(amount);
};

export const formatMoneyNoSign = (amount: number): string => {
  return formatMoney(amount).replace(/^(^\D)/, '');
};

export const formatMoneyUSD = (amount: number): string => {
  return currencyFormatterUSD.format(amount).replace(/[^$\d.-]/g, '');
};

export const formatPhoneNumber = (number: string | number): string => {
  return String(number)
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
};

export const formatClabe = (str: string): string => {
  return str.replace(/[^\d]/g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{6})/, '$1 $2 $3 $4');
};
