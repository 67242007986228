enum ROUTES {
  // NOTE: this should be the same base used in the container app
  LANDING = '/payment-transactions',
  DETAILS = '/payment-transactions/:transactionId',
  LOGIN = '/login',
  // Will change later
  BOILERPLATE = '/movements',
  TEST = '/:testId',
  TEST2 = '/test2',

  /* Add routes here that won't use project base route */
  PROFILE = '/user/profile',
}

export default ROUTES;
