import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ oysterTheme }: Theme) => ({
  fileUpload: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `0.0625em dashed ${oysterTheme.stone}`,
    backgroundColor: oysterTheme.pearl,
    color: oysterTheme.pine,
    fontSize: '1em',
    width: '29em',
    maxWidth: '100%',
    padding: '1em 0.625em',
    borderRadius: '0.5em',
    height: '3.4375em',
    outline: 'none',
    cursor: 'pointer',
    '& input[type="file"]': {
      display: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  error: {
    color: oysterTheme.ferrari,
  },
  startAdornment: {
    marginRight: '0.5em',
    '& img': {
      width: '1em',
      height: '1em',
    },
  },
  fileName: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 20,
  },
  endAdornment: {
    '& button': {
      width: '1em',
      height: '1em',
      padding: 0,
      margin: 0,
      border: 'none',
      outline: 'none',
    },
  },
  customUpload: {
    display: 'flex',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default useStyles;
