import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '@yaydoo/react-mobx-stores/lib/http/services/OysterService';
import { BatchListQueryParams } from './models/types';

/**
 * Services can extends from abstract class Service for clean configs
 * https://github.com/yaydoo/react-mobx-stores/blob/main/src/http/services/index.ts
 *
 * or can can extends from OysterService to use default base config for oyster endpoints that include some default interceptors
 * https://github.com/yaydoo/react-mobx-stores/blob/main/src/http/services/OysterService.ts
 *
 * @param @optional connection string Connection name to get axios config default is 'api'
 * @param @optional requestInterceptors RequestInterceptor[] interceptors to be applied before each request
 * @param @optional requestInterceptors ResponseInterceptor[] interceptors to be applied after each response
 */
export default class BatchService extends OysterService {
  /**
   * Upload a batch file
   * @param fileContent sting
   * @return Promise<AxiosResponse | AxiosError>
   */
  uploadBatchFile(fileContent: string): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/accounts-payable/upload/batch', { fileContent });
  }

  /**
   * Get batch list
   * @param params BatchListQueryParams
   * @return Promise<AxiosResponse | AxiosError>
   */
  getBatchList(params: BatchListQueryParams = {}): Promise<AxiosResponse | AxiosError> {
    return this.client.get('/accounts-payable', { params });
  }

  /**
   * Get batch resume
   * @param accountsPayableLinkId sting
   * @return Promise<AxiosResponse | AxiosError>
   */
  getResume(accountsPayableLinkId: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(`/accounts-payable/${accountsPayableLinkId}/download/resume`);
  }
}
