import { RefObject, useEffect, useState } from 'react';

type UseIsOnScreen = (ref: RefObject<Element>) => boolean;

const useIsOnScreen: UseIsOnScreen = (ref) => {
  const [isOnScreen, setIsOnScreen] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return isOnScreen;
};

export default useIsOnScreen;
