import { Instance, types } from 'mobx-state-tree';

const Metadata = types.model({
  accountsPayableLinkUrl: '',
});

export interface MetadataStore extends Instance<typeof Metadata> {}

const LastBatchCreated = types
  .model({
    accountsPayableLinkId: '',
    externalRefId: '',
    totalAmount: types.optional(types.union(types.number, types.string), ''),
    createdAt: '',
    metadata: types.optional(Metadata, {}),
  })
  .actions((self) => ({
    resetValues: () => {
      self.accountsPayableLinkId = '';
      self.externalRefId = '';
      self.totalAmount = '';
      self.createdAt = '';
      self.metadata = { accountsPayableLinkUrl: '' };
    },
  }));

export interface LastBatchCreatedStore extends Instance<typeof LastBatchCreated> {}
export default LastBatchCreated;
