import { FC } from 'react';
import { IconProps, pine } from './types';

const SecurityOptionsIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.537 2.90909C12.8539 1.69697 11.146 1.69697 10.4629 2.90909L1.24037 19.2727C0.557227 20.4849 1.41116 22 2.77745 22H21.2224C22.5887 22 23.4426 20.4849 22.7595 19.2727L13.537 2.90909ZM10.4702 7.16091C10.3956 6.26682 11.1012 5.5 11.9984 5.5C12.8956 5.5 13.6012 6.26682 13.5267 7.16091L12.9009 14.6695C12.8618 15.1389 12.4694 15.5 11.9984 15.5C11.5274 15.5 11.135 15.1389 11.0959 14.6695L10.4702 7.16091ZM13.6492 18.3501C13.6492 19.2614 12.9104 20.0001 11.9992 20.0001C11.0879 20.0001 10.3492 19.2614 10.3492 18.3501C10.3492 17.4388 11.0879 16.7001 11.9992 16.7001C12.9104 16.7001 13.6492 17.4388 13.6492 18.3501Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

SecurityOptionsIcon.defaultProps = {
  fill: pine,
};

export default SecurityOptionsIcon;
