import { types } from 'mobx-state-tree';
import Auth, { TAuthStore } from './auth';
import User, { TUserStore } from './user';
import Business, { TBusinessStore, PhoneModel } from './business';
import Address from './address';
import Account, { TAccountStore } from './account';
import { AddressType } from './address/enums';

export interface IGlobalStore {
  auth: TAuthStore;
  user: TUserStore;
  business: TBusinessStore;
  account: TAccountStore;
}

export const GlobalStore = types.model({
  auth: Auth,
  user: User,
  business: Business,
  account: Account,
});

const store: IGlobalStore = GlobalStore.create({
  auth: Auth.create({}),
  account: Account.create({}),
  user: User.create({
    phone: PhoneModel.create({}),
  }),
  business: Business.create({
    businessAddress: Address.create({
      type: AddressType.LEGAL,
    }),
    personalAddress: Address.create({
      type: AddressType.PERSONAL,
    }),
  }),
});

export default store;
