import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormButton from '@yaydoo/react-components/lib/components/FormButton';
import Input from '@yaydoo/react-components/lib/components/Input';
import CheckboxFilledIcon from '@yaydoo/react-components/lib/icons/CheckboxFilled';
import SecurityOptions from '@yaydoo/react-components/lib/icons/SecurityOptions';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import useStore from '@hooks/useStore';
import useCommonStore from '@hooks/useCommonStore';

import useStyles from '../styles';

interface IFormData {
  url: string;
}

const WebhookForm: FC = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { webhooks } = useStore();
  const { business } = useCommonStore();
  const { setWebhook, updateWebhook, flags, webhookURL, businessWebhooks, enableEditing } =
    webhooks;
  const {
    isWebhookLoading,
    isUpdatingWebhook,
    isURLSavedSuccess,
    isURLSavedError,
    isEditEnabled,
    updateField,
  } = flags;
  const css = useStyles();

  const isWebhookActive = businessWebhooks[0]?.isActive;

  const validationSchema = yup.object().shape({
    url: yup
      .string()
      .url(t('paymentTools:tools.webhooks.urlForm.errors.urlInvalid'))
      .required(t('paymentTools:tools.webhooks.urlForm.errors.urlInvalid')),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<IFormData>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      url: '',
    },
  });

  const handleSaveUrl = async (form: IFormData) => {
    await setWebhook({
      businessId: business.businessId,
      url: form.url,
      method: 'POST',
    });
  };

  const handleUpdateUrl = async (form: IFormData) => {
    await updateWebhook({
      webhookSubscriptionId: businessWebhooks[0]?.webhookSubscriptionId,
      url: form.url,
      fieldMask: 'url',
    });
  };

  const buttonDisabled = isWebhookLoading || !isValid;

  useEffect(() => {
    if (isURLSavedError) {
      openToast({
        type: 'error',
        title: t('paymentTools:tools.webhooks.urlForm.errors.updateURL.title'),
        message: t('paymentTools:tools.webhooks.urlForm.errors.updateURL.message'),
      });
      updateField('isURLSavedError', false);
    }
  }, [isURLSavedError]);

  return (
    <form
      data-testid="webhook-form"
      onSubmit={!isEditEnabled ? handleSubmit(handleSaveUrl) : handleSubmit(handleUpdateUrl)}
    >
      <Grid spacing={2} container>
        <Grid item xs={12} md={6}>
          {webhookURL === '' ? (
            <Controller
              name="url"
              control={control}
              defaultValue=""
              render={({ fieldState, field: { ref, ...rest } }) => (
                <Input
                  {...rest}
                  fullWidth
                  focused
                  placeholder="www.myurl.com"
                  variant="outlined"
                  label={t('paymentTools:tools.webhooks.urlForm.label')}
                  disabled={isWebhookLoading}
                  inputRef={ref}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  className={css.input}
                  inputProps={{ 'data-testid': 'input-url' }}
                  InputProps={
                    isURLSavedSuccess
                      ? {
                          endAdornment: (
                            <InputAdornment position="start">
                              <CheckboxFilledIcon className={css.activeIcon} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              )}
            />
          ) : (
            <Input
              fullWidth
              disabled={true}
              className={css.input}
              value={webhookURL}
              label={t('paymentTools:tools.webhooks.urlForm.label')}
              variant="outlined"
              inputProps={{ 'data-testid': 'saved-url' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {isWebhookActive ? (
                      <CheckboxFilledIcon className={css.activeIcon} />
                    ) : (
                      <SecurityOptions className={css.disableIcon} />
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={
                !isWebhookActive && t('paymentTools:tools.webhooks.enableDisable.urlDisabled')
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {webhookURL === '' ? (
            <FormButton
              fullWidth
              data-testid="save-url-btn"
              type="submit"
              color="primary"
              size="large"
              label={t('paymentTools:tools.webhooks.urlForm.submit')}
              disabled={buttonDisabled}
              className={css.formBtn}
              isSubmitting={isUpdatingWebhook}
            />
          ) : (
            <FormButton
              fullWidth
              data-testid="edit-url-btn"
              color="primary"
              size="large"
              disabled={!isWebhookActive}
              label={t('paymentTools:tools.webhooks.urlForm.edit')}
              className={css.formBtn}
              onClick={(e) => {
                e.preventDefault();
                enableEditing();
                reset();
              }}
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(WebhookForm);
