import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { formatMoney } from '@yaydoo/react-components/lib/utils/common';

import Badge, { BadgeProps } from '@components/Badge';
import { IPaymentLink } from '@store/links/models/Link';
import { TicketStatus } from '@constants/paymentLink';

import LinkOptions from './LinkOptions';
import useStyles from './styles';

export const getBadgeColor = (status: string): BadgeProps['color'] => {
  switch (status) {
    case TicketStatus.PENDING: {
      return 'pineapple';
    }
    case TicketStatus.PAID: {
      return 'oyster';
    }
    case TicketStatus.REFUNDED: {
      return 'ferrari';
    }
    default:
      return 'pine';
  }
};

const RightComponent: FC<{ paymentLink: IPaymentLink }> = ({ paymentLink }) => {
  const css = useStyles();
  const { t } = useTranslation();

  const linkStatusLabels = {
    [TicketStatus.UNKNOWN]: t('collect.payments.links.status.pending'),
    [TicketStatus.PENDING]: t('collect.payments.links.status.pending'),
    [TicketStatus.PAID]: t('collect.payments.links.status.paid'),
    [TicketStatus.SETTLED]: t('collect.payments.links.status.settled'),
    [TicketStatus.ERROR]: t('collect.payments.links.status.pending'),
    [TicketStatus.CLOSED]: t('collect.payments.links.status.pending'),
    [TicketStatus.REFUNDED]: t('collect.payments.links.status.refunded'),
  };

  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <Grid container direction="column" className={css.alignRight}>
          <span data-testid="link-total" className={css.itemTotal}>
            {formatMoney(paymentLink.totalAmount)}
          </span>
          <Badge
            label={linkStatusLabels[paymentLink.displayStatus]}
            color={getBadgeColor(paymentLink.displayStatus)}
          />
          {paymentLink.shouldDisplayAmountOfRefunds && (
            <span className={css.refund}>
              {paymentLink.refundCount}{' '}
              {t('collect.payments.links.refund', {
                plural: paymentLink.refundCount > 1 ? 's' : '',
              })}
            </span>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <LinkOptions paymentLink={paymentLink} />
      </Grid>
    </Grid>
  );
};

export default RightComponent;
