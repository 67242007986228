import { FC } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CaretRightIcon from '../../icons/CaretRight';
import LogoutIcon from '../../icons/Logout';
import UserIcon from '../../icons/User';
import useStyles from './styles';

export interface ISidebarUserFooter {
  logout?: () => void;
  goToProfile: () => void;
  logoutStr: string;
  fullName: string;
  profilePicUrl?: string;
  accountType: string;
  buttonLogoutId?: string;
  buttonAccountId?: string;
  disableGoToProfile?: boolean;
}

const SidebarUserFooter: FC<ISidebarUserFooter> = ({
  logout,
  logoutStr,
  goToProfile,
  fullName,
  profilePicUrl,
  accountType,
  buttonLogoutId = 'btn_logout',
  buttonAccountId = 'btn_profile_settings',
  disableGoToProfile = false,
}) => {
  const css = useStyles();

  return (
    <div className={css.sidebarFooter} data-testid="sidebarFooter">
      {profilePicUrl ? (
        <img src={profilePicUrl} alt="Avatar" className={css.profilePic} />
      ) : (
        <UserIcon width={36} height={36} className={css.profilePic} defaultColor />
      )}
      <section>
        <Button
          disabled={disableGoToProfile}
          className={disableGoToProfile ? css.profileDisabledBtn : css.profileBtn}
          type="button"
          onClick={goToProfile}
          data-testid="goToProfileBtn"
          id={buttonAccountId}
        >
          <div>
            <Typography variant="h6" className={css.accountType}>
              {accountType}
            </Typography>
            <Typography variant="h4" className={css.fullName}>
              {fullName}
            </Typography>
          </div>
          {!disableGoToProfile && <CaretRightIcon className={css.profileIcon} defaultColor />}
        </Button>
        <Button
          className={css.logout}
          onClick={logout}
          id={buttonLogoutId}
          data-testid="sidebarFooterLogout"
        >
          {logoutStr}
          <LogoutIcon width={15} height={15} className={css.logoutIcon} />
        </Button>
      </section>
    </div>
  );
};

export default SidebarUserFooter;
