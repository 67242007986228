import { FC } from 'react';
import { IconProps, pine } from './types';

const MoreIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  const fill = defaultColor ? props.fill : 'currentColor';
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="3.94013" cy="12" rx="1.94013" ry="2" fill={fill} />
      <ellipse cx="12.0001" cy="12" rx="1.94013" ry="2" fill={fill} />
      <ellipse cx="20.06" cy="12" rx="1.94013" ry="2" fill={fill} />
    </svg>
  );
};

MoreIcon.defaultProps = {
  fill: pine,
};

export default MoreIcon;
