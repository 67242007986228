import { FC, useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Popover from '@yaydoo/react-components/lib/components/Popover';
import PayCardIcon from '@yaydoo/react-components/lib/icons/PayCard';
import SalesIcon from '@yaydoo/react-components/lib/icons/Sales';
import IncomingIcon from '@yaydoo/react-components/lib/icons/Arrows/Incoming';
import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import { OverallStatusProductTypes } from '@yaydoo/react-mobx-stores/lib/business/enums';

import { PAYMENT_LINK_CARD_CAP } from '@constants/paymentLink';
import useBreakpoints from '@hooks/useBreakpoints';

import { CapabilityContentProps, CapabilityButtonWrapperProps } from './types';
import Loadable from '@components/Loadable';
import UpsellGuide from './UpsellGuide';
import useStyles from './styles';
import useCommonStore from '@hooks/useCommonStore';

const CapabilityButtonWrapper: FC<CapabilityButtonWrapperProps> = ({ children, onClick }) => {
  const css = useStyles();

  return (
    <Button
      data-testid="upsell-popover-button"
      className={css.upsellPopoverButton}
      variant="text"
      onClick={onClick}
    >
      <div>{children}</div>
    </Button>
  );
};

const CapabilityContent: FC<CapabilityContentProps> = ({
  className,
  id,
  isLoading,
  icon,
  capLabel,
  methodLabel,
}) => {
  const css = useStyles();

  return (
    <>
      <div className={className} data-testid={id}>
        <Loadable isLoading={isLoading} size={15}>
          <Box display="flex" alignItems="flex-end">
            <div className={css.icon}>{icon}</div>
            <Typography
              noWrap
              role="capability-label"
              data-testid={`label-${id}`}
              className={css.capLabel}
            >
              {capLabel}
            </Typography>
          </Box>
        </Loadable>
      </div>
      <div className={css.methodLabel}>{methodLabel}</div>
    </>
  );
};

const noCapLabel = 'collect.payments.capabilities.noCap';
const cardCapLabel = 'collect.payments.capabilities.cardCap';

const PaymentLinkCapabilities: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const { isSmallScreen, isExtraSmallScreen, isTouchScreen } = useBreakpoints();
  const { business } = useCommonStore();
  const capRef = useRef<HTMLDivElement | null>(null);
  const [isDisplayingUpsellPopover, setIsDisplayingUpsellPopover] = useState(false);

  const capabilities = useMemo(
    () =>
      [
        {
          id: 'capabilities-card',
          icon: <PayCardIcon />,
          methodLabel: t('collect.payments.capabilities.cardMethod'),
          capLabel: t(business.isOverallStatusCompleted ? noCapLabel : cardCapLabel, {
            amount: formatMoney(PAYMENT_LINK_CARD_CAP),
          }),
          hasCap: !business.isOverallStatusCompleted,
          loading: !business.isOverallStatusFetched,
        },
        {
          id: 'capabilities-cash',
          icon: <SalesIcon />,
          methodLabel: t('collect.payments.capabilities.cashMethod'),
          capLabel: t(noCapLabel),
          hasCap: false,
          loading: false,
          hide: isTouchScreen,
        },
        {
          id: 'capabilities-transfers',
          icon: <IncomingIcon />,
          methodLabel: t('collect.payments.capabilities.transfersMethod'),
          capLabel: t(noCapLabel),
          hasCap: false,
          loading: false,
          hide: isTouchScreen,
        },
      ].filter((filter) => !filter.hide),
    [business.isOverallStatusCompleted, business.isOverallStatusFetched, isTouchScreen],
  );

  useEffect(() => {
    if (!business.isOverallStatusFetched)
      business.getOverallStatus({
        productType: OverallStatusProductTypes.PRODUCT_TYPE_PAYMENT_LINK,
      });
  }, []);

  if (business.isOverallStatusCompleted || !business.isOverallStatusFetched) return null;

  return (
    <div className={css.root} data-testid="capabilities-section">
      <Typography component="h4" className={css.title}>
        {t('collect.payments.capabilities.title')}
      </Typography>

      <Grid container spacing={isExtraSmallScreen ? 2 : 6}>
        {capabilities.map((capability) => {
          const capabilityContainerClassName = clsx(css.capabilityContainer, {
            [css.cap]: capability.hasCap,
            [css.noCap]: !capability.hasCap,
          });

          return (
            <Grid item key={capability.id}>
              {capability.hasCap ? (
                <CapabilityButtonWrapper onClick={() => setIsDisplayingUpsellPopover(true)}>
                  <div className={css.popoverRef} ref={capRef}>
                    <CapabilityContent
                      capLabel={capability.capLabel}
                      className={capabilityContainerClassName}
                      icon={capability.icon}
                      id={capability.id}
                      isLoading={capability.loading}
                      methodLabel={capability.methodLabel}
                    />
                  </div>
                </CapabilityButtonWrapper>
              ) : (
                <div>
                  <CapabilityContent
                    capLabel={capability.capLabel}
                    className={capabilityContainerClassName}
                    icon={capability.icon}
                    id={capability.id}
                    isLoading={capability.loading}
                    methodLabel={capability.methodLabel}
                  />
                </div>
              )}
            </Grid>
          );
        })}
      </Grid>

      <Popover
        arrow
        showBackdrop
        open={isDisplayingUpsellPopover}
        onClose={() => setIsDisplayingUpsellPopover(false)}
        targetRef={capRef}
        title={t('collect.payments.capabilities.upsell.title')}
        placement={isSmallScreen ? 'bottom-start' : 'right-start'}
      >
        <div data-testid="upsell-popover">
          <UpsellGuide />
        </div>
      </Popover>
    </div>
  );
};

export default observer(PaymentLinkCapabilities);
