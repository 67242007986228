import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    capabilityContainer: {
      minHeight: 24,
    },
    title: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.oysterTheme.shark,
      letterSpacing: '0.08em',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: 12,
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    methodLabel: {
      clear: 'left',
      fontSize: 11,
      fontWeight: 600,
      color: theme.oysterTheme.dolphin,
    },
    capLabel: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 600,
    },
    cap: {
      '& $icon:after': {
        backgroundColor: theme.oysterTheme.ferrari,
      },
    },
    noCap: {
      '& $icon:after': {
        backgroundColor: theme.oysterTheme.oyster,
      },
    },
    upsellPopoverButton: {
      textAlign: 'left',
      padding: 0,
      lineHeight: 1.3,
    },
    icon: {
      marginRight: 5,
      position: 'relative',
      height: 24,
      fontSize: 30,
      '&:after': {
        content: '""',
        height: 10,
        width: 10,
        display: 'block',
        border: '1px solid #fff',
        position: 'absolute',
        background: 'red',
        right: -3,
        bottom: -2,
        borderRadius: '50%',
      },
    },
    popoverRef: {
      paddingRight: theme.spacing(1),
    },
  }),
);

export default useStyles;
