import { cast, Instance, types } from 'mobx-state-tree';

import CanNotProcess, { TCanNotProcessStore } from './CanNotProcess';
import CanProcess, { TCanProcessStore } from './CanProcess';

const AccountsPayableStore = types
  .model({
    canProcess: types.optional(types.array(CanProcess), []),
    canNotProcess: types.optional(types.array(CanNotProcess), []),
  })
  .views((self) => ({
    get getCanProcessLength() {
      return self.canProcess.length;
    },
    get getCanNotProcessLength() {
      return self.canNotProcess.length;
    },
  }))
  .actions((self) => ({
    updateAccountsPayable: (data: Instance<typeof self>) => {
      self.canProcess = cast(
        data?.canProcess?.map((item: TCanProcessStore) => ({
          ...item,
        })),
      );
      self.canNotProcess = cast(
        data?.canNotProcess?.map((item: TCanNotProcessStore) => ({
          ...item,
        })),
      );
    },
  }));

export interface TAccountsPayableStore extends Instance<typeof AccountsPayableStore> {}
export default AccountsPayableStore;
