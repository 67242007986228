import { FC } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useModal from '@yaydoo/react-components/lib/hooks/useModal';

import useStyles from './styles';

export interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  message: string;
  confirmLabelButton: string;
  cancelLabelButton: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  onConfirm,
  onCancel,
  title,
  message,
  confirmLabelButton,
  cancelLabelButton,
}) => {
  const css = useStyles();
  const { closeModal } = useModal();

  const handleOnConfirm = async () => {
    await onConfirm();
    closeModal();
  };

  const handleCancel = async () => {
    onCancel && (await onCancel());
    closeModal();
  };

  return (
    <div className={css.modalContainer}>
      <p className={css.modalContainerHeader}>{title}</p>
      <span className={css.modalContainerText}>{message} </span>
      <Box className={css.modalButtons}>
        <Button className={css.modalButton} onClick={handleCancel} data-testid="cancel-button">
          {cancelLabelButton}
        </Button>
        <Button
          className={`${css.modalButton} ${css.modalButtonRed}`}
          onClick={handleOnConfirm}
          data-testid="confirm-button"
        >
          {confirmLabelButton}
        </Button>
      </Box>
    </div>
  );
};

export default ConfirmModal;
