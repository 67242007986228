import { createContext, FC } from 'react';
import Variables from '@utils/Variables';

export interface IEnvironmentVariables {
  variables: Variables;
}

const EnvironmentVariablesContext = createContext<IEnvironmentVariables>({
  variables: new Variables(),
});

const EnvironmentVariablesProvider: FC<IEnvironmentVariables> = ({ variables, children }) => {
  return (
    <EnvironmentVariablesContext.Provider value={{ variables }}>
      {children}
    </EnvironmentVariablesContext.Provider>
  );
};

export { EnvironmentVariablesContext, EnvironmentVariablesProvider };
