import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';

import endpoints from './endpoints';

export default class CommonService extends OysterService {
  /**
   * Get a list of posts from json placeholder service
   * @return Promise<AxiosResponse | AxiosError>
   */
  fetchBalance(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.creditBalance);
  }
  getSubscribedLoan(): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.getSubscribedLoan);
  }
  fetchKycStatus(productType: string): Promise<AxiosResponse | AxiosError> {
    return this.client.get(endpoints.kycStatus, {
      params: {
        productType,
      },
    });
  }
}
