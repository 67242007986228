import { Instance, flow, types } from 'mobx-state-tree';

import Common from '../common';
import { Gender } from './enums';
import UserService from './service';
import { errors } from '../constants';
import { PhoneModel, TPhoneModel } from '../business';
import OnboardingVersionType from '../constants/onboardingVersionType';

type GenderStrings = keyof typeof Gender;

const UserStore = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      isFetching: false,
      onboardingCompleted: false,
      emailVerified: false,
      userId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: PhoneModel,
      countryCode: 'MX',
      country: '',
      gender: 1,
      profilePicUrl: '',
      kycApplicantId: '',
      dob: '',
      createdAt: '',
      updatedAt: '',
      defaultBusinessId: '',
      curp: '',
      occupation: '',
      userRefId: '',
      birthState: '',
      birthCountry: 'MEX',
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      taxId: '',
      nationality: '',
      onboardingState: 0,
      intercomHash: '',
      termsConditionsVersion: '',
      onboardingVersionName: '',
      initialDashboardAccessAt: types.maybeNull(types.string),
      refUserType: '',
    }),
  )
  .views((self) => ({
    get service() {
      return new UserService();
    },
    get fullName() {
      return `${self.firstName} ${self.middleName} ${self.lastName}`.trim();
    },
    get onboardingVersionType() {
      if (self.onboardingVersionName.includes(OnboardingVersionType.CREDIT)) {
        return OnboardingVersionType.CREDIT;
      }
      if (self.onboardingVersionName.includes(OnboardingVersionType.PAYMENT)) {
        return OnboardingVersionType.PAYMENT;
      }
      return OnboardingVersionType.DEBIT;
    },
  }))
  .actions((self) => ({
    setGender: (gender: GenderStrings) => {
      self.gender = (Gender[gender] || Gender.UNKNOWN_GENDER) as number;
    },
    setProfilePicUrl: (profilePicUrl: string) => {
      const re = /\.(?:com|io|mx)\b/gi;
      profilePicUrl = profilePicUrl.split(re)[1];
      self.profilePicUrl = profilePicUrl;
    },
  }))
  .actions((self) => ({
    update: ({
      user,
      userOnboardingVersionInfo,
    }: {
      user: Partial<IGetUser>;
      userOnboardingVersionInfo?: { onboardingVersionName?: string };
    }) => {
      self.curp = user.curp || self.curp;
      self.email = user.email || self.email;
      self.firstName = user.firstName || self.firstName;
      self.middleName = user.middleName || self.middleName;
      self.lastName = user.lastName || self.lastName;
      self.userId = user.userId || self.userId;
      self.userRefId = user.userRefId || self.userRefId;
      self.kycApplicantId = user.kycApplicantId || self.kycApplicantId;
      self.emailVerified = !!user.emailVerified;
      self.birthState = user.birthState || self.birthState;
      self.onboardingCompleted = user.onboardingCompleted || self.onboardingCompleted;
      self.occupation = user.occupation || self.occupation;
      self.defaultBusinessId = user.defaultBusinessId || self.defaultBusinessId;
      self.taxId = user.taxId || '';
      if (user.gender) self.setGender(user.gender as GenderStrings);
      if (user.dob) {
        self.dob = user.dob;
        const [year, month, day] = user.dob.split('-');
        self.birthYear = year;
        self.birthMonth = month;
        self.birthDay = day;
      }

      if (user.profilePicUrl) {
        self.setProfilePicUrl(user.profilePicUrl);
      }

      if (user.termsConditionsVersion) {
        self.termsConditionsVersion = String(user.termsConditionsVersion).toUpperCase();
      }

      if (user.phone && user.phone.fullPhoneNumber) {
        const phoneData = {
          ...user.phone,
          valid: true,
          phone: user.phone.fullPhoneNumber.substring(3),
        };
        self.phone.setPhone(phoneData);
      }

      if (userOnboardingVersionInfo) {
        self.onboardingVersionName = userOnboardingVersionInfo.onboardingVersionName || '';
      }

      self.initialDashboardAccessAt =
        user.initialDashboardAccessAt || self.initialDashboardAccessAt;

      self.isFetched = user.isFetched || self.isFetched;
      self.refUserType = user.refUserType || self.refUserType;
    },
  }))
  .actions((self) => ({
    getDetails: flow(function* () {
      self.isFetched = false;
      try {
        self.submitting = true;
        const { data } = yield self.service.fetchUser();
        self.isFetched = true;
        self.update(data);
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    uploadProfilePic: flow(function* (file: File) {
      try {
        self.submitting = true;
        const formData = new FormData();
        formData.append('file', file);

        const { data } = yield self.service.uploadProfilePic(formData);
        if (data.user) {
          const { profilePicUrl } = data.user;
          self.setProfilePicUrl(profilePicUrl);
        }
        return data;
      } catch (error) {
        self.setError({
          type: errors.PROFILE_PIC,
        });
        throw error;
      } finally {
        self.submitting = false;
      }
    }),
    fetchIntercomHash: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield self.service.fetchIntercomHash();
        if (data.success) {
          self.intercomHash = data.intercomUserHash;
        }
      } catch (error) {
        self.setError({
          type: errors.INTERCOM_HASH,
        });
      } finally {
        self.submitting = false;
      }
    }),
    updateUserInitialDashboardAccess: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield self.service.fetchInitialAccess();
        return data;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TUserStore = Instance<typeof UserStore>;
export interface IGetUser extends Omit<TUserStore, 'phone' | 'gender'> {
  phone: Partial<TPhoneModel>;
  gender?: string;
}
export default UserStore;
