export default {
  ticket: {
    title: 'Link de pago',
    to: 'Para',
    from: 'De',
    details: 'Ver detalles de pago',
    createAt: 'Link creado el {{date}}, a las {{time}} por',
    copyLink: 'Copia tu link',
    copiedLink: '¡Copiaste el link!',
    items: {
      quantity: 'CANT.',
    },
    status: {
      UNKNOWN: 'DESCONOCIDO',
      PENDING: 'PENDIENTE',
      PAID: 'PAGADO',
      SETTLED: 'DEPOSITADO',
      CLOSED: 'CERRADO',
      REFUNDED: 'REEMBOLSADO',
      ERROR: 'ERROR',
    },
    settled: {
      amount: 'Monto Cobrado',
      fee: 'Comisión Oyster',
      total: 'Total',
    },
    refunded: {
      amount: 'Monto Reembolsado',
      fee: 'Comisión Oyster',
      total: 'Total',
    },
  },
};
