import { FC } from 'react';
import { IconProps, oyster } from './types';

const CaretRightIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="0.625em"
      height="1em"
      viewBox="0 0 8 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.979185C0.683417 0.588661 1.31658 0.588661 1.70711 0.979185L7.36396 6.63604C7.75449 7.02656 7.75449 7.65973 7.36396 8.05025L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.097631 13.3166 -0.0976313 12.6834 0.292893 12.2929L5.24264 7.34315L0.292893 2.3934C-0.0976315 2.00287 -0.0976315 1.36971 0.292893 0.979185Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CaretRightIcon.defaultProps = {
  fill: oyster,
};

export default CaretRightIcon;
