import { FC } from 'react';
import { IconProps, obsidian } from './types';

const DepositIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7273 40C36.7439 40 40 36.7439 40 32.7273C40 28.7107 36.7439 25.4545 32.7273 25.4545C28.7107 25.4545 25.4546 28.7107 25.4546 32.7273C25.4546 36.7439 28.7107 40 32.7273 40ZM33.5708 31.9191H36.3631V33.5353H33.5708V36.3636H31.8828V33.5353H29.0904V31.9191H31.8828V29.0909H33.5708V31.9191Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0358 35.4007C22.1989 36.025 20.2299 36.3636 18.1818 36.3636C8.14028 36.3636 0 28.2234 0 18.1818C0 8.14028 8.14028 0 18.1818 0C28.2234 0 36.3636 8.14028 36.3636 18.1818C36.3636 20.2299 36.025 22.1989 35.4007 24.0358C34.5554 23.7761 33.6577 23.6364 32.7273 23.6364C27.7065 23.6364 23.6364 27.7065 23.6364 32.7273C23.6364 33.6577 23.7761 34.5554 24.0358 35.4007ZM25.4545 21.7099C25.4545 18.4836 22.3061 17.6712 19.4178 17.0445V12.3559C20.7969 12.4952 22.254 12.8897 23.6331 13.6093L24.7 11.265C23.2948 10.4526 21.3433 9.94197 19.4178 9.84913V7.27273H17.2841V9.89555C13.2249 10.2437 11.2994 12.3095 11.2994 14.677C11.2994 17.9033 14.3958 18.7389 17.2841 19.3656V24.0077C15.3066 23.8453 13.355 23.1721 12.08 22.2437L10.9091 24.588C12.2622 25.6325 14.7341 26.3752 17.2841 26.5145V29.0909H19.4178V26.4913C23.503 26.1199 25.4545 24.0774 25.4545 21.7099ZM14.656 14.5377C14.656 13.5164 15.4367 12.6809 17.2841 12.4255V16.5571C15.7749 16.1625 14.656 15.6518 14.656 14.5377ZM19.4178 23.9613V19.853C20.979 20.2476 22.1239 20.7814 22.1239 21.9188C22.1239 22.9168 21.3173 23.706 19.4178 23.9613Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

DepositIcon.defaultProps = {
  fill: obsidian,
};

export default DepositIcon;
