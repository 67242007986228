import { Instance, types } from 'mobx-state-tree';

const AppStore = types
  .model({
    loadingStatus: false,
    displayHeader: true,
  })
  .actions((self) => ({
    setLoading: () => {
      self.loadingStatus = true;
    },
    clearLoading: () => {
      self.loadingStatus = false;
    },
    showHeader: () => {
      self.displayHeader = true;
    },
    hideHeader: () => {
      self.displayHeader = false;
    },
  }));

export interface AppStoreType extends Instance<typeof AppStore> {}
export default AppStore;
