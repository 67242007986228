import { flow, Instance, types } from 'mobx-state-tree';
import CommonStore from '@yaydoo/react-mobx-stores/lib/common';

import TestService from './service';

const TestStore = types
  .compose(
    CommonStore,
    types.model({
      flag: false,
    }),
  )
  .views(() => ({
    get service() {
      return new TestService();
    },
  }))
  .actions((self) => ({
    toggleFlag: () => {
      self.flag = !self.flag;
    },
    fetchPosts: flow(function* () {
      const data = yield self.service.fetchPosts();
      // Add your logic
      return data;
    }),

    findPost: flow(function* (id: string) {
      const data = yield self.service.findPost(id);
      // Add your logic
      return data;
    }),
  }));

export type TestStoreType = Instance<typeof TestStore>;
export default TestStore;
