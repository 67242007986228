import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import hasActionAccess from '@yaydoo/react.ui-access-manager/lib/access/hasActionAccess';
import { allowedActions } from '@yaydoo/react.ui-access-manager/lib/constants/allowedActions';
import DownloadIcon from '@yaydoo/react-components/lib/icons/Arrows/Download';
import UploadIcon from '@yaydoo/react-components/lib/icons/Arrows/Upload';
import Button from '@material-ui/core/Button';

import CustomDrawer from '@components/CustomDrawer';
import DownloadCsv from '@components/DownloadCsv';
import CsvUpload from '@components/CsvUpload';
import useStyles from './styles';

const PaymentLinkOptions: FC = () => {
  const css = useStyles();
  const lastOption = clsx(css.menuOption, css.noBorder);
  const { t } = useTranslation();
  const { showDrawer, closeDrawer } = useDrawer();

  const onShowBulkCsv = () => {
    showDrawer({
      component: () => (
        <CustomDrawer>
          <CsvUpload />
        </CustomDrawer>
      ),
      options: {
        useCustomDrawer: true,
        useDefaultWidth: true,
      },
    });
  };

  const onDownloadCsv = () => {
    showDrawer({
      component: () => (
        <CustomDrawer headerClassName={css.drawer}>
          <DownloadCsv onSuccess={closeDrawer} />
        </CustomDrawer>
      ),
      options: {
        useCustomDrawer: true,
        useDefaultWidth: true,
      },
    });
  };

  const UploadCsvOption = hasActionAccess({
    Component: () => (
      <Button
        onClick={onShowBulkCsv}
        className={css.menuOption}
        startIcon={<UploadIcon className={css.optionIcon} />}
      >
        {t('collect.payments.buttons.csv')}
      </Button>
    ),
    action: allowedActions.PAYMENTS_BULK_PL_UPLOAD,
  });

  const DownloadCsvOption = hasActionAccess({
    Component: () => (
      <Button
        onClick={onDownloadCsv}
        className={lastOption}
        startIcon={<DownloadIcon className={css.optionIcon} />}
      >
        {t('collect.payments.buttons.downloadCsv')}
      </Button>
    ),
    action: allowedActions.PAYMENTS_DOWNLOAD_REPORTS,
  });

  return (
    <div className={css.optionsContainer}>
      <UploadCsvOption />
      <DownloadCsvOption />
    </div>
  );
};

export default PaymentLinkOptions;
