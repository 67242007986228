import { useContext } from 'react';
import { EnvironmentVariablesContext } from '@components/EnvironmentVarsWrapper';
import Variables from '@utils/Variables';

const useEnvironmentVars = (): Variables => {
  const { variables } = useContext(EnvironmentVariablesContext);

  return variables;
};

export default useEnvironmentVars;
