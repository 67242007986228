import { Position, DropdownPosition } from './types';

export const getPosition = (position?: Position): DropdownPosition => {
  switch (position) {
    case 'left':
      return {
        anchor: {
          vertical: 'center',
          horizontal: 'left',
        },
        transform: {
          vertical: 'center',
          horizontal: 'right',
        },
      } as const;

    case 'right':
      return {
        anchor: {
          vertical: 'center',
          horizontal: 'right',
        },
        transform: {
          vertical: 'center',
          horizontal: 'left',
        },
      } as const;

    case 'top':
      return {
        anchor: {
          vertical: 'top',
          horizontal: 'center',
        },
        transform: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      } as const;

    case 'bottom':
    default:
      return {
        anchor: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transform: {
          vertical: 'top',
          horizontal: 'center',
        },
      } as const;
  }
};
