import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      background: 'rgba(0, 0, 0, 0.2)',
    },
    isActiveRef: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 2,
    },
    isActive: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 3,
    },
    hasArrow: {
      '&[x-placement*="bottom"]': {
        marginTop: '0.4em',
        '& $arrow': {
          top: 0,
          left: 0,
          marginTop: '-0.9em',
          width: '3em',
          height: '1em',
          '&::before': {
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent ${theme.oysterTheme.cotton} transparent`,
          },
        },
      },
      '&[x-placement*="top"]': {
        marginBottom: '0.4em',
        '& $arrow': {
          bottom: 0,
          left: 0,
          marginBottom: '-0.9em',
          width: '3em',
          height: '1em',
          '&::before': {
            borderWidth: '1em 1em 0 1em',
            borderColor: `${theme.oysterTheme.cotton} transparent transparent transparent`,
          },
        },
      },
      '&[x-placement*="right"]': {
        marginLeft: '0.4em',
        '& $arrow': {
          left: 0,
          marginLeft: '-0.9em',
          height: '3em',
          width: '1em',
          '&::before': {
            borderWidth: '1em 1em 1em 0',
            borderColor: `transparent ${theme.oysterTheme.cotton} transparent transparent`,
          },
        },
      },
      '&[x-placement*="left"]': {
        marginRight: '0.4em',
        '& $arrow': {
          right: 0,
          marginRight: '-0.9em',
          height: '3em',
          width: '1em',
          '&::before': {
            borderWidth: '1em 0 1em 1em',
            borderColor: `transparent transparent transparent ${theme.oysterTheme.cotton}`,
          },
        },
      },
    },
    paper: {
      borderRadius: 10,
    },
    header: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(2),
    },
    title: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      marginRight: theme.spacing(3),
      color: theme.oysterTheme.charcoal,
    },
    closeButton: {
      color: theme.oysterTheme.dolphin,
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
  }),
);

export default useStyles;
