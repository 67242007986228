import { FC } from 'react';
import { IconProps, obsidian } from './types';

const MarketingIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4659 22.1613C24.8707 22.3469 20.5201 23.5846 16.6437 25.6358L11.6592 28.6169C10.341 29.4042 8.65748 28.9317 7.90096 27.5734L5.36776 23.0301C4.60962 21.6693 5.0615 19.9227 6.37529 19.1375L10.505 16.6676C10.7441 16.5246 11.0499 16.6094 11.188 16.8571L15.9479 25.4541C16.0884 25.7061 16.4026 25.7897 16.6437 25.6358C16.8769 25.4869 16.9406 25.1633 16.8036 24.9176L12.0203 16.2786C11.8904 16.0456 11.9542 15.7483 12.1676 15.5951C15.5769 13.1468 18.4612 10.0286 20.6763 6.39205C21.5155 5.01321 23.4708 5.05011 24.2595 6.46455L31.1966 18.9109C31.985 20.3223 31.0411 22.0979 29.4659 22.1613ZM30.3561 13.4929C30.6129 11.9704 29.7514 10.4434 28.3589 9.92073C27.9212 9.75643 27.5241 10.2498 27.7568 10.6674C28.3108 11.6616 28.8668 12.6592 29.4291 13.6674C29.6639 14.0885 30.2753 13.972 30.3561 13.4929ZM18.8138 31.8182L16.4409 27.5518C16.3031 27.3039 15.9969 27.2189 15.7575 27.3619C14.4716 28.1304 14.423 28.1602 12.7076 29.1858C12.4684 29.3288 12.3861 29.6457 12.5243 29.8935L14.9012 34.1571C15.2058 34.7031 15.7538 35 16.3118 35C16.8531 35 17.0601 34.8103 18.2187 34.1176C18.995 33.6535 19.2619 32.6216 18.8138 31.8182ZM34.0069 9.5551C34.2461 9.41209 34.3281 9.09534 34.1899 8.84765C34.0519 8.60003 33.7462 8.51503 33.5069 8.6581L32.2132 9.43177C31.7704 9.69655 31.9556 10.3983 32.4637 10.3983C32.6415 10.3982 32.6059 10.3929 34.0069 9.5551ZM29.4092 6.87142L29.7247 5.65204C29.7962 5.37574 29.6379 5.0918 29.3712 5.01774C29.1042 4.94381 28.8302 5.10766 28.7587 5.38396L28.4432 6.60335C28.3583 6.93169 28.5972 7.25544 28.9265 7.25544C29.1472 7.25538 29.3493 7.10279 29.4092 6.87142ZM34.9828 15.0851C35.0543 14.8088 34.896 14.5248 34.6293 14.4508L33.4519 14.124C33.1852 14.0502 32.911 14.214 32.8395 14.4903C32.768 14.7665 32.9264 15.0505 33.1931 15.1246C34.4807 15.4819 34.41 15.4691 34.5001 15.4691C34.7209 15.4691 34.923 15.3164 34.9828 15.0851Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

MarketingIcon.defaultProps = {
  fill: obsidian,
};

export default MarketingIcon;
