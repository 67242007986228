import { Instance, types } from 'mobx-state-tree';

export const emptyItem = {
  paymentOrderItemId: '',
  paymentOrderId: '',
  name: '',
  quantity: '',
  price: '',
  total: '0.00',
};

export const defaultItem = {
  paymentOrderItemId: '',
  paymentOrderId: '',
  name: '',
  quantity: 1,
  price: 0,
  total: 0,
};

const OrderItem = types
  .model({
    paymentOrderItemId: '',
    paymentOrderId: '',
    name: '',
    quantity: types.optional(types.union(types.number, types.string), ''),
    price: types.optional(types.union(types.number, types.string), ''),
    total: types.optional(types.union(types.number, types.string), ''),
  })
  .views((self) => ({
    get parsedItems() {
      return {
        paymentOrderItemId: self.paymentOrderItemId,
        paymentOrderId: self.paymentOrderId,
        name: self.name,
        quantity: +self.quantity,
        price: +self.price / 100,
        total: +self.total / 100,
      };
    },
  }));

export interface TOrderItem extends Instance<typeof OrderItem> {}
export default OrderItem;
