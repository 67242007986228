import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export type TVariant = 'small' | 'medium' | 'big' | 'info' | 'infoSecondary' | 'bold';
interface IProps {
  variant: TVariant;
  hideBottomSeparator: boolean;
}

const getVariantCss = (variant: TVariant, theme: Theme) => {
  if (variant === 'small') {
    return {
      borderBottom: `0.0513333125em solid ${theme.oysterTheme.stone}`,
      primary: {
        fontSize: '0.82125em',
        fontWeight: 500,
        color: theme.oysterTheme.charcoal,
      },
      secondary: {
        fontSize: '0.71875em',
        fontWeight: 400,
        color: theme.oysterTheme.shark,
      },
    };
  }
  if (variant === 'medium') {
    return {
      borderBottom: `0.0513333125em solid ${theme.oysterTheme.stone}`,
      primary: {
        fontSize: '0.875em',
        fontWeight: 400,
        color: theme.oysterTheme.charcoal,
      },
      secondary: {
        fontSize: '0.875em',
        fontWeight: 400,
        color: theme.oysterTheme.dolphin,
      },
    };
  }
  if (variant === 'info') {
    return {
      borderBottom: `0.0625em solid ${theme.oysterTheme.stone}`,
      paddingTop: '0.2em',
      paddingBottom: '0.2em',
      minHeight: '4.375em',
      primary: {
        fontSize: '0.875em',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: theme.oysterTheme.dolphin,
      },
      secondary: {
        fontSize: '1em',
        color: theme.oysterTheme.shark,
      },
    };
  }
  if (variant === 'infoSecondary') {
    return {
      borderBottom: `0.0625em solid ${theme.oysterTheme.stone}`,
      primary: {
        color: theme.oysterTheme.shark,
        fontSize: '0.875em',
        fontWeight: 600,
      },
      secondary: {
        color: theme.oysterTheme.extraGrey,
        fontSize: '1em',
      },
    };
  }
  if (variant === 'bold') {
    return {
      borderBottom: `0.0625em solid ${theme.oysterTheme.stone}`,
      primary: {
        color: theme.oysterTheme.charcoal,
        fontSize: '16px',
        fontWeight: 600,
      },
      secondary: {
        color: theme.oysterTheme.shark,
        fontSize: '14px',
      },
    };
  }
  return {
    borderBottom: `0.0625em solid ${theme.oysterTheme.stone}`,
    primary: {
      fontSize: '1em',
      fontWeight: 400,
      color: theme.oysterTheme.charcoal,
    },
    secondary: {
      fontSize: '0.875em',
      fontWeight: 400,
      color: theme.oysterTheme.shark,
    },
  };
};

const getIconCss = (variant: TVariant) => {
  if (variant === 'small') {
    return {
      minWidth: '2.4em',
      width: '1.3125em',
      height: '1.3125em',
    };
  }
  return {
    minWidth: '2.5em',
    width: '1.5em',
    height: '1.5em',
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: (props: IProps) => {
      const { primary, secondary, borderBottom, ...restStyles } = getVariantCss(
        props.variant,
        theme,
      );
      const borderStyles = !props.hideBottomSeparator ? { borderBottom: borderBottom } : {};
      return {
        width: '100%',
        '& .MuiListItemText-primary': primary,
        '& .MuiListItemText-secondary': secondary,
        ...borderStyles,
        ...restStyles,
        '&.Mui-disabled': {
          backgroundColor: `${theme.oysterTheme.dolphin}40`,
          cursor: 'not-allowed',
        },
      };
    },
    icon: (props: IProps) => getIconCss(props.variant),
    leftText: {
      fontWeight: 400,
      color: theme.oysterTheme.shark,
    },
  }),
);

export default useStyles;
