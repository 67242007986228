import useFirebaseAnalytics from '@yaydoo/react-components/lib/hooks/useFirebaseAnalytics';
import getEventValues, { EventParams } from '@utils/getEventValues';

interface IUseAnalytics {
  sendEvent: (params: EventParams) => void;
}

const useAnalytics = (): IUseAnalytics => {
  const { logEvent } = useFirebaseAnalytics();

  const sendEvent = (params: EventParams) => {
    const { name, eventParams } = getEventValues(params);

    logEvent(name, eventParams);
  };

  return { sendEvent };
};

export default useAnalytics;
