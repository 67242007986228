import { FC, ReactElement, MouseEvent } from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import MenuItem, { MenuItemProps as MuiMenuItemProps } from '@material-ui/core/MenuItem';
import MoreIcon from '@yaydoo/react-components/lib/icons/More';

import DropdownMenu from '@components/DropdownMenu';
import { CustomButton } from '@components/DropdownMenu/types';
import useStyles from './styles';

export interface IMoreMenuOption extends MuiMenuItemProps {
  key: string;
  label?: string;
  component?: ReactElement;
}

export interface IMoreMenu {
  options: Array<IMoreMenuOption>;
}

const MoreIconButton: FC<CustomButton> = ({ onClick }) => (
  <IconButton
    data-testid="more-icon"
    color="secondary"
    disableRipple
    size="small"
    onClick={onClick}
  >
    <MoreIcon />
  </IconButton>
);

const handleOnClick = (
  event: MouseEvent<HTMLLIElement>,
  onClick?: (event: MouseEvent<HTMLLIElement>) => void,
) => {
  event.stopPropagation();
  onClick && onClick(event);
};

const MoreMenu: FC<IMoreMenu> = ({ options }) => {
  const css = useStyles();

  return (
    <DropdownMenu className={css.root} customButton={MoreIconButton}>
      {options?.map((option, index) => {
        const menuItemClassName = clsx(css.menuItem, option.className);

        return (
          <MenuItem
            role="option-menu"
            data-testid="option-menu"
            key={option.key}
            onClick={(e) => handleOnClick(e, option.onClick)}
            divider={index + 1 < options.length}
            disabled={option.disabled}
            classes={{
              root: menuItemClassName,
            }}
          >
            {option.component ? option.component : option.label}
          </MenuItem>
        );
      })}
    </DropdownMenu>
  );
};

export default MoreMenu;
