import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';
import Input from '@yaydoo/react-components/lib/components/Input';

import useStore from '@hooks/useStore';
import { emailReg } from '@utils/validators';
import NumberFormat from '@components/common/NumberFormat';
import usePaymentToolsTranslation from '@hooks/usePaymentToolsTranslation';
import useStyles from '../styles';

const Support: FC = () => {
  const { t } = usePaymentToolsTranslation();
  const { control, formState } = useFormContext();
  const {
    settings: {
      cobranding: { contactInfo },
    },
  } = useStore();
  const css = useStyles();

  return (
    <Grid container className={`${css.wrapper} ${css.noBorder}`} spacing={2}>
      <Grid item xs={12}>
        <div className={css.sectionTitle}>{t('tools.cobranding.support')}</div>
        <div className={css.description}>{t('tools.cobranding.supportDescription')}</div>
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="contactInfo.email"
          control={control}
          defaultValue={contactInfo.email}
          rules={{ pattern: emailReg }}
          render={({ field: { ref, ...field }, fieldState }) => (
            <Input
              {...field}
              fullWidth
              inputRef={ref}
              label={t('tools.cobranding.supportEmail')}
              error={fieldState.invalid}
              disabled={formState.isSubmitting}
              inputProps={{
                'data-testid': 'email',
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="contactInfo.phone"
          control={control}
          defaultValue={contactInfo.phone}
          rules={{ minLength: 10, maxLength: 10 }}
          render={({ field: { ref, ...field }, fieldState }) => (
            <Input
              {...field}
              fullWidth
              inputProps={{
                'data-testid': 'phone',
                allowNegative: false,
                thousandSeparator: true,
                decimalScale: 0,
                format: '(###) ### ####',
                mask: '_',
              }}
              InputProps={{ inputComponent: NumberFormat }}
              inputRef={ref}
              label={t('tools.cobranding.supportPhone')}
              error={fieldState.invalid}
              disabled={formState.isSubmitting}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default observer(Support);
