import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import Badge, { BadgeProps } from '@components/Badge';
import Loadable from '@components/Loadable';
import Tooltip from '@components/Tooltip';
import useStore from '@hooks/useStore';
import useStyles from './styles';

const Balance: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const {
    paymentLink: { balance, getBalance, flags },
  } = useStore();
  const hasAmount = balance.balanceFormatted > 0;
  const badgeColor: BadgeProps['color'] = hasAmount ? 'pineapple' : 'oyster';
  const label = hasAmount ? t('collect.layout.balance.pending') : t('collect.layout.balance.empty');

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Loadable isLoading={flags.isFetchingBalance}>
      <div data-testid="balance">
        {hasAmount && (
          <div className={css.amount} data-testid="amount">
            <Tooltip arrow title={t('collect.layout.balance.tooltip')} placement="right">
              <span>{formatMoney(balance.balanceFormatted)}</span>
            </Tooltip>
          </div>
        )}
        <Badge label={label} color={badgeColor} className={css.badge} />
      </div>
    </Loadable>
  );
};

export default observer(Balance);
