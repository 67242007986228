import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  const upScale = theme.breakpoints.up('md');

  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 440,
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(1),
      [upScale]: {
        padding: 'unset',
      },
    },
    header: {
      display: 'block',
      fontSize: 24,
      fontWeight: 700,
      marginBottom: theme.spacing(5),
      [upScale]: {
        marginLeft: theme.spacing(3),
      },
    },
    //Bottom
    footer: {
      display: 'flex',
      flexDirection: 'column',
      width: '85%',
      alignSelf: 'center',
    },
    submit: {
      marginTop: theme.spacing(6),
      backgroundColor: theme.oysterTheme.pine,
      color: theme.oysterTheme.cotton,
      fontWeight: 600,
      padding: theme.spacing(1.2),
    },
    download: {
      color: theme.oysterTheme.oyster,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    downloadIcon: {
      height: 10,
      width: 10,
    },
    fileInputContainer: {
      textAlign: 'center',
    },
    // Slider
    sliderContainer: {
      backgroundColor: theme.oysterTheme.pearl,
      padding: '1em 4em',
      marginBottom: theme.spacing(4),
    },
    dots: {
      width: 'unset',
    },
    nextButton: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: 0.4,
      color: theme.oysterTheme.pine,
    },
    card: {
      height: 320,
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    step: {
      fontSize: 11.8462,
      fontWeight: 600,
      marginTop: theme.spacing(1),
    },
    image: {
      alignSelf: 'center',
    },
    description: {
      fontSize: 14,
      marginBottom: theme.spacing(1),
    },
  });
});

export default useStyles;
