import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from '@yaydoo/react-components/lib/components/Slider';
import Button from '@yaydoo/react-components/lib/components/Button';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';
import useDrawer from '@yaydoo/react-components/lib/hooks/useDrawer';
import DownloadIcon from '@yaydoo/react-components/lib/icons/Arrows/Download';
import useEnvironmentVars from '@yaydoo/react-components/lib/hooks/useEnvironmentVars';

import Loading from '@components/Loading';
import FileInput from '@components/common/FileInput';
import FileUploadSuccess from '@components/FileUploadSuccess';
import { ReactComponent as FirstStep } from '@assets/images/csvGuide/step1.svg';
import { ReactComponent as SecondStep } from '@assets/images/csvGuide/step2.svg';
import { ReactComponent as ThirdStep } from '@assets/images/csvGuide/step3.svg';

import { BATCH_URL as batchUrl } from '@constants/csv';
import getAssetsUrl from '@utils/getAssetsUrl';
import useStore from '@hooks/useStore';
import useStyles from './styles';

const CsvUpload: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const { paymentLink } = useStore();
  const { openToast } = useToast();
  const environmentVars = useEnvironmentVars();
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { showDrawer } = useDrawer();

  const steps = useMemo(
    () => [
      {
        step: FirstStep,
        description: t('collect.payments.csv.step1'),
      },
      {
        step: SecondStep,
        description: t('collect.payments.csv.step2'),
      },
      {
        step: ThirdStep,
        description: t('collect.payments.csv.step3'),
      },
    ],
    [],
  );

  const onFileChange = (file: File | null) => {
    if (!file) {
      setFileContent(null);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const content = reader.result as string;
        setFileContent(content);
      },
      false,
    );

    reader.readAsDataURL(file);
  };

  const successHandler = () => {
    showDrawer({
      component: FileUploadSuccess,
      options: {
        useCustomDrawer: true,
        useDefaultWidth: true,
      },
    });
  };

  const errorHandler = () => {
    openToast({
      type: 'error',
      title: t('collect.payments.csv.error'),
      message: t('collect.payments.csv.errorMsg'),
    });
  };

  const onSubmit = async () => {
    if (!fileContent) return;
    setIsLoading(true);
    try {
      await paymentLink.uploadPaymentLinkBatchFile(fileContent);
      successHandler();
    } catch {
      errorHandler();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className={css.root}>
      <span className={css.header}>{t('collect.payments.csv.title')}</span>
      <div className={css.sliderContainer}>
        <Slider
          showPrevArrow={false}
          nextButtonLabel={t('collect.payments.csv.next')}
          classes={{ dots: css.dots, nextButton: css.nextButton }}
        >
          {steps.map(({ step: Step, description }, index) => (
            <div key={index} className={css.card}>
              <span className={css.step}>{`${t('collect.payments.csv.step')} ${index + 1}`}</span>
              <Step className={css.image} />
              <span className={css.description}>{description}</span>
            </div>
          ))}
        </Slider>
      </div>
      <div className={css.footer}>
        <Button
          variant="text"
          href={getAssetsUrl(batchUrl, environmentVars.s3Assets)}
          label={t('collect.payments.csv.download')}
          className={css.download}
          startIcon={<DownloadIcon className={css.downloadIcon} />}
        />
        <div className={css.fileInputContainer}>
          <FileInput
            hasFile={!!fileContent}
            fileType=".csv"
            onChange={onFileChange}
            label={t('collect.payments.csv.input')}
          />
        </div>
        <Button
          label={t('collect.payments.csv.submit')}
          className={css.submit}
          disabled={!fileContent}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default CsvUpload;
