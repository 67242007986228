import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import { formatMoneyNoSign, formatMoney } from '@yaydoo/react-components/lib/utils/common';
import Button from '@yaydoo/react-components/lib/components/FormButton';

import { DATE_FORMAT_FORM } from '@constants/date';
import useGetUserBusinessData from '@hooks/useGetUserBusinessData';
import { TPaymentOrderDetails } from '@store/common/order';
import useStyles from './styles';

interface RefundTicketViewProps {
  paymentOrder: TPaymentOrderDetails;
  onRefund: () => void;
  balance: number;
}

const RefundTicket: FC<RefundTicketViewProps> = ({ paymentOrder, onRefund, balance }) => {
  const css = useStyles();
  const { t } = useTranslation();
  const { name } = useGetUserBusinessData();
  const hasEnoughBalance = +balance >= paymentOrder.totalAmount;
  const createdAt =
    paymentOrder?.createdAt && format(new Date(paymentOrder?.createdAt), DATE_FORMAT_FORM);

  return (
    <div className={css.root} data-testid="refund-ticket">
      <div className={css.container}>
        <div className={css.infoContainer}>
          <section className={css.header}>
            <div className={css.dateContainer}>
              <span>
                <strong className={`${css.paidLabel} ${css.textMargin}`}>
                  {t('collect.refund.ticket.header.paid')}
                </strong>
              </span>
              <span className={css.textMargin}>{t('collect.refund.ticket.header.on')}</span>
              <span>{createdAt}</span>
            </div>

            <div className={css.amountContainer}>
              <span className={`${css.currencySymbol} ${css.textMargin}`}>
                {t('collect.refund.ticket.header.currencySymbol')}
              </span>
              <span className={css.amountText}>{formatMoneyNoSign(paymentOrder.totalAmount)}</span>
            </div>

            <div className={css.memoContainer}>
              <span className={css.memoText}>{paymentOrder.title}</span>
              <span>{paymentOrder.linkNumberFormatted}</span>
            </div>
          </section>

          <section className={css.body}>
            <Grid className={css.amountInfoContainer}>
              <Grid container className={css.bodyInfoRow}>
                <Grid item xs={8}>
                  {t('collect.refund.ticket.body.refundAmountLabel')}
                </Grid>
                <Grid item xs={4} className={`${css.alignRight} ${css.errorText}`}>
                  -{formatMoney(paymentOrder.totalAmount)}
                </Grid>
              </Grid>
              <Grid container className={css.bodyInfoRow}>
                <Grid item xs={8}>
                  {t('collect.refund.ticket.body.totalBalanceLabel')}
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(css.alignRight, { [css.errorText]: !hasEnoughBalance })}
                >
                  {formatMoney(balance)}
                </Grid>
              </Grid>
            </Grid>

            <Grid className={css.totalContainer}>
              {hasEnoughBalance ? (
                <Grid container className={css.bodyInfoRow}>
                  <Grid item xs={8}>
                    {t('collect.refund.ticket.body.finalAmountLabel')}
                  </Grid>
                  <Grid item xs={4} className={css.alignRight}>
                    {formatMoney(balance - paymentOrder.totalAmount)}
                  </Grid>
                </Grid>
              ) : (
                <span className={css.insufficientBalanceText}>
                  {t('collect.refund.ticket.body.insufficientBalanceInfo')}
                </span>
              )}
            </Grid>
          </section>

          <section className={css.footer}>
            <div className={css.footerContainer}>
              <Grid container className={css.footerRow}>
                <Grid item xs={3} className={css.footerTitle}>
                  {t('collect.refund.ticket.footer.to')}
                </Grid>
                <Grid item xs={9} className={css.row}>
                  <span className={css.footerName}>
                    <strong>{paymentOrder.paymentFullName}</strong>
                  </span>
                  <span>{paymentOrder.paymentEmail}</span>
                </Grid>
              </Grid>
              <Grid container className={css.footerRow}>
                <Grid item xs={3} className={css.footerTitle}>
                  {t('collect.refund.ticket.footer.from')}
                </Grid>
                <Grid item xs={9}>
                  <span className={css.footerName}>
                    <strong>{name}</strong>
                  </span>
                </Grid>
              </Grid>
            </div>
          </section>
        </div>

        <section className={css.buttonContainer}>
          {hasEnoughBalance && (
            <Button
              label={t('collect.refund.ticket.oysterButtonLabel')}
              onClick={onRefund}
              data-testid="action-button"
              color="primary"
              disabled={false}
              type="submit"
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default RefundTicket;
