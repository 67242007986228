import { FC, useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

import { formatMoney } from '@yaydoo/react-components/lib/utils/common';
import CustomRow from '@yaydoo/react-components/lib/components/CustomRow';
import DepositIcon from '@yaydoo/react-components/lib/icons/Deposit';
import useToast from '@yaydoo/react-components/lib/hooks/useToast';

import useStore from '@hooks/useStore';
import { TDeposit } from '@store/deposits/models/Deposit';
import { DATE_FORMAT_V1 } from '@constants/date';
import { COLLECT_ROUTES } from '@constants/routes';
import useCommonStore from '@hooks/useCommonStore';
import EmptyListSkeleton from '@components/EmptyListSkeleton';
import InfiniteScroll from '@components/InfiniteScroll';

import DepositOptions from './DepositOptions';
import useStyles from './style';

const SecondaryText: FC<{ deposit: TDeposit }> = ({ deposit }) => {
  const css = useStyles();
  const { t } = useTranslation();
  return (
    <span>
      <span className={css.title} role="deposit-title">
        {t('collect.payments.deposits.depositId')} {deposit.displaySettlementId}
      </span>
      <span role="deposit-date">{moment(deposit.settledAt).format(DATE_FORMAT_V1)}</span>
    </span>
  );
};

const RightComponent: FC<{ deposit: TDeposit }> = ({ deposit }) => {
  const css = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row">
      <Grid item>
        <Grid container direction="column" className={css.textRight}>
          <Grid role="deposit-amount">{formatMoney(+deposit.displayTotalNetAmount)}</Grid>
          <Grid>
            <span role="deposit-status" className={css.badge}>
              {t('collect.payments.deposits.deposited')}
            </span>
          </Grid>
        </Grid>
      </Grid>
      {deposit.external && (
        <Grid item>
          <DepositOptions deposit={deposit} />
        </Grid>
      )}
    </Grid>
  );
};

const Deposits: FC = () => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { deposits: depositsStore } = useStore();
  const { openToast } = useToast();
  const { account } = useCommonStore();

  const { deposits, pagination, flags } = depositsStore;
  const { isLoadingDeposits } = flags;
  const lastFourDigits = account.clabe.slice(-4);
  const isEmptyList = !deposits.length;

  const clickListItemHandler = (deposit: TDeposit) => {
    history.push(`${COLLECT_ROUTES.DEPOSIT_DETAILS}/${deposit.settlementId}`);
  };

  useEffect(() => {
    const getDeposits = async () => {
      try {
        await depositsStore.getDeposits();
      } catch {
        openToast({ message: t('collect.common.error.message'), type: 'error' });
      }
    };

    getDeposits();

    return () => {
      depositsStore.clearDeposits();
    };
  }, []);

  if (isEmptyList) {
    return (
      <EmptyListSkeleton
        label={t('collect.payments.deposits.empty')}
        loadingLabel={t('collect.payments.deposits.loadingLabel')}
        isLoading={isLoadingDeposits}
      />
    );
  }

  return (
    <div className={css.root}>
      <div data-testid="deposit-info" className={css.info}>
        {t('collect.payments.deposits.info', { lastFourDigits })}
      </div>
      <InfiniteScroll
        next={depositsStore.getMoreDeposits}
        hasMore={pagination.hasMore}
        dataLength={deposits.length}
        scrollableTarget="list-container"
      >
        <List className={css.list}>
          {deposits.map((deposit) => (
            <CustomRow
              key={deposit.settlementId}
              data-testid="deposit-list-item"
              className={css.deposited}
              onClick={() => clickListItemHandler(deposit)}
              leftIcon={() => <DepositIcon className={css.icon} />}
              secondaryTextComponent={<SecondaryText deposit={deposit} />}
              rightComponent={<RightComponent deposit={deposit} />}
            />
          ))}
        </List>
      </InfiniteScroll>
    </div>
  );
};

export default observer(Deposits);
