const errors = {
  SERVICE_ERROR: 'SERVICE_ERROR',
  /* user erros */
  PROFILE_PIC: 'PROFILE_PIC',
  INTERCOM_HASH: 'INTERCOM_HASH',
  BUSINESS_DATA: 'BUSINESS_DATA',
  BUSINESS_ADDRESS: 'BUSINESS_ADDRESS',
  BALANCE: 'BALANCE',
  CLABE: 'CLABE',
  BENEFICIARIES: 'BENEFICIARIES',
  OTP_INIT: 'OTP_INIT',
  INTERACTIONS: 'INTERACTIONS',
  INTERACTIONS_BY_NAME: 'INTERACTIONS_BY_NAME',
  LAST_INTERACTION: 'LAST_INTERACTION',
  LOG_INTERACTION: 'LOG_INTERACTION',
  EXTERNAL_CLABE: 'EXTERNAL_CLABE',
  EXTERNAL_CLABE_SAVE: 'EXTERNAL_CLABE_SAVE',
  BANKNAME_BY_CLABE: 'BANKNAME_BY_CLABE',
  ACCESS_USER_TAGS: 'ACCESS_USER_TAGS',
  OVERALL_BUSINESS_STATUS: 'OVERALL_BUSINESS_STATUS',
};

const API_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const creditEndPoint = '/credit/api/v1';

export { errors, creditEndPoint, API_STATUS };
