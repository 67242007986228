import { FC, useEffect, useCallback } from 'react';
import useInterval from '@hooks/useInterval';
import debounce from 'lodash/debounce';

import ConfirmDialog from '@components/ConfirmDialog';

import { getCurrentTime } from './utils';
import useStyles from './styles';
import useATState from './hooks';

export const SHOW_MODAL_MINUTES = 9;
export const LOGOUT_MINUTES = 10;
export const UPDATETTL_MINUTES = 9.5;
export const MINUTES_TO_MILISECONDS = 1000 * 60;

export interface ActivityTrackerProps {
  showModalMinutes?: number;
  updateTtlMinutes?: number;
  logoutTimeoutDifference?: number;
  logoutText: string;
  ttlUpdateText: string;
  title: string;
  contentText: string;
  logout: () => void;
  updateTTL: () => Promise<void> | void;
}

const ActivityTracker: FC<ActivityTrackerProps> = ({
  showModalMinutes = SHOW_MODAL_MINUTES,
  updateTtlMinutes = UPDATETTL_MINUTES,
  logoutTimeoutDifference = LOGOUT_MINUTES,
  logoutText,
  ttlUpdateText,
  children,
  logout,
  updateTTL,
  title,
  contentText,
}) => {
  const {
    state,
    setModal,
    setSeconds,
    setUserInactive,
    resetInterval,
    setIntervalId,
    setLogoutTimestamp,
    setTimeoutId,
    resetTimeout,
  } = useATState();
  const css = useStyles();
  const currentTime = getCurrentTime();
  const { logoutTimestamp } = state;
  const logoutDifference = currentTime - logoutTimestamp;
  const logoutMinutes = logoutTimeoutDifference * 60;
  const INACTIVE_USER_TIME_LIMIT = showModalMinutes * MINUTES_TO_MILISECONDS;
  const UPDATE_TTL_MINUTES = updateTtlMinutes * MINUTES_TO_MILISECONDS;

  const userInactive = useCallback(() => {
    setUserInactive(true);
    setModal(true);
    if (state.interval) {
      clearInterval(state.interval);
      resetInterval();
    }
  }, [state]);

  const setTTLInterval = useCallback(() => {
    const intervalId = setInterval(() => {
      updateTTL();
    }, UPDATE_TTL_MINUTES);
    setIntervalId(Number(intervalId));
  }, [updateTTL, setIntervalId]);

  const setShowModalTimeout = useCallback(() => {
    const timeout = window.setTimeout(() => {
      userInactive();
    }, INACTIVE_USER_TIME_LIMIT);
    setTimeoutId(timeout);
  }, [userInactive, setTimeoutId]);

  const resetUserActivityTimeout = useCallback(
    async (uiReset = false) => {
      if (uiReset) setModal(false);
      if (!state.modal) {
        await updateTTL();
        if (state.timeout) {
          clearTimeout(state.timeout);
          resetTimeout();
        }
        setUserInactive(false);
        setLogoutTimestamp(getCurrentTime());
        setShowModalTimeout();
      }
    },
    [state, userInactive],
  );

  const trackActivity = debounce(() => {
    setLogoutTimestamp(getCurrentTime());
  }, 400);

  /**
   * Resets the timer for the modal
   */
  useEffect(() => {
    if (!state.modal) {
      setSeconds(60);
    }
  }, [state.modal]);

  /**
   * Logs out the user when is inactive and surpases the differnce
   */
  useEffect(() => {
    // LogoutUseEffect: add 5 seconds, in case dashboard trackActivity func caches activity
    if (state.userInactive && logoutDifference >= logoutMinutes + 5) {
      logout();
    }
  }, [state.userInactive, logoutDifference, logoutMinutes]);

  /**
   * Sets the interval to refresh the ttl
   */
  useEffect(() => {
    setTTLInterval();
    setShowModalTimeout();
  }, []);

  /**
   * Updates the timer for the modal and logs out the user before 1 minutes has passed
   */
  useInterval(() => {
    if (state.modal) {
      const remainingTime = Math.floor(logoutMinutes - logoutDifference);
      // if phone is blocked, JS is not updated, so counter freezes, with this
      // the second counter will be update to what actually is remaining
      let newSecs = state.seconds - 1;
      newSecs = remainingTime < newSecs ? remainingTime : newSecs;
      setSeconds(newSecs);
    }

    if (state.seconds <= 0) logout();
  }, 1000);

  useInterval(() => {
    const currentTimeStamp = getCurrentTime();
    // Set user as inactive if 5 seconds have passed from the last
    // logoutTimestamp (set everytime the user is active) plus the modal timeout (second digits)
    // if user is active before that period, the logoutTimestamp will update and this wont get called
    // also if use is active 5 seconds from userInactive set, trackActivity func will revert it
    // to handle mobile browsers deactivating JS on phone block
    if (currentTimeStamp - logoutTimestamp >= showModalMinutes * 60 + 5 && !state.userInactive) {
      userInactive();
    }
  }, 1000);

  return (
    <>
      <ConfirmDialog
        open={state.modal}
        disableBackdropClick
        disableEscapeKeyDown
        message={contentText}
        title={title}
        onConfirm={() => resetUserActivityTimeout(true)}
        confirmButtonText={ttlUpdateText}
        onCancel={logout}
        cancelButtonText={logoutText}
      />
      <div
        id="activity-tracker"
        className={css.root}
        onMouseMove={trackActivity}
        onKeyDown={trackActivity}
        onClick={trackActivity}
        onTouchStart={trackActivity}
      >
        {children}
      </div>
    </>
  );
};

export default ActivityTracker;
