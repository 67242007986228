import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.oysterTheme.pearl,
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        marginTop: 100,
      },
    },
  });
});

export default styles;
