import { ElementType, FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CopyVariant from '@icons/CopyVariant';
import { IconButton } from '@material-ui/core';

import useStyles from './styles';

export type bankDataElement = {
  label: string | ElementType;
  value: string | number;
  onCopy: () => void;
};

export interface IBankData {
  bankDataList: bankDataElement[];
  className?: string;
}

const BankData: FC<IBankData> = ({ bankDataList, className }) => {
  const css = useStyles();

  return (
    <div className={className}>
      {bankDataList.map(({ label, onCopy, value }, index) => (
        <div
          className={`${css.containerBankElement} ${index === 0 ? css.firstElement : ''}`}
          key={`bank-item-${index}`}
        >
          <div className={css.label}>{label}</div>
          <div className={css.referenceData}>
            <span>{value}</span>
            <CopyToClipboard onCopy={onCopy} text={`${value}`}>
              <IconButton size="small" className={css.copyButton}>
                <CopyVariant />
              </IconButton>
            </CopyToClipboard>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BankData;
