export enum AddressType {
  UNKNOWN_ADDRESS = 0,
  WORK = 1,
  HOME = 2,
  BILLING = 3,
  SHIPPING = 4,
  LEGAL = 5,
  TAX = 6,
  PERSONAL = 7,
}
