import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    btn: {
      border: `1px dashed ${theme.oysterTheme.stone}`,
      backgroundColor: theme.oysterTheme.pearl,
      height: 55,
      color: theme.oysterTheme.shark,
      justifyContent: 'space-between',
      width: '100%',
      '& span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    error: {
      borderColor: theme.oysterTheme.ferrari,
    },
    success: {
      color: theme.oysterTheme.brandGreen,
      '& span': {
        color: theme.oysterTheme.pine,
      },
    },
    icon: {
      transform: 'rotate(45deg)',
      fontSize: 18,
      color: theme.oysterTheme.pine,
      marginLeft: theme.spacing(1),
    },
    checkIcon: {
      fontSize: 18,
      marginRight: theme.spacing(1),
    },
    checkIconContainer: {
      display: 'flex',
      maxWidth: '90%',
      alignItems: 'center',
    },
    root: {
      width: '100%',
      maxWidth: '100%',
    },
  });
});

export default styles;
