import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useLabelStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: '14px',
      color: theme.oysterTheme.charcoal,
    },
  }),
);

export const useCheckBoxStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '42px',
      height: '42px',
      padding: '9px',
    },
    checked: {
      '& svg': {
        color: theme.oysterTheme.oyster,
      },
    },
    disabled: {
      '& svg': {
        color: theme.oysterTheme.dolphin,
      },
    },
  }),
);
