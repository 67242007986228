import { FC, useRef, useEffect } from 'react';
import BaseInfiniteScroll from 'react-infinite-scroll-component';
import Refresh from '@material-ui/icons/Refresh';

import { DefaultIndicatorProps, InfinityScrollProps } from '@components/InfiniteScroll/types';
import useBreakpoints from '@hooks/useBreakpoints';
import useIsOnScreen from '@hooks/useIsOnScreen';
import useStyles from '@components/InfiniteScroll/styles';
import Loading from '@components/Loading';

const LoaderComponent: FC<DefaultIndicatorProps> = ({ customIndicator, label, callback }) => {
  const css = useStyles();
  const ref = useRef(null);
  const isOnScreen = useIsOnScreen(ref);

  useEffect(() => {
    if (isOnScreen && callback) callback();
  }, [isOnScreen]);

  return (
    <div data-testid="loader-wrapper" ref={ref}>
      {customIndicator ? customIndicator : <Loading className={css.loader} />}
      {label}
    </div>
  );
};

const RefreshContent: FC<DefaultIndicatorProps> = ({ customIndicator, label }) => {
  const css = useStyles();
  return (
    <div data-testid="pull-down-to-refresh-wrapper" className={css.refreshWrapper}>
      {customIndicator ? customIndicator : <Refresh className={css.refresh} />}
      {label}
    </div>
  );
};

const InfiniteScroll: FC<InfinityScrollProps> = ({
  children,
  loader,
  loaderLabel,
  pullDownToRefreshContent: refresh,
  refreshLabel,
  scrollableTarget,
  next,
  ...props
}) => {
  const { isTouchScreen } = useBreakpoints();

  return (
    <BaseInfiniteScroll
      {...props}
      next={next}
      scrollableTarget={isTouchScreen ? undefined : scrollableTarget}
      loader={<LoaderComponent label={loaderLabel} customIndicator={loader} callback={next} />}
      pullDownToRefreshContent={<RefreshContent label={refreshLabel} customIndicator={refresh} />}
    >
      {children}
    </BaseInfiniteScroll>
  );
};

export default InfiniteScroll;
