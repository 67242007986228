import { addI18nNamespace } from '@yaydoo/react-components/lib';
import en from '@translations/en';
import es from '@translations/es';

const addTranslationFiles = (): void => {
  addI18nNamespace('en', 'paymentTools', en);
  addI18nNamespace('es', 'paymentTools', es);
};

export default addTranslationFiles;
