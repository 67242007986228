import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    container: {
      display: 'grid',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'minmax(70%, 1fr) minmax(auto, 350px)',
      },
      maxHeight: '100vh',
      width: '100%',
    },
    leftComponent: {
      flexBasis: 'unset',
      flexGrow: 'unset',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: '100vh',
        overflowY: 'scroll',
      },
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    mobileHeader: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    rightComponent: {
      maxWidth: '100%',
      flexBasis: 'unset',
      flexGrow: 'unset',
      maxHeight: 'fit-content',
      [theme.breakpoints.up('md')]: {
        maxHeight: '100vh',
        overflow: 'hidden',
      },
    },
  });
});

export default styles;
