import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => {
  return createStyles({
    downloadLink: {
      '& svg:first-child': { fontSize: 18 },
    },
  });
});

export default styles;
