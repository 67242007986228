import { FC } from 'react';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';
import { IListItemSkeletonProps } from './types';

const ListItemSkeleton: FC<IListItemSkeletonProps> = ({ animation = 'pulse' }) => {
  const css = useStyles();
  return (
    <div className={css.item}>
      <div className={css.avatarWrapper}>
        <Skeleton
          role="item-avatar"
          className={css.avatar}
          variant="rect"
          width={30}
          height={30}
          animation={animation}
        />
      </div>
      <div className={css.itemContent}>
        <Skeleton className={css.line} role="item-line" variant="rect" animation={animation} />
        <Skeleton
          className={clsx(css.line, 'short')}
          role="item-line"
          variant="rect"
          animation={animation}
        />
      </div>
    </div>
  );
};

export default ListItemSkeleton;
