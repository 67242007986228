import { FC } from 'react';
import { IconProps, defaultIconColor } from './types';

const ProfileIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9881 23.3937C23.5381 21.4062 20.9692 20.4375 20.0816 20.125C19.1066 19.7812 17.719 19.7 16.8252 19.5C16.3127 19.3875 15.5689 19.1062 15.3189 18.8062C15.0689 18.5063 15.2189 15.725 15.2189 15.725C15.2189 15.725 15.6814 15.0062 15.9314 14.3687C16.1815 13.7375 16.4565 12 16.4565 12C16.4565 12 16.969 12 17.1502 11.1C17.344 10.1188 17.6503 9.7375 17.6128 9.00625C17.5753 8.2875 17.1815 8.30625 17.1815 8.30625C17.1815 8.30625 17.5628 7.2625 17.6065 5.1C17.6628 2.53125 15.6502 0 12.0062 0C8.31231 0 6.34347 2.53125 6.39972 5.1C6.44972 7.2625 6.81849 8.30625 6.81849 8.30625C6.81849 8.30625 6.42472 8.2875 6.38722 9.00625C6.34972 9.7375 6.65598 10.1188 6.84974 11.1C7.02475 12 7.54353 12 7.54353 12C7.54353 12 7.81854 13.7375 8.06855 14.3687C8.31856 15.0062 8.78109 15.725 8.78109 15.725C8.78109 15.725 8.93109 18.5063 8.68108 18.8062C8.43107 19.1062 7.68728 19.3875 7.17476 19.5C6.28096 19.7 4.8934 19.7812 3.91835 20.125C3.0308 20.4375 0.461928 21.4062 0.0119054 23.3937C-0.056848 23.7062 0.180664 24 0.50568 24H23.5006C23.8193 24 24.0568 23.7062 23.9881 23.3937Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

ProfileIcon.defaultProps = {
  fill: defaultIconColor,
};

export default ProfileIcon;
