import { ElementType, FC, ChangeEvent } from 'react';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import Commission from '@icons/Commission';
import { ArrowDown } from '@components/Select';
import GoIcon from '@icons/Arrows/Go';
import { formatMoney } from '@utils/common';

import useStyles from './styles';

export interface IExtraButton {
  label: string;
  onClick: () => void;
}

export interface IOption {
  label: string;
  icon: ElementType;
  amount?: number | string;
  value: number | string;
  menuItemId?: string;
}

export type OnSidebarAccountChange = ChangeEvent<{ name?: string; value: unknown }>;

export interface SidebarAccountProps extends SelectProps {
  options: IOption[];
  extraButton?: IExtraButton;
  value: number | string;
  displayExtraButton?: boolean;
  disabled?: boolean;
}

const SidebarAccount: FC<SidebarAccountProps> = ({
  options,
  extraButton,
  value = 0,
  displayExtraButton,
  disabled = false,
  ...props
}) => {
  const css = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={css.container}>
      <Select
        disabled={disabled}
        value={value}
        fullWidth
        className={css.select}
        IconComponent={disabled ? () => <div className={css.disabled} /> : ArrowDown}
        disableUnderline
        defaultValue={options[0].label}
        data-testid="select"
        {...props}
      >
        {options.map(({ value: itemValue, label, icon: Icon = Commission, amount, menuItemId }) => {
          const formattedAmount = typeof amount === 'number' ? formatMoney(amount) : amount;

          return (
            <MenuItem key={label} id={menuItemId} value={itemValue}>
              <div className={css.option}>
                <Icon className={css.icon} />
                <div className={css.content}>
                  <span className={css.label}>{label}</span>
                  {amount && <span className={css.amount}>{formattedAmount}</span>}
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
      {displayExtraButton && extraButton && (
        <Button className={css.extraButton} onClick={extraButton.onClick}>
          {extraButton.label}
          {!isSmallScreen && <GoIcon className={css.rightArrow} />}
        </Button>
      )}
    </div>
  );
};

export default SidebarAccount;
