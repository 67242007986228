import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => {
  return createStyles({
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      letterSpacing: '0.02em',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.oysterTheme.oyster,
    },
  });
});

export default styles;
