import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpChaContainer: {
      width: '100%',
      fontSize: 16,
    },
    phoneNumber: {
      color: theme.oysterTheme.oyster,
    },
    title: {
      color: theme.oysterTheme.shark,
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      fontSize: '12px',
      fontWeight: 600,
    },
    footer: {
      color: theme.oysterTheme.shark,
      fontSize: 12,
    },
    icon: {
      fontSize: 17,
      width: 17,
      height: 17,
      color: theme.oysterTheme.pine,
      fill: theme.oysterTheme.pine,
    },
    option: {
      '& span': {
        fontWeight: 500,
        fontSize: '13.5px',
        color: theme.oysterTheme.charcoal,
      },
      '& p > span': {
        fontWeight: 400,
        fontSize: '12px',
        color: theme.oysterTheme.shark,
      },
      '& .MuiListItemIcon-root': {
        minWidth: '2.2em',
        height: 'auto',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  }),
);

export default useStyles;
