import { MouseEvent, ReactNode, FC, ElementType } from 'react';
import { FileRejection } from 'react-dropzone';
import { IconProps } from '@icons/types';

export enum UploadState {
  uploading = 'UPLOADING',
  uploaded = 'UPLOADED',
  error = 'ERROR',
  default = 'DEFAULT',
}

export interface OnDrop {
  files: File[];
  rejectedFiles?: FileRejection[];
}

export type OnDeleteEvent = MouseEvent<HTMLButtonElement>;

export interface UploaderProps {
  uploadState?: UploadState;
  label?: string;
  accept?: string;
  file?: File;
  onUpload?: (args: OnDrop) => void;
  onDrop?: (args: OnDrop) => void;
  /**
   *  @deprecated use onUploadedFileRemove instead
   * */
  deleteFile?: (e: OnDeleteEvent) => void;
  onFileRemove?: (e: OnDeleteEvent) => void;
  onUploadedFileRemove?: (e: OnDeleteEvent) => void;
  Component?: ReactNode;
  multiple?: boolean;
  minSize?: number;
  maxSize?: number;
  controlledUpload?: boolean;
  className?: string;
  atachIcon?: ElementType | FC<IconProps>;
}
