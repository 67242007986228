import { FC, useState } from 'react';

import CloseIcon from '@yaydoo/react-components/lib/icons/Close';
import AddIcon from '@yaydoo/react-components/lib/icons/AddPlain';

import useStyles from './styles';

interface IUploaderComponent {
  title: string | JSX.Element;
  file?: string;
  onDelete?: (evt: React.MouseEvent) => void;
}

const UploaderComponent: FC<IUploaderComponent> = ({ title, file, onDelete }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const css = useStyles();

  const PlaceholderImage = () => (
    <div className={css.addIcon} data-testid="addIcon">
      <AddIcon />
    </div>
  );

  return (
    <div className={css.customUploader}>
      <div className={css.title}>{title}</div>
      <div>
        {file ? (
          <div className={css.imgWrapper} data-testid="fileWrapper">
            <div className={css.closeWrapper}>
              <CloseIcon className={css.close} onClick={onDelete} data-testid="delete" />
              {!isLoaded && <PlaceholderImage />}
              <img src={file} alt="logo" onLoad={() => setIsLoaded(true)} />
            </div>
          </div>
        ) : (
          <PlaceholderImage />
        )}
      </div>
    </div>
  );
};

export default UploaderComponent;
